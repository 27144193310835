import React from "react";
import { Dropdown, Label } from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import { PayPerDataContext } from "../../../services/payperDataContext.js";

const options = [
  {
    key: "DRI6",
    text: "Scarcity",
    value: "DRI6",
    description:
      "There is not enough of it for the people who need it or want it.",
  },
  {
    key: "DRI7",
    text: "Ownership",
    value: "DRI7",
    description: "The state or fact of being an owner.",
  },
  {
    key: "DRI5",
    text: "Avoidance",
    value: "DRI5",
    description: " The act of keeping away from or preventing from happening",
  },
  {
    key: "DRI4",
    text: "Unpredictability",
    value: "DRI4",
    description: "Something not predictable; not to be foreseen or foretold",
  },
  {
    key: "DRI3",
    text: "Social Influence",
    value: "DRI3",
    description:
      "A person's attitudes or behaviour are altered by some form of social communication.",
  },
  {
    key: "DRI2",
    text: "Empowerment",
    value: "DRI2",
    description:
      "The giving or delegation of power or authority; authorization",
  },
  {
    key: "DRI1",
    text: "Meaning",
    value: "DRI1",
    description:
      "The inner, symbolic, or true interpretation, value. Intending; having purpose",
  },
  {
    key: "DRI8",
    text: "Acomplishment",
    value: "DRI8",
    description: "The successful achievement of a task.",
  },
];

const PayPerDataDrivers = () => {

  const renderLabel = (label) => ({
    content: `${label.text}`,
  });

  const [metricsState, dispatchMetrics] = useContext(PayPerDataContext);
  const [values, setValues] = useState([]);
  
  const saveOptionsToContext = (e, { value }) => {
    console.log("saveOptionsToContext:value", value);
    setValues(value);
    dispatchMetrics({ type: "SET_SUPEREMOS_DRIVERS", payload: value });
  };

  /*
   metricsState.effectiveFilters={
      hashtags:[],
      superEmos:[],
      drivers:[],
      dateRange:{init:null,end:null},
      hoursRange:[],
      locations:[],
      planetids:[]
    }
  */
  useEffect(() => {

    console.log("PayPerDataDrivers:metricsState", metricsState.effectiveFilters);
    
    if (metricsState.effectiveFilters && metricsState.effectiveFilters.drivers) {
      setValues(metricsState.effectiveFilters.drivers);
    }
    
  }, [metricsState.effectiveFilters]);



  return (
    <>
      <label style={{ width: "190px" }}>Drivers </label>
      <Dropdown
        fluid
        openOnFocus
        search
        clearable
        placeholder="Search"
        multiple
        selection
        options={options}
        renderLabel={renderLabel}
        onChange={saveOptionsToContext}
        value={values}
      />
    </>
  );
};

export default PayPerDataDrivers;
