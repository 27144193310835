import { getUserDevice } from './device';
import { showVars } from './initVarsAndFunctions';



export const initApp = () => {
    console.log("\n\n\n\n\n\n\n\n");
    console.log("------init App ----");
    console.log(showVars());
    console.log("------init App ----");
    console.log("\n\n\n\n\n\n\n\n");
    getUserDevice();
}
