import React from "react";
import { Card, Label } from "semantic-ui-react";

const TotalsStatisticBox = (props) => {
  return (
    <>
      <Card fluid>
        <Card.Content>
          <Label attached='top'>{props.dataRibbon}</Label>
          {/* <Card.Description>{props.content}</Card.Description> */}
          <Card.Header>{props.value}</Card.Header>
        </Card.Content>
      </Card>
    </>
  );
};

export default TotalsStatisticBox;
