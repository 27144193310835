import { List } from "semantic-ui-react";
import "./Emotion.scss";

console.log("EMOTION-COMPONENT-MODULE-INIT");

const Emotion = (props) => {

  console.log("---EMOTION-COMPONENT-INIT---");

  // console.log(props.value / 20);
  return (
    <>
      <List>
        <List.Item>
          <List.Content floated='right'>
            {((props.value / 20 / 6) * 100).toFixed(2)} %
          </List.Content>
          <List.Content className="emotion__name">{props.emotion}</List.Content>
        </List.Item>
      </List>
    </>
  );
};

export default Emotion;
