const wsBlk = "wss://71dawbae3k.execute-api.eu-west-3.amazonaws.com/production"
//             wss://71dawbae3k.execute-api.eu-west-3.amazonaws.com/production

console.log("WSSUBSCRIPTION-MODULE-INIT");

export function wsSubscriptionQuery(setQueryResponse) {

    this.connectionId = " ";
    this.hasConnectionId = false;
    var opened = false;
    var arraySends = [];
    var w;

    var countsockets = 0;
    var scope = this;
    this.dreceive = null;


    
    connect();

    function connect() {
        countsockets++;
        console.log("$sq$sq$sq AWS SUBSCRIPTIONQUERY CONNECTION: %%% COUNT SOCKET CONNECTIONS:"+countsockets)
        w = new WebSocket(wsBlk);

        w.onopen = function (event) {
            console.log("$sq$sq$sq AWS SUBSCRIPTIONQUERY CONNECTION: ON_OPEN:",event, "COUNT CONECTION SOCKET=" +countsockets);

            opened = true;
            w.send("{ERROR}");
            
            if (arraySends.length > 0) {
                for (let i = 0; i < arraySends.length; i++) {
                    var send = arraySends[i];
                    w.send(send);
                    console.log("$sq$sq$sq AWS SUBSCRIPTIONQUERY CONNECTION: ARRAY SENDING DATA...",send);
                }
                arraySends.length = 0;

            }
            //setTimeout(function () {
            //    w.close();
            //}, 1500000);
        }

        w.onclose = function (event) {
            opened = false;
            console.log('$sq$sq$sq AWS SUBSCRIPTIONQUERY CONNECTION: Socket is closed.'); 
            //setTimeout(function () {
            //    connect();
            //}, 1000);
        }

        w.onmessage = function (event) {
            console.log("$sq$sq$sq AWS SUBSCRIPTIONQUERY CONNECTION: ON_MESSAGE, receive:")
            //console.log(event.data);

            console.log("$sq$sq$sq AWS SUBSCRIPTIONQUERY CONNECTION: PARSING in DRECEIVE");
            var dreceive = JSON.parse(event.data);
            //console.log(dreceive);
            this.dreceive = dreceive;
            setQueryResponse(dreceive);


            if (!scope.hasConnectionId && event.data.includes("connectionId")) {
                console.log("$sq$sq$sq AWS SUBSCRIPTIONQUERY CONNECTION: CONNECTION_ID_CATCHING:");
                scope.connectionId = dreceive.connectionId;
                console.log("$sq$sq$sq AWS SUBSCRIPTIONQUERY CONNECTION: ConnectionId=",scope.connectionId);
                scope.hasConnectionId = true;
            }
          

            if(
                dreceive.functionLambda === "create_subscription" || 
                dreceive.functionLambda === "get_active_subscription"
              )
            {
                console.log("$sq$sq$sq AWS SUBSCRIPTIONQUERY PREPARE TO CLOSE");
                w.close();
            }
           
            
        }

        w.onerror = function (error) {
            console.log("$sq$sq$sq AWS SUBSCRIPTIONQUERY CONNECTION: [error] ", error.message);
            w.close()
        }
    }

    this.send = function (data) {
        
        if (!opened) arraySends.push(data);
        else {
            w.send(data);
            console.log("$sq$sq$sq AWS SUBSCRIPTIONQUERY CONNECTION: SENDING DATA...",data);
                   
        }
    }

    
}