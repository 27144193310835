const wsBlk = "wss://71dawbae3k.execute-api.eu-west-3.amazonaws.com/production"
//             wss://71dawbae3k.execute-api.eu-west-3.amazonaws.com/production

console.log("WSBLOCKCHAIN-MODULE-INIT");

export function wsBlockChain() {

    this.connectionId = " ";
    this.hasConnectionId = false;
    var opened = false;
    var arraySends = [];
    var w;

    var countsockets = 0;
    var scope = this;
    this.dreceive = null;

    connect();

    function connect() {
        countsockets++;
        console.log("B$B$B$B$ AWS BLOCKCHAIN CONNECTION: %%% COUNT SOCKET CONNECTIONS:"+countsockets)
        w = new WebSocket(wsBlk);

        w.onopen = function (event) {
            console.log("B$B$B$B$ AWS BLOCKCHAIN CONNECTION: ON_OPEN:",event, "COUNT CONECTION SOCKET=" +countsockets);

            opened = true;
            w.send("{ERROR}");
            if (arraySends.length > 0) {
                for (let i = 0; i < arraySends.length; i++) {
                    var send = arraySends[i];
                    w.send(send);
                    console.log("B$B$B$B$ AWS BLOCKCHAIN CONNECTION: ARRAY SENDING DATA...",send);
                }
                arraySends.length = 0;

            }
            //setTimeout(function () {
            //    w.close();
            //}, 1500000);
        }

        w.onclose = function (event) {
            opened = false;
            console.log('B$B$B$B$ AWS BLOCKCHAIN CONNECTION: Socket is closed. Reconnect will be attempted in 1 second.', event.reason);
            //setTimeout(function () {
            //    connect();
            //}, 1000);
        }

        w.onmessage = function (event) {
            console.log("B$B$B$B$ AWS BLOCKCHAIN CONNECTION: ON_MESSAGE, receive:")
            console.log(event.data);

            console.log("B$B$B$B$ AWS BLOCKCHAIN CONNECTION: PARSING in DRECEIVE");
            var dreceive = JSON.parse(event.data);
            console.log(dreceive);
            this.dreceive = dreceive;


            if (!scope.hasConnectionId && event.data.includes("connectionId")) {
                console.log("B$B$B$B$ AWS BLOCKCHAIN CONNECTION: CONNECTION_ID_CATCHING:");
                scope.connectionId = dreceive.connectionId;
                console.log("B$B$B$B$ AWS BLOCKCHAIN CONNECTION: ConnectionId=",scope.connectionId);
                scope.hasConnectionId = true;
            }
            return dreceive;
        }

        w.onerror = function (error) {
            console.log("B$B$B$B$ AWS BLOCKCHAIN CONNECTION: [error] ", error.message);
            w.close()
        }
    }

    this.send = function (data) {
        if (!opened) arraySends.push(data);
        else {
            w.send(data);
            console.log("B$B$B$B$ AWS BLOCKCHAIN CONNECTION: SENDING DATA...",data);        
        }
    }
}