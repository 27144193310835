import { Subject } from "rxjs";
import { getUserBalance, getUserData } from "./userWallet";
import { getUserAWS } from "./Graphql/User/queryUser";
import { updateUserSessions } from "./Graphql/User/updateUser";

console.log("USERPARAMS-MODULE-INIT");

const userSubject = new Subject();
const galaxySubject = new Subject();

export const userService = {
  setUser: (user) => userSubject.next(user),
  getUserData: () => userSubject.asObservable(),
  setGalaxy: (galaxy) => galaxySubject.next(galaxy),
  getGalaxy: () => galaxySubject.asObservable(),
};

const setUserData = (user) => {
  userService.setUser(user);
};

export const setGalaxyData = (galaxy) => {
  userService.setGalaxy(galaxy);
};

export var userId = undefined;
export const updateUserId = (newData) => {
  userId = newData;
};
export const userDefault = {
  noAvatar: "https://seekicon.com/free-icon-download/header-user_1.svg",
  items: 0,
  payxdata: false,
  hashxmatic: false,
  balance: 0,
  config: {
    networkId: undefined,
  },
  tokensOfOwner: {
    owner: "",
    tokens: [],
  },
  userAddressId: undefined,
  user: undefined,
  joined: "1/Jan/2021",
  avatarLink: "",
  backgroundImageLink: "",
  userDescription: "",
  publicProfile: false,
  myGalaxyName: "",
};
export var userData = JSON.parse(JSON.stringify(userDefault));

export var nextTokenList = [];
export const resetNextTokenList = () => {
  nextTokenList = [];
};

export var connectionData = {};

export const setUser = (newData) => {
  // console.log(newData);
  if (newData) {
    updateUserId(newData.userAddressId);
    if (userId) {
      sessionStorage.setItem("address", userId);
    }
    if (newData.session) {
      sessionStorage.setItem("session", newData.session);
    }

    userData.userDescription = newData.userDescription; // userProfile.data.getMasterUser.userDescription,
    userData.userAddressId = newData.userAddressId; // userProfile.data.getMasterUser.userAddressId
    userData.balance = newData.balance; // contracts.balance
    userData.user = newData.user; //userProfile.data.getMasterUser.user,
    userData.joined = newData.joined; //userProfile.data.getMasterUser.joined,
    userData.publicProfile = newData.publicProfile; //userProfile.data.getMasterUser.publicProfile,
    userData.myGalaxyName = newData.myGalaxyName; //userProfile.data.getMasterUser.myGalaxyName,
    userData.avatarLink = newData.avatarLink; // userProfile.data.getMasterUser.avatarLink,
    userData.backgroundImageLink = newData.backgroundImageLink; //
    userData.userDescription = newData.userDescription; //userProfile.data.getMasterUser.userDescription,
    userData.email = newData.email; //userProfile.data.getMasterUser.email,
    userData.realName = newData.realName; // userProfile.data.getMasterUser.user,
    userData.webAddress = newData.webAddress; //userProfile.data.getMasterUser.webAddress,
    userData.twitterAccount = newData.twitterAccount; //userProfile.data.getMasterUser.twitterAccount,
    userData.instagramAccount = newData.instagramAccount; // userProfile.data.getMasterUser.instagramAccount,
    userData.discordAccount = newData.discordAccount; // userProfile.data.getMasterUser.discordAccount,
    userData.redditAccount = newData.redditAccount; // userProfile.data.getMasterUser.redditAccount,
    userData.sessions = newData.sessions; //userSummary.data.getMasterUser.currentSessionIds,
    userData.tags = newData.tags; //userProfile.data.getMasterUser.tags,
    userData.hashtags = newData.hashtags; //userSummary.data.getMasterUser.currentHashtags,
    userData.currentFavoritePlanetIds = newData.currentFavoritePlanetIds; //userSummary.data.getMasterUser.currentFavoritePlanetIds,
    userData.currentFavoritePlanetIdsCount =
      newData.currentFavoritePlanetIdsCount; //userSummary.data.getMasterUser.currentFavoritePlanetIdsCount
    userData.subscriptionIds = newData.subscriptionIds; // userSummary.data.getMasterUser.subscriptionIds,
    userData.subscriptionKeys = newData.subscriptionKeys; // userSummary.data.getMasterUser.subscriptionKeys,
    userData.queryHashes = newData.queryHashes; // userSummary.data.getMasterUser.queryHashes,
    userData.currentPublicPlanetIds = newData.currentPublicPlanetIds; // userSummary.data.getMasterUser.currentPublicPlanetIds,
    userData.currentPublicPlanetsCount = newData.currentPublicPlanetsCount; // userSummary.data.getMasterUser.currentPublicPlanetsCount,
    userData.currentPlanetIds = newData.currentPlanetIds; // userSummary.data.getMasterUser.currentPlanetIds
    userData.currentPlanetIdsCount = newData.currentPlanetIdsCount; // userSummary.data.getMasterUser.currentPlanetIdsCount,
    userData.currentHashtagsCount = newData.currentHashtagsCount; // userSummary.data.getMasterUser.currentHashtagsCount,
    userData.currentLocations = newData.currentLocations; // userSummary.data.getMasterUser.currentLocations
  } else {
    userData = JSON.parse(JSON.stringify(userDefault));
  }
  setUserData(userData);
};

export const getUser = async (userId) => {
  const user = await getUserAWS(userId);
  if (user.data.listMasterUsers.items[0]) {
    user.data.listMasterUsers.items[0].noAvatar =
      "https://seekicon.com/free-icon-download/header-user_1.svg";
  }
  const userData = user.data.listMasterUsers.items[0];
  const balance = await getUserBalance(window.web3, userId);
  if (userData) {
    userData.balance = balance;
  }
  return userData;
};

export const getUserSigned = async (userId, sign) => {
  const user = await getUserAWS(userId, sign);
  console.log("GETUSERSIGNED", user);
  if (user.data.listMasterUsers && user.data.listMasterUsers.items[0]) {
    user.data.listMasterUsers.items[0].noAvatar =
      "https://seekicon.com/free-icon-download/header-user_1.svg";
  }
  const userData = user.data.listMasterUsers.items[0];
  const balance = await getUserBalance(window.web3, userId);
  if (userData) {
    userData.balance = balance;
  }
  setUser(userData);

  return userData;
};

const updateSessions = (session) => {
  var sessions = userData.sessions.replace(/ |\]|\[|/g, "");
  const sessionsArray = sessions.split(",");
  if (sessions !== "") {
    sessionsArray.unshift(session);

    updateUserSessions(userData.userAddressId, sessionsArray);
  }
};

export const setSession = (session) => {
  userData.session = session;
  setUser(userData);
  if (userData.sessions) {
    updateSessions(session);
  } else {
    updateUserSessions(userData.userAddressId, [session]);
  }
};
