
import Baselayout from "../theme/layouts/Baselayout";
import Home from "../pages/Home";
import MyGalaxy from "../pages/Galaxy";
import Marketplace from "../pages/Marketplace";
import Error404 from "../pages/Error404";
import Auth from "../pages/Auth";
import PlanetDetail from "../pages/PlanetDetail"
import MyProfile from "../pages/MyProfile"
import About from '../pages/Documentation/About';
import Terms from '../pages/Documentation/Terms';
import Policy from '../pages/Documentation/Policy/Policy';
import Metrics from '../pages/Metrics/Metrics';
import Pricing from '../pages/Pricing/Pricing';
import HowitWorks from '../pages/HowitWorks/HowitWorks';
import PayperData from '../pages/PayperData/PayperData';
import Queries from '../pages/Queries/Queries';
import PayPerDataMetrics from '../pages/PayPerDataMetrics/PayPerDataMetrics';


console.log("ROUTES-MODULE-INIT");

const routes = [
    {
        path: "/",
        layout: Baselayout,
        component: Home,
        exact: true,
        AuthRequired: false,
    },
    {
        path: "/login",
        layout: Baselayout,
        component: Auth,
        exact: true,
        AuthRequired: false,
    },
    {
        path: "/marketplace",
        layout: Baselayout,
        component: Marketplace,
        exact: true,
        AuthRequired: false,
    },
    {
        path: "/profile",
        layout: Baselayout,
        component: MyProfile,
        exact: true,
        AuthRequired: true,
    },
    {
        path: "/metrics",
        layout: Baselayout,
        component: Metrics,
        exact: true,
        AuthRequired: true,
    },
    {
        path: "/howitworks",
        layout: Baselayout,
        component: HowitWorks,
        exact: true,
        AuthRequired: false,
    },
    {
        path: "/payperdata",
        layout: Baselayout,
        component: PayperData,
        exact: true,
        AuthRequired: false,
    },
    {
        path: "/pricing",
        layout: Baselayout,
        component: Pricing,
        exact: true,
        AuthRequired: false,
    },
    {
        path: "/mygalaxy/:userName",
        layout: Baselayout,
        component: MyGalaxy,
        exact: true,
        AuthRequired: true,
    },
    {
        path: "/payperdatametrics/:metricsId",
        layout: Baselayout,
        component: PayPerDataMetrics,
        exact: true,
        AuthRequired: false,
    },
    {
        path: "/planetdetail/:planetDetail",
        layout: Baselayout,
        component: PlanetDetail,
        exact: true,
        AuthRequired: false,
    },
    {
        path: "/about",
        layout: Baselayout,
        component: About,
        exact: true,
        AuthRequired: false,
    },
    {
        path: "/policy",
        layout: Baselayout,
        component: Policy,
        exact: true,
        AuthRequired: false,
    },
    {
        path: "/terms",
        layout: Baselayout,
        component: Terms,
        exact: true,
        AuthRequired: false,
    },

    {
        path: "/queries",
        layout: Baselayout,
        component: Queries,
        exact: true,
        AuthRequired: true,
    },
   
    {
        path:"/hashtags/:hashtagsList",
        layout:Baselayout,
        component: Home,
        exact: true,
        AuthRequired: false,

    },

    {
        layout: Baselayout,
        component: Error404,
    },

];

export default routes;






