export const matrix_emo_driver=
[
    {key:"EMO1_0",emos:[
                        {key:"EMO1_1", drivers:[1,	1,	0,	0,	0,	0,	0,	1]},
                        {key:"EMO1_2", drivers:[1,	1,	0,	0,	0,	0,	0,	1]},
                        {key:"EMO1_3", drivers:[1,	0,	0,	0,	0,	0,	1,	1]},
                        {key:"EMO1_4", drivers:[1,	1,	0,	0,	0,	0,	0,	1]},
                        {key:"EMO1_5", drivers:[1,	1,	1,	0,	0,	0,	0,	0]},
                        {key:"EMO1_6", drivers:[1,	1,	1,	0,	0,	0,	0,	0]}
    ]},
    {key:"EMO2_0",emos:[
                        {key:"EMO2_1", drivers:[1,	1,	1,	0,	0,	0,	0,	0]},
                        {key:"EMO2_2", drivers:[1,	1,	1,	0,	0,	0,	0,	0]},
                        {key:"EMO2_3", drivers:[0,	1,	1,	1,	0,	0,	0,	0]},
                        {key:"EMO2_4", drivers:[0,	0,	1,	1,	1,	0,	0,	0]},
                        {key:"EMO2_5", drivers:[1,	1,	1,	0,	0,	0,	0,	0]},
                        {key:"EMO2_6", drivers:[1,	0,	0,	0,	0,	0,	1,	1]}
    ]},
    {key:"EMO3_0",emos:[
                        {key:"EMO3_1", drivers:[1,	1,	1,	0,	0,	0,	0,	0]},
                        {key:"EMO3_2", drivers:[1,	1,	1,	0,	0,	0,	0,	0]},
                        {key:"EMO3_3", drivers:[1,	0,	0,	0,	0,	0,	1,	1]},
                        {key:"EMO3_4", drivers:[1,	1,	0,	0,	0,	0,	0,	1]},
                        {key:"EMO3_5", drivers:[1,	0,	0,	0,	0,	0,	1,	1]},
                        {key:"EMO3_6", drivers:[1,	0,	0,	0,	0,	0,	1,	1]}
    ]},
    {key:"EMO4_0",emos:[
                        {key:"EMO4_1", drivers:[1,	1,	0,	0,	0,	0,	0,	1]},
                        {key:"EMO4_2", drivers:[0,	0,	0,	1,	1,	0,	0,	0]},
                        {key:"EMO4_3", drivers:[1,	1,	0,	0,	0,	0,	0,	0]},
                        {key:"EMO4_4", drivers:[0,	0,	0,	1,	0,	0,	0,	0]},
                        {key:"EMO4_5", drivers:[0,	0,	0,	1,	0,	0,	0,	0]},
                        {key:"EMO4_6", drivers:[0,	0,	1,	1,	1,	0,	0,	0]}
    ]},
    {key:"EMO5_0",emos:[
                        {key:"EMO5_1", drivers:[0,	1,	1,	1,	0,	0,	0,	0]},
                        {key:"EMO5_2", drivers:[0,	1,	0,	0,	0,	0,	0,	0]},
                        {key:"EMO5_3", drivers:[0,	1,	0,	0,	0,	0,	0,	0]},
                        {key:"EMO5_4", drivers:[1,	0,	0,	0,	0,	0,	0,	1]},
                        {key:"EMO5_5", drivers:[1,	1,	0,	0,	0,	0,	0,	1]},
                        {key:"EMO5_6", drivers:[1,	1,	1,	0,	0,	0,	0,	0]}
    ]},
    {key:"EMO6_0",emos:[
                        {key:"EMO6_1", drivers:[1,	1,	0,	0,	0,	0,	0,	1]},
                        {key:"EMO6_2", drivers:[1,	1,	0,	0,	0,	0,	0,	1]},
                        {key:"EMO6_3", drivers:[1,	1,	0,	0,	0,	0,	0,	1]},
                        {key:"EMO6_4", drivers:[1,	1,	0,	0,	0,	0,	0,	1]},
                        {key:"EMO6_5", drivers:[1,	1,	0,	0,	0,	0,	0,	1]},
                        {key:"EMO6_6", drivers:[0,	1,	0,	0,	0,	0,	0,	0]}
    ]},
    {key:"EMO7_0",emos:[
                        {key:"EMO7_1", drivers:[1,	1,	0,	0,	0,	0,	0,	0]},
                        {key:"EMO7_2", drivers:[1,	1,	0,	0,	0,	0,	0,	0]},
                        {key:"EMO7_3", drivers:[1,	1,	0,	0,	0,	0,	0,	0]},
                        {key:"EMO7_4", drivers:[1,	0,	0,	0,	0,	0,	0,	0]},
                        {key:"EMO7_5", drivers:[1,	1,	0,	0,	0,	0,	0,	1]},
                        {key:"EMO7_6", drivers:[1,	1,	0,	0,	0,	0,	0,	0]}
    ]},
    {key:"EMO8_0",emos:[
                        {key:"EMO8_1", drivers:[1,	0,	1,	0,	0,	0,	0,	0]},
                        {key:"EMO8_2", drivers:[1,	1,	0,	0,	0,	0,	0,	0]},
                        {key:"EMO8_3", drivers:[1,	1,	0,	0,	0,	0,	0,	1]},
                        {key:"EMO8_4", drivers:[1,	1,	0,	0,	0,	0,	0,	0]},
                        {key:"EMO8_5", drivers:[0,	1,	0,	0,	0,	0,	0,	0]},
                        {key:"EMO8_6", drivers:[1,	1,	1,	0,	0,	0,	0,	0]}
    ]},
    {key:"EMO9_0",emos:[
                        {key:"EMO9_1", drivers:[1,	1,	1,	0,	0,	0,	0,	0]},
                        {key:"EMO9_2", drivers:[1,	1,	1,	0,	0,	0,	0,	0]},
                        {key:"EMO9_3", drivers:[1,	1,	0,	0,	0,	0,	0,	0]},
                        {key:"EMO9_4", drivers:[1,	1,	0,	0,	0,	0,	0,	0]},
                        {key:"EMO9_5", drivers:[1,	0,	0,	0,	0,	0,	0,	0]},
                        {key:"EMO9_6", drivers:[1,	1,	0,	0,	0,	0,	0,	1]}
    ]},
    {key:"EMO10_0",emos:[
                        {key:"EMO10_1",drivers:[0,	0,	0,	1,	1,	0,	0,	0]},
                        {key:"EMO10_2",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO10_3",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO10_4",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO10_5",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO10_6",drivers:[0,	0,	0,	0,	1,	1,	0,	0]}
    ]},
    {key:"EMO11_0",emos:[
                        {key:"EMO11_1",drivers:[0,	0,	0,	0,	1,	0,	0,	0]},
                        {key:"EMO11_2",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO11_3",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO11_4",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO11_5",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO11_6",drivers:[0,	0,	0,	0,	1,	1,	0,	0]}
    ]},
    {key:"EMO12_0",emos:[
                        {key:"EMO12_1",drivers:[0,	0,	0,	1,	1,	0,	0,	0]},
                        {key:"EMO12_2",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO12_3",drivers:[0,	0,	0,	1,	1,	0,	0,	0]},
                        {key:"EMO12_4",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO12_5",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO12_6",drivers:[0,	0,	0,	0,	1,	1,	0,	0]}
    ]},
    {key:"EMO13_0",emos:[
                        {key:"EMO13_1",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO13_2",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO13_3",drivers:[0,	0,	0,	1,	1,	0,	0,	0]},
                        {key:"EMO13_4",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO13_5",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO13_6",drivers:[0,	0,	0,	0,	1,	1,	0,	0]}
    ]},
    {key:"EMO14_0",emos:[
                        {key:"EMO14_1",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO14_2",drivers:[0,	0,	0,	1,	1,	0,	0,	0]},
                        {key:"EMO14_3",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO14_4",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO14_5",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO14_6",drivers:[0,	0,	0,	0,	1,	1,	0,	0]}
    ]},
    {key:"EMO15_0",emos:[
                        {key:"EMO15_1",drivers:[0,	0,	0,	1,	1,	0,	0,	0]},
                        {key:"EMO15_2",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO15_3",drivers:[0,	0,	0,	1,	1,	0,	0,	0]},
                        {key:"EMO15_4",drivers:[0,	0,	0,	1,	1,	1,	0,	0]},
                        {key:"EMO15_5",drivers:[0,	0,	0,	1,	1,	1,	0,	0]},
                        {key:"EMO15_6",drivers:[0,	0,	0,	1,	1,	1,	0,	0]}
    ]},
    {key:"EMO16_0",emos:[
                        {key:"EMO16_1",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO16_2",drivers:[0,	0,	0,	1,	1,	1,	0,	0]},
                        {key:"EMO16_3",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO16_4",drivers:[0,	0,	0,	1,	1,	1,	0,	0]},
                        {key:"EMO16_5",drivers:[0,	0,	0,	1,	1,	0,	0,	0]},
                        {key:"EMO16_6",drivers:[0,	0,	0,	1,	1,	0,	0,	0]}
    ]},
    {key:"EMO17_0",emos:[
                        {key:"EMO17_1",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO17_2",drivers:[0,	0,	0,	1,	1,	1,	0,	0]},
                        {key:"EMO17_3",drivers:[0,	0,	0,	1,	1,	1,	0,	0]},
                        {key:"EMO17_4",drivers:[0,	0,	0,	1,	1,	1,	0,	0]},
                        {key:"EMO17_5",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO17_6",drivers:[0,	0,	0,	0,	1,	1,	0,	0]}
    ]},
    {key:"EMO18_0",emos:[
                        {key:"EMO18_1",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO18_2",drivers:[0,	0,	0,	1,	1,	0,	0,	0]},
                        {key:"EMO18_3",drivers:[0,	0,	0,	1,	1,	1,	0,	0]},
                        {key:"EMO18_4",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO18_5",drivers:[0,	0,	0,	0,	1,	1,	0,	0]},
                        {key:"EMO18_6",drivers:[0,	0,	0,	1,	1,	1,	0,	0]}
    ]}
]

