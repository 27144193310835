import { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { updateUserAvatar } from '../../services/Graphql/User/updateUser';
import ProfileForm from '../../components/MyProfile/ProfileForm';
import UserHeader from '../../components/User/UserHeader';
import { userData, userService } from '../../services/userParams';
import "./MyProfile.scss"

console.log("MYPROFILE-PAGE-MODULE-INIT");

const MyProfile = () => {
  console.log("--MYPROFILE-PAGE-INIT--"); 
  var [userDataArray, setUser] = useState(userData);
  var [modified, setModified] = useState(true);
  var userDataSubs = undefined;

  const history = useHistory();

  const assignUser = () => {
    userDataSubs = userService.getUserData().subscribe({
      next: (u) => {
        if (u && u.userAddressId) {
          // console.log('PROFILE');
          setUser(null);
          setUser(u);
        } else {
          history.push({
            pathname: "/login",
            state: {
              response: 'err404'
            }
          });
        }
      },
      error: (error) => {
        this.errors = error;
        console.log(error)
      },
      complete: () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      }
    });
  }

  useEffect(() => {
      window.scrollTo(0, 0);
      assignUser();
      return (() => {
          userDataSubs.unsubscribe();
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clearData = ((e) => {
    // console.log(e, userData);
    setUser(null);
    if (e === 'twitter') {
      userData.twitterAccount = '';
    } else if (e === 'instagram') {
      userData.instagramAccount = '';
    } else if (e === 'reddit') {
      userData.redditAccount = '';
    } else if (e === 'discord') {
      userData.discordAccount = '';
    } else if (e === 'web') {
      userData.webAddress = '';
    } else if (e === 'email') {
      userData.email = '';
    }
    setUser(userData);
    setModified(!modified);
  });

  const setAvatar = async (value) => {
    userData.avatarLink = value;
    setUser(userData);
    await updateUserAvatar(userDataArray.userAddressId, value);
  }

  return (
    <>
      <UserHeader title={"MY PROFILE"} userData={userDataArray} profile={true} clearData={clearData} setAvatar={setAvatar} />
      <ProfileForm userData={userDataArray} modified={modified} clearData={clearData} />
    </>
  )
}

export default MyProfile;