import { async } from "rxjs";

// handlePasswordOnAWSTable(password,mode,userState,dispatchUser);
export const handlePasswordOnAWSTable = async (password,mode,userState,dispatchUser) => {
    console.log("SECMANAGEMENT: handlePasswordOnAWSTable:",password,mode,userState,dispatchUser);
    if (
        userState &&
        userState.userData &&
        userState.userData.userAddressId &&
        userState.userData.userAddressId.length > 0
      ) {
        console.log("SECMANAGEMENT: userAddressId:",userState.userData.userAddressId);
      }else{
        console.log("SECMANAGEMENT: NO USERADDRESSID FOUND");
      }
}