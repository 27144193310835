export const aboutEmotiony = [
  {
    key: "emotionyWorks",
    titleContent: "How does emotiony.io work?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet.\n\nOr   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  },
  {
    key: "emotionsplanetstheory",
    titleContent:
      "How does emotiony.io converts emotions into color, planets and art?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet. Or   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  },
  {
    key: "emotionalDrivers",
    titleContent: "How does emotiony.io manage emotional drivers and emotions?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet. Or   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  },
  {
    key: "newvisuallenguage",
    titleContent: "Is emotiony.io creating a new visual lenguage?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet. Or   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  },
  {
    key: "joinemotiony",
    titleContent: "How do I join emotiony.io?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet. Or   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  }
];

export const aboutMoney = [
  {
    key: "earnmoney",
    titleContent: "Could I earn money (Matics) with emotiony.io?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet. Or   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  },
  {
    key: "matixxhashtag",
    titleContent: "How do I join the program MaticXHashTag?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet. Or   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  },
  {
    key: "maticinwallet",
    titleContent: "How will I get my Matics?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet. Or   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  }
];

export const aboutData = [
  {
    key: "getdata",
    titleContent: "May I consult and buy emotional data from emotiony.io?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet. Or   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  },
  {
    key: "payperdata",
    titleContent: "How do I join the program PerXData?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet. Or   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  },
  {
    key: "price",
    titleContent: "What is the price? Are there subscriptions and plans?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet. Or   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  },
  {
    key: "metrics",
    titleContent: "What metrics will I get after subscribing?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet. Or   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  }
];

export const aboutnft = [
  {
    key: "mintnft",
    titleContent: "May I mint my planet?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet. Or   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  },
  {
    key: "tradenft",
    titleContent: "May I trade my NFT?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet. Or   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  },
  {
    key: "gasfree",
    titleContent: "Is emotiony.io a Gas-free Marketplace?",
    content:
      "It is entirely acceptable to feed your dog a pure kibble diet. Or   you can mix their diet up with some cooked or raw meat, fish, vegetables and rice."
  }
];
