import {
  Grid,
  Segment,
  Header,
  Label,
  Message,
  Button,
  Card,
  Checkbox,
  Icon,
} from "semantic-ui-react";
import { useState } from "react";

const subscriptionModal = {
  success: {
    option: "success",
    title: "Success!. Thank you for your subscription",
    content: `
    Enjoy HashtagXmatic program.
    It’s time to change your emotions
    into cryptocurrency
    `,
    button: "OK",
  },
  error: {
    option: "error",
    title: "Error!. Something went wrong",
    content: `
    Please try again later
    `,
    button: "OK",
  },
};

export const HashtagxMatic = (props) => {
  const [subscriptionHashtagxMatic, setSubscriptionHashtagxMatic] =
    useState(true);
  const [subscriptionState, setSubscriptionState] = useState(null);

  const subscribeHandler = () => {
    setSubscriptionHashtagxMatic(!subscriptionHashtagxMatic);
    setSubscriptionState(subscriptionModal.success);
  };

  const unsubscribeHandler = () => {
    setSubscriptionHashtagxMatic(!subscriptionHashtagxMatic);
  };

  const closeModalHandler = () => {
    setSubscriptionState(false);
  };

  return (
    <Grid columns={1} stackable>
      <Grid.Row>
        <Grid.Column>
          <div>
            <Header
              as="h2"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              Hashtag x Matic{" "}
              <Checkbox
                toggle
                checked={subscriptionHashtagxMatic}
                onChange={
                  subscriptionHashtagxMatic
                    ? unsubscribeHandler
                    : subscribeHandler
                }
              />
            </Header>
            <Message className="noborder">
              HashtagxMatic is a program that allows you to earn cryptocurrency
              by sharing your emotions on social networks.
            </Message>
          </div>
        </Grid.Column>
      </Grid.Row>
      {subscriptionHashtagxMatic &&
      subscriptionState &&
      subscriptionState.option === "success" ? (
        <Grid.Row>
          <Grid.Column>
            <Card fluid style={{ width: "100%" }}>
              <Label>{subscriptionState.title}</Label>
              <div>{subscriptionState.content}</div>
              <Button fluid className="filled" onClick={closeModalHandler}>
                {subscriptionState.button}
              </Button>
            </Card>
          </Grid.Column>
        </Grid.Row>
      ) : null}
    </Grid>
  );
};

/* import { Grid, Segment, Header, Label, Message, Button, Card, Icon } from "semantic-ui-react";
import { useState } from "react";

const subscriptionModal = {
  success:{
    option:"success",
    title:"Success!. Thank you for your subscription",
    content:`
    Enjoy HashtagXmatic program.
    It’stime to change your emotions
    into criptocurrency
    `,
    button:"OK"
  },
  error:{
    option:"error",
    title:"Error!. Something went wrong",
    content:`
    Please try again later
    `,
    button:"OK"
  }
}

export const HashtagxMatic = (props) => {

  const [isSubscribed, setIsSubscribed] = useState(true);
  const [subscriptionState, setSubscriptionState] = useState(null);
  
  const toggleSubscriptionHandler = () => {
    setIsSubscribed(!isSubscribed);
    setSubscriptionState(subscriptionModal.success);
    console.log("HASHTAGXMATIC: user subscribed to hashtagxMatic",subscriptionState);
  };

  const closeSubscriptionHandler = () => {
    setSubscriptionState(null);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Segment>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Header as="h2">Hashtag x Matic</Header>
              <Button icon onClick={toggleSubscriptionHandler} className="noborder">
                <Icon name={isSubscribed ? 'check square outline' : 'square outline'} />
              </Button>
            </div>
            <Message className="noborder">HashtagxMatic is a program that allows you to earn cryptocurrency by sharing your emotions on social networks.</Message>
            {isSubscribed && subscriptionState && subscriptionState.option==="success" ?
              <Card>
                <Label>{subscriptionState.title}</Label>
                <div>{subscriptionState.content}</div>
                <Button fluid className="filled"  onClick={closeSubscriptionHandler}>{subscriptionState.button}</Button>
              </Card> : null 
            }  
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
 */
