import { Button, Icon, Image } from 'semantic-ui-react'
import { appParams } from '../../../../services/appParams'

console.log("GARGANTUAFASE-COMPONENT-MODULE-INIT");

const GargantuaFase = (props) => {
    
    console.log("----GARGANTUAFASE-COMPONENT-INIT----");

    const i = props.i;
    const j = `gargantuaBlock-${props.j}`;
    
    return (
        <>
            <div ref={i.ref} key={j} className={`${j} imageContainer ${i.shown ? '' : 'hide'}`}>
                <div className='image-text'>
                    <div className='text'>
                        <div className='first fadeable'>{i.title.first}</div>
                        <div className='second fadeable'>{i.title.second}</div>
                    </div>
                    <div className={`title space fadeable ${appParams.device === 'mobile' ? 'small' : ''}`}>
                        {i.mainTitle}
                    </div>
                    <div className='fade-in-image fadeable'>
                        <Image src={i.image} className='image' />
                    </div>
                </div>
                <div className='actions-text'>
                    <div className='first fadeable'>{i.actionText.first}</div>
                    <div className='second fadeable'>{i.actionText.second}</div>
                    <div className='third fadeable'>{i.actionText.third}</div>
                    <div className='fourth fadeable'>{i.actionText.fourth}</div>
                </div>
                <div className={`scroll-buttons ${appParams.device === 'mobile' ? 'mobile' : ''}`}>
                    <Icon name='chevron left' onClick={() => props.stepScroll(-1)} className={`${appParams.device === 'mobile' ? 'hide' : ''}`}></Icon>
                    <div className='actions'><Button className='fadeable' onClick={props.onGameScroll}>{i.button}</Button></div>
                    <Icon name='chevron right' onClick={() => props.stepScroll(0)} className={`${appParams.device === 'mobile' ? 'hide' : ''}`}></Icon>
                </div>
            </div>
        </>
    )
}

export default GargantuaFase;