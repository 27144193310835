import React from "react";
import { Dropdown, Label } from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import { PayPerDataContext } from "../../../services/payperDataContext.js";



const PayPerDataHashtags = () => {
  const [metricsState, dispatchMetrics] = useContext(PayPerDataContext);
  const [options, setOptions] = useState([]);
  const [values,setValues]=useState([]);

  const renderLabel = (label) => ({
    content: `${label.text}`,
  });

  const saveOptionsToContext = (e, { value }) => {
    console.log("saveOptionsToContext:event", e);
    console.log("saveOptionsToContext:value",value);
    setValues(value);
    dispatchMetrics({ type: "SET_HASHTAG_FILTERS", payload: value });
  };

  /*
   metricsState.effectiveFilters={
      hashtags:[],
      superEmos:[],
      drivers:[],
      dateRange:{init:null,end:null},
      hoursRange:[],
      locations:[],
      planetids:[]
    }
  */

  useEffect(() => {
    if (metricsState.metricFilters && metricsState.metricFilters.hashtags) {
      const newOpt = metricsState.metricFilters.hashtags.map((h) => ({
        key: h,
        text: h,
        value: h,
      }));
      setOptions(newOpt);
    }
  }, [metricsState.metricFilters]);

  useEffect(()=>{
    console.log("PayPerDataHashtags:metricsState",metricsState.effectiveFilters)
    if(metricsState.effectiveFilters && metricsState.effectiveFilters.hashtags){
      setValues(metricsState.effectiveFilters.hashtags);
    }
  },[metricsState.effectiveFilters]);
  
  return (
    <>
      <label style={{ width: "190px" }}> Hashtags </label>
      <Dropdown
        fluid
        openOnFocus
        search
        clearable
        placeholder="Search"
        multiple
        selection
        options={options}
        renderLabel={renderLabel} 
        onChange={saveOptionsToContext}
        value={values}
      />
    </>
  );
};

export default PayPerDataHashtags;

/*
const Hashtags = () => {
  const [metricsState, dispatchMetrics] = useContext(MetricsContext);
  const [options,setOptions]= useState(opt);
  const renderLabel = (label) => ({
    content: `${label.text}`
  });

  const saveOptionsToContext=(e,{value})=>{
    //console.log("saveOptionsToContext:value",value);
    dispatchMetrics({type:"SET_HASHTAG_FILTERS",payload:value})
  }

  useEffect(()=>{
    if(metricsState.metricFilters && metricsState.metricFilters.hashtags) {
      let newOpt=[];
      for(let i=0;i<metricsState.metricFilters.hashtags.length;i++){
        let h=metricsState.metricFilters.hashtags[i]
        newOpt.push({key:h,text:h,value:h})
      }
      console.log("HASTAGS-METRICS OPTIONS",newOpt);
      setOptions(newOpt);
    }
  },[metricsState.metricFilters]);

  return (
    <>

      <label style={{ width: "190px" }}>Hashtags</label>
      <Dropdown
        fluid
        openOnFocus
        search
        clearable
        placeholder="Search"
        multiple
        selection
        options={options}
        renderLabel={renderLabel}
        onChange={saveOptionsToContext}
      />

    </>
  );
};

export default Hashtags;

*/
