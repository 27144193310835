// METRICS REDUCER PARTS:

// metricsAction.type
// metricsAction.payload
// mertricsAction.error
// metricsAction.meta
// metricsAction.OTHER <= ( all other things! )

console.log("METRICSREDUCER-MODULE-INIT");

export const metricsReducer= (metricsState,metricsAction) =>{
    var result=null;
    
    switch (metricsAction.type) {
        case "SET_METRIC_FILTERS":
            result= {
                ...metricsState,
                metricFilters: {
                    hashtags: metricsAction.payload.hashtags,
                    drivers:[],
                    dateRange:{init:null,end:null},
                    hoursRange:[],
                    locations:metricsAction.payload.locations,
                    planetids:metricsAction.payload.planetids                            
                }
            };
            console.log("METRICSREDUCER:SET_METRIC_FILTERS",result.metricFilters);
            break;
        case "SET_METRIC_RESULTS":
            result= {
                ...metricsState,
                metricResults: metricsAction.payload
            };
            console.log("METRICSREDUCER:SET_METRIC_RESULTS",metricsAction.payload);
            break;
        case "SET_DASHBOARD_STATE":
            result= {
               ...metricsState,
               dashboardState: metricsAction.payload
            };
            console.log("METRICSREDUCER:SET_DASHBOARD_STATE",metricsAction.payload); 
            break;
        case "SET_HASHTAG_FILTERS":
            result=metricsState;
            result.effectiveFilters.hashtags=metricsAction.payload;  
            console.log("METRICSREDUCER:SET_HASHTAG_FILTERS",metricsAction.payload);           
            break;
        case "SET_SUPEREMOS_FILTERS":
            result=metricsState;
            result.effectiveFilters.superEmos=metricsAction.payload;  
            console.log("METRICSREDUCER:SET_SUPEREMOS_FILTERS",metricsAction.payload);           
            break;
        case "SET_SUPEREMOS_DRIVERS":
                result=metricsState;
                result.effectiveFilters.drivers=metricsAction.payload;  
                console.log("METRICSREDUCER:SET_SUPEREMOS_DRIVERS",metricsAction.payload);           
            break;
        case "SET_DATE_RANGE":
                result=metricsState;
                result.effectiveFilters.dateRange=metricsAction.payload;  
                console.log("METRICSREDUCER:SET_DATE_RANGE",metricsAction.payload);           
            break;
        case "SET_HOURS_RANGE":
                result=metricsState;
                result.effectiveFilters.hoursRange=metricsAction.payload;  
                console.log("METRICSREDUCER:SET_HOURS_RANGE",metricsAction.payload);           
            break;
        case "SET_LOCATION_FILTERS":
                result=metricsState;
                result.effectiveFilters.locations=metricsAction.payload;  
                console.log("METRICSREDUCER:SET_LOCATION_FILTERS",metricsAction.payload);           
            break;
        case "SET_METRIC_DATAHASH":
            result=metricsState;
            let comprobation=0;
            let hash=metricsAction.payload.hash;
            if(result.metricDataHash)
            {
                    for(let i=0;i<result.metricDataHash.length;i++)
                    {
                        console.log("METRICSREDUCER:SET_METRIC_DATAHASH: ??? HASH:"+hash+" === METRICSTATE["+i+"]:"+metricsState.metricDataHash[i].hash);
                        if(metricsState.metricDataHash[i].hash===hash)
                        {
                            comprobation=1;
                        }
                    }
            }
            
            if(comprobation===0) {
                result.metricDataHash.push(metricsAction.payload);
                console.log("METRICSREDUCER:SET_METRIC_DATAHASH",metricsAction.payload);
                console.log("METRICSREDUCER:Setting in metricDataHashArray:",result.metricDataHash);  
            }else{
                console.log("METRICSREDUCER:EXISTS HASH:",hash," !!!! (NOT SETTED):",result.metricDataHash);   
            }
                     
            break;
        default:
            result = metricsState;

    }
    sessionStorage.setItem("metricsState",JSON.stringify(result));
    return result;

}