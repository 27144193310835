import { useContext, useState, useEffect } from "react";
import { Menu, Label, Segment } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

export const DateRangeListAndChipsComponent = (props) => {
  const { clearAll, setter, setted, lista, title } = props;
  const [dates, setDates] = useState([]);
  const [values, setValues] = useState([]);
  var [clearValues, setClearValues] = useState(false);

  const handleDatesChange = (event, data) => {
    console.log("HANDLE DATESCHANGE", event, data);
    setDates(data.value && data.value.length>1?data.value:[]);
    setter(data.value && data.value.length>1?data.value:[]);
    var init = 0;
    var finish = 0;
    if (data && data.value && data.value.length > 1) {
      var dinit = new Date(data.value[0]);
      var dfinish = new Date(data.value[1]);
      init = dinit.getTime();
      finish = dfinish.getTime();
      setValues(data.value);
      setDates([init, finish]);
      setter([init, finish]);
      console.log("SETTING DATE:", [init, finish]);
    }
  };

  const renderLabel = (label) => ({
    content: `${label.text}`,
    icon: "check",
  });

  useEffect(() => {
    if (clearAll && !clearValues) {
      setValues([]);
      setClearValues(true);
      setter([]);
      console.log("FILTERCOMPONENT:", title, " CLEARED");
    }
    if (!clearAll) {
      setClearValues(false);
    }
  }, [clearAll, clearValues]);

  useEffect(() => {
    setValues(setted);
  }, [setted]);

  return (
    <SemanticDatepicker
      format="MM-DD-YYYY"
      icon="calendar outline"
      onChange={handleDatesChange}
      type="range"
      label={renderLabel}
      placeholder={lista}
      value={values}
    />
  );
};
