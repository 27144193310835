import { textAlign } from "@mui/system";
import React from "react";
import HowitWorksComponent from './../../components/HowitWorks/HowitWorksComponent'

console.log("HOW-IT-WORKS-MODULE-INIT");

function HowitWorks() {
  console.log("---HOW-IT-WORKS-PAGE-INIT----");
  return <div className="ui container howitworks"
          style={{
            textAlign:"center"
          }}
         > 
            <br/>
            HOW IT WORKS
            <HowitWorksComponent/>
            </div>;
}
export default HowitWorks;