import { getGeolocation } from "./geolocation";
var dataGame;

console.log("DATAGAME-COMPONENT-MODULE-INIT");

export function dataGameCreator(geolocation, websocketEngine) {
  console.log("---DATAGAME-COMPONENT-INIT---");

  var iip = GetIP();
  var emotions = [
    ["EMO10_3", 120],
    ["EMO10_4", 80],
    ["EMO10_6", 120],
    ["EMO15_5", 120],
    ["EMO15_6", 120],
    ["EMO16_2", -1],
    ["EMO16_3", -1],
    ["EMO18_1", 120],
    ["EMO1_6", 40],
    ["EMO2_3", 120],
    ["EMO3_1", 120],
    ["EMO3_3", -1],
    ["EMO4_1", 120],
    ["EMO4_2", 120],
    ["EMO4_4", 120],
    ["EMO5_5", 120],
    ["EMO7_4", 20],
    ["EMO9_1", 120],
  ];
  var data = {
    emotions: emotions,
    generalData: createGeneralData(geolocation, websocketEngine, iip),
    /*{
            "Email": "albert",
            "Date": "7/28/2020 9:07:16 PM",
            "Geoloc": "-1LAT @104LONG",
            "GameId": "175668069112105", 
            "DeviceId" : "mobileOrPcId", 
            "IP": "83.44.237.224",
            "Username": "DEFAULTPLAYER"
        }*/
    //"location": getGeolocation(iip)
  };

  data.setNewEmotions = function () {};

  // console.log(data);
  dataGame = data;

  return dataGame;
}

function getUniqueAndNotDuplicateLongUserName() {
  // username based on fruit, animal, color, 
  /*
  //cities, and tool or office things
  // (all in English) and date.getTime();
  // Arrays of word are 30 words c/u
  */

  // 30 diferent fruits
  var fruit = [
    "apple",
    "banana",
    "orange",
    "grape",
    "strawberry",
    "watermelon",
    "melon",
    "peach",
    "pear",
    "pineapple",
    "kiwi",
    "mango",
    "cherry",
    "lemon",
    "lime",
    "coconut",
    "blueberry",
    "raspberry",
    "blackberry",
    "apricot",
    "avocado",
    "fig",
    "grapefruit",
    "guava",
    "papaya",
    "plum",
    "pomegranate",
    "tangerine",
    "tomato",
    "pepper",
  ];

  // 30 diferent animals
  var animal = [
    "dog",
    "cat",
    "bird",
    "fish",
    "turtle",
    "rabbit",
    "hamster",
    "mouse",
    "rat",
    "parrot",
    "canary",
    "goldfish",
    "shark",
    "whale",
    "dolphin",
    "tiger",
    "lion",
    "elephant",
    "giraffe",
    "zebra",
    "monkey",
    "gorilla",
    "snake",
    "crocodile",
    "lizard",
    "frog",
    "toad",
    "chameleon",
    "iguana",
    "alligator",
  ];
  // 30 diferent colors
  var color = [
    "red",
    "blue",
    "green",
    "yellow",
    "orange",
    "purple",
    "pink",
    "brown",
    "black",
    "white",
    "gray",
    "silver",
    "gold",
    "beige",
    "turquoise",
    "violet",
    "magenta",
    "cyan",
    "olive",
    "lime",
    "navy",
    "maroon",
    "teal",
    "indigo",
    "azure",
    "lavender",
    "coral",
    "crimson",
    "fuchsia",
    "khaki",
  ];
/*
  // 30 diferent cities
  var citie = [
    "London",
    "NewYork",
    "Paris",
    "Madrid",
    "Barcelona",
    "Berlin",
    "Rome",
    "Moscow",
    "Tokyo",
    "Beijing",
    "Seoul",
    "ElCairo",
    "BuenosAires",
    "SaoPaulo",
    "Cairo",
    "Lagos",
    "Kinshasa",
    "Shanghai",
    "Lima",
    "Bogota",
    "Istanbul",
    "Karachi",
    "Mumbai",
    "Delhi",
    "Dhaka",
    "Jakarta",
    "Lahore",
    "Kolkata",
    "Tehran",
    "Bangkok",
  ];

  // 30 diferent tools and office things
  var tool = [
    "hammer",
    "screwdriver",
    "wrench",
    "pliers",
    "saw",
    "drill",
    "chisel",
    "sander",
    "level",
    "screw",
    "nail",
    "bolt",
    "paper",
    "pen",
    "pencil",
    "eraser",
    "stapler",
    "tape",
    "scissors",
    "ruler",
    "calculator",
    "clock",
    "calendar",
    "phone",
    "computer",
    "printer",
    "monitor",
    "keyboard",
    "headphones",
    "camera",
    "laptop"
  ];

*/







    // usermname is a random word from each array  + date.getTime()
    /* 
      // (30 x 30 x 30 x 30 x 30) x (10^13) = 243,000,000,000,000,000 = 243 trillion combinations
    */
    var _color =  color[Math.floor(Math.random() * 30)] ;
    var _fruit=  fruit[Math.floor(Math.random() * 30)] ;
    var _animal = animal[Math.floor(Math.random() * 30)] ;
   
    //var _citie = citie[Math.floor(Math.random() * 30)] ;
    //var _tool = tool[Math.floor(Math.random() * 30)] ;
    // upercase 1st letter from c/u word
    
    _fruit = _fruit.charAt(0).toUpperCase() + _fruit.slice(1);
    _animal = _animal.charAt(0).toUpperCase() + _animal.slice(1);
    _color = _color.charAt(0).toUpperCase() + _color.slice(1);
    
    //_citie = _citie.charAt(0).toUpperCase() + _citie.slice(1);
    //_tool = _tool.charAt(0).toUpperCase() + _tool.slice(1);
    // get date
    var date = new Date();
    var username = _color + 
                   _fruit + 
                   _animal + 
                   //"_" +
                   // _citie + 
                   // _tool +
                   date.getTime();

  return username;
}

function createGeneralData(geolocation, websocketEngine, iip) {
  var email = "default@email.com";
  var date = new Date();
  var gameId = generateId();
  var deviceId = getUA();
  var ip = iip; // "dummy.dummy.dummy.dummy";
  var username = getUniqueAndNotDuplicateLongUserName();

  return {
    email: email,
    date: date,
    geoloc: {
      accuracy: geolocation.accuracy,
      latitude: geolocation.latitude,
      longitude: geolocation.longitude,
    },
    gameId: gameId,
    pngId: gameId + "-" + date.getTime(),
    emoViewConnectionID: websocketEngine.connectionId,
    posnegPlanet: " ",
    deviceId: deviceId,
    ip: ip,
    username: username,
  };

  //return generaldata;
}

export function changeGeneralData(generalData, websocketEngine, idk) {
  var ndate = new Date();
  generalData.date = ndate;
  generalData.pngId = generalData.gameId + "-" + ndate.getTime();
  //generalData.pngId = idk + "@" + generalData.gameId + "-" + ndate.getTime();
  //generalData.emoViewConnectionID = websocketEngine.connectionId;
}

function getUA() {
  let device = "Unknown";
  const ua = {
    "Generic Linux": /Linux/i,
    Android: /Android/i,
    BlackBerry: /BlackBerry/i,
    Bluebird: /EF500/i,
    "Chrome OS": /CrOS/i,
    Datalogic: /DL-AXIS/i,
    Honeywell: /CT50/i,
    iPad: /iPad/i,
    iPhone: /iPhone/i,
    iPod: /iPod/i,
    macOS: /Macintosh/i,
    Windows: /IEMobile|Windows/i,
    Zebra: /TC70|TC55/i,
  };
  Object.keys(ua).map((v) => navigator.userAgent.match(ua[v]) && (device = v));
  return device;
}

function GetIP() {
  return httpGet("https://api.ipify.org/");
}

function httpGet(theUrl) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.open("GET", theUrl, false); // false for synchronous request
  xmlHttp.send();
  var ip = xmlHttp.responseText;
  console.log("GET IP", ip);
  return ip;
}

export function generateId() {
  var id = "";
  var m = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
  var Idlength = 64;
  for (let i = 0; i < Idlength; i++) {
    var rnd = parseInt((Math.random() * Number.MAX_SAFE_INTEGER) % m.length);

    var c = m[rnd];
    while (i === 0 && c === "-") {
      rnd = parseInt((Math.random() * Number.MAX_SAFE_INTEGER) % m.length);
      c = m[rnd];
    }
    id = id + c;
  }
  return id;
}

export function getFakeGeoLoc() {
  return {
    geolocation: {
      accuracy: 0,
      latitude: 0,
      longitude: 0,
    },
  };
}

export function getFakeWebSocketEngine() {
  return {
    websocketEngine: {
      connectionId: "dummy",
    },
  };
}

export function dataGameCreatorInit() {
  dataGame = dataGameCreator(
    getFakeGeoLoc().geolocation,
    getFakeWebSocketEngine().websocketEngine
  );
  return dataGame;
}

export function getDataGame() {
  return dataGame;
}
