import { Label, Message, Icon, Button } from "semantic-ui-react";
import React, { useEffect, useState, useContext } from "react";

//import { userData, connectionData } from "../../../services/userParams.js";
import { UserContext } from "../../../services/userContext";

import MaticLogo from "../../../shared/assets/icons/matic_cryptocurrency_logo_icon_131424.svg";
import NFTLogo from "../../../shared/assets/icons/NFT.svg";
import {
  getTokenizeState,
  tokenize,
  tokenizeStatus,
} from "../../../services/tokenizeService";

console.log("TOKENIZEPLANET-COMPONENT-MODULE-INIT");

const Tokenize = (props) => {
  var {
    stateTokenize,
    setStateTokenize,
    stateDisableTokenize,
    setStateDisableTokenize,
  } = props;
  console.log("---TOKENIZEPLANET-COMPONENT-INIT---");
  var tokenizeProps = props;
  var planetId = tokenizeProps.planetId;
  var isConfigNetWorkId = false;
  var [labelText, setLabelText] = useState("No info");
  var [userState, dispatchUser] = useContext(UserContext);
  var _reducer = undefined;

  const handleTokenize = (event, ButtonProps) => {
    console.log("TOKENIZEPLANET:EVENT", event, "BUTTONPROPS", ButtonProps); //, "MANAGER:", manager);
    console.log("TOKENIZEPLANET:USERDATA", userState.userData);
    console.log("TOKENIZEPLANET:PLANETID", planetId);
    _reducer = { userState: userState, dispatchUser: dispatchUser };
    tokenize(
      event,
      planetId,
      setStateTokenize,
      setStateDisableTokenize,
      _reducer
    );
  };

  useEffect(() => {
    if (props) {
      console.log("TOKENIZEPLANET: USEEFFECT PROPS", props);
    }
  }, [props]);

  useEffect(() => {
    console.log(" TOKENIZEPLANET USEFFECT:");
    let connectionData = userState.userData.connectionData;

    getTokenizeState(
      connectionData,
      planetId,
      setStateDisableTokenize,
      setStateTokenize,
      tokenizeStatus,
      setLabelText
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.userData.connectionData.connectedBlockchain]);

  console.log("TOKENIZEPLANET: STATE TOKENIZE:", stateTokenize);
  return (
    //stateTokenize === tokenizeStatus.TOKENIZED ||
    //stateTokenize === tokenizeStatus.DONT_READ_STATUS ) ? null : 
    <>
      <Button
        fluid
        onClick={handleTokenize}
        size="big"
        disabled={stateDisableTokenize || (!stateTokenize !== tokenizeStatus.TOKENIZED)}
        className="filled"
      >
        Tokenize
      </Button>

      {userState.userData.userAddressId ? (
        <Message>
          <Message.Content>
            <Message.Header>Status</Message.Header>
            {stateTokenize}
          </Message.Content>
        </Message>
      ) : (
        <Message>
          <Message.Content>
            <Message.Header>Status</Message.Header>
            {labelText}
          </Message.Content>
        </Message>
      )}
    </>
  )//;   
};

export default Tokenize;
