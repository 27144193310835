import React from "react";
import{Box,SimpleCardBox} from "./Box";
import { pricingData ,plans} from "../../services/pricingService";

console.log("PRICING-COMPONENT-MODULE-INIT");

const PricingComponent = () => {

  console.log("---PRICING-COMPONENT-INIT---");
  
    
    return (
      <>
        <Box
          price={pricingData[plans.BASIC].data.price}
          title={pricingData[plans.BASIC].data.title}
          extra={pricingData[plans.BASIC].data.extra}
          feature={pricingData[plans.BASIC].data.feature}
        />

        <Box
          price={pricingData[plans.PRO].data.price}
          title={pricingData[plans.PRO].data.title}
          extra={pricingData[plans.PRO].data.extra}
          feature={pricingData[plans.PRO].data.feature}
        />

        <Box
          price={pricingData[plans.PREMIUM].data.price}
          title={pricingData[plans.PREMIUM].data.title}
          extra={pricingData[plans.PREMIUM].data.extra}
          feature={pricingData[plans.PREMIUM].data.feature}
        />
      
        <Box
          price={pricingData[plans.ENTERPRISE].data.price}
          title={pricingData[plans.ENTERPRISE].data.title}
          extra={pricingData[plans.ENTERPRISE].data.extra}
          feature={pricingData[plans.ENTERPRISE].data.feature}
        />
      </>
    );
  };
  
  export default PricingComponent;
  