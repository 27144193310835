import { useEffect, useState } from 'react';
import { Segment, Header, List } from 'semantic-ui-react';
import { providersList } from '../../../services/appParams';
import metamaskIcon from '../../../shared/assets/icons/MetaMask_color.png';
import Provider from '../Provider/Provider';


console.log("PROVIDERSLIST-COMPONENT-MODULE-INIT");

const ProvidersList = (props) => {

  console.log("---PROVIDERSLIST-COMPONENT-INIT----");
  
  var [providers, setProviders] = useState([]);
 
  const getProviders = async () => {
    if (providersList) {
      //console.log(providersList);
      const provs = providersList.map((i, j) => {
        return (
          <Provider key={j + i.name} index={j} item={i} metamaskIcon={metamaskIcon} pageProps={props.pageProps} />
        )
      });
      setProviders(provs);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { 
    getProviders(); 
  }, [])

  return (
    <>
      <Segment>
        <Header as ="h1">Connect your wallet.</Header>
        <p>Connect or create a new one.</p>
      
        <List divided verticalAlign='middle'>
          {providers}
        </List>
      </Segment>
    </>
  );
}
export default ProvidersList;