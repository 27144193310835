import React from "react";
import { Container } from "semantic-ui-react";
import "./Auth.scss";
import ProvidersList from '../../components/Auth/ProvidersList/ProvidersList';

console.log("AUTH-PAGE-MODULE-INIT");

const Auth = (props) => {
  console.log("---AUTH-PAGE-INIT---");

  return (
    <ProvidersList pageProps={props} />
  );
}
export default Auth;
