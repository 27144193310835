import { version } from "react";
import { mode } from "../../../mode.js";
var dataJsonFromAWS;

console.log("FAKEDATA-COMPONENT-MODULE-INIT");

function fakeDataInit2() {
  console.log("---FAKEDATAINIT2-COMPONENT-INIT---");
  console.log("FAKEDATA fakeDataInit2");
  var req = new XMLHttpRequest();

  req.open(
    "GET",
    "https://emotionydata.s3.eu-west-3.amazonaws.com/hashtagstemplate.json",
    false
  );

  req.addEventListener("load", function () {
    if (req.status === 200) {
      console.log("RESPONSE:", req.responseText);
      dataJsonFromAWS = JSON.parse(req.responseText);
      console.log("JSON ES=", dataJsonFromAWS);
    } else {
      console.log("Error en la solicitud. Estado:", req.status);
    }
  });

  req.send(null);
}

fakeDataInit2();

export const userAdressidList = [
  //"0x46a7bd7B1A92cB6ddbC7aB8ebFc608471187D1ed",
  //"0x570d0c26Be47312F3648E5fe27692803f8fD8ac6",
  "0x8A1E4F5203550E7115cC1672B6B6Db5e14DAbbA3",
  //"0x224fb42C5bA622E26BA1F69babba2506d6e1dd78",
  "0x2a238A2C3f7e8B41B9a1f85c07374eA897A1DD4e",
  "0x54d0997F5E192bC641a60b34fC95947e116Fe6d5",
  "0x76e95eA01D73765C45117A8f9489fc557cE9002A",
  "0x10998a005EbCA52F82A6bd722cC55b85fF42a629",
  "0xc2BfcCB6279f65A1279121fC8C24838FB4A9153E",
  "0x473a0be988f9100Dcc4Fe6520AB1FBa2F169A78c",
  "0x281AAc9e7B2d948253F42Be186a6b6CEF4bDa8C5",
  "0x1e2f7c50d36324467FF98Bf29507752f5cC8be5b",
  "0x2944FaE3228DBA6a7D10145287893Fd89c5eF1A7",
  "0x1cff9d7fF0d554500550C97d0d3399bC8D0e7be1",
  "0x155A85F50b57f865a90832f65d8f416A5B1674C6",
  "0xA9aB1B64E076211fC4C9f5E46Ad943E7A2B6907E",
  "0xaac657A49C0d69a8b7CA3A4b9836A55E53d0D596",
  "0x262da5f1a2E3Ce21d4c328808d5dcca4DBb08dc0",
  "0x1465014386d7174885807A89617B3B0f3772EB39",
  //"0xC4C08F1345FbCA0948f23e26Cd79c01A86256FBe"
];

const textGameHashTagList2 = dataJsonFromAWS;
console.log("JSON PARA REACT=", textGameHashTagList2);

function textGameHashTagList() {
  let list = textGameHashTagList2[textGameHashTagList2.current];
  let txtGame = "";
  for (var t of list) {
    txtGame += "#" + t + " ";
  }

  return txtGame;
}

const Locationlist =
  "Paris, France#" +
  "Barcelona, Spain#" +
  "Rome, Italy#" +
  "London, UK#" +
  "New Dheli, India#" +
  "Buenos Aires, Argentina#" +
  "Chicago, USA#" +
  "Toronto, Canada#" +
  "Berlin, Germany#" +
  "Essen, Germany#" +
  "Springfield, USA#" +
  "Cairo, Egypt#" +
  "Being, China#" +
  "Sydney, Australia#" +
  "Hawai, USA#" +
  "Lima, Peru#" +
  "Chiclayo, Peru#" +
  "New York, USA#" +
  "Kiev, Ukraine";

export function getLocation() {
  var locationl = Locationlist.split("#");
  var rand = Math.round(Math.random() * (locationl.length - 1));
  var loc = locationl[rand];
  console.log("GET_LOCATION=", loc);
  return loc;
}

export function getgameText() {
  var numOfHastTags = Math.round(Math.random() * 8.0 + 1.0);
  var txtgame = "";
  var textGameHashTag = textGameHashTagList.split("#");
  console.log("HASHTAGLIST", textGameHashTag);
  console.log("NUMOFHASHTAGS=", numOfHastTags);
  for (var i = 0; i < numOfHastTags; i++) {
    var rand = Math.round(Math.random() * (textGameHashTag.length - 1));
    console.log("RAND=", rand);
    var txt = textGameHashTag[rand].split(" ")[0].split(",")[0].trim();
    console.log("txt:{" + txt + "}");
    var htg = "#" + txt + " ";

    if (htg != "# ") {
      txtgame += htg;
    } else {
      console.log("ALEERT!!! HASHTAG BOMBA!!!!");
      console.log("ALEERT!!! HASHTAG BOMBA!!!!");
      console.log("ALEERT!!! HASHTAG BOMBA!!!!");
      console.log("ALEERT!!! HASHTAG BOMBA!!!!");
    }
  }
  console.log("textGame=", txtgame);
  return txtgame;
}

export function getuserAddressId() {
  var rand = Math.round(Math.random() * (userAdressidList.length - 1));
  console.log("USERADDRESSID RAND=", userAdressidList[rand]);
  return userAdressidList[rand];
}
export function get18HashTags2() {
  var hashtagList = [];
  var numOfHastTags = mode.NumHashtags;
  //var txtgame="";
  var txtAux = textGameHashTagList();
  console.log("--------------------> TXT AUX :", txtAux);
  var textGameHashTag = txtAux.split("#");
  console.log("HASHTAGLIST", textGameHashTag);
  console.log("NUMOFHASHTAGS=", numOfHastTags);
  for (var i = 0; i < numOfHastTags; i++) {
    let select = false;
    while (!select) {
      var rand = Math.round(Math.random() * (textGameHashTag.length - 1));
      console.log("RAND=", rand);
      var txt = textGameHashTag[rand].split(" ")[0].split(",")[0].trim();

      console.log(
        'hashtagList.indexOf("#' + txt + ' ")=',
        hashtagList.indexOf("#" + txt + " ")
      );
      if (hashtagList.indexOf("#" + txt + " ") === -1) {
        select = true;
        console.log("NO EXISTE!", select);
      }
    }
    console.log("txt:{" + txt + "}");
    var htg = "#" + txt + " ";

    if (htg !== "# ") {
      //txtgame+=htg;
      hashtagList.push(htg);
    } else {
      console.log("ALEERT!!! HASHTAG BOMBA!!!!");
      console.log("ALEERT!!! HASHTAG BOMBA!!!!");
      console.log("ALEERT!!! HASHTAG BOMBA!!!!");
      console.log("ALEERT!!! HASHTAG BOMBA!!!!");
    }
  }
  console.log("hashTagListArray=", hashtagList);
  return hashtagList;
}

export function get18HashTags() {
  var hashtagList = [];
  var numOfHastTags = mode.NumHashtags;

  var typeOfHastTags = textGameHashTagList2.current;
  if (
    mode.TypeInManualHashtags !== null &&
    mode.TypeInManualHashtags !== undefined &&
    mode.TypeInManualHashtags !== ""
  ) {
    
    if(textGameHashTagList2[mode.TypeInManualHashtags]){
        typeOfHastTags = mode.TypeInManualHashtags;
    }
  }

  var textGameHashTag = textGameHashTagList2[typeOfHastTags];

  console.log("HASHTAGLIST", textGameHashTag);
  console.log("NUMOFHASHTAGS=", numOfHastTags);
  console.log(
    "------------------------EL JSON ES",
    JSON.stringify(textGameHashTagList2)
  );

  for (var i = 0; i < numOfHastTags; i++) {
    let select = false;
    while (!select) {
      var rand = Math.round(Math.random() * (textGameHashTag.length - 1));
      //console.log("RAND=",rand);
      var txt = textGameHashTag[rand];

      var htg = "#" + txt;

      //console.log("hashtagList.indexOf("+htg+")=",hashtagList.indexOf(htg));
      if (hashtagList.indexOf(htg.replace(" ", "_")) === -1) {
        select = true;
        //console.log("NO EXISTE!",select);
      }
    }
    //console.log("htg:{"+htg+"}");

    if (htg !== "# ") {
      //txtgame+=htg;
      if (htg.indexOf(" ") !== -1) {
        htg = htg.replace(" ", "_");
      }
      hashtagList.push(htg);
    } else {
      console.log("ALEERT!!! HASHTAG BOMBA!!!!");
      console.log("ALEERT!!! HASHTAG BOMBA!!!!");
      console.log("ALEERT!!! HASHTAG BOMBA!!!!");
      console.log("ALEERT!!! HASHTAG BOMBA!!!!");
    }
  }
  //console.log("hashTagListArray=",hashtagList);
  return hashtagList;
}

export function get18HashTags_chooseValuersInCircles() {
  var keys = Object.keys(textGameHashTagList2);
  var values = Object.values(textGameHashTagList2);
  var hashtagList = [];

  let index = 0;
  while (hashtagList.length < 20) {
    var currentList = values[index];
    var randomIndex = Math.floor(Math.random() * currentList.length);
    var randomValue = currentList[randomIndex];

    // randomValue length < 2 do not push!

    if (randomValue && randomValue !== undefined && randomValue.length > 2) {
      //if randomValue have spaces, replace it with "_" (underscore)
      if (randomValue.indexOf(" ") !== -1) {
        randomValue = randomValue.replace(" ", "_");
      }
      //if ("#"+randomValue) is in hashtagList, do not push!
        if (hashtagList.indexOf("#" + randomValue) === -1){
            hashtagList.push("#" + randomValue);
        }

    }

    index = (index + 1) % keys.length;
  }
  hashtagList = hashtagList.slice(0, 19);

  return hashtagList;
}
