import { API, graphqlOperation } from "aws-amplify";
import { areEqual } from "../../planetFilter";
console.log("QUERYFILTER MODULE INIT");

var getPlanetFilterFlag = false;

export const getPlanetsFilter = async (
  emoreducer,
  filterPlanet,
  filterFirstElement,
  pageLimit
) => {
  /* 
   
    USE PLANETIDS ARRAYS TO GET INDIVIDUAL PLANETS
    USING QUERYGAMES FUNCTIONS WITH GAMEID PARAMETER
    TO GET INDIVIDUAL GRAPHQL DATA
    ADD THE DATA OF PLANET TO THE ITEMS ARRAY
    AND STORE ITEMS IN GLOBAL STATE
    IN THE FILTERPLANETS ARRAY 
    FILTERPLANET FORMAT:
    filterPlanet={type:type, userAddressId: null, filter: null, items: [], firstElement:firstElement  })
    CHARGE PLANETS TO GLOBALSTATE filterplanet.items
    items obtain from grahpqlquery is an complex object stored at items: []
    and contains { gameId: "gameId", ...and other data get from graphql query }
    THE FUNCTION RETURNS ( pageLimit - filterPlanet.firstElement ) ARRAY PLANETS    
  */

  console.log("QUERYFILTER: GETPLANETS FILTER");

  var { globalState, dispatchGlobal } = emoreducer;
  // "SET_FIRST_ELEMENT":
  // EMOACTION.PAYLOAD FORMAT:
  // var {type, userAddressId, filter, firstElement}

  const getQuery = (gameId) => {
    return `
        query getGameById {
          getMasterGameById(idGame: "${gameId}") {
            idGame
            planetOperation
            userAddressId
            connectionid
            connectionData
            sourceip
            requesttime
            emotions
            description
            gameDesign
            generalData {
              date
              gameId
              emoViewConnectionID
              posnegPlanet
              geoloc {
                accuracy
                latitude
                longitude
              }
              pngId
              deviceId
              email
              username
            }
            hashtags
            tags
            quote
            status
            textGame
            favorites
            location
            locationFilter
            locationPretty
            likes
            timestampEpoch
          }
      }
      `;
  };
  // THIS FUNCTION GET 10 OR (the rest) of planetIds.lenght PLANETS FROM GRAPHQL QUERY
  // AND STORES IT IN ITEMS ARRAY
  // DEPENDS OF THE PARAMETER firstElement
  // IF firstElement=0 GET 10 PLANETS
  // IF firstElement=10 GET THE OTHER 10 PLANETS
  // IF firstElement=20 GET THE OTHER 10 PLANETS
  // AND SO ON
  const getPlanets = async (
    globalState,
    dispatchGlobal,
    filterPlanet,
    pageLimit,
  ) => {
    console.log("QUERYFILTER: GETPLANETS");

    let items = [];
    let planetIds = filterPlanet.items.map((item) => item.idGame);
    let firstElement = filterFirstElement;//filterPlanet.firstElement;
    let lastElement = firstElement + pageLimit;
    //FIND FILTERPLANE FROM GLOBALSTATE.FILTERPLANETS.
    let filterPlanetFromGlobalState = null;
    let filterPlanetIndexFromGlobalState = globalState.filterPlanets.findIndex(
      (filterPlanetItem) => {
        return (
          filterPlanetItem.type === filterPlanet.type &&
          filterPlanetItem.userAddressId === filterPlanet.userAddressId &&
          areEqual(filterPlanetItem.filter, filterPlanet.filter)
        );
      }
    );
    console.log(
      "QUERYFILTER: GETPLANETS FILTERPLANETINDEXFROMGLOBALSTATE=",
      filterPlanetIndexFromGlobalState
    );
    if (filterPlanetIndexFromGlobalState > -1) {
      filterPlanetFromGlobalState =
        globalState.filterPlanets[filterPlanetIndexFromGlobalState];
      console.log(
        "QUERYFILTER: GETPLANETS FOUND FILTERPLANET FROM GLOBALSTATE=",
        filterPlanetFromGlobalState
      );
      console.log("QUERYFILTER: GETPLANETS FIRST ELEMENT=", firstElement);
      console.log("QUERYFILTER: GETPLANETS LAST ELEMENT=", lastElement);
      console.log("QUERYFILTER: GETPLANETS PLANETIDS=", planetIds);
      console.log(
        "QUERYFILTER: GETPLANETS PLANETIDS.LENGTH=",
        planetIds.length
      );
      if (lastElement > planetIds.length) {
        lastElement = planetIds.length;
      }
      console.log("QUERYFILTER: GETPLANETS LAST ELEMENT=", lastElement);
      for (let i = firstElement; i < lastElement; i++) {
        console.log("QUERYFILTER: GETPLANETS PLANETID=", planetIds[i]);
        let game = null;

        if (
          filterPlanetFromGlobalState.items.findIndex(
            (item) => (     
              item.generalData && item.generalData.pngId === planetIds[i]
            )
          ) === -1
        ) {
          game = await API.graphql(graphqlOperation(getQuery(planetIds[i])));
          console.log(
            "QUERYFILTER: GETPLANETS GAME FROM QUERY GRAPHQLOPERATION=",
            game
          );
          items.push(game.data.getMasterGameById);
        } else {
          game = filterPlanetFromGlobalState.items.find(
            (item) => item.idGame === planetIds[i]
          );
          console.log("QUERYFILTER: GETPLANETS GAME FROM GLOBALSTATE=", game);
          items.push(game);
        } 
      }
      console.log("QUERYFILTER: GETPLANETS ITEMS=", items);
      //PAYLOAD FORMAT:
      // var {items:[],filterPlanet}
      await dispatchGlobal({
        type: "SET_FILTER_PLANETS_ITEMS",
        payload: { items: items, filterPlanet: filterPlanetFromGlobalState },
      });
      getPlanetFilterFlag=false;
      console.log("QUERYFILTER:---UNBLOCKED--- GETPLANETFILTERFLAG=", getPlanetFilterFlag);
      return items;
    } else {
      console.log("QUERYFILTER: GETPLANETS FILTERPLANET NOT FOUND");
      getPlanetFilterFlag=false;
      console.log("QUERYFILTER:---UNBLOCKED--- GETPLANETFILTERFLAG=", getPlanetFilterFlag);
      return null;
    }
  };

  if (!getPlanetFilterFlag) {
    getPlanetFilterFlag = true;
    console.log("QUERYFILTER: ----PASS---- GETPLANETFILTERFLAG=", getPlanetFilterFlag);
    await dispatchGlobal({
      type: "SET_FIRST_ELEMENT",
      payload: {
        type: filterPlanet.type,
        userAddressId: filterPlanet.userAddressId,
        filter: filterPlanet.filter,
        firstElement: filterFirstElement,
      },
    });
    return await getPlanets(
      globalState,
      dispatchGlobal,
      filterPlanet,
      pageLimit,
    );
  }else{
    console.log("QUERYFILTER:---BLOCKED--- GETPLANETFILTERFLAG=", getPlanetFilterFlag);
  }
};

/*
   THIS FUNCTION RECEIVES USER ADDRESS ID 
   AND GET ITEMS FROM GLOBAL_STATE OR
   GET ITEMS FROM METRICS AND STORES IT IN GLOBAL_STATE.
   FINALLY SET THE ITEMS IN SET_ITEMS GALAXY HOOK
   
  */
export const getItemsFromMetrics = async (
  userAddressId,
  setItemsPlanets, //<---this is useState Galaxy HOOK
  globalState,
  dispatchGlobal
) => {
  let gmrf = null;
  // if (globalState.itemsPlanetsGalaxy) {
  //   gmrf = globalState.itemsPlanetsGalaxy;
  //   console.log("QUERYFILTER: GLOBALSTATE GMRF=", gmrf);
  // } else
   
    let query = `query MyQuery {
      getGameMetrics(limit: -2, userAddressId: "${userAddressId}") {
        metricsGame
      }
    }`;

    console.log("QUERYFILTER:", query);
    let result = await API.graphql(graphqlOperation(query));
    let gamegmrf = JSON.parse(result.data.getGameMetrics.metricsGame);
    gmrf = gamegmrf.gmrf;
    console.log(
      "QUERYFILTER: QUERY RESULT FROM METRICS=",
      result,
      " GMRF=",
      gmrf
    );
    
    await dispatchGlobal({ type: "SET_ITEMS_PLANETS_GALAXY", payload: gmrf });
  
    setItemsPlanets(gmrf);
};
