import QueriesComponent from "../../components/QueriesComponent/QueriesComponent";
import { Button, Icon, Segment } from "semantic-ui-react";
import { useEffect, useState, useContext } from "react";
import { getRandomDate } from "../../core/Utils.js";
import UserHeader from "../../components/User/UserHeader";
import { UserContext } from "../../services/userContext";
import { useHistory } from "react-router-dom";

const queries = [];

function generateRandomAddress() {
  const randomArray = new Uint32Array(10);
  window.crypto.getRandomValues(randomArray);
  const address = `0x${(
    randomArray[0].toString(16) +
    randomArray[1].toString(16) +
    randomArray[2].toString(16) +
    randomArray[3].toString(16) +
    randomArray[4].toString(16)
  ).padStart(40, "0")}`;
  return address;
}

const generateRandomDataToQUeries = () => {
  for (let i = 0; i < 93; i++) {
    var subscriptionId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    var querie = {
      // Random Date
      date: new Date(getRandomDate(new Date("2000-01-01"), new Date())),
      // Random Number(0-50) + Random 64 chars Subscription ID
      queryId: Math.floor(Math.random() * 50) + 1 + "-" + subscriptionId,
      // Random Tag
      tag: Math.random().toString(36).substring(2, 15),
      link: "https://test.emotiony.io",
      jsonDataMetrics: {
        // Random hexadecimal Ethereum blockchain AddressId
        userAddressId: generateRandomAddress(),
        filtersData: "dummy filters data",
        metricsResult: "dummy metrics result",
      },
    };
    queries.push(querie);
  }
};

generateRandomDataToQUeries();
console.log("QUERIES-PAGE MODULE-INIT", queries);

const Queries = () => {
  console.log(" QUERIES COMPONENT INIT");
  const [userState, dispatchUser] = useContext(UserContext);

  const history = useHistory();

  useEffect(() => {
    if (userState.userData) {
      console.log("QUERIES-PAGE - USERDATA", userState.userData);
    }
  }, [userState.userData]);

  const handleClickBack = (e) => {
    e.preventDefault();
    history.push("/payperdata");
  };

  return (
    <div>
      <UserHeader
        title={
          <>
            {/* <Button icon circular onClick={handleClickBack}>
              <Icon name="arrow left" size={"tiny"}/>
              
            </Button> */}
            MY QUERIES
          </>
        }
        userData={userState.userData}
      />
      <Segment
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button onClick={handleClickBack}>PAYPERDATA</Button>
      </Segment>
      <QueriesComponent queries={queries} />
    </div>
  );
};

export default Queries;
