import { Subject } from 'rxjs';

console.log("GAMEPARAMS-MODULE-INIT");
const gameSubject = new Subject();

export const gameService = {
    setGameId: game => gameSubject.next(game),
    getGameId: () => gameSubject.asObservable(),
};

export const setGameData = (game) => {
    gameService.setGameId(game);
}

