import React from "react";
import { Dropdown, Label } from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import {PayPerDataContext} from "../../../services/payperDataContext.js";

const opt = [
  {
    key: "Nothing",
    text: "Nothing",
    value: "Nothing",
  }
];

const PayPerDataLocationsGames = () => {
  const [options,setOptions]=useState(opt);
  const [metricsState, dispatchMetrics] = useContext(PayPerDataContext);
  const [values,setValues]=useState([]);

  const renderLabel = (label) => ({
    content: `${label.text}`
  });
  const saveOptionsToContext=(e,{value})=>{
   
    //console.log("saveOptionsToContext:value",value);
    setValues(value);
    dispatchMetrics({type:"SET_LOCATION_FILTERS",payload:value})

  }
  /*
   metricsState.effectiveFilters={
      hashtags:[],
      superEmos:[],
      drivers:[],
      dateRange:{init:null,end:null},
      hoursRange:[],
      locations:[],
      planetids:[]
    }
  */

  useEffect(()=>{
    console.log("METRICSTATE",metricsState); 
    
    let newOpt=[];
    if(metricsState.metricFilters && metricsState.metricFilters.locations)
    {
      for(let i=0;i<metricsState.metricFilters.locations.length;i++){
        let v=metricsState.metricFilters.locations[i]
        if(v.length>1){
          newOpt.push({key:v,text:v,value:v})
        }
      }
      //console.log("OPTIONS",newOpt);
      setOptions(newOpt);
    }    
  },[metricsState.metricFilters]);

  useEffect(()=>{
    
    console.log("PayPerDataLocationsGames:metricsState",metricsState.effectiveFilters)
    
    if(metricsState.effectiveFilters && metricsState.effectiveFilters.locations){
      setValues(metricsState.effectiveFilters.locations);
    }
  }
  ,[metricsState.effectiveFilters]);


  return (
    <>
      <label style={{ width: "190px" }}>Locations </label>
      <Dropdown
        fluid
        openOnFocus
        search
        clearable
        placeholder="Search"
        multiple
        selection
        options={options}
        renderLabel={renderLabel}
        onChange={saveOptionsToContext}
        value={values}
      />
    </>
  );
};

export default PayPerDataLocationsGames;
