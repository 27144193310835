import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
} from "react-share";

import { Button } from "semantic-ui-react";
import "./NetworkShare.scss";

console.log("NETWORKSHARE-COMPONENT-MODULE-INIT");

const NetworkShare = (props) => {
  // const options = [
  //   {
  //     key: 1,
  //     text: (
  //       <FacebookShareButton
  //         url={props.shareUrl}
  //         quote={props.title}
  //         className="some-network__share-button"
  //         data-tooltip="Facebook"
  //         data-position="bottom center"
  //       >
  //         <FacebookIcon size={32} round />
  //       </FacebookShareButton>
  //     ),
  //     value: 1,
  //   },
  //   {
  //     key: 2,
  //     text: (
  //       <TwitterShareButton
  //         url={props.shareUrl}
  //         title={props.separatortitle}
  //         className="some-network__share-button"
  //         data-tooltip="Twitter"
  //         data-position="bottom center"
  //       >
  //         <TwitterIcon size={32} round />
  //       </TwitterShareButton>
  //     ),
  //     value: 2,
  //   },
  //   {
  //     key: 3,
  //     text: (
  //       <TelegramShareButton
  //         url={props.shareUrl}
  //         title={props.title}
  //         className="some-network__share-button"
  //         data-tooltip="Telegram"
  //         data-position="bottom center"
  //       >
  //         <TelegramIcon size={32} round />
  //       </TelegramShareButton>
  //     ),
  //     value: 3,
  //   },
  //   {
  //     key: 4,
  //     text: (
  //       <WhatsappShareButton
  //         url={props.shareUrl}
  //         title={props.title}
  //         separator=":: "
  //         className="some-network__share-button"
  //         data-tooltip="Whatsapp"
  //         data-position="bottom center"
  //       >
  //         <WhatsappIcon size={32} round />
  //       </WhatsappShareButton>
  //     ),
  //     value: 4,
  //   },
  //   {
  //     key: 5,
  //     text: (
  //       <LinkedinShareButton
  //         url={props.shareUrl}
  //         className="some-network__share-button"
  //         data-tooltip="Linkedin"
  //         data-position="bottom center"
  //       >
  //         <LinkedinIcon size={32} round />
  //       </LinkedinShareButton>
  //     ),
  //     value: 5,
  //   },
  //   {
  //     key: 6,
  //     text: (
  //       <PinterestShareButton
  //         url={String(window.location)}
  //         media={`${String(window.location)}/${props.exampleImage}`}
  //         className="some-network__share-button"
  //         data-tooltip="Pinterest"
  //         data-position="bottom center"
  //       >
  //         <PinterestIcon size={32} round />
  //       </PinterestShareButton>
  //     ),
  //     value: 6,
  //   },
  //   {
  //     key: 7,
  //     text: (
  //       <RedditShareButton
  //         url={props.shareUrl}
  //         title={props.title}
  //         windowWidth={660}
  //         windowHeight={460}
  //         className="some-network__share-button"
  //         data-tooltip="Reddit"
  //         data-position="bottom center"
  //       >
  //         <RedditIcon size={32} round />
  //       </RedditShareButton>
  //     ),
  //     value: 7,
  //   },
  // ];

  return (
    <div className="mt--double">
      {/* <Button 
        fluid 
        size="big" 
        // onClick={handleShowShareButtons} 
        className="outlined mt--simple"
      >
        Share planet
      </Button> */}

      <FacebookShareButton
        url={props.shareUrl}
        quote={props.title}
        className="some-network__share-button"
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton
        url={props.shareUrl}
        title={props.separatortitle}
        className="some-network__share-button"
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <TelegramShareButton
        url={props.shareUrl}
        title={props.title}
        className="some-network__share-button"
      >
        <TelegramIcon size={32} round />
      </TelegramShareButton>

      <WhatsappShareButton
        url={props.shareUrl}
        title={props.title}
        separator=":: "
        className="some-network__share-button"
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <LinkedinShareButton
        url={props.shareUrl}
        className="some-network__share-button"
      >
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>

      <PinterestShareButton
        url={String(window.location)}
        media={`${String(window.location)}/${props.exampleImage}`}
        className="some-network__share-button"
      >
        <PinterestIcon size={32} round />
      </PinterestShareButton>

      <RedditShareButton
        url={props.shareUrl}
        title={props.title}
        windowWidth={660}
        windowHeight={460}
        className="some-network__share-button"
      >
        <RedditIcon size={32} round />
      </RedditShareButton>
    </div>
  );
};
export default NetworkShare;
