const varsAndFunctions = {
    controlVar: "control1",
    webSocketLogin: undefined
}

export const showVars = () =>{
    return("---VARS--- : ",varsAndFunctions);
}

export const setVars = (typeVar,valueVar) => {
    varsAndFunctions[typeVar]=valueVar;
    console.log("Setting var:",valueVar," in ",typeVar);
    console.log("---SETVARS--- : ",varsAndFunctions);
}

export const getVars =(typeVar) =>{
    return varsAndFunctions[typeVar]
}