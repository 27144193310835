import React from "react";
import { Card, Button, Image, List, Label } from "semantic-ui-react";
import logo from "../../shared/assets/images/logo-emotiony2.png";

console.log("BOX-COMPONENT-MODULE-INIT");

export const Box = (props) => {

  console.log("---BOX-COMPONENT-INIT---");

  const { price, title, extra, feature } = props;
  const listItems = feature.map((item) => <List.Item>{item}</List.Item>);
  const oneSingleLineItems=feature.map((item)=>{return(item+" ")})

  return (
    <>
      <Card fluid>
        <Card.Content>
          <Label ribbon content="Popular" />

          <Card.Header textAlign="center">
            {title}
            <Image floated="center" size="big" src={logo} wrapped ui={false} />
          </Card.Header>
        
          <Card.Meta textAlign="center">
            <span>{price} month</span>
          </Card.Meta>

          <Card.Description textAlign="center">
            <List>{listItems}</List>
          </Card.Description>
        </Card.Content>

        <Button fluid size="big">
          {extra}
        </Button>
      </Card>
    </>
  );
};


// shows box data in single line
export const SimpleCardBox = (props) => {
  const { handlebutton,price, title, extra, feature } = props;


  const oneSingleLineItems=[feature.map((item)=>{return(item+" ")})]

  // LIST OF SINLE LINE ALL DATA ITEMS
  return(
  <>  
    <Card fluid>
      <Card.Content>
        <Card.Header>{title} <Label tag style={{float: 'right'}}>{price}</Label></Card.Header>
        <Card.Description className="mt--double">{oneSingleLineItems}</Card.Description>
      </Card.Content>

      <Card.Content extra>
        <Button fluid id={title} onClick={handlebutton} className="filled">{extra}</Button>
      </Card.Content>
    </Card>
  </>
  )
}
