import Amplify, { API, graphqlOperation } from "aws-amplify";
import awsconfig from "../../../core/a-cf";
import { resetNextTokenList } from "../../userParams";
var countQ = 0;

var getgamesString = "GETGAMES";
var getheadersString = "GETHEADERS";


export const getGalaxyList= async (
  
  minPlanet,
  numPlanets, 
  filters,
  _userReducer,
  setFilterHeaders
) => {
  
  console.log(" QUERYGALAXY:  GETGALAXYLIST", getgamesString, getheadersString, " MINPLANET=",minPlanet," MAXPLANET:",minPlanet+numPlanets);
  
  

 
  if(filters){
     // CASE HET HEADERS: ALL PLANETS
    if(filters.getheaders){
      console.log(" QUERYGALAXY:  ", getheadersString, "GET ALL HEADER (TO FILTER) ITEMS" );
      var getPlanetsHeaders = await getGalaxyListXpages(
        0,
        filters,
        _userReducer,
        setFilterHeaders
      ) 
        return (getPlanetsHeaders);
    }
     // CASE GET GAMES: CALCULATE EXACTLY PAGE
    if(filters.getgames){
      
      var userData = _userReducer.userState.userData;
      var lengthItems= userData.items.length;
      
      console.log(" QUERYGALAXY:  ", getgamesString, "LENGTH ITEMS=",lengthItems );
      
      if(minPlanet < 0){
        minPlanet = 0;
      }

      if(numPlanets > 9){
        numPlanets = 9;
      }

      if((minPlanet+numPlanets) > (lengthItems)){
        minPlanet = (lengthItems) - numPlanets;
      }

      var page1=Math.floor((minPlanet)/10);
      var page2=Math.floor((minPlanet+numPlanets)/10);
      
      var arrayResp=[];
      
      if(page1===page2){
        var minInPage1=minPlanet%10;
        var maxInPage1=(minPlanet+numPlanets)%10;

        var getPlanetsGames = await getGalaxyListXpages(
          page1,
          filters,
          _userReducer,
          setFilterHeaders
        )

        for(let i = minInPage1 ; i < maxInPage1 ; i++){
            arrayResp.push(getPlanetsGames[i]);
        }
      
      console.log(" QUERYGALAXY:  ", getgamesString," MINPLANET=",minPlanet," MAXPLANET:",minPlanet+numPlanets); 
      console.log(" QUERYGALAXY:  ", getgamesString," getPlanetGames=",getPlanetsGames); 
      console.log(" QUERYGALAXY:  ", getgamesString," PAGE=",page1," min=",minInPage1," max=",maxInPage1);  
      console.log(" QUERYGALAXY:  ", getgamesString," RESP =",arrayResp);
      return (arrayResp);

      }else{
        var minInPage1 = minPlanet%10;
        var maxInPage1 = 10;
        var minInPage2 = 0;
        var maxInPage2 = numPlanets-(10-minInPage1);
         
        var getPlanetsGames1 = await getGalaxyListXpages(
          page1,
          filters,
          _userReducer,
          setFilterHeaders
        )

        var getPlanetsGames2 = await getGalaxyListXpages(
          page2,
          filters,
          _userReducer,
          setFilterHeaders
        )
      
        for(let i = minInPage1 ; i < maxInPage1 ; i++){
          arrayResp.push(getPlanetsGames1[i]);
        }
        for(let i = minInPage2 ; i < maxInPage2 ; i++){
          arrayResp.push(getPlanetsGames2[i]);
        }
        console.log(" QUERYGALAXY:  ", getgamesString," MINPLANET=",minPlanet," MAXPLANET:",minPlanet+numPlanets); 
        console.log(" QUERYGALAXY:  ", getgamesString," getPlanetGames1=",getPlanetsGames1);
        console.log(" QUERYGALAXY:  ", getgamesString," getPlanetGames2=",getPlanetsGames2);  
        console.log(" QUERYGALAXY:  ", getgamesString," PAGE1=",page1," min=",minInPage1," max=",maxInPage1);
        console.log(" QUERYGALAXY:  ", getgamesString," PAGE2=",page2," min=",minInPage2," max=",maxInPage2);   
        console.log(" QUERYGALAXY:  ", getgamesString," RESP =",arrayResp);
        return (arrayResp);
      }
    }

  }
}

const  getGalaxyListXpages = async (
  page,
  filters,
  _userReducer,
  setFilterHeaders
) => {
 

  var cf = 0 + countQ; //COUNT FUNCTIONS QUERYGALAXY
  countQ++;
  var userData = _userReducer.userState.userData;
  /*
   * {userState,dispatchUser}=userReducer;
   * page = 10 planets
   * List Structure:
   * const resultList= data:{
   *      listMasterGames:{
   *          items:[
   *              0: {game1},
   *              1: {game2},
   *              2: {game3}
   *              ...
   *          ]
   *      }
   * }
   * filters.getheaders = true = getlist whit plahetids hashtags, locations, emos, tags
   */
  if (filters) {
    if (filters.getheaders) {
      getgamesString = "";
    }
    if (filters.getgames) {
      getheadersString = "";
    }

    if (filters.getheaders || filters.getgames) {
      // CREATE REQUEST TO GET ALL FILTER DATA
      var nextToken = -1;
      var countQueries = 0;
      var resultPartial = undefined;
      var resultPartialWithoutQuery = undefined;
      var nextTokenWithoutQuery = undefined;
      
      var countWhile=0;
     
      //SECURITY TO WHILE: 900 MAX NEXTTOKENS (9000 planets)
      while (nextToken !== null && countWhile<900) {
        console.log(cf, " QUERYGALAXY: COUNTWHILE=",countWhile, getgamesString, getheadersString);
        countWhile++;
        console.log(cf, " QUERYGALAXY:  ", getgamesString, getheadersString, "NEXTTOKEN=",nextToken);
        let comprobateNextToken = false;
        // NEXT TOKEN COMPROBATION
        let nextTokensFromReducer = undefined;
        // CASE HEADERS
        if (filters.getheaders) {
          console.log(cf, " QUERYGALAXY:  NEXT TOKEN COMPROBATION :  ", getheadersString);
          nextTokensFromReducer =
            _userReducer.userState.userData.nextTokensHeader;
        }
        // CASE GAMES
        if (filters.getgames) {
          console.log(cf, " QUERYGALAXY: NEXT TOKEN COMPROBATION :  ", getgamesString);
          nextTokensFromReducer = _userReducer.userState.userData.nextTokens;
        }
        console.log(cf, " QUERYGALAXY:  ", getgamesString, getheadersString, "NEXT TOKEN=",nextTokensFromReducer);
        // FIRST EXECUTION CASE
        if (nextTokensFromReducer.length === 0) {
          console.log(cf, " QUERYGALAXY: ", getgamesString, getheadersString, " FIRST EXECUTION, NOT COMPROBATE NEXTOKEN" );
        }
        // SET PAGES
        if (filters.getgames) {
          console.log(cf, " QUERYGALAXY: ", getgamesString, " SET PAGES ");
          if (page > ( _userReducer.userState.userData.nextTokensHeader.length) - 1) {
            console.log(cf, " QUERYGALAXY: ", getgamesString," SET PAGES: PAGE HIGHER:", page,"CHANGE!!!");
            page = _userReducer.userState.userData.nextTokensHeader.length - 1;
            console.log(cf, " QUERYGALAXY: ", getgamesString, getheadersString,": SET PAGES: DOWN TO", page);
          }
          if (page < 0) {
            console.log(cf, " QUERYGALAXY: ", getgamesString," SET PAGES: PAGE LOWER:", page,"CHANGE!!!");
            page = 0;
            console.log(cf, " QUERYGALAXY: ", getgamesString," SET PAGES: UP TO ", page
            );
          }
          // IF PAGE NOT IN REDUCER, SET PAGE:
          let isPageInReducer = false;
          for (
            let i = 0;
            i < _userReducer.userState.userData.pages.length;
            i++
          ) {
            let pageInReducer = _userReducer.userState.userData.pages[i];
            if (page === pageInReducer) {
              console.log(cf, " QUERYGALAXY: ", getgamesString, getheadersString,": PAGE IN REDUCER ",page);
              isPageInReducer = true;
              break;
            }
          }

          if (!isPageInReducer) {
            console.log(cf, " QUERYGALAXY: ", getgamesString, getheadersString,": NOT PAGE IN REDUCER: SET THIS ", page);
            await _userReducer.dispatchUser({
              type: "SET_PAGE",
              payload: page,
            });
          }
          nextToken = _userReducer.userState.userData.nextTokensHeader[page];
          console.log(cf, " QUERYGALAXY:  ", getgamesString, " SET PAGES : PAGE=",
            page, " NEXTOKEN=",
            nextToken
          );
        }

        // NEXTOKEN COMPROBATION LOOP
        console.log(cf, "QUERYGALAXY:  ", getgamesString, getheadersString, "NEXTOKEN COMPROBATION LOOP NEXTOKENSFROMREDUCER=",nextTokensFromReducer);
        for (let i = 0; i < nextTokensFromReducer.length; i++) {
          let ntq = nextTokensFromReducer[i];
          //console.log(cf, " QUERYGALAXY: ", getgamesString, getheadersString, " COMPROBATE NEXTOKEN",comprobateNextToken, " NT= ",ntq, " nextToken=",nextToken);
          if (ntq === nextToken) {
            comprobateNextToken = true;
            console.log(cf, " QUERYGALAXY: ", getgamesString, getheadersString," NT=nextToken");
            break;
          }
        }

        // CONTROL NEXTOKEN
        // NOT IN NEXTOKENSFROMREDUCER
        if (!comprobateNextToken) {
          console.log(cf, " QUERYGALAXY: ", getgamesString, getheadersString, " CONTROL NEXTTOKEN  NOT IN NEXTOKENSFROMREDUCER:" );
          // CASE HEADERS
          if (filters.getheaders) {
            console.log(cf, " QUERYGALAXY: ", getheadersString," SET_NEXTTOKENHEADER");
            await _userReducer.dispatchUser({
              type: "SET_NEXTTOKENHEADER",
              payload: nextToken,
            });
          }
          // CASE GAMES
          if (filters.getgames) {
            console.log(cf, " QUERYGALAXY: ", getgamesString, " SET_NEXTTOKEN");
            await _userReducer.dispatchUser({
              type: "SET_NEXTTOKEN",
              payload: nextToken,
            });
          }
        } else {
          console.log(cf, " QUERYGALAXY: ", getgamesString, getheadersString, " CONTROL NEXTTOKEN IN REDUCER: !comprobateNextToken=FALSE" );
        }

        // CORRECTING NEXT NEXT TOKEN (LAMBDA FUNCTION WITH NEXTOKEN-1)
        console.log(cf, "QUERYGALAXY:  ", getgamesString, getheadersString, "CORRECTING NEXT NEXT TOKEN (LAMBDA FUNCTION WITH NEXTOKEN-1)");
        if (nextToken === -1) {
          console.log(cf, " QUERYGALAXY:  ", getgamesString, getheadersString, " NEXTOKEN=NULL" );
          nextToken = null;
        } else {
          console.log(cf, " QUERYGALAXY:  ", getgamesString, getheadersString, " NEXTOKEN= -1" );
          nextToken -= 1;
        }
        // PRINT CORRECTED NEXTTOKEN
        console.log(cf, "QUERYGALAXY:  ", getgamesString, getheadersString, "CORRECTED  NEXT TOKEN =",
          nextToken
        );


        // IF COMPROBATION, (NOT IN NEXTOKENSFROMREDUCER) PROCEED:
        if (!comprobateNextToken) {
          console.log(cf, " QUERYGALAXY:  ", getgamesString, getheadersString, "!COMPROBATE NEXT TOKEN" );
          countQueries++;
          console.log(cf, " QUERYGALAXY: ", getgamesString, getheadersString, " COUNTQUERIES",countQueries);

          // PREPARE QUERY
          var query = undefined;
          console.log(cf, " QUERYGALAXY: ", getgamesString, getheadersString, " PREPARE QUERY" );

          if (filters.getheaders) {
             console.log(cf, " QUERYGALAXY: ", getheadersString," QUERY ITEM HEADERS");
             query = queryItemHeaders(userData.userAddressId, nextToken);
          }
          if (filters.getgames) {
            console.log(cf, " QUERYGALAXY: ", getgamesString," QUERY ITEM GAMES");
            query = queryItemGamesComplete(userData.userAddressId, nextToken);
          }
          console.log(cf, " QUERYGALAXY: ", getgamesString, getheadersString, "  QUERY=",query);

          // IF FILTERGAMES EXECUTE QUERY
          if(filters.getgames){
           resultPartial = await API.graphql(graphqlOperation(query));
           nextToken = resultPartial.data.listeMaster20GamesPartiQL.nextToken;
          }

          if(filters.getheaders){
            // OBTAIN DATA NOT FROM QUERY ; BUT ONLY FROM USERSTATE:
              resultPartialWithoutQuery = { 
                data: {
                  listeMaster20GamesPartiQL: { 
                    items: userData.currentPlanetIds.slice(
                            (countWhile - 1) * 10,
                            (((countWhile - 1) * 10 + 10) <= userData.currentPlanetIds.length) ? 
                              ((countWhile - 1) * 10 + 10) : 
                              (userData.currentPlanetIds.length )).map(value=>{
                                return {idGame:value,generalData:{}}
                              }) 
                }}};
              nextTokenWithoutQuery = ( (((countWhile) * 10) + 1 ) <= userData.currentPlanetIds.length) ? (((countWhile) * 10) + 1 ) : null;
              console.log(cf, " QUERYGALAXY: ", getgamesString, getheadersString," RESULT-PARTIAL-WQ:",resultPartialWithoutQuery, " NEXT-TOKEN-WQ:",nextTokenWithoutQuery);
              resultPartial = resultPartialWithoutQuery;
              nextToken = nextTokenWithoutQuery;
          
          }
          // CASE GET GAMES  
          if (filters.getgames) {
            console.log(cf, " --------- QUERYGALAXY: ", getgamesString," . NEXTTOKEN=NULL --------");
            nextToken = null;
          }
          console.log(cf, " QUERYGALAXY:  ", getgamesString, " NEXT-TOKEN=", nextToken);
          console.log(cf, " QUERYGALAXY:  ", getgamesString, getheadersString, " COUNT QUERIES", countQueries, " resultPartial:",resultPartial);

          
          // CONTROL OF ARRAY ELEMENTS : NO REPEATED ELEMENTS!
          let dataArrNoRepeated = [] 
          
          for (
            let r = 0;
            r < resultPartial.data.listeMaster20GamesPartiQL.items.length;
            r++
          ){
            let item1 = resultPartial.data.listeMaster20GamesPartiQL.items[r];
            let comprobationNotRepeated = false;
            for (
              let q = 0;
              q < dataArrNoRepeated.length;
              q++
            ){
              let item2 = dataArrNoRepeated[q];
              //console.log(cf, " QUERYGALAXY:  ", getgamesString, getheadersString," I1:",item1," I2:",item2);
              if(item1.idGame === item2.idGame){
                comprobationNotRepeated=true;
                break;
              }
            }
            if(!comprobationNotRepeated){
              dataArrNoRepeated.push(item1);
            }

          }
          
          resultPartial.data.listeMaster20GamesPartiQL.items = dataArrNoRepeated;
          console.log(cf, " QUERYGALAXY:  ", getgamesString, getheadersString, " DATA ARR NO REPEATED=",dataArrNoRepeated);
          

          // COMPROBATE ITEMS IN REDUCER:
          let comprobator = false;

          console.log(cf, " QUERYGALAXY:  ", getgamesString, getheadersString, " COMPROBATE ITEMS IN RESULTPARTIAL", resultPartial.data.listeMaster20GamesPartiQL.items);
          
          for (
            let i = 0;
            i < resultPartial.data.listeMaster20GamesPartiQL.items.length;
            i++
          ) {
            var itemPartial =
              resultPartial.data.listeMaster20GamesPartiQL.items[i];
            for (
              let j = 0;
              j < _userReducer.userState.userData.items.length;
              j++
            ) {
              var item = _userReducer.userState.userData.items[j];
              //console.log(cf, " QUERYGALAXY:  ", getgamesString, getheadersString, "ITEM[",j,"]",item,"<<<-->>> ITEMPARTIAL[",i,"]=",itemPartial);
              
              // CASE HEADERS
               if (filters.getheaders && item.idGame === itemPartial.idGame) {
                console.log( cf, " QUERYGALAXY: ", getheadersString, ": EQUAL ITEM.IDGAME=", item.idGame);
                comprobator = true;
                break;
              }
              // CASE GAME
              if (
                filters.getgames && 
                item.generalData.pngId === itemPartial.generalData.pngId
              ) {
                console.log(cf," QUERYGALAXY: ", getgamesString, ": EQUAL ITEM.generalData.pngId=",item.generalData.pngId );
                comprobator = true;
                break;
              }
            }
          }

          // IF NOT, SET THIS!
          if (!comprobator) {
            console.log(cf, " QUERYGALAXY: ", getgamesString, getheadersString," !COMPROBATOR" );
            if (filters.getheaders) {
              console.log(cf, " QUERYGALAXY: ", getheadersString, " !COMPROBATOR : SET_ITEMS_HEADER " );
              await _userReducer.dispatchUser({
                type: "SET_ITEMS_HEADER",
                payload: resultPartial.data.listeMaster20GamesPartiQL,
              });
            }
            if (filters.getgames) {
              console.log(cf, " QUERYGALAXY: ", getgamesString, " !COMPROBATOR : SET_ITEMS_GAMES " );
              await _userReducer.dispatchUser({
                //type: "SET_ITEMS_GAMES",
                type: "SET_ITEMS_GAMES_COMPLETE",
                payload: resultPartial.data.listeMaster20GamesPartiQL,
              });
            }
          }

        }
        else{
          //(FOUND IN NEXTOKENSFROMREDUCER)
          if (filters.getgames) {
            console.log(cf, " FOUND IN NEXTOKENSFROMREDUCER <--------- QUERYGALAXY: ", getgamesString," . NEXTTOKEN=NULL --------");
            nextToken = null;
          }        
        }
      } // END OF WHILE
      

      //CASE HEADERS : SET FILTERHEADERS
      if (filters.getheaders) {
        console.log(cf, "QUERYGALAXY:  ", getheadersString, ": RETURN_userReducer.userState.userData.items",_userReducer.userState.userData.items);
        setFilterHeaders(true);
        return _userReducer.userState.userData.items;
      }

      // CASE GAMES
      if (filters.getgames) {
        console.log(cf, " QUERYGALAXY: ", getgamesString, ":");
        if (resultPartial) {
          console.log(cf, " QUERYGALAXY:  ", getgamesString, ": RESULTPARTIAL=resultPartial.data.listeMaster20GamesPartiQL.items", resultPartial.data.listeMaster20GamesPartiQL.items);
          return (resultPartial.data.listeMaster20GamesPartiQL.items);
        } else {
          console.log(cf, " QUERYGALAXY:  ", getgamesString, ": NOT RESULTPARTIAL:" );
          console.log("----",cf, " QUERYGALAXY:  ", getgamesString, " OBTAIN FROM REDUCER" );        
          var nextTokensForGetGames =
            _userReducer.userState.userData.nextTokensHeader;
          var initialItem = nextTokensForGetGames[page];
          if (initialItem === -1) {
            initialItem = 0;
          } else {
            initialItem -= 1;
          }
          var endItem = initialItem + 10;
          // CORRECT END ITEM TO LENGTH  OF ITEMS IN USERDATA
          if( endItem >  (_userReducer.userState.userData.items.length )) {
             endItem = _userReducer.userState.userData.items.length;
          } 
          console.log(cf, " QUERYGALAXY:  ", getgamesString, ": nextTokens=",nextTokensForGetGames);
          console.log(cf, " QUERYGALAXY:  ", getgamesString, ": INITIAL ELEMENT=(page)=",nextTokensForGetGames);
          console.log(cf, " QUERYGALAXY:  ", getgamesString, ": INITIAL ITEM=",initialItem);
          console.log(cf, " QUERYGALAXY:  ", getgamesString, ": ITEMS < ",endItem, " END ITEM" );
          
          var tempReturnItemsGetGames = [];
          for (let i = initialItem; i < endItem; i++) {
            let item = _userReducer.userState.userData.items[i];
            tempReturnItemsGetGames.push(item);
          }
          console.log(cf, " QUERYGALAXY:  ", getgamesString, ": TMP RETURN ITEMS=",tempReturnItemsGetGames);

          return tempReturnItemsGetGames;
        }
      }
    }
  }
};


const queryItemHeaders = (userAdressId, nextToken) => {
  var filterNextToken = "";
  if (nextToken) {
    filterNextToken = `nextToken: "${nextToken}",`;
  }
  return `query MyQuery {
            listeMaster20GamesPartiQL (${filterNextToken} userAddressId: "${userAdressId}") {
              items {
                idGame
                generalData {
                  date
                }
                emotionsFilter
              }
              nextToken
            }
          }
          `;
};

const queryItemGames = (userAdressId, nextToken) => {
  var filterNextToken = "";
  if (nextToken) {
    filterNextToken = `nextToken: "${nextToken}",`;
  }
  return `query MyQuery {
              listeMaster20GamesPartiQL (${filterNextToken} userAddressId: "${userAdressId}") {
                items {
                  idGame
                  planetOperation
                  userAddressId
                  connectionid
                  connectionData
                  sourceip
                  requesttime
                  emotions  
                  description
                  gameDesign
                  generalData{
                    gameId
                    emoViewConnectionID
                    posnegPlanet
                    geoloc {
                      accuracy
                      latitude
                      longitude
                    }
                    pngId
                    deviceId
                    email
                    username
                  }
                  hashtags
                  tags
                  quote
                  status
                  textGame
                  timestampEpoch
                  favorites
                  location
                  locationFilter
                  locationPretty
                  likes 
                }
                nextToken
              }
            }
            `;
};

const queryItemGamesComplete = (userAdressId, nextToken) => {
  var filterNextToken = "";
  if (nextToken) {
    filterNextToken = `nextToken: "${nextToken}",`;
  }
  return `query MyQuery {
              listeMaster20GamesPartiQL (${filterNextToken} userAddressId: "${userAdressId}") {
                items {
                  idGame
                  planetOperation
                  userAddressId
                  connectionid
                  connectionData
                  sourceip
                  requesttime
                  emotions
                  emotionsFilter  
                  description
                  gameDesign
                  generalData{
                    gameId
                    date
                    emoViewConnectionID
                    posnegPlanet
                    geoloc {
                      accuracy
                      latitude
                      longitude
                    }
                    pngId
                    deviceId
                    email
                    username
                  }
                  hashtags
                  tags
                  quote
                  status
                  textGame
                  timestampEpoch
                  favorites
                  location
                  locationFilter
                  locationPretty
                  likes 
                }
                nextToken
              }
            }
            `;
};