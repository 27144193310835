import React from "react";
// Import Highcharts
import Highcharts from "highcharts/highstock";
// import HcMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
// import DarkUnica from "highcharts/themes/dark-unica";

import { useContext, useEffect, useState } from "react";
import { Super_Emos, Drivers } from "../../../core/Utils.js";

// DarkUnica(Highcharts);
// HcMore(Highcharts);

import { Card } from "semantic-ui-react";
// MODULO DE EXPORTACION HIGHCHARTS
import HighChartExport from "highcharts/modules/exporting";
HighChartExport(Highcharts);

var data = [
  {
    name: "Morning",
    // y: 5,
    // color: "#CC1264",
    sliced: true,
    selected: true,
  },
  {
    name: "Afternoon",
    // y: 2,
    // color: "#6684A7",
    sliced: true,
    selected: true,
  },
  {
    name: "Evening",
    // y: 9,
    // color: "#1C2833"
  },
  {
    name: "Night",
    // y: 2,
    // color: "black"
  },
];

function createOptions(data) {
  return {
    title: {
      text: "When do you feel better?"
    },
    subtitle: {
      text: "Discover your best moment of the day"

    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    legend: {
      align: "right",
      horizontalAlign: "right",
      layout: "vertical",
    },
    chart: {
      type: "pie",
    },
    credits: {
      enabled: false,
    },

    series: [
      {
        data: data,
      },
    ],
  };
}

// Render app with demo chart
function PieTime(props) {
  const { metricsState } = props;

  const [options, setOptions] = useState(createOptions(data));

  useEffect(() => {
    if (metricsState.metricResults && metricsState.metricResults.Timeoftheday) {
      let tod = metricsState.metricResults.Timeoftheday;
      console.log("TIMEOFTHEDAY", tod);
      data[0].y = tod[0].numgames + tod[1].numgames;
      data[1].y = tod[2].numgames + tod[3].numgames;
      data[2].y = tod[4].numgames + tod[5].numgames;
      data[3].y = tod[6].numgames + tod[7].numgames;

      console.log("NEW-TIMEOFTHEDAY", data);
      setOptions(createOptions(data));
    }
  }, [metricsState.metricResults]);
  return (
    <Card fluid>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Card>
  );
}
export default PieTime;
