import { Accordion, Header, Icon } from "semantic-ui-react";
import { useState } from "react";

console.log("EMOTIONSDETAIL-COMPONENT-MODULE-INIT");
export const EmotionsDetail = (props) => {

  console.log("---EMOTIONSDETAIL-COMPONENT-INIT---");

  const { emotions,owner } = props;

  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (event, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  //only shows 30% of emotions if owned...
  // count only emotipns items not null:
  // emotions is an object, not an array
  var emoLen=0;
  for(var i in emotions){
    if(emotions[i]!==null) emoLen++;
  }

  



  console.log("emotions: ", emotions,typeof(emotions),emoLen);
  
  return (
    <Accordion>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={handleClick}
      >
        <Header as="h2">Emotions Detail <Icon name="dropdown" /></Header>
      </Accordion.Title>
      
      <Accordion.Content active={activeIndex === 0}>
        {owner?emotions:emotions.slice(0,(3/10*emoLen))}
        {owner?null:<p>...</p>}
      </Accordion.Content>
    </Accordion>
  );
};
