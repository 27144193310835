import _ from "lodash";
import React from "react";
import { Header, Divider } from "semantic-ui-react";
import { aboutEmotiony, aboutMoney, aboutData, aboutnft } from "./datafaqs";


console.log("BUILDERLAYOUTFAQS-COMPONENT-MODULE-INIT");

const aboutEmotionyRender = _.times(aboutEmotiony.length, (i) => ({
  key: `${aboutEmotiony[i].key}`,
  title: {
    content: (
      <>
        <Header
          as="h4"
          color="grey"
          content={`${aboutEmotiony[i].titleContent}`}
          inverted
        />
        <Divider />
      </>
    )
  },
  content: {
    content: (
      <>
        <p>{`${aboutEmotiony[i].content}`}</p>
      </>
    )
  }
}));

const aboutMoneyRender = _.times(aboutMoney.length, (i) => ({
  key: `${aboutMoney[i].key}`,
  title: {
    content: (
      <>
        <Header
          as="h4"
          color="grey"
          content={`${aboutMoney[i].titleContent}`}
          inverted
        />
        <Divider />
      </>
    )
  },
  content: {
    content: (
      <>
        <p>{`${aboutEmotiony[i].content}`}</p>
      </>
    )
  }
}));

const aboutDataRender = _.times(aboutData.length, (i) => ({
  key: `${aboutData[i].key}`,
  title: {
    content: (
      <>
        <Header
          as="h4"
          color="grey"
          content={`${aboutData[i].titleContent}`}
          inverted
        />
        <Divider />
      </>
    )
  },
  content: {
    content: (
      <>
        <p>{`${aboutData[i].content}`}</p>
      </>
    )
  }
}));

//const aboutnftliteral= 'aboutnft';
//console.log( "size: " + `${ aboutnftliteral.length}`  );
//console.log( "size: " + aboutnft.length  );

const aboutnftRender = _.times(aboutnft.length, (i) => ({
  key: `${aboutnft[i].key}`,
  title: {
    content: (
      <>
        <Header
          as="h4"
          color="grey"
          content={`${aboutnft[i].titleContent}`}
          inverted
        />
        <Divider />
      </>
    )
  },
  content: {
    content: (
      <>
        <p>{`${aboutnft[i].content}`}</p>
      </>
    )
  }
}));

export const propsAboutEmotiony = {
  header: "Emotiony",
  subheader: "Discover your emotions",
  panels: aboutEmotionyRender
};

export const propsAboutMoney = {
  header: " Money and Matics",
  subheader: "Earn money with your hashtags",
  panels: aboutMoneyRender
};

export const propsAboutData = {
  header: " Emotional Data",
  subheader: "Make emotional-data-driven decisions",
  panels: aboutDataRender
};

export const propsAboutnft = {
  header: " Crypto-Art Emo-Planet NFTs",
  subheader: "Create your own NFTs",
  panels: aboutnftRender
};
