import imag from "./fiber/assets/images/image_defaultbb.png";
import iconRotate from "./fiber/assets/images/icon_rotate.png";

import { Label, Image, Header, Button, Input, Icon } from "semantic-ui-react";
import { Suspense, useState, useEffect, useContext, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { webSocketEngine } from "./fiber/websocketEngine.js";
import { OrbitControls, Stars } from "@react-three/drei";
import { Cloud } from "./fiber/cloudEmotion";
import { Core } from "./fiber/coreEmoSphere";
import {
  get18HashTags,
  get18HashTags_chooseValuersInCircles,
} from "./fiber/fakeData.js";
import { getEmotionyNames } from "./fiber/getemotionyname.js";
import { dataGameCreatorInit } from "./fiber/dataGame.js";
import { getDataGame, changeGeneralData } from "./fiber/dataGame.js";

import { setGameData } from "../../services/gameParams.js";
import { IMAGE } from "./fiber/image.js";
import NetworkShare from "../PlanetDetail/UserPlanetDetail/NetworkShare.js";
import { mode } from "../../mode.js";
import ToggleButton from "react-toggle-button";
import { ButtonEmotions } from "./fiber/buttonEmotion";
import { useHistory } from "react-router-dom";
import { showVars } from "../../core/initVarsAndFunctions";
import { UserContext } from "../../services/userContext";

import catImage from "../../shared/assets/images/cat.png";
import catImageBeta from "../../shared/assets/images/cat_beta.png";
import "./GameApp.scss";
import "./Trends.scss";
import { HttpRequest } from "aws-sdk";
import { xor } from "lodash";
import { handlePasswordOnAWSTable } from "../../services/secManagement.js";

console.log("GAMEAPP-COMPONENT-MODULE-INIT");

var emotionList = [
  ["open", 0],
  ["loving", 0],
  ["happy", 0],
  ["interested", 0],
  ["alive", 0],
  ["positive", 0],
  ["peaceful", 0],
  ["strong", 0],
  ["relaxed", 0],
  ["angry", 0],
  ["depressed", 0],
  ["confused", 0],
  ["helpless", 0],
  ["indiferent", 0],
  ["afraid", 0],
  ["hurt", 0],
  ["sad", 0],
  ["judmental", 0],
];

var w = null; // WEBSOCKET ENGINE
const cheerio = require("cheerio");

const GameApp = (props) => {
  //GAMEAPP
  const [listValues, setlistValues] = useState([]);
  const [typeCloud, setTypeCloud] = useState("hashtag");
  const [typeValuesSetting, setTypeValuesSetting] = useState(false);
  const [imgPlanet, setImgPlanet] = useState(imag);
  const [finishGame, setFinishGame] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [notFoundImage, setNotFoundImage] = useState(false);
  const [location, setLocation] = useState(null);
  const [userState, dispatchUser] = useContext(UserContext);
  const [password, setPassword] = useState("");
  const [inputVisible, setInputVisible] = useState(false);
  const passwordInputRef = useRef(null);
  const overlayRef = useRef(null);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputVisible &&
        !passwordInputRef.current.contains(event.target) &&
        !overlayRef.current.contains(event.target)
      ) {
        setPassword("");
        setInputVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputVisible]);

  const handleLetterClick = () => {
    setInputVisible(true);
    setTimeout(() => passwordInputRef.current.focus(), 0);
  };

  console.log("\n\n-----GAME APP INIT ----");
  console.log(showVars());
  console.log("-----GAME APP INIT ----\n\n");
  console.log(" HASHTAGS IN PROPS:", props, props.hashtags);

  var hashtagsFromProps = null;
  if (props.hashtags) {
    var auxHashtags = props.hashtags;
    console.log("AUXHASHTAGS:", auxHashtags);
    if (auxHashtags) {
      var splitaux = auxHashtags.split(",");
      //console.log("SPLIT AUXHASHTAGS:",splitaux);
      hashtagsFromProps = "";
      for (var iH = 0; iH < splitaux.length; iH++) {
        hashtagsFromProps += "#" + splitaux[iH];
        if (iH < splitaux.length - 1) hashtagsFromProps += " ";
      }
    }

    console.log(">>>>HASHTAGS FROM PROPS:", hashtagsFromProps);
  }
  //getGeolocation_jsonp(geolocation_jsonp);

  function getLoc(data) {
    console.log("GETLOC DATAGAME", data.generalData.geoloc);
    // console.log('geolocation');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    //console.log("Latitude: " + position.coords.latitude + "Longitude: " + position.coords.longitude + "Accuraccy:"+position.coords.accuracy);
    setLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      accuracy: position.coords.accuracy,
    });
  }
  const getRandomLetterIndex = () => {
    const letters = mode.phraseAtStartHome;
    var randomIndex = Math.floor(Math.random() * letters.length);
    while (letters[randomIndex] === " ") {
      randomIndex = Math.floor(Math.random() * letters.length);
    }
    return randomIndex;
  };

  useEffect(() => {
    console.log("GAMEAPP USEEFFECT SET TYPECLOUD", typeCloud);
    console.log("GAMEAPP USEEFFECT SET TYPEVALUESETTING", typeValuesSetting);

    if (finishGame && typeCloud === "image") {
      console.log("GAMEAPP USEEFFECT FINISHGAME", finishGame);
      w = new webSocketEngine(mode, notFoundImage, setNotFoundImage);

      var data = getDataGame();

      data.textGame = inputValue;
      data.planetOperation = "planetCreationTest";

      w.setImgPlanet = setImgPlanet;

      //var idK = "00000000000kKey";

      changeGeneralData(data.generalData, w, "");

      const gameId = data.generalData.pngId;
      //setGameData(gameId);
      console.log("gameId", data.gameId);

      w.imgurl = w.url + gameId + ".png";

      // recreate dataGame with new data and websocket

      console.log("FINISHING GAME");
      console.log("DATA GAME EMOTIONY = ", data);
      console.log("w.imgurl = ", w.imgurl);

      if (
        userState &&
        userState.userData &&
        userState.userData.userAddressId &&
        userState.userData.userAddressId.length > 0
      ) {
        console.log(
          "GAMEDATAAPP: CHANGE2: SET USERNAME FROM USERSTATE: previous:",
          data.username
        );
        data.generalData.username = userState.userData.realname;
        console.log(
          "GAMEDATAAPP: CHANGE2: SET USERNAME FROM USERSTATE: new:",
          data.generalData.username
        );
      } else {
        console.log("GAMEDATAAPP: NO CHANGES IN USERNAME=", data.username);
      }

      w.set(data);
      //w.send(data);
    }

    if (!typeValuesSetting && typeCloud === "emotions") {
      setList(getEmotionyNames(dataGameCreatorInit()), typeCloud);
      setTypeValuesSetting(true);

      var data = getDataGame();
      getLoc(data);

      if (userState.userData && userState.userData.userAddressId) {
        console.log("SETTING USERADDRESID", userState.userData.userAddressId);
        data.userAddressId = userState.userData.userAddressId;
      } else {
        console.log(" NO USERADDRESSID EXISTS!!!, NOT SETTED");
      }

      if (userState.userData)
        console.log("GETUSERDATA FROM CONTEXT:", userState.userData);
    }
  }, [typeCloud, userState, typeValuesSetting]);

  useEffect(() => {
    if (location) {
      var dataInLocation = getDataGame();
      if (
        userState &&
        userState.userData &&
        userState.userData.userAddressId &&
        userState.userData.userAddressId.length > 0
      ) {
        console.log(
          "GAMEDATAAPP: CHANGE2: SET USERNAME FROM USERSTATE: previous:",
          dataInLocation.username
        );
        dataInLocation.generalData.username = userState.userData.realname;
        console.log(
          "GAMEDATAAPP: CHANGE2: SET USERNAME FROM USERSTATE: new:",
          dataInLocation.generalData.username
        );
      } else {
        console.log(
          "GAMEDATAAPP: NO CHANGES IN USERNAME=",
          dataInLocation.username
        );
      }

      dataInLocation.generalData.geoloc = location;
      console.log("LOCATIONUSEEFFECT", location);
      console.log("DATAGAME IN EFFECT", dataInLocation);
    }
  }, [location]);

  console.log(typeCloud);

  if (!typeValuesSetting && typeCloud === "hashtag") {
    if (mode.selectHashtagsAutomatic) {
      setList(get18HashTags_chooseValuersInCircles(), typeCloud);
    } else {
      setList(get18HashTags(), typeCloud);
    }

    setTypeValuesSetting(true);
    if (hashtagsFromProps) {
      setInputValue(hashtagsFromProps);
      setlistValues(hashtagsFromProps);

      console.log("PREVIOUS EMOTIONLIST", emotionList);

      console.log("BUTTPLAY HASHTAG INPUT VALUE", inputValue);
      console.log("BUTTPLAY LISTVALUES", listValues);
      //console.log("DATAGAME",getDataGame());
      //getDataGame().textGame=hashtagValue;
      setTypeValuesSetting(false);
      setTypeCloud("emotions");

      //setList(get18EmotionyNames(dataGameCreatorInit()),typeCloud);
      console.log("LAST EMOTIONLIST", emotionList);
      hashtagsFromProps = null;
    }
  }

  if (typeCloud === "hashtag") {
    const randomIndex = getRandomLetterIndex();
    return (
      <>
        <Image
          centered
          size="tiny"
          src={mode.modetestCat ? catImageBeta : catImage}
          wrapped
          ui={false}
        />

        <Header as="h2" className="mt--none">
          {mode.phraseAtStartHome.split("").map((letter, index) => (
            <span key={index}>
              {index === randomIndex && letter !== " " ? (
                <a
                  href="#"
                  onClick={handleLetterClick}
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  {letter}
                </a>
              ) : (
                letter
              )}
            </span>
          ))}
        </Header>

        <Trends
          typeToShow=""
          setInputValue={setInputValue}
          inputValue={inputValue}
        />

        <HashtagButt
          inputValue={inputValue}
          setInputValue={setInputValue}
          setTypeValuesSetting={setTypeValuesSetting}
          typeCloud={typeCloud}
          setTypeCloud={setTypeCloud}
          listValues={listValues}
          setlistValues={setlistValues}
          props={props}
        />

        <Header as="h3" className="mt--double">
          Discover your emotions!
        </Header>

        <ButtPlay
          setTypeValuesSetting={setTypeValuesSetting}
          typeCloud={typeCloud}
          setTypeCloud={setTypeCloud}
          listValues={listValues}
          setlistValues={setlistValues}
          inputValue={inputValue}
        />
        {inputVisible && (
          <div
            ref={overlayRef}
            style={{
              display: inputVisible ? "block" : "none",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              padding: "10px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <input
              type="password"
              placeholder="...Secret"
              ref={passwordInputRef}
              value={password}
              onChange={handlePasswordChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  //setPassword('');
                  handlePasswordOnAWSTable(password,mode,userState,dispatchUser);
                  setInputVisible(false);
                }
              }}
              style={
                {
                  /* Estilos adicionales para el cuadro de input */
                }
              }
            />
          </div>
        )}
      </>
    );
  } else if (typeCloud === "emotions") {
    return (
      <>
        <GameAppEmotiony
          setImgPlanet={setImgPlanet}
          typeCloud={typeCloud}
          setTypeCloud={setTypeCloud}
          listValues={listValues}
          setlistValues={setlistValues}
          setFinishGame={setFinishGame}
          inputValue={inputValue}
        />

        <OnlyEndGameButton
          setTypeCloud={setTypeCloud}
          setImgPlanet={setImgPlanet}
          setFinishGame={setFinishGame}
          props={props}
        />

        <Image
          centered
          size="mini"
          src={mode.modetestCat ? catImageBeta : catImage}
          wrapped
          ui={false}
        />
      </>
    );
  } else {
    return (
      <>
        <IMAGE
          src={imgPlanet}
          alt_txt={imgPlanet}
          width="100%"
          notFoundImage={notFoundImage}
          setNotFoundImage={setNotFoundImage}
          wengine={w}
          finishGame={finishGame}
          setFinishGame={setFinishGame}
        />

        <ReplayShareButton
          imgPlanet={imgPlanet}
          setlistValues={setlistValues}
          typeCloud={typeCloud}
          setTypeCloud={setTypeCloud}
          setTypeValuesSetting={setTypeValuesSetting}
          setImgPlanet={setImgPlanet}
          setFinishGame={setFinishGame}
          setInputValue={setInputValue}
          notFoundImage={notFoundImage}
          props={props}
        />
      </>
    );
  }
};

const handleClickIndicationGame = (event) => {
  console.log("CLICKED INDICATION GAME", event);
  var button = document.getElementById("buttIndic");
  button.style.display = "none";
};

export default GameApp;

const GameAppEmotiony = ({
  setImgPlanet,
  typeCloud,
  setTypeCloud,
  listValues,
  setlistValues,
  setFinishGame,
  inputValue,
}) => {
  // TOOGLE SETTINGS

  const [checkToogle, setCheckToogle] = useState(false);

  function toogleFunction() {
    console.log("toogleFunction");
    //const checkbox = document.getElementById("toogle");
    //console.log("CHECKBOX",checkbox)
    //checkbox.ckecked = !checkbox.checked;
    setCheckToogle(!checkToogle);
  }

  return (
    <div className="emotionsList">
      {/* <Header as="h1">Rate your emotions for:</Header> */}
      <Header as="h1">{inputValue}</Header>

      <p>Rate emotions from xxxx to xxxx or discard it.</p>

      <ButtonEmotions
        setImgPlanet={setImgPlanet}
        emotionList={emotionList}
        listValues={listValues}
        setlistValues={setlistValues}
        typeCloud={typeCloud}
        setTypeCloud={setTypeCloud}
        setFinishGame={setFinishGame}
        inputValue={inputValue}
      />

      {/* {
        checkToogle && typeCloud === "emotions" ? (
          <Canvas
            shadowMap
            colorManagement
            dpr={[1, 2]}
            camera={{
              position: [0, 0, 35],
              fov: 90,
              zoom: mode.ZoomCanvas,
            }}
            concurrent
          >
            <Suspense fallback={null}>
              <fog attach="fog" args={["#061610", 18, 45]} />
              <ambientLight intensity={0.5} />
              <directionalLight position={[0, 0, 51]} intensity={1450} />
              <pointLight position={[0, -10, 5]} intensity={900} />
              <Stars
                radius={100}
                depth={50}
                count={1000}
                factor={3}
                saturation={1}
                fade="true"
              />
              <Cloud
                setImgPlanet={setImgPlanet}
                emotionList={emotionList}
                listValues={listValues}
                setlistValues={setlistValues}
                type={typeCloud}
                setTypeCloud={setTypeCloud}
                setFinishGame={setFinishGame}
                inputValue={inputValue}
                radius={mode.RadiusEmoSphere}
              />
              )
              <OrbitControls
                enableZoom={false}
                enablePan={false}
                autoRotate
                autoRotateSpeed="1"
              />
              <Core type={typeCloud} />
            </Suspense>
          </Canvas>
        ) : typeCloud === "emotions" ? (
          <ButtonEmotions
            setImgPlanet={setImgPlanet}
            emotionList={emotionList}
            listValues={listValues}
            setlistValues={setlistValues}
            typeCloud={typeCloud}
            setTypeCloud={setTypeCloud}
            setFinishGame={setFinishGame}
            inputValue={inputValue}
          />
        ) : null
      } */}
      {/* <div>
        {false && (
          <ToggleButton
            inactiveLabel={"Butt."}
            activeLabel={"Sph."}
            colors={{
              activeThumb: {
                base: "rgb(50,50,50)",
              },
              inactiveThumb: {
                base: "rgb(62,30,77)",
              },
              active: {
                base: "rgb(62,30,77)",
                hover: "rgb(92,70,127)",
              },
              inactive: {
                base: "rgb(65,66,68)",
                hover: "rgb(95,96,98)",
              },
            }}
            value={checkToogle}
            onToggle={toogleFunction}
          />
        )}
      </div> */}
    </div>
  );
};

const Trends = ({ typeToShow, setInputValue, inputValue }) => {
  //Todo: GET TYPE TO SHOW ITEMS, IF EMPTY GET TWITTER TRENDS: https://developer.twitter.com/en/docs/twitter-api/v1/trends/trends-for-location/api-reference/get-trends-place

  // TRENDS FROM EXTERN JSON

  let trends = emotionList.map((item) => item[0]); //get18HashTags();

  const list = trends.slice(0, 8).map((trend, i) => (
    <Label
      as="button"
      key={i}
      className="trends__list__item"
      onClick={handleClickTrendButton}
      data-value={trend}
    >
      {trend}
    </Label>
  ));

  function handleClickTrendButton(event) {
    var i = inputValue;
    if (i === "" || i === undefined || i === null) {
      setInputValue(event.target.dataset.value);
    } else {
      var e = event.target.dataset.value;
      if (!i.includes(e)) {
        setInputValue(i + " " + e);
      } else {
        // BLINK INIT
        var blink = setInterval(function () {
          setInputValue(i.replace(e, ""));
          setTimeout(function () {
            setInputValue(i);
          }, 67);
        }, 125);
        setTimeout(function () {
          clearInterval(blink);
        }, 250);
        // BLINK END
      }
    }
  }

  return (
    <div className="trends">
      {/* <Header className="trends__title" as="h3">
        Actual trends
      </Header> */}
      <div className="trends__list">{list}</div>
    </div>
  );
};

const ButtPlay = ({
  setTypeValuesSetting,
  typeCloud,
  setTypeCloud,
  listValues,
  setlistValues,
  inputValue,
}) => {
  const [activePlay, setActivePlay] = useState(false);

  function handleClickButton(event) {
    event.stopPropagation();

    console.log("HANDLECLICKBUTTON", event);
    setHashtagsAndChangeEmotionCloud(false);
  }

  function setHashtagsAndChangeEmotionCloud(typeValueSetting) {
    console.log("PREVIOUS EMOTIONLIST", emotionList);

    console.log("BUTTPLAY HASHTAG INPUT VALUE", inputValue);
    console.log("BUTTPLAY LISTVALUES", listValues);
    //console.log("DATAGAME",getDataGame());
    //getDataGame().textGame=hashtagValue;
    setTypeValuesSetting(typeValueSetting);
    setTypeCloud("emotions");

    //setList(get18EmotionyNames(dataGameCreatorInit()),typeCloud);
    console.log("LAST EMOTIONLIST", emotionList);
  }

  useEffect(() => {
    if (inputValue.length > 2) setActivePlay(true);
    else setActivePlay(false);
  }, [inputValue]);

  return (
    <Button
      fluid
      size="big"
      disabled={!activePlay}
      onClick={handleClickButton}
      className="filled mt--double"
    >
      Play
    </Button>
  );
};

const HashtagButt = ({
  inputValue,
  setInputValue,
  setTypeValuesSetting,
  typeCloud,
  setTypeCloud,
  listValues,
  setlistValues,
  props,
}) => {
  const handleClickInputValue = (e) => {
    e.stopPropagation();
    let input = e.target;
    let end = input.value.length;
    console.log("HANDLECLICKINPUTVALUE", e, input, end);
    input.setSelectionRange(end, end);
    input.focus();
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      //INTRO KEY!!!

      console.log("PREVIOUS EMOTIONLIST", emotionList);

      console.log("BUTTPLAY HASHTAG INPUT VALUE", inputValue);
      //console.log("BUTTPLAY LISTVALUES", listValues);

      setTypeValuesSetting(false);
      setTypeCloud("emotions");

      console.log("LAST EMOTIONLIST", emotionList);
    }
  };

  const handleChangeInputValue = async (e) => {
    e.stopPropagation();

    setInputValue(e.target.value);
    console.log(e.target.value, e.nativeEvent.inputType);
    if (
      e.nativeEvent.inputType &&
      e.nativeEvent.inputType.includes("insertFromPaste")
    ) {
      //HANDLEPASTE
      console.log("HANDLE PASTE EVENT", e);
      let inputPaste = e.target.value;
      console.log("PASTE:", inputPaste);

      //PARSE CLIPBOARD PASTE:
      let subPaste = inputPaste.substring(0, 8);
      console.log("SUBPASTE:", subPaste);
      if (
        inputPaste.substring(0, 8) === "https://" ||
        inputPaste.substring(0, 7) === "http://"
      ) {
        //PARSE HTTP
        console.log("IS HTTP(S):", inputPaste);
        // ANALIZING URL ; GET ALL TEXT CONTENT OF
        // URL PAGE AND ANALIZING ALL TEXT
        // OPEN URL; READ ALL TEXT IN THE WEB PAGE,
        // LOCATE #HASHTAGS IN THIS AND ADDING TO (phrase)
        let phrase = "";
        // GET TEXT FROM URL
        phrase = await getHashtagsFromWebpage(inputPaste);

        console.log("PHRASE CATCH:", phrase);

        //phrase = "
        //
        //  #hashtag1 #hashtag2 #hashtag3
        //  #hashtag4 #hashtag5 #hashtag6
        //  #hashtag7 #hashtag8 #hashtag9
        //  #hashtag10 #hashtag11 #hashtag12
        //  #hashtag13 #hashtag14 #hashtag15
        //  #hashtag16 #hashtag17 #hashtag18
        //  #hashtag19 #hashtag20 #hashtag21
        //  ...
        //  #hashtagN
        //  "
        //

        console.log("PHRASE CATCH:", phrase);
        setInputValue(phrase);
        //setlistValues(phrase);
      } else {
        //const inputPaste = "Texto con #hashtags y #etiquetas.";
        const regex = /#[^\s#]*/g;
        const hashtags = inputPaste.match(regex);
        const phrase = hashtags ? hashtags.join(" ") : "";
        console.log("PHRASE CATCH:", phrase);
        setInputValue(phrase);
        //setlistValues(phrase);
      }
    }

    if (e.nativeEvent.inputType === "deleteContentBackward") {
      console.log("DELETING");
    }
  };

  const getHashtagsFromWebpage = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch page: ${response.status}`);
      }
      const html = await response.text();
      const $ = cheerio.load(html);
      let hashtags = "";
      $("*").each((i, element) => {
        const text = $(element).text();
        const regex = /#[^\s]+/g;
        let match;
        while ((match = regex.exec(text)) !== null) {
          hashtags += match[0] + " ";
        }
      });
      hashtags = hashtags.trim();
      return hashtags;
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  return (
    <Input
      iconPosition="left"
      placeholder={mode.hashtagPlaceHolder}
      onChange={handleChangeInputValue}
      onClick={handleClickInputValue}
      onKeyDown={handleKeyDown}
      value={inputValue}
    >
      {/* <Icon name="hashtag" /> */}
      <input />
    </Input>
  );
};

const OnlyEndGameButton = ({
  setTypeCloud,
  setImgPlanet,
  setFinishGame,
  ...props
}) => {
  function handleClickonlyEndGameButt(event) {
    console.log("FINISH GAME EVENT handleClickonlyEndGameButt", event);
    event.stopPropagation();
    setTypeCloud("image");
    setFinishGame(true);
  }

  return (
    <Button
      fluid
      size="big"
      onClick={handleClickonlyEndGameButt}
      className="filled"
    >
      Discover your planet
    </Button>
  );
};

const ReplayShareButton = ({
  imgPlanet,
  setlistValues,
  typeCloud,
  setTypeCloud,
  setTypeValuesSetting,
  setImgPlanet,
  setFinishGame,
  setInputValue,
  notFoundImage,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const [userState, dispatchUser] = useContext(UserContext);

  const history = useHistory();
  console.log("REPLAYSHAREBUTTON imgPlanet", imgPlanet);
  var uriPlanet = imgPlanet
    ? imgPlanet.split(".")[imgPlanet.split(".").length - 2].split("/")[1]
    : "";
  if (uriPlanet) uriPlanet = uriPlanet.substring(0, uriPlanet.length - 1);
  console.log("URIPLANET=", uriPlanet);
  let planetDetail = "/planetdetail/" + uriPlanet;
  let myMetrics = "/metrics";
  let myGalaxy = "/mygalaxy/" + userState.userData.realname;

  const shareButtonPropsFa = {
    url:
      //imgPlanet,
      //"https://dev.emotiony.io/share/poster/"+uriPlanet,
      "https://emo-htmlfileshare.s3.eu-west-3.amazonaws.com/" +
      uriPlanet +
      ".html",

    network: "twitter",
    text: uriPlanet,
    longtext: "EMOTIONY PLANET ",
    //"Social sharing buttons for React. Use one of the build-in themes or create a custom one from the scratch."
  };

  function handleClickReplayShareButt(event) {
    console.log("EVENT handleClickReplayShareButt", event);
    event.stopPropagation();

    setTypeValuesSetting(false);
    setImgPlanet(imag);
    setFinishGame(false);
    setInputValue("");
    setlistValues([]);
    setTypeCloud("hashtag");
    history.push("/"); // window.location.href = "/";
  }

  useEffect(() => {
    console.log("ReplayShareButton - CHANGE imgPlanet:", imgPlanet);
    console.log("ReplayShareButton - CHANGE URIPLANET:", uriPlanet);

    if (typeCloud === "image") {
      if (imgPlanet.includes("planetsimagelowresolution")) {
        console.log(imgPlanet, "CONTAINS planetsimagelowresolution");
        console.log("POSTEERRRRR", shareButtonPropsFa.url);
        setActive(true);
      } else {
        setActive(false);
      }

      if (notFoundImage) {
        console.log("USE EFFECT NOT FOUND IMAGE");
        setActive(true);
      }
    }
  }, [imgPlanet, notFoundImage]);

  return (
    <>
      <div>
        <Button
          as="a"
          fluid
          size="big"
          disabled={!active}
          className="outlined mt--simple"
          href={myGalaxy}
        >
          My Galaxy
        </Button>

        <Button
          as="a"
          fluid
          size="big"
          disabled={!active}
          className="outlined mt--simple"
          href={planetDetail}
        >
          Planet detail
        </Button>

        <Button
          fluid
          size="big"
          id="replayShareButt"
          onClick={handleClickReplayShareButt}
          disabled={!active}
          className="filled mt--simple"
        >
          Play again
        </Button>

        <Button
          as="a"
          fluid
          size="big"
          disabled={!active}
          className="outlined mt--simple"
          href={myMetrics}
        >
          My Metrics
        </Button>

        {!active || notFoundImage ? null : (
          <NetworkShare
            shareUrl={shareButtonPropsFa.url}
            title={shareButtonPropsFa.longtext}
            exampleImage={imgPlanet}
          />
        )}
      </div>
    </>
  );
};

function setList(list, type) {
  emotionList = [];

  for (var i = 0; i < mode.NumEmotions; i++) {
    console.log(" = list[" + i + "]:" + list[i]);
    emotionList.push([list[i], 0]);
  }
}
