/* eslint-disable no-unused-vars */
console.log("APPPARAMS-MODULE-INIT");

export const appParams = () => {

    var device = 'web';
    var filterVisible = true;
}
export const providersList = [
    {
        "name": "MetaMask",
        "logo": "MetaMask_color.png",
        "activator": "window.ethereum"
    }
];
