import {QueryMetricsTest} from "./QueryMetricsTest";


console.log("MAINPROBE: TEST");

export const MainProbe = () => {

    
  return (
    <div>
        <h1>MAINPROBE: TEST</h1>
       
        <QueryMetricsTest />
    </div>
  );
}