import React from "react";
import { Dropdown, Label } from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import {PayPerDataContext} from "../../../services/payperDataContext.js";

const options = [
  {
    key: "EMO1_0",
    text: "Open",
    value: "EMO1_0",
    description:
      "understanding, confident, friendly, connected, flexible, sympathetic"
  },
  {
    key: "EMO2_0",
    text: "Loving",
    value: "EMO2_0",
    description: "considerate, affectionate, sensitive, tender, warm, attracted"
  },
  {
    key: "EMO3_0",
    text: "Happy",
    value: "EMO3_0",
    description: "blissful, joyous, delighted, overjoyed, glad, thankful"
  },
  {
    key: "EMO4_0",
    text: "Interested",
    value: "EMO4_0",
    description: "fascinated, intrigued, absorbed, amazed, engrossed, curious"
  },
  {
    key: "EMO5_0",
    text: "Alive",
    value: "EMO5_0",
    description:
      "playful, courageous, energetic, liberated, optimistic, animated"
  },
  {
    key: "EMO6_0",
    text: "Positive",
    value: "EMO6_0",
    description:
      "motivated, constructive, creative, inspired, enthusiastic, brave"
  },
  {
    key: "EMO7_0",
    text: "Peaceful",
    value: "EMO7_0",
    description: "calm, good, at ease, comfortable, pleased, quiet"
  },
  {
    key: "EMO8_0",
    text: "Strong",
    value: "EMO8_0",
    description: "honest, sure, unique, complete, energized, hardy"
  },
  {
    key: "EMO9_0",
    text: "Relaxed",
    value: "EMO9_0",
    description: "glowing, radiant, unhurried, fluid, meditative, centered"
  },

  {
    key: "EMO10_0",
    text: "Angry",
    value: "EMO10_0",
    description: "irritated, enraged, hostile, insulting, frustrate, upset"
  },
  {
    key: "EMO11_0",
    text: "Depressed",
    value: "EMO11_0",
    description:
      "disappointed, discouraged, ashamed, powerless, diminished, guilty"
  },
  {
    key: "EMO12_0",
    text: "Confused",
    value: "EMO12_0",
    description: "doubtful, shy, indecisive, perplexed, embarrassed, hesitant"
  },
  {
    key: "EMO13_0",
    text: "Helpless",
    value: "EMO13_0",
    description: "incapable, alone, paralyzed, fatigued, useless, inferior"
  },
  {
    key: "EMO14_0",
    text: "Indiferent",
    value: "EMO14_0",
    description: "insensitive, dull, robotic, tired, cold, preoccupied"
  },
  {
    key: "EMO15_0",
    text: "Afraid",
    value: "EMO15_0",
    description: "fearful, terrified, nervous, anxious, alarmed, panicke"
  },
  {
    key: "EMO16_0",
    text: "Hurt",
    value: "EMO16_0",
    description: "crushed, tormented, victimized, pained, tortured, rejected"
  },
  {
    key: "EMO17_0",
    text: "Sad",
    value: "EMO17_0",
    description: "tearful, sorrowful, anguished, desolate, unhappy, lonely"
  },
  {
    key: "EMO18_",
    text: "Judmental",
    value: "EMO18_0",
    description: "stony, serious, blunt, hard, brutal, disgusted"
  }
];

const PayPerDataSuperEmos = () => {
  const renderLabel = (label) => ({
    content: `${label.text}`
  });

  const [metricsState, dispatchMetrics] = useContext(PayPerDataContext);
  const [value, setValue] = useState(metricsState.superEmosFilters);

  const saveOptionsToContext=(e,{value})=>{
    console.log("saveOptionsToContext:value",value);
    setValue(value);
    dispatchMetrics({type:"SET_SUPEREMOS_FILTERS",payload:value})
  }

  /*
   metricsState.effectiveFilters={
      hashtags:[],
      superEmos:[],
      drivers:[],
      dateRange:{init:null,end:null},
      hoursRange:[],
      locations:[],
      planetids:[]
    }
  */

 useEffect(()=>{

  console.log("PayPerDataSuperEmos:metricsState",metricsState.effectiveFilters);
  
  if(metricsState.effectiveFilters.superEmos.length>0){
    setValue(metricsState.effectiveFilters.superEmos);
  }
  
 },[metricsState.effectiveFilters]);


  return (
    <>
      <label style={{ width: "190px" }}>SuperEmos </label>
      <Dropdown
        fluid
        openOnFocus
        search
        clearable
        placeholder="Search"
        multiple
        selection
        options={options}
        renderLabel={renderLabel}
        onChange={saveOptionsToContext}
      />
    </>
  );
};

export default PayPerDataSuperEmos;
