import { Header, Icon, Input, List } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { updateTags } from "../../../services/Graphql/Game/updateGame.js";
import Chip from "../../commons/chip";
import { updateUserTags } from "../../../services/Graphql/User/updateUser.js";
import { userId } from "../../../services/userParams.js";

let gameId = "";
let tagValues = [];


console.log("TAGS-COMPONENT-MODULE-INIT");
const Tags = (props) => {
  console.log("---TAGS-COMPONENT-INIT---");
  var [activeIndex, setActiveIndex] = useState(null);
  var [tag, setTag] = useState("");
  var [listTags, setListTags] = useState(null);
  var [planetOperation, setPlanetOperation] = useState(null);
  var [owner, setOwner] = useState(false);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const setTagsData = (tags) => {
    gameId = props.gameData.idGame;
    if (tags) {
      tagValues = owner?tags:tags.slice(0, 3/10*tags.length);
      const listTags = tags.map((d, i) => {
        const e = { title: d };
        return tags.length > 0 ? (
          <Chip key={i} e={e} i={i} deselectItem={deselectItem}></Chip>
        ) : null;
      });
      setListTags(listTags);
    }
  };
  const setTgs = (e, { value }) => {
    setTag(value);
  };
  const clearTgs = () => {
    setTag("");
  };
  const addTag = () => {
    if (tag !== "") {
      tagValues.push(tag);
      clearTgs();
      updateTagsData();
    }
  };
  const deselectItem = (e, i) => {
    tagValues.splice(i, 1);
    tag = e.title;
    updateTagsData(true);
  };
  const updateTagsData = async (remove = false) => {
    if (userId) {
      console.log("UPDATEUSERTAG");
      await updateUserTags(userId, tag, remove);
    }
    //console.log("PLANET OPERATION=",planetOperation,props.gameData.planetOperation);
    await updateTags(
      gameId,
      planetOperation ? planetOperation : props.gameData.planetOperation,
      tagValues
    );
    setTagsData(tagValues);
  };
  useEffect(() => {
    if (
      props &&
      props.gameData &&
      props.gameData.tags &&
      props.gameData.planetOperation
    ) {
      setTagsData(props.gameData.tags);
      setPlanetOperation(props.gameData.planetOperation);
      console.log(
        "SET PLANET OPERATION IN TAGS",
        props.gameData.planetOperation
      );
    }
    if (
      props &&
      props.owner){
        setOwner(props.owner);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gameData,props.owner]);

  return (
    <>
      <Header as="h2">Tags</Header>

      <Input
        as="div"
        icon={<Icon name="check" link disabled={!owner} onClick={addTag} />}
        placeholder='Add tag...'
        onChange={setTgs}
        value={tag}
        disabled={!owner}
      />

      {listTags}
    </>
  );
};

export default Tags;
