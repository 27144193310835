import { emotionyLanguage } from "./emotionyLanguage.js";
import { mode } from "../../../mode.js";

var numPositives = 0;
var numNegatives = 0;
var numEmotionys = mode.NumEmotions;
var emolang = new emotionyLanguage();
//var language="català";
//var language = "castellano";
var language = "english";

emolang.setlanguage(language);
var emotions = [];

console.log("GETEMOTIONYMAME-COMPONENT-MODULE-INIT");

export function getEmoLanguage() {
  return language;
}

export function getEmotionyName(num, dataGame) {
  console.log("---GETEMOTIONYMAME-COMPONENT-INIT---");

  let name;
  let select = false;
  let nameInEmotion;
  let l = 0;
  let grDefinitive = 0;
  let eDefinitive = 0;
  if (num === -1) {
    //console.log("RESET EMOTIONYS: EMOTIONS[].length=0, numPositives=0, numNegatives=0");
    numPositives = 0;
    numNegatives = 0;
    emotions.length = 0;
    dataGame.emotions.length = 0;
  } else {
    if (num >= 0 && num < numEmotionys) {
      while (!select && numPositives + numNegatives < numEmotionys) {
        let gr = Math.floor(Math.random() * 9);
        let e = Math.floor(Math.random() * 6);
        let posNeg = Math.random() * 20;

        if (posNeg < mode.NumEmotions / 2 + 2) {
          //POSITIVES
          if (numPositives < mode.NumEmotions / 2) {
            name = emolang.getEmotion(gr, e);
            numPositives++;
            grDefinitive = gr;
            eDefinitive = e;
            //console.log(grDefinitive,e,name," positives:",numPositives);
            select = true;
          }
        } else {
          //NEGATIVES
          if (numNegatives < mode.NumEmotions / 2) {
            name = emolang.getEmotion(gr + 9, e);
            numNegatives++;
            grDefinitive = gr + 9;
            eDefinitive = e;
            //console.log(grDefinitive,e,name," negatives:",numNegatives);
            select = true;
          }
        }
        if (!select) {
          console.log("UNDEFINED:", name, " REPEATING SELECTION");
        } else {
          for (l = 0; l < emotions.length; l++) {
            nameInEmotion = emotions[l];
            //console.log("[",l,"] COMPARING:",name, "WITH ",nameInEmotion,":","CDOMPARE=:",(name==nameInEmotion));

            if (name === nameInEmotion) {
              select = false;
              if (posNeg < 11) {
                numPositives--;
              } else {
                numNegatives--;
              }
              //console.log("REPEATED,", name," = ",nameInEmotion, " REPEATING SELECTION");
              break;
            }
          }
        }
      }
    }
    //let name="Emotiony "+num;
    let eEmo = "EMO" + (grDefinitive + 1) + "_" + (eDefinitive + 1);
    dataGame.emotions.push([eEmo, 0]);

    //console.log(">>>>>>>>>>>>>>>>>>>>>> PUSH EMOTION: >>>>>>>>>>>>: [ ",name," ]:[",eEmo,"]");
    emotions.push(name);
    return name;
  }
}

export function getEmotionyNames(dataGame) {
  console.log("---GETEMOTIONYMAMES-COMPONENT-MODULE-INIT---");
  //reset emotions
  getEmotionyName(-1, dataGame);
  // set emotions
  for (var i = 0; i < numEmotionys; i++) {
    getEmotionyName(i, dataGame);
  }

  return emotions;
}

export function getNameEmotionFromCode(code) {
  // EXAMPLE: "EMO10_3"
  var emoResult = undefined;
  var [emoGroup, emoNum] = code.split("_").map((value) => {
    if (value.substring(0, 3) === "EMO") {
      value = value.split("EMO")[1];
      if (value === "" || !(value >= 1 && value <= 18)) value = -1;
    } else {
      if (!(value >= 1 && value <= 6)) value = -1;
    }
    return value;
  });

  if (!(emoGroup === -1 || emoNum === -1)) {
    emoGroup -= 1;
    emoNum -= 1;
    emoResult = emolang.getEmotion(emoGroup, emoNum);
  }
  return emoResult;
}

export function getCodeEmotionFromName(name) {
  return emolang.getEmotionCode(name);
}

export function getEmotionsArraysFromPropsFromDinamo(
  emotionsArrayStringFromPropsFromDinamo
) {
  let emotionsArrayCodesAux = undefined;
  if (!Array.isArray(emotionsArrayStringFromPropsFromDinamo)) {
    let emotionsWithQuotes = emotionsArrayStringFromPropsFromDinamo
      .replace(/(\w+)/g, '"$1"')
      .replace(/(-\")(.*?)(\")/g, function (match, p1, p2, p3) {
        return '"-' + p2 + '"';
      });

    emotionsArrayCodesAux = JSON.parse(emotionsWithQuotes);
  } else {
    emotionsArrayCodesAux = emotionsArrayStringFromPropsFromDinamo;
  }

  let emotionsArrayCodes = emotionsArrayCodesAux.map((em) => [
    em[0],
    parseInt(em[1]),
  ]);

  let emotionsNames = emotionsArrayCodes.map((value) => {
    return [getNameEmotionFromCode(value[0]), value[1]];
  });

  return {
    emotionsArrayCodes: emotionsArrayCodes,
    emotionsNames: emotionsNames,
  };
}

export function getSuperEmotionArrayNamesFromPropsFromDinamo(
  emotionsArrayStringFromPropsFromDinamo
) {
  let emotionsArrayCodesAux = undefined;
  if (!Array.isArray(emotionsArrayStringFromPropsFromDinamo)) {
    let emotionsWithQuotes = emotionsArrayStringFromPropsFromDinamo
      .replace(/(\w+)/g, '"$1"')
      .replace(/(-\")(.*?)(\")/g, function (match, p1, p2, p3) {
        return '"-' + p2 + '"';
      });
    emotionsArrayCodesAux = JSON.parse(emotionsWithQuotes);
  } else {
    emotionsArrayCodesAux = emotionsArrayStringFromPropsFromDinamo;
  }

  let emotionsArrayCodes = emotionsArrayCodesAux.map((em) => [
    em[0],
    parseInt(em[1]),
  ]);

  let superEmotionsArrayNames = emotionsArrayCodes.map((value) => {
    return [getNameSuperEmotionFromEmotionyCode(value[0]), value[1]];
  });

  return superEmotionsArrayNames;
}

export function getSuperEmotionArrayNamesWithoutRepetitionsFromPropsFromDinamo(
  emotionsArrayStringFromPropsFromDinamo
) {
  let resultPartial = getSuperEmotionArrayNamesFromPropsFromDinamo(
    emotionsArrayStringFromPropsFromDinamo
  );
  let aggregated = {};

  resultPartial.forEach(([key, value]) => {
    if (aggregated.hasOwnProperty(key)) {
      if (value !== -1) aggregated[key] += value;
    } else {
      aggregated[key] = value;
    }
  });
  let superEmotionsArrayNames = Object.entries(aggregated);
  return superEmotionsArrayNames;
}

export function getNameSuperEmotionFromEmotionyCode(code) {
  // EXAMPLE: "EMO10_3"
  var superEmoResult = undefined;
  var [emoGroup, emoNum] = code.split("_").map((value) => {
    if (value.substring(0, 3) === "EMO") {
      value = value.split("EMO")[1];
      if (value === "" || !(value >= 1 && value <= 18)) value = -1;
    } else {
      if (!(value >= 1 && value <= 6)) value = -1;
    }
    return value;
  });

  if (!(emoGroup === -1 || emoNum === -1)) {
    emoGroup -= 1;
    emoNum -= 1;
    superEmoResult = emolang.getSuperEmotion(emoGroup);
  }
  return emoGroup + 1 + "#" + superEmoResult;
}

export function getNameSuperEmotionFromEmotionyName(name) {
  return getNameSuperEmotionFromEmotionyCode(emolang.getEmotionCode(name));
}

export function getAllEmotionyNames() {
  return emolang.getAllEmotions();
}
