import {createContext} from 'react';

console.log("METRICSCONTEXT-MODULE-INIT");

export const MetricsContext = createContext();


var metricFilters={
    hashtags:[],
    superEmos:[],
    drivers:[],
    dateRange:{init:null,end:null},
    hoursRange:[],
    locations:[],
    planetids:[]
}

var metricResults=null;
var metricDataHash=[];


var dashboardState="nothing";
var effectiveFilters={
    hashtags:[],
    superEmos:[],
    drivers:[],
    dateRange:{init:null,end:null},
    hoursRange:[],
    locations:[],
    planetids:[]
}

// metricDataHash data structure:
// {
//     hash:hash,
//     query:queryResp,
//     metricFilters:metricsState.metricFilters,
//     metricResults:metrics
// }

export const metricsInitialState={
    metricFilters:metricFilters,
    metricResults:metricResults,
    metricDataHash:metricDataHash,
    dashboardState,
    effectiveFilters
};
