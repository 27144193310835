import { createContext } from "react";

console.log("GLOBALCONTEXT-MODULE-INIT");

var marketPlaceGrapQlParams={
    status: "published",
    limit:10,
    nextToken: "",
    page:0
}

 var marketPlacePlanetResults={
     data: null
 }

// var filterPlanet={
//     userAddressId: null,    
//     filter:null,
//     items:[]
// }

// var filterPlanets=[];

//FILTER MODEL:

// filter.userAddressId = null;
// filter.dataLocations=[];
// filter.dataEmos=[];
// filter.dataHashtags=[];
// filter.dataTags=[];

// filterData.dataSuperEmos=superEmos;
// filterData.dataDrivers=drivers;
// filterData.hourRanges=hourRanges;
// filterData.dataDateRange=["placeHolderDate1","placeHolderDate2"];
// filterData.dataEmos=getAllEmotionyNames()

/**
 * setSubmitFilter({
        selectedDataHashtags: selectedDataHashtags,
        selectedDataHourRange: selectedDataHourRange,
        selectedDataLocations: selectedDataLocations,
        selectedDataEmos: selectedDataEmos,
        selectedDataDateRange: selectedDataDateRange,
        selectedDataSuperEmos: selectedDataSuperEmos,
        selectedDataDrivers: selectedDataDrivers,
        selectedDataTags: selectedDataTags,
      });
 */

var userWallet = null;



export const EmoContext = createContext();


export const emoInitialState={
    marketPlaceGrapQlParams:marketPlaceGrapQlParams,
    marketPlacePlanetResults:marketPlacePlanetResults,
    marketPlaceNextTokens: [],
    marketPlacePages:[],
    marketPlaceFirstPlanet: 0,
    filterPlanets: [],
    itemsPlanetsGalaxy: null,
    itemsPlanetsMarketPlace: null,
    userWallet
}