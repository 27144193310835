import React from "react";
import { useState, useContext, useEffect } from "react";
import { PayPerDataContext } from "../../services/payperDataContext";
import { Grid, Divider, Header, Icon, Dropdown } from "semantic-ui-react";
import { UserContext } from "../../services/userContext";
import PayPerDataFilters from "./PayPerDataFilters.jsx";
import { wsSubscriptionQuery } from "../../services/WebSockets/wsSubscriptionQuery";
import { createHashOperation } from "../../core/blockchain/blockchainSigner";
import {
  Label,
  Form,
  Card,
  Button,
  Input,
  Segment,
  Message,
} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import UserHeader from "../User/UserHeader";
import {
  prepareFilters,
  prepareMetricsQuery,
  getMetrics,
} from "../../services/Graphql/Game/queryMetrics";

console.log("PAYPERDATA-COMPONENT-MODULE-INIT");

function PayperDataComponent() {
  console.log("---PAYPERDATA-COMPONENT-INIT---");

  const [metricsState, dispatchMetrics] = useContext(PayPerDataContext);
  const [payperDataState, setpayperDataState] = useState("initial");

  const [userState, dispatchUser] = useContext(UserContext);

  var setPayPerDataFilters = async (user) => {
    console.log("setpayperDataFilters");
    if (payperDataState === "initial") {
      console.log("setpayperDataFilters initial");

      let query = prepareMetricsQuery("", -4, false);
      console.log("PAYPERDATA-COMPONENT QUERY", query);
      let result = await getMetrics(query);
      // result.data.getGameMetrics.hashtags =
      //   result.data.getGameMetrics.hashtags.split(",");
      console.log("PAYPERDATA-COMPONENT RESULT=", result.data.getGameMetrics);
      let gmrf = JSON.parse(result.data.getGameMetrics.metricsGame).gmrf;
      let locations = [];
      let hashtags = [];
      let tags = [];
      let planetids = [];

      console.log("PAYPERDATA-COMPONENT RESULT=", gmrf, gmrf.length);
      for (let i = 0; i < gmrf.length; i++) {
        //console.log("PAYPERDATA gmrf[i]=",gmrf[i]);
        if (gmrf[i].hashtags) {
          gmrf[i].hashtags = gmrf[i].hashtags.split(",");
          //console.log("PAYPERDATA gmrf[i].hashtags=",gmrf[i].hashtags,gmrf[i].hashtags.length);
          // add unique elements to arrays:
          for (let i1 = 0; i1 < gmrf[i].hashtags.length; i1++) {
            let h = gmrf[i].hashtags[i1];

            if (!hashtags.includes(h)) hashtags.push(h);
          }
        }
        if (gmrf[i].tags) {
          gmrf[i].tags = gmrf[i].tags.split(",");
          // add unique elements to arrays:
          for (let i2 = 0; i2 < gmrf[i].tags.length; i2++) {
            let t = gmrf[i].tags[i2];
            if (!tags.includes(t)) tags.push(t);
          }
        }

        if (gmrf[i].locationFilter)
          if (!locations.includes(gmrf[i].locationFilter))
            locations.push(gmrf[i].locationFilter);
        if (gmrf[i].idGame)
          if (!planetids.includes(gmrf[i].idGame))
            planetids.push(gmrf[i].idGame);
      }

      let ggm = {
        locations: locations,
        hashtags: hashtags,
        tags: tags,
        planetids: planetids,
      };
      console.log("PAYPERDATA-COMPONENT RESULT=GGM", ggm);

      await setpayperDataState("getPayPerDataInitialData");

      dispatchMetrics({
        type: "SET_METRIC_FILTERS",
        payload: ggm,
      });
    }
  };

  useEffect(() => {
    if (userState.userData) {
      console.log(
        "PAYPERDATA-COMPONENT-USEEFFECT-USERDATA",
        userState.userData
      );
      setPayPerDataFilters(userState.userData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.userData]);

  return (
    <div className="payxdata">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment textAlign="center" className="noborder">
              {userState.userData && userState.userData.userAddressId ? (
                <UserHeader title="My Pay x Data" />
              ) : (
                <Header as="h1" textAlign="center">
                  My Pay x Data
                </Header>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment className="noborder">
              <PayPerDataFilters />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default PayperDataComponent;
