import { useEffect, useRef, useState, useContext } from "react";
import { Segment,Button } from "semantic-ui-react";
import { appParams } from "../../services/appParams";
//import GridPlanet from "../Grid/GridPlanet";
import GridPlanetG from "../Grid/GridPlanetg";
import "./Galaxy.scss";
//import { gamesList } from "../../services/Graphql/Game/queryLists";
import { resetNextTokenList, userService } from "../../services/userParams";
import { AllFiltersComponent } from "../Filters/allFiltersComponent";
import { UserContext } from "../../services/userContext";
import { getGalaxyList } from "../../services/Graphql/Game/queryGalaxy";
import {
  getPlanetFilter,
  getPlanetsFiltered,
} from "../../services/planetFilter";
import { getItemsFromMetrics } from "../../services/Graphql/Game/queryFIlter";
import { EmoContext } from "../../services/globalContext";
import { _getUserSigned } from "../../services/userParamsToReducer";
let count = 0;
let numPlanets = 4;
var totalPlanets = null;

console.log("GALAXY-COMPONENT-MODULE-INIT");

const Galaxy = (props) => {
  console.log("---GALAXY-COMPONENT-INIT---");

  console.log("GALAXY", props);

  var [stateList, setStateList] = useState(null);
  var [rcount, setRcount] = useState(0);
  var [dataToFilters, setDataToFilters] = useState(null);
  var [submitFilter, setSubmitFilter] = useState(null);
  const [userState, dispatchUser] = useContext(UserContext);
  var [filterHeaders, setFilterHeaders] = useState(false);
  const [firstPlanetG, setFirstPlanetG] = useState(0);
  const [itemsPlanets, setItemsPlanets] = useState(null);
  const [globalState, dispatchGlobal] = useContext(EmoContext);
  const [filtersMode,setFiltersMode] = useState(false);
  const [filtersFirstElement,setFiltersFirstElement] = useState(0);
  const[selectedButtonG,setSelectedButtonG]=useState(null);
  const [useRefresh,setUseRefresh] = useState(false);

  const gridRef = useRef(null);

  const gridScroll = (ref) => {
    console.log("gridScroll");
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const element = ref.current;
    const headerOffset = 80;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + top - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    }); 
  };

  const getHeaders = async () => {
    let filters = {
      getheaders: true,
    };

    let _reducer1 = { userState: userState, dispatchUser: dispatchUser };
    let result1 = await getGalaxyList(
      0,
      0,
      filters,
      _reducer1,
      setFilterHeaders
    );
    console.log("HEADERS TO FILTERS=", result1);
  };

  const getGames = async (firstPlanetG) => {
    let filters = {
      getgames: true,
    };
    let _reducer2 = { userState: userState, dispatchUser: dispatchUser };
    let result2 = await getGalaxyList(
      firstPlanetG,
      numPlanets,
      filters,
      _reducer2,
      setFilterHeaders
    );
    console.log(
      "GETGAMES FROM :",
      firstPlanetG,
      " NUMPLANETS= ",
      numPlanets,
      " =  RESULT:",
      result2
    );
    setStateList(result2);
  };

  const handleRefreshMyGalaxy = async () => {
    console.log("GALAXY: REFRESH MY GALAXY"); 
    

    // PREPARE SYSTEM TO RECEIVE NEW DATA FROM AWS (nextTokenHeader = null)

    // SET TO NULL the array of nextTokensHeaders only
    // payload = { nextTokens: [array_of_next_tokens], nextTokensHeader: [array_of_nextTokenHeader] }
    // case "SET_REFRESH_GALAXY":
    
    // GET USER DATA FROM AWS
    var userGalaxy = await _getUserSigned(userState.userData.accounts[0], userState.session);
    await dispatchUser({ type: "SET_AWS_USER_DATA", payload: userGalaxy });
    
    await dispatchUser({ type: "SET_REFRESH_GALAXY", payload: { nextTokens: null, nextTokensHeader: null } });
    await setFilterHeaders(false);
    await setUseRefresh(!useRefresh);
    await setItemsPlanets(null);
    
  };

  console.log("GALAXY: RECHARGE COUNT", rcount, count);

  useEffect(() => {
    console.log("GALAXY: GET HEADERS USE EFFECT");
    setRcount(rcount + 1);
    count = ++count;
    console.log("GALAXY:USERDATA FROM REDUCER:", userState.userData);
    if (userState.userData.firstPlanet > 0) {
      console.log(
        "GALAXY:FOUND PREVIOUS FIRSTPLANET FROM REDUCER",
        userState.userData.firstPlanet
      );
      setFirstPlanetG(userState.userData.firstPlanet);
    }
    getHeaders();
    if (!itemsPlanets) {
      getItemsFromMetrics(
        userState.userData.userAddressId,
        setItemsPlanets,
        globalState,
        dispatchGlobal
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.userData.userAddressId,useRefresh]);

  useEffect(() => {
    if (filterHeaders) {
      totalPlanets = userState.userData.items.length;
      console.log(
        "GALAXY: SET FILTER HEADERS USE EFFECT: LENGHT TOTALPLANETS =",
        totalPlanets
      );
      getGames(firstPlanetG);
      if (userState.userData) {
        console.log("GALAXY:  SETTING DATA TO FILTERS");
        let dfilters = getPlanetFilter(userState.userData);
        console.log("GALAXY: FILTERS = ", dfilters);
        setDataToFilters(dfilters);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterHeaders, firstPlanetG,useRefresh]);

  useEffect(() => {
    if (submitFilter && itemsPlanets) {
      console.log("GALAXY: SUBMIT FILTER:");
      console.log(submitFilter);
      let idPlanets = getPlanetsFiltered("Galaxy", submitFilter, itemsPlanets);
      // SETTING IN REDUCER userAddressId,submitFilters,and idPlanets at GLOBALSTATE
      // FILTERPLANET FORMAT:
      // {type:type, userAddressId: null, filter: null, items: [], firstElement:firstElement  })
      // PREVENTS USERDATA IS NULL
      // ITEMS IS AN COMPLEX OBJECT FROM IDPLANETS
     
      if (idPlanets.length>0) {
        let items=[];
        for(let index in idPlanets){
          let item={
            idGame:idPlanets[index]
          }
          items.push(item);
        }

        let complexObject={
          type: "Galaxy",
          userAddressId:
            userState.userData && userState.userData.userAddressId
              ? userState.userData.userAddressId
              : null,
          filter: submitFilter,
          items: items,
          firstElement: 0
        };
        dispatchGlobal({
          type: "SET_FILTER_PLANETS",
          payload: complexObject,
          other:setSelectedButtonG
          
        });
      }
    }
  }, [submitFilter]);

  return (
    <>
      <>
      <Segment textAlign="left">
        <AllFiltersComponent
          selectedButton={selectedButtonG}
          setSelectedButton={setSelectedButtonG}
          titleHeader="My Galaxy Filters"
          filterData={dataToFilters}
          submitFilter={submitFilter}
          setSubmitFilter={setSubmitFilter}
        />
        <Button
          className="aslink mt--double"
          attached="bottom"
          size="mini"
          onClick={handleRefreshMyGalaxy}
          
        >
          Refresh
        </Button>
      </Segment>
      
      </>

      {stateList ? (
        <GridPlanetG
          modeGrid="galaxy"
          list={stateList}
          firstPlanet={0}
          firstPlanetG={firstPlanetG}
          setFirstPlanet={setFirstPlanetG}
          numPlanets={numPlanets}
          step={numPlanets}
          totalPlanets={totalPlanets}
        />
      ) : (
        <div> Loading...</div>
      )}
    </>
  );
};

export default Galaxy;
