import React from "react";
// Import Highcharts
import Highcharts from "highcharts/highstock";
// import HcMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
// import DarkUnica from "highcharts/themes/dark-unica";

import { useContext, useEffect, useState } from "react";
import { Super_Emos } from "../../../core/Utils.js";

// DarkUnica(Highcharts);
// HcMore(Highcharts);

import { Card } from "semantic-ui-react";

function createOptions(datapos, dataneg) {
  return {
    series: [
      {
        name: "Positive",
        data: datapos,
        _colorIndex: 9,
      },
      {
        name: "Negative",
        data: dataneg,
        _colorIndex: 5,
      },
    ],
    chart: {
      polar: false,
      type: "column",
    },
    title: {
      text: "Top-5 Super-Emotions",
    },
    subtitle: {
      //text: "Top-5 Super-Emotions"

    },
    legend: {
      align: "center",
      horizontalAlign: "down",
      layout: "horizontal",
    },
    xAxis: {
      tickmarkPlacement: "on",
      type: "category",
    },
    yAxis: {
      min: 0,
      endOnTick: false,
      showLastLabel: true,

      labels: {
        formatter: function () {
          return this.value;
        },
      },
      reversedStacks: true,
    },
    tooltip: {
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yValue:.2f}</b><br/>',
    },
    plotOptions: {
      series: {
        stacking: "normal",
        shadow: false,
        groupPadding: 0,
        pointPlacement: "on",
      },
    },
    credits: {
      enabled: false,
    },
  };
}
function TopEmotionsBar(props) {
  
  const { metricsState } = props;
  //TopEmoRank

  var datapos=[
    ["EMO1", 0],
    ["EMO2", 0],
    ["EMO3", 0],
    ["EMO4", 0],
    ["EMO5", 0],
  ];

  var dataneg = [
    ["EMO10", 0],
    ["EMO11", 0],
    ["EMO12", 0],
    ["EMO13", 0],
    ["EMO14", 0],
  ];
  const [options, setOptions] = useState(createOptions(datapos, dataneg));

  useEffect(() => {
    var semolistpos = [];
    var semolistneg = [];
    var newdatapos = [];
    var newdataneg = [];
    var auxdatapos = [];
    var auxdataneg = [];

    if (
      metricsState.metricResults &&
      metricsState.metricResults.TotalGamesSuperEmo
    ) {
      for (
        let i = 0;
        i < metricsState.metricResults.TotalGamesSuperEmo.length;
        i++
      ) {
        let se = metricsState.metricResults.TotalGamesSuperEmo[i];
        let val = parseInt(se.semo.split("O")[1]);
        //console.log("VAL=",val);
        if (val < 9) {
          semolistpos.push(se);
        } else {
          semolistneg.push(se);
        }
      }

      for (let i = 0; i < semolistpos.length; i++) {
        let e = semolistpos[i];
        auxdatapos.push([e.semo.split("_")[0], e.sum_points]);
      }

      for (let i = 0; i < semolistneg.length; i++) {
        let e = semolistneg[i];
        auxdataneg.push([e.semo.split("_")[0], e.sum_points]);
      }

      for (let i = 0; i < 5; i++) {
        newdatapos.push(auxdatapos[i]);
        newdataneg.push(auxdataneg[i]);
      }

      newdatapos.sort((a, b) => {
        if (parseInt(a[0].split("O")[1]) > parseInt(b[0].split("O")[1]))
          return 1;
        if (parseInt(a[0].split("O")[1]) < parseInt(b[0].split("O")[1]))
          return -1;
        return 0;
      });

      newdataneg.sort((a, b) => {
        if (parseInt(a[0].split("O")[1]) > parseInt(b[0].split("O")[1]))
          return 1;
        if (parseInt(a[0].split("O")[1]) < parseInt(b[0].split("O")[1]))
          return -1;
        return 0;
      });
      console.log("TOPEMOTIONS 5: emopos", newdatapos);
      console.log("TOPEMOTIONS 5: emoneg", newdataneg);

      // TRASLATE EMOTIONS TO REAL TEXT INIT
      for (let i = 0; i < newdatapos.length; i++) {
        let e = newdatapos[i];
        newdatapos[i][0] = (Super_Emos[e[0].split("O")[1]-1]).text;
      }
      for (let i = 0; i < newdataneg.length; i++) {
        let e = newdataneg[i];
        newdataneg[i][0] = (Super_Emos[e[0].split("O")[1]-1]).text;
      }
      console.log("TOPEMOTIONS 5 TRANSLATE: emopos", newdatapos);
      console.log("TOPEMOTIONS 5: TRANSLATE emoneg", newdataneg);
      // TRASLATE EMOTIONS TO REAL TEXT END

      setOptions(createOptions(newdatapos, newdataneg));
    }
  }, [metricsState.metricResults]);

  return (
    <Card fluid>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Card>
  );
}
export default TopEmotionsBar;
