import Amplify, { API, graphqlOperation } from "aws-amplify";
import awsconfig from "../../../core/a-cf";

// Considering you have an existing aws-exports.js configuration file
Amplify.configure(awsconfig);

console.log("QUERYGAME-MODULE-INIT");

const getGameByIdQuery = (gameId) => {
  return `
      query listGameTables {
        getMasterGameById(idGame: "${gameId}") {
          idGame
          planetOperation
          userAddressId
          connectionid
          connectionData
          sourceip
          requesttime
          emotions
          description
          gameDesign
          generalData{
            date
            gameId
            emoViewConnectionID
            posnegPlanet
            geoloc {
              accuracy
              latitude
              longitude
            }
            pngId
            deviceId
            email
            username
          }
          hashtags
          tags
          quote
          status
          textGame
          favorites
          location
          locationFilter
          locationPretty
          likes
        }
      }
      `;
};
export const getGameById = async (gameId) => {
  //const gameById = await API.graphql(
  console.log("GET-GAME-BY-ID:", getGameByIdQuery(gameId));
  const game = await API.graphql(graphqlOperation(getGameByIdQuery(gameId)));
  //var game={
  //  "data":{
  //    "getMasterGame": gameById.data.getMasterGameById
  //  }
  //}
  //console.log("QUERY GAME FAVORITES",`"${game.data.getMasterGameById.favorites}"`);
  //console.log("QUERY GAME FAVORITES.length", game.data.getMasterGameById.favorites.length);
  if (
    game.data.getMasterGameById.favorites &&
    game.data.getMasterGameById.favorites.length > 0
  ) {
    game.data.getMasterGameById.favorites =
      game.data.getMasterGameById.favorites.toString().split(",");
  } else {
    game.data.getMasterGameById.favorites = [];
  }

  if (
    game.data.getMasterGameById.tags &&
    game.data.getMasterGameById.tags.length > 0
  ) {
    game.data.getMasterGameById.tags = game.data.getMasterGameById.tags
      .toString()
      .split(",");
  } else {
    game.data.getMasterGameById.tags = [];
  }
  console.log("QUERYGAME:", game);

  return game;
};
