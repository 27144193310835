import React from "react";
import { Card, Label } from "semantic-ui-react";

const TotalsTopBox = (props) => {
  //console.log(props.value);
  var values=props.value;
  if (props.dataRibbon.includes("locations")){
    console.log("TOTALSTOPBOX:locations",values);
    for(let i=0;i<values.length;i++){
      let value=values[i];
      let valueSplit=value.split(",");
      if(valueSplit.length===5){
        values[i]=valueSplit[3]+", "+valueSplit[1];
      }
    }

  }
  const list = values.map((item) => <span>{item}, </span>);

  return (
    <>
      <Card fluid>
        <Card.Content>
          <Label attached='top'>{props.dataRibbon}</Label>
          <Card.Description>{list}</Card.Description>
        </Card.Content>
      </Card>
    </>
  );
};

export default TotalsTopBox;
