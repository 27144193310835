import { UserContext } from "../../services/userContext";
import { EmoContext } from "../../services/globalContext";
import { useContext, useEffect, useState } from "react";
import {
  Grid,
  Label,
  Button,
  Dropdown,
  Container,
  Segment,
} from "semantic-ui-react";
import { PayPerDataContext } from "../../services/payperDataContext";
import { Link } from "react-router-dom";

console.log("QUERIES-COMPONENT-MODULE-INIT");

const QUERIES_PER_PAGE = 10;
const QueriesComponent = (props) => {
  console.log("QUERIES-COMPONENT-INIT", props);
  const [userState, dispatchUser] = useContext(UserContext);
  const [globalState, dispatchGlobal] = useContext(EmoContext);
  const [metricsState, dispatchMetrics] = useContext(PayPerDataContext);
  const [page, setPage] = useState(0);
  const [queries, setQueries] = useState([]);

  // TRIVIAL FAKER DATA SECTION
  // useEffect(() => {
  //   if (props) {
  //     setQueries(props.queries);
  //   }
  // }, [props]);

  useEffect(() => {
    console.log("QUERIES-COMPONENT-EFFECT - USERDATA", userState.userData);
    console.log("QUERIES-COMPONENT-EFFECT - GLOBALDATA", globalState);
    console.log("QUERIES-COMPONENT-EFFECT - PAYPERDATAMETRICS", metricsState);
    console.log("QUERIES-COMPONENT-EFFECT - QUERIES", metricsState.queries);
    setQueries(metricsState.queries);
  }, [userState.userData, globalState, metricsState]);

  const Query = ({ query, index }) => {
    const [showJson, setShowJson] = useState(false);
    const [showqueriIdComplete, setShowqueriIdComplete] = useState(false);

    const toggleJson = () => setShowJson(!showJson);

    // const rowStyle = {
    //   display: "flex",
    //   justifyContent: "space-between",
    //   alignItems: "center",
    //   padding: "10px",
    //   backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#ffffff",
    // };

    const dropdownOptions = [
      {
        key: "json",
        text: showJson ? "x" : "View JSON DataMetrics",
        value: "json",
        onClick: toggleJson,
      },
    ];

    const chipsFilterCriteria = (effectiveFilters) => {
      let chips = [];

      for (const key in effectiveFilters) {
        const values = effectiveFilters[key];

        if (Array.isArray(values) && values.length > 0) {
          values.forEach((value) => {
            //console.log("---------------VALUE", value, value.length);
            if (value.length > 1) {
              chips.push(
                <div className="label ui" key={`${key}-${value}`}>
                  {value}
                </div>
              );
            }
          });
        } else if (typeof values === "object" && values !== null) {
          const dateRange = values;
          //console.log("---------------VALUE DATE RANGE", values);

          if (dateRange.init !== null && !isNaN(new Date(dateRange.init))) {
            chips.push(
              <div className="label ui" key={`${key}-init`}>
                {new Date(dateRange.init).toLocaleDateString()}
              </div>
            );
          }
          if (dateRange.end !== null && !isNaN(new Date(dateRange.end))) {
            chips.push(
              <div className="label ui" key={`${key}-end`}>
                {new Date(dateRange.end).toLocaleDateString()}
              </div>
            );
          }
        }
      }

      return chips;
    };

    return (
      <Segment>
        {/*style={rowStyle}>*/}
        <div>
          <Label
            as="a"
            basic
            onClick={() => setShowqueriIdComplete(!showqueriIdComplete)}
          >
            Query ID:
          </Label>{" "}
          {showqueriIdComplete ? (
            <div style={{ overflow: "auto", maxWidth: "100%" }}>
              {query.queryId}
            </div>
          ) : (
            query.queryId.substring(0, 12) +
            "..." +
            query.queryId.substring(query.queryId.length - 4)
          )}
          <div>
            <Label as="a" basic>
              Filter Criteria:
            </Label>
            {chipsFilterCriteria(
              query.jsonDataMetrics.filtersData.effectiveFilters
            )}
          </div>
          <Label as="a" basic>
            Date:
          </Label>{" "}
          {
            query.date //(new Date(query.date)).toLocaleString()
          }
          <br />
          {/* <Label color="white">Tag:</Label> {query.tag} */}
          <div>
            <Label as="a" basic>
              Metric Charts:
            </Label>{" "}
            {/* <a href={query.link} target="_blank" rel="noreferrer">
            Link
          </a> */}
            <Link to={query.link} item={query.queryId}>
              Metrics
            </Link>
          </div>
        </div>
        <Dropdown
          inline
          trigger={
            <Label as="a" basic>
              JSON data:
            </Label>
          }
          options={dropdownOptions}
          pointing="right"
        />
        {showJson && (
          <div style={{ marginTop: "10px" }}>
            <Label color="white">JSON DataMetrics:</Label>{" "}
            {JSON.stringify(query.jsonDataMetrics)}
          </div>
        )}
      </Segment>
    );
  };

  const QueryList = ({ queries }) => {
    const [currentPage, setCurrentPage] = useState(0);

    const startIndex = currentPage * QUERIES_PER_PAGE;
    const endIndex = startIndex + QUERIES_PER_PAGE;
    const pageQueries = queries.slice(startIndex, endIndex);

    const totalPages = Math.ceil(queries.length / QUERIES_PER_PAGE);

    const prevPage = () => {
      setCurrentPage((currentPage) => currentPage - 1);
      scrolling();
    };
    const nextPage = () => {
      setCurrentPage((currentPage) => currentPage + 1);
      scrolling();
    };

    const scrolling = () => {
      //soft scroll to top
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    return (
      <div>
        {pageQueries.map((query, index) => (
          <Query key={query.queryId} query={query} index={index} />
        ))}

        {pageQueries.length > 0 && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button.Group>
              {currentPage > 0 && (
                <Button onClick={() => prevPage()}>Prev</Button>
              )}
              {currentPage < totalPages - 1 && (
                <Button onClick={() => nextPage()}>Next</Button>
              )}
            </Button.Group>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <QueryList queries={queries} />
    </div>
  );
};
export default QueriesComponent;
