import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { mode } from "./mode";

import "semantic-ui-css/semantic.min.css";
import "./theme/scss/index.scss";
import { initApp } from "./core/_index.js";

// ENVIRONMENT
if (mode.environment === "prod") {
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};
  console.info = function () {};
}

// get type of device in app params
console.log("\n");
console.log("------------------");
console.log("\n\n");
console.log("INDEX-MODULE-INIT");
console.log("\n\n");
console.log("------------------");
console.log("\n");

initApp();

ReactDOM.render(<App />, document.getElementById("root"));
