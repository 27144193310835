import AWS from "aws-sdk";
//import { connectionData, userData } from "../userParams.js";
import { wsBlockChain } from "../WebSockets/wsBlockChain";

const axios = require("axios");
const FormData = require("form-data");
var _connectionData = undefined;
var _userData = undefined;

// WEB SOCKET CONNECTION
console.log("CHARGEIMAGETOIPFSPINATA-MODULE-INIT");
var ws = new wsBlockChain();

// TOKENIZE: FIRST PART TO TOKENIZE: GET DATA FROM S3 PLANETS LOW RESOLUTION REPOSITORY
// WITH CORS PASSPORT
export const chargeImageToIPFSpinata1 = async (
  event,
  planetId,
  hookSet,
  tokenizeStatus,
  setStateDisableTokenize,
  _reducer
) => {
  
  console.log("---CHARGEIMAGETOIPFSPINATA1-INIT--");
  // console.log("FUNCTION CHARGEIMAGETOIPFSPINATA1");
  _userData = _reducer.userState.userData;
  _connectionData = _userData.connectionData;
  //var uri=item.item.url;
  var uri = `https://planetsimagelowresolution.s3.eu-west-3.amazonaws.com/${planetId}.png`;
  //var idImage=item.item.urldummy;
  //var idGame=item.item.idGame;
  var idGame = planetId;
  // THIS MEGADATA TRANSMIT DATA OVER NEXTS FUNCTIONS CALLS INTO SUPER-NESTED ASYNCS
  var megadata = {
    url: uri,
    //idImage: idImage,
    idGame: idGame,
    event: event,
    //item: item,
    hookSet: hookSet,
    tokenizeStatus: tokenizeStatus,
    setStateDisableTokenize: setStateDisableTokenize,
  };
  //console.log("WINDOW",window);
  // console.log("MEGADATA", megadata);
  await downloadImage(megadata);
};
// TOKENIZE : DOWNLOADIMAGE FROM PUBLIC S3 URL USING AWS
async function downloadImage(megadata) {
  // console.log("DWL-DOWNLOAD-IMAGE");
  let urlArray = megadata.url.split("/");
  let awsregion = "eu-west-3";

  let preBucket = urlArray[2].split(".");
  let bucket = preBucket[0];
  let key = urlArray[3];
  let bucketParams = {
    Bucket: bucket,
  };
  let objectParams = {
    Bucket: bucket,
    Key: key,
  };
  /* let awsApp1Credentials=new AWS.Credentials(
    config.emotionyApp1S3Planets.aws_access_key_id,
    config.emotionyApp1S3Planets.aws_secret_access_key,
    null 
  ); */
  // DATA TO CORS OPERATIONS

  // console.log("DWL-URL ARRAY:", urlArray);
  // console.log("DWL-BUCKET PARAMS:", bucketParams);
  // console.log("DWL-OBJECT PARAMS:", objectParams);

  // S3 Instance on AMAZON S3
  const s3 = new AWS.S3({
    region: awsregion,
    accessKeyId: _connectionData.config.emotionyApp1S3Planets.aws_access_key_id,
    secretAccessKey:
      _connectionData.config.emotionyApp1S3Planets.aws_secret_access_key,
  });

  // TOKENIZE: GET BUCKET CORS
  await s3.getBucketCors(bucketParams, function (err, data) {
    if (err) console.log(err, err.stack); // an error occurred
    else {
      // console.log("DWL-GET BUCKET CORS");
      // console.log(data);
    }
  });
  // TOKENIZE: PROBE ACL PROPERTIES AT BUCKET. CREATE CACHE DATA FROM NEXT OPERATION
  await s3.getObjectAcl(objectParams, function (err, data) {
    if (err) console.log(err, err.stack); // an error occurred
    else {
      // console.log("DWL-GET OBJECT ACL");
      // console.log(data);
    } // successful response
  });

  // console.log("DWL-BLOB GETTING OK!");
  // TOKENIZE: GETTING BLOB : SIGNED URL TO DOWNLOAD URL CONTAINS THE BLOB
  megadata.hookSet(megadata.tokenizeStatus.GETTING_BLOB);
  // TOKENIZE: PREPARING SIGNED URL
  await s3.getSignedUrl("getObject", objectParams, function (err, url) {
    if (err) console.log(err, err.stack); // an error ocurred
    else {
      // console.log("DWL-GET SIGNED URL");
      //TOKENIZE: FETCHING SIGNED URL
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          // TOKENIZE: OBTAINING BLOB IN BASE 64 MODE
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            megadata.base64data = base64data;

            if (megadata.hashOperation == null) {
              megadata.hashOperation = createHashOperation(
                megadata.idGame,
                _userData.userAddressId,
                megadata.base64data
              );
            }

            var wsOperation = {
              operation: "blobcreation",
              step_num: "00",
              hash_operation: megadata.hashOperation,
              planetId: megadata.idGame,
              address: megadata.mainAccount,
              //"email": "example@email.com",
              error_blob: "0",
              get_planet: "ok",
              size_blob: blob.size,
            };
            // console.log("SEND:", JSON.stringify(wsOperation));
            ws.send(JSON.stringify(wsOperation));

            // TOKENIZE: JUMP TO SECOND PART OF ASYNCRONOUS OPERATION
            chargeImageToIPFSpinata2(megadata);
          };
        })
        .catch((err) => {
          console.log("DWL-ERROR!!", err);
        });
    }
  });
}

async function chargeImageToIPFSpinata2(megadata) {
  // console.log("FUNCTION CHARGEIMAGETOIPFSPINATA2");
  console.log("BLOG SAVING OK!");

  //TOKENIZE: BLOB SAVING
  megadata.hookSet(megadata.tokenizeStatus.SAVING_BLOB);

  console.log(
    "\nEVENT:",
    megadata.event,
    "\n",
    "URI:",
    megadata.uri,
    "\n",
    "IDIMAGE:",
    megadata.idImage,
    "\n",
    "HOOKSET:",
    megadata.hookSet,
    "\n",
    "IDGAME:",
    megadata.idGame,
    "\n",
    "IMAGEBASE64:",
    megadata.base64data,
    "\n",
    "TOKENIZESTATUS:",
    megadata.tokenizeStatus
  );

  let data = new FormData();
  // TOKENIZE: CONVERT BLOB TO BINARY DATA

  let blob = b64toBlob(megadata);
  data.append("file", blob, megadata.idGame);
  megadata.hookSet(megadata.tokenizeStatus.SENDING_IMG_TO_IPFS);

  // TOKENIZE: UPLOADING BLOB TO IPFS USING PINATA
  // PINATA: https://app.pinata.cloud

  axios
    .post(_connectionData.config.url, data, {
      axContentLength: "Infinity",
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        pinata_api_key: _connectionData.config.pinataApiKey,
        pinata_secret_api_key: _connectionData.config.pinataSecretApiKey,
      },
    })
    .then(function (response) {
      console.log("pin-PNGtoIPFS:", megadata.idGame, " :::: ", response.data);

      var wsOperation = {
        operation: "pinatadata",
        step_num: "01",
        hash_operation: megadata.hashOperation,
        error_upload: "0",
        size_of_upload: blob.size,
        error_reference: "0",
        link_json: "https://ipfs.io/ipfs/" + response.data.IpfsHash,
      };
      // console.log("SEND:", JSON.stringify(wsOperation));
      ws.send(JSON.stringify(wsOperation));

      // TOKENIZE: CHARGE PNG RESPONSE JSON TO IPFS
      createJSONtoPIN(megadata, response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}
// TOKENIZE: THIS FUNCTION CREATES METACONTENT OF THE EMOTIONY'S NFTS
function createJSONtoPIN(megadata, dataToCreateJson) {
  megadata.hookSet(megadata.tokenizeStatus.CREATING_JSON_METADATA);
  var nameJson = megadata.idGame;
  // console.log("createJSONtoPIN:", nameJson, dataToCreateJson.IpfsHash);
  //HASH TO ACCESS IPFS PNG FROM NFT CONTRACT
  megadata.pngHash = dataToCreateJson.IpfsHash;
  var jsonMaker = {
    name: "Emotiony NFT:" + nameJson,
    description: "Emotiony Planet Image",
    image_url: "https://ipfs.io/ipfs/" + dataToCreateJson.IpfsHash,
    hash: dataToCreateJson.IpfsHash,
    by: "Emotiony",
  };
  /*
    DATA FROM DOCS:OPENSEA
    https://docs.opensea.io/reference/asset-object
    Here's an overview of some of the fields contained in an asset:

    Field name       | Description
    token_id         | The token ID of the ERC721 asset
    image_url        | An image for the item
    background_color | The background color to be displayed with the item
    name             | Name of the item
    external_link    | External link to the original website for the item
    asset_contract   | Dictionary of data on the contract itself (see asset contract section)
    owner            | Dictionary of data on the owner (see account section)
    traits           | A list of traits associated with the item (see traits section)
    last_sale        | When this item was last sold (null if there was no last sale)

  */
  megadata.jsonMetaMaker = {
    pinataMetadata: {
      name: nameJson + ".json",
    },
    pinataContent: jsonMaker,
  };

  var wsOperation = {
    operation: "createmetadata",
    step_num: "02",
    hash_operation: megadata.hashOperation,
    error_json: "0",
    size_of_json_metadata: JSON.stringify(megadata.jsonMetaMaker).length,
  };
  // console.log("SEND:", JSON.stringify(wsOperation));
  ws.send(JSON.stringify(wsOperation));

  pinJSONtoIPFS(megadata);
}
// TOKENIZE: UPLOADING PNG JSON METADATA TO IPFS
const pinJSONtoIPFS = (megadata) => {
  let json = megadata.jsonMetaMaker;
  megadata.hookSet(megadata.tokenizeStatus.SENDING_JSON_TO_IPFS);
  return axios
    .post(_connectionData.config.urlJson, json, {
      headers: {
        pinata_api_key: _connectionData.config.pinataApiKey,
        pinata_secret_api_key: _connectionData.config.pinataSecretApiKey,
      },
    })
    .then(function (response) {
      // console.log("pinJSONtoIPFS:::IPFSHASH:::::", json.pinataMetadata.name, response.data.IpfsHash);
      //URI ACCESS TO METADATA AT IPFS FROM EMOTIONY CONTRACT
      megadata.uriAccessToMetadata = response.data.IpfsHash;

      var wsOperation = {
        operation: "uploadpinatametadata",
        step_num: "03",
        hash_operation: megadata.hashOperation,
        error_upload_pi: "0",
        size_of_upload_pi: JSON.stringify(megadata.jsonMetaMaker).length,
        error_reference_pi: "0",
        link_json_pi: "https://ipfs.io/ipfs/" + megadata.uriAccessToMetadata,
      };

      // console.log("SEND:", JSON.stringify(wsOperation));
      ws.send(JSON.stringify(wsOperation));

      pushToBlockchain(megadata);
    })
    .catch(function (error) {
      console.log(error);
    });
};
//TOKENIZE: AWARD IPFS TOKEN TO BLOCKCHAIN
function pushToBlockchain(megadata) {
  console.log("PUSH TO ETHEREUM ");

  megadata.hookSet(megadata.tokenizeStatus.SENDING_DATA_TO_CONTRACT);
  let hash = megadata.pngHash;
  let metadata = "https://ipfs.io/ipfs/" + megadata.uriAccessToMetadata;
  let recipient = _userData.userAddressId;

  _userData.contractEmotionyAsset().methods
    .awardItem(recipient, hash, metadata)
    .send({ from: _userData.userAddressId })
    .once("receipt", (receipt) => {
      console.log(receipt);
      megadata.hookSet(megadata.tokenizeStatus.MINTING_NFT);
    })
    .then((res, err) => {
      var result = res;
      megadata.result = result;
      console.log("RESULT:", result);
      //alert ("MINTING RESULT IS TOKEN NUMBER: "+(result.events.Transfer.returnValues.tokenId).toString()+" IN ACCOUNT: "+recipient);
      console.log(
        "MINTING RESULT IS TOKEN NUMBER: ",
        result.events.Transfer.returnValues.tokenId,
        " IN ACCOUNT: ",
        recipient
      );
      megadata.hookSet(megadata.tokenizeStatus.TOKENIZED);
      megadata.setStateDisableTokenize(true);
      /*
        {
            "transactionHash": "0xb73eaf8196305aac432b6d510d0ef92214e540256ef9f315ec349d3343ef39aa",
            "transactionIndex": 0,
            "blockHash": "0xa59eb1664dfbab3711eda526100327f38274dc8bf77a9ff8a34f0df46f5b4a3d",
            "blockNumber": 8,
            "from": "0x66616cc118a6774b19dcaf2ef8a939c0733c0511",
            "to": "0xf1bfa9c4576c9b04d3ade21ad27ae708eddb37a1",
            "gasUsed": 209537,
            "cumulativeGasUsed": 209537,
            "contractAddress": null,
            "status": true,
            "logsBloom": "0x00000002000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000008000000000000000000000000000100000000000008000000020000000000000000000800000000000000000000000010000000000000000000000000000000000000000000020000000000000000000000000000000000000000000000000000000000000000000000000000002080000000000000000002000000000000000000000000000000008000000000000000000020000000000000100000000000000000000000000000000000000000000000000000",
            "events": {
                "Transfer": {
                    "logIndex": 0,
                    "transactionIndex": 0,
                    "transactionHash": "0xb73eaf8196305aac432b6d510d0ef92214e540256ef9f315ec349d3343ef39aa",
                    "blockHash": "0xa59eb1664dfbab3711eda526100327f38274dc8bf77a9ff8a34f0df46f5b4a3d",
                    "blockNumber": 8,
                    "address": "0xF1bfa9c4576C9B04d3ade21AD27aE708EDDb37A1",
                    "type": "mined",
                    "id": "log_78ab86b5",
                    "returnValues": {
                        "0": "0x0000000000000000000000000000000000000000",
                        "1": "0x66616Cc118a6774B19DcAF2EF8a939c0733c0511",
                        "2": "4",
                        "from": "0x0000000000000000000000000000000000000000",
                        "to": "0x66616Cc118a6774B19DcAF2EF8a939c0733c0511",
                        "tokenId": "4"
                    },
                    "event": "Transfer",
                    "signature": "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
                    "raw": {
                        "data": "0x",
                        "topics": [
                            "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
                            "0x0000000000000000000000000000000000000000000000000000000000000000",
                            "0x00000000000000000000000066616cc118a6774b19dcaf2ef8a939c0733c0511",
                            "0x0000000000000000000000000000000000000000000000000000000000000004"
                        ]
                    }
                }
            }
        }
      
      */

      var wsOperation = {
        operation: "solidityaward",
        step_num: "04",
        hash_operation: megadata.hashOperation,
        tx_hash: megadata.result.transactionHash,
        gas: megadata.result.gasUsed,
        num_token: megadata.result.events.Transfer.returnValues[2],
        error_award: "0",
      };
      //console.log("SEND:", JSON.stringify(wsOperation));
      ws.send(JSON.stringify(wsOperation));

      if (err) {
        console.log("ERROR!!!:", err);
        alert("ERROR!!!:" + err);
      }
    });
}

function b64toBlob(megadata) {
  megadata.hookSet(megadata.tokenizeStatus.CONVERTING_BLOB);
  console.log("CONVERTING BLOB:", megadata.tokenizeStatus.CONVERTING_BLOB);
  let dataURI = megadata.base64data;
  let byteString = atob(dataURI.split(",")[1]);
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
}

function createHashOperation(idGame, mainAccount, base64data) {
  var ho0 = hashcode(idGame);
  var ho1 = hashcode(mainAccount);
  var ho2 = hashcode(base64data);
  return ho0 + "1" + ho1 + "1" + ho2;
}

function hashcode(dataStr) {
  return dataStr.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}
