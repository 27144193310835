import React from "react";
import { useHistory } from "react-router-dom";
import PayPerDataHashtags from "./Filters/PayPerDataFilterHashTags";
import PayPerDataSuperEmos from "./Filters/PayPerDataFilterSuperEmos";
import PayPerDataDrivers from "./Filters/PayPerDataFilterDrivers";
import PayPerDataLocationsGames from "./Filters/PayPerDataFilterLocationsGames";
import PayPerDataDatesGames from "./Filters/PayPerDataFilterDatesGames";
import PayPerDataHoursGames from "./Filters/PayPerDataFilterHoursGames";
import { Message, Form, Button, Segment,Header } from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import { PayPerDataContext } from "../../services/payperDataContext.js";
import { UserContext } from "../../services/userContext.js";
import {
  prepareFilters,
  prepareMetricsQuery,
  getMetrics,
} from "../../services/Graphql/Game/queryMetrics";
import { PulseLoader } from "react-spinners";
import SubscriptionComponent from "../MyProfile/SubscriptionComponent";
import { getUserQueries } from "../../services/Graphql/User/queryUser";
import { updateUserQueries } from "../../services/Graphql/User/updateUser";
import { get, isString, update } from "lodash";

var MD5 = require("md5.js");

console.log("PAYPERDATAFILTERS-COMPONENT-MODULE-INIT");

function PayPerDataFilters() {
  console.log("---PAYPERDATAFILTERS-COMPONENT-INIT----");

  const [userState, dispatchUser] = useContext(UserContext);

  const [metricsState, dispatchMetrics] = useContext(PayPerDataContext);
  const [user, setuser] = useState(null);
  const [executingQuery, setExecutingQuery] = useState(false);
  const [actualHashComprobation, setActualHashComprobation] = useState(false);

  const history = useHistory();
  var subscription = null;
  const setSubscription = (subs) => {
    console.log("PAYPERDATAFILTERS-COMPONENT: setSubscription SSSSUBS", subs);
    subscription = subs;
    dispatchMetrics({type:"SET_SUBSCRIPTION", payload:subs});
  };
  // HANDLEPAYPERDATA IS LOAD QUERIES PAGE

  const submitFilters = async (e) => {
    e.preventDefault();
    console.log("PAYPERDATAFILTERS-COMPONENT:subMitFilters");

    if (user && user.userAddressId && subscription) {
      setExecutingQuery(true);
      console.log(
        "PAYPERDATAFILTERS: EFFECTIVE_FILTERS:",
        metricsState.effectiveFilters
      );
      var filter = prepareFilters(
        metricsState.effectiveFilters.dateRange.init,
        metricsState.effectiveFilters.dateRange.end,
        metricsState.effectiveFilters.drivers,
        metricsState.effectiveFilters.hashtags,
        metricsState.effectiveFilters.locations,
        metricsState.effectiveFilters.superEmos,
        metricsState.effectiveFilters.hoursRange
      );
      console.log("PAYPERDATAFILTERS: FILTER", filter);
      let queryResp = prepareMetricsQuery(null, 10, filter);
      console.log("PAYPERDATAFILTERS: QUERYRESP", queryResp);

      var md5stream = new MD5();
      md5stream.end(queryResp);
      var hash = md5stream.read(queryResp).toString("hex");

      var comprobationHash = await comprobateHash(hash);
      console.log("PAYPERDATAFILTERS: COMPROBATIONHASH", comprobationHash);

      var rmetrics = null;
      var metrics = null;

      if (comprobationHash.result === null) {
        await setActualHashComprobation(false);
        rmetrics = await getMetrics(queryResp);
        console.log("PAYPERDATAFILTERS: METRICS RESULT=", rmetrics);
        metrics = JSON.parse(rmetrics.data.getGameMetrics.metricsGame);
        console.log("PAYPERDATAFILTERS: METRICS FROMJSON", metrics);
        await dispatchMetrics({
          type: "SET_METRIC_DATAHASH",
          payload: {
            hash: hash,
            query: queryResp,
            metricFilters: metricsState.effectiveFilters,
            metricResults: metrics,
          },
        });
      } else {
        await setActualHashComprobation(true);
        metrics = comprobationHash.result;
        console.log(
          " PAYPERDATAFILTERS: >>>>>> DIRECT >>>>>> COMPROBATIONHASH HMETRICS=",
          metrics
        );
      }

      await dispatchMetrics({ type: "SET_METRIC_RESULTS", payload: metrics });
      // PUSH ROUTE ("/")

      // create query

      // queryId is number of query + subscriptionId
      // queryId number = is the next number of queries length in MasterUser (graphql)
      // if not store in MasterUser, store it with graphql (mutation)
      // add to queries list
      // the link is to page "PayPerData metrics result", int this page,
      // the link stores it metrics in metricsResult
      // with dispatchMetrics

      // get queries from MasterUser

      var queryNumber = 0;
      if (metricsState.queries && metricsState.queries.length > 0) {
        queryNumber = metricsState.queries.length;
      }
      // CREATING ACTUAL QUERY
      var queryId =
        queryNumber + "-" + subscription.subscriptionActive.subscriptionId;
      var link = "/payperdatametrics/" + queryId.replace(/%/g, '.');
      var date = new Date(Date.now()).toLocaleString();
      var tag = "";
      var qResp = queryResp;
      var metricsResult = metrics;
      var filtersData = {
        effectiveFilters: metricsState.effectiveFilters,
        queryResp: qResp,
        hash: hash,
      };
      var userAddressId = user.userAddressId;

      var query = {
        date: date,
        queryId: queryId,
        tag: tag,
        link: link,
        jsonDataMetrics: {
          userAddressId: userAddressId,
          filtersData: filtersData,
          metricsResult: metricsResult,
        },
      };

      if (actualHashComprobation === false) {
        console.log("PAYPERDATAFILTERS: PUSHING && UPDATING && (to AWS) QUERY");
        // query is an enormous object, so we store it in AWS
        // stores in local storage (payperData Metrics)
        await dispatchMetrics({
          type: "PUSH_QUERIES",
          payload: query,
        });

        // CONVERT TO JSON C/ELEMENT OF ARRAY "q" (contains all queries)
        // TO STORES IN AWS: c/ ELEMENT WILL BE A ENOURMOUS STRING

        var qToStoreInAWS = [];
        for (let i = 0; i < metricsState.queries.length; i++) {
          let individualQuery = JSON.stringify( metricsState.queries[i]);
          // individualquery is a string?
          console.log(
            "PAYPERDATAFILTERS: INDIVIDUALQUERY",
            individualQuery,
            "IS A STRING?= ",
            typeof individualQuery
          );

          qToStoreInAWS.push(individualQuery);
        }
        updateUserQueries(user.userAddressId, qToStoreInAWS);
      }
      console.log("PAYPERDATAFILTERS: QUERY", query);
      console.log("PAYPERDATAFILTERS: QUERIES IN METRICSSTATE", metricsState.queries);
      console.log("PAYPERDATAFILTERS: QUERIES-JSON-STRINGIFY", qToStoreInAWS);

      setExecutingQuery(false);
      console.log("PAYPERDATAFILTERS: FINAL PUSH /QUERIES");
      //<Link to={query.link} item={query.queryId}>
      //history.push("/queries");
      history.push({
        pathname: query.link,
        state: {
          props: {
            match: {
              params: {
                metricsId: query.queryId
              }
            },
            subscription: subscription,
          }
        }
      });
    } else {
      history.push("/profile");
    }
  };
  /*
    queries:[
      
    0:{
    "date": "2019-04-06T00:00:00.000Z",
    "queryId": "24-32zt42v9ey6t5nwggs73co",
    "tag": "ih3s8dwvimn",
    "link": "https://test.emotiony.io",
    "jsonDataMetrics": {
        "userAddressId": "0xa1b8fc96da1aad79415c1d00abb361615db61d96",
        "filtersData": "dummy filters data",
        "metricsResult": "dummy metrics result"
    },
    1:{....},
    2:{....},
  ]
}

    */

  const comprobateHash = async (hash) => {
    console.log(
      "COMPROBATEHASH: metricState=",
      metricsState,
      " metricState.metricDataHash=",
      metricsState.metricDataHash,
      " metricsState.metricDataHash.length   =",
      metricsState.metricDataHash.length
    );
    if (metricsState.metricDataHash) {
      for (let i = 0; i < metricsState.metricDataHash.length; i++) {
        console.log(
          "COMPROBATE HASH:" +
            hash +
            "METRICSTATE[" +
            i +
            "]=" +
            metricsState.metricDataHash[i].hash
        );
        if (metricsState.metricDataHash[i].hash === hash) {
          return { result: metricsState.metricDataHash[i].metricResults };
        }
      }
    }
    return { result: null };
  };

  const getQueriesFromAWS = async (userAddressId) => {
    console.log("PAYPERDATAFILTERS: GETQUERIESFROMAWS");
    // get queries from MasterUser (theoretically is an Array of strings)
    // quer = ["{query1}", "{query2}", "{query3}", ...]
    // query1, query2, query3 ... are JSON strings of enourmous size objects

    var quer = await getUserQueries(userAddressId);
    console.log("PAYPERDATAFILTERS: QUERIES=", quer);

    //{
    //  "data": {
    //      "getMasterUser": {
    //          "queries": null
    //      }
    //  }
    //}
    // QAWS: QUERIE FROM AWS

    var qaws = quer.data.getMasterUser.queries;
    console.log("PAYPERDATAFILTERS: QUERIES=", qaws);

    // qaws = ["{query1}", "{query2}", "{query3}", ...] NOT NULL:
    if (qaws !== null) {
      let q = []; // stores only in function to AWS data

      for (let i = 0; i < qaws.length; i++) {
        // qaws[i] is String?
        console.log(
          "PAYPERDATAFILTERS: QUERIES-JSON-STRINGIFY=",
          qaws[i],
          "IS A STRING?=",
          typeof qaws[i] === "string"
        );
        // PARSING THE STRING JSON TO OBJECT
        let qawsparse = JSON.parse(qaws[i]);
        console.log("PAYPERDATAFILTERS: QUERIES-JSON-PARSE=", qawsparse);

        q.push(qawsparse); // STORES NORMAL
      }

      await dispatchMetrics({ type: "PUSH_ALL_QUERIES_FROM_AWS", payload: q });
    } else {
      //QAWS IS NULL
      console.log("PAYPERDATAFILTERS: QUERIES-JSON-STRINGIFY= NULL");
    }
  };

  const handleCclick = (event) => {
    event.preventDefault();
    console.log("PAYPERDATAFILTERS: HANDLECLICK");
    // goto the page '/queries' in route
    history.push("/queries");
  }

  useEffect(() => {
    if (metricsState.queries) {
      console.log("PAYPERDATAFILTERS: QUERIES IN METRICSSTATE", metricsState.queries);
    }
  },[metricsState.queries])

  useEffect(() => {
    if (userState.userData) {
      console.log("PAYPERDATAFILTERS-COMPONENT:USER", userState.userData);
      setuser(userState.userData);
      getQueriesFromAWS(userState.userData.userAddressId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.userData]);

  useEffect(() => {
    if (subscription) {
      console.log("PAYPERDATAFILTERS-COMPONENT:SUBSCRIPTION", subscription);
    }
  }, [subscription]);

  return (
    <div>
      {
        (metricsState.queries && metricsState.queries.length >0) &&
        <Segment className="fluid flex ui segment center aligned">
          <Button onClick={handleCclick} >Your queries</Button>
        </Segment>

      }
      <Message
        attached
        header="Filters"
        content="Filter your planets and games by the next selections"
      />
      

      <Form className="attached fluid segment">
        <Form.Field>
          <PayPerDataHashtags />
        </Form.Field>

        <Form.Field>
          <PayPerDataSuperEmos />
        </Form.Field>

        <Form.Field>
          <PayPerDataDrivers />
        </Form.Field>

        <Form.Field>
          <PayPerDataDatesGames />
        </Form.Field>

        <Form.Field>
          <PayPerDataHoursGames />
        </Form.Field>

        <Form.Field>
          <PayPerDataLocationsGames />
        </Form.Field>

        <SubscriptionComponent
          mode="payperdata"
          user={user}
          _setsubscription={setSubscription}
        />

        <Button
          onClick={submitFilters}
          type="submit"
          fluid
          size="big"
          className="filled mt--double"
        >
          {executingQuery ? (
            <>
              <div
                className="sweet-loading"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <PulseLoader
                  size={15}
                  margin={2}
                  color={"#36D7B7"}
                  loading={executingQuery}
                />
              </div>
            </>
          ) : (
            <>Submit</>
          )}
        </Button>
      </Form>
    </div>
  );
}

export default PayPerDataFilters;
