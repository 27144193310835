import React from "react";
import { Header, Divider, Accordion, Image } from "semantic-ui-react";
import logo from "../../shared/assets/images/logo-emotiony2.png";

console.log("PANELFAQS-COMPONENT-MODULE-INIT");

const PanelFaqs = (props) => {

  console.log("---PANELFAQS-COMPONENT-INIT---");

  return (
    <>
      <Header as="h2" style={{ color: "white" }}>
        <Image floated="left" size="large" src={logo} ui={false} />
        <Header.Content>
          {props.data.header}
          <Header.Subheader style={{ color: "#34495E" }}>
            {props.data.subheader}
          </Header.Subheader>
        </Header.Content>
      </Header>

      <Divider />
      <Accordion defaultActiveIndex={1} panels={props.data.panels} />
    </>
  );
};

export default PanelFaqs;
