import "./Home.scss";
import React, { useEffect, useRef } from "react";
import { Segment, Grid } from "semantic-ui-react";
import GameApp from "../../components/Home/GameApp";

console.log("HOME-PAGE-MODULE-INIT");

const Home = (props) => {
  console.log("HOME-PAGE-INIT");
  var hashtagsList = null;
  if (
    props &&
    props.match &&
    props.match.params &&
    props.match.params.hashtagsList
  ) {
    hashtagsList = props.match.params.hashtagsList;
    props.match.params.hashtagsList = null;
  }

  const gameRef = useRef(null);

  const gameScroll = () => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const element = gameRef.current;
    const headerOffset = 80;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + top - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  // TODO Activate when all elements loaded n' ready
  const initPage = () => {
    setTimeout(() => {
      const game = localStorage.getItem("game");
      if (game) {
        gameScroll();
        localStorage.removeItem("game");
      }
    }, 300);
  };

  useEffect(() => {
    initPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="home">
      <Segment textAlign="center" className="noborder">
        <div ref={gameRef}>
          <GameApp hashtags={hashtagsList} />
        </div>
      </Segment>
    </div>
  );
};

export default Home;
