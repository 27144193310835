// PAYPERDATA METRICS REDUCER PARTS:

// metricsAction.type
// metricsAction.payload
// mertricsAction.error
// metricsAction.meta
// metricsAction.OTHER <= ( all other things! )

console.log("PAYPERDATAREDUCER-MODULE-INIT");

export const payperDataReducer = (metricsState, metricsAction) => {
  var ret = null;

  switch (metricsAction.type) {
    case "SET_METRIC_FILTERS":
      ret = {
        ...metricsState,
        metricFilters: {
          hashtags: metricsAction.payload.hashtags,
          drivers: [],
          dateRange: { init: null, end: null },
          hoursRange: [],
          locations: metricsAction.payload.locations,
          planetids: metricsAction.payload.planetids,
        },
      };
      console.log("PAYPERDATAREDUCER:SET_METRIC_FILTERS", ret.metricFilters);
      break;
    case "SET_METRIC_RESULTS":
      ret = {
        ...metricsState,
        metricResults: metricsAction.payload,
      };
      console.log(
        "PAYPERDATAREDUCER:SET_METRIC_RESULTS",
        metricsAction.payload
      );
      break;
    case "SET_PAYPERDATA_STATE":
      ret = {
        ...metricsState,
        payperDataState: metricsAction.payload,
      };
      console.log(
        "PAYPERDATAREDUCER:SET_PAYPERDATA_STATE",
        metricsAction.payload
      );
      break;
    case "SET_HASHTAG_FILTERS":
      ret = metricsState;
      ret.effectiveFilters.hashtags = metricsAction.payload;
      console.log(
        "PAYPERDATAREDUCER:SET_HASHTAG_FILTERS",
        metricsAction.payload
      );
      break;
    case "SET_SUPEREMOS_FILTERS":
      ret = metricsState;
      ret.effectiveFilters.superEmos = metricsAction.payload;
      console.log(
        "PAYPERDATAREDUCER:SET_SUPEREMOS_FILTERS",
        metricsAction.payload
      );
      break;
    case "SET_SUPEREMOS_DRIVERS":
      ret = metricsState;
      ret.effectiveFilters.drivers = metricsAction.payload;
      console.log(
        "PAYPERDATAREDUCER:SET_SUPEREMOS_DRIVERS",
        metricsAction.payload
      );
      break;
    case "SET_DATE_RANGE":
      ret = metricsState;
      ret.effectiveFilters.dateRange = metricsAction.payload;
      console.log("PAYPERDATAREDUCER:SET_DATE_RANGE", metricsAction.payload);
      break;
    case "SET_HOURS_RANGE":
      ret = metricsState;
      ret.effectiveFilters.hoursRange = metricsAction.payload;
      console.log("PAYPERDATAREDUCER:SET_HOURS_RANGE", metricsAction.payload);
      break;
    case "SET_LOCATION_FILTERS":
      ret = metricsState;
      ret.effectiveFilters.locations = metricsAction.payload;
      console.log(
        "PAYPERDATAREDUCER:SET_LOCATION_FILTERS",
        metricsAction.payload
      );
      break;
    case "SET_METRIC_DATAHASH":
      ret = metricsState;
      let comprobation = 0;
      let hash = metricsAction.payload.hash;
      if (ret.metricDataHash) {
        for (let i = 0; i < ret.metricDataHash.length; i++) {
          console.log(
            "PAYPERDATAREDUCER: SET_METRIC_DATAHASH: ??? HASH:" +
              hash +
              " === METRICSTATE[" +
              i +
              "]:" +
              metricsState.metricDataHash[i].hash
          );
          if (metricsState.metricDataHash[i].hash === hash) {
            comprobation = 1;
          }
        }
      }

      if (comprobation === 0) {
        ret.metricDataHash.push(metricsAction.payload);
        console.log(
          "PAYPERDATAREDUCER:SET_METRIC_DATAHASH",
          metricsAction.payload
        );
        console.log(
          "PAYPERDATAREDUCER:Setting in metricDataHashArray:",
          ret.metricDataHash
        );
      } else {
        console.log(
          "PAYPERDATAREDUCER:EXISTS HASH:",
          hash,
          " !!!! (NOT SETTED):",
          ret.metricDataHash
        );
      }

      break;
    case "PUSH_QUERIES":
      /*
            QUERIE SRUCTURE:

            var queryId = queryNumber + "-" + subscription.subscriptionId;
            var link = "/payperdataMetrics/" + queryId;
            var date = new Date(Date.now()).toLocaleString();
            var tag = "";
            var qResp = queryResp;
            var metricsResult = metrics;
            var filtersData = {
            effectiveFilters: metricsState.effectiveFilters,
            queryResp: qResp,
            hash: hash,
            };
            var userAddressId = user.userAddressId;

            var query = {
            date: date,
            queryId: queryId,
            tag: tag,
            link: link,
            jsonDataMetrics: {
                userAddressId: userAddressId,
                filtersData: filtersData,
                metricsResult: metricsResult,
            },
            };
            */
      ret = metricsState;
      // IF QUERY EXISTS, DO NOT PUSH
      let queryId = metricsAction.payload.queryId;
      let comprobation2 = 0;
     
      for (let i = 0; i < ret.queries.length; i++) {
        console.log(
          "PAYPERDATAREDUCER: PUSH_QUERIES: ??? QUERYID:" +
            queryId +
            " === ret.queries[" +
            i +
            "]:" +
            ret.queries[i].queryId
        );
        if (ret.queries[i].queryId === queryId) {
          comprobation2 = 1;
        }
      }
      

      if (comprobation2 === 0) {
        ret.queries.push(metricsAction.payload);
        console.log("PAYPERDATAREDUCER:PUSH_QUERIES", metricsAction.payload);
        console.log("PAYPERDATAREDUCER:Setting in queriesArray:", ret.queries);
      } else {
        console.log(
          "PAYPERDATAREDUCER:EXISTS QUERYID:",
          queryId,
          " !!!! (NOT SETTED):",
          ret.queries
        );
      }
      break;
      
    case "PUSH_ALL_QUERIES_FROM_AWS":
        ret = metricsState;
        ret.queries = metricsAction.payload;
        console.log("PAYPERDATAREDUCER:PUSH_ALL_QUERIES_FROM_AWS", metricsAction.payload);
        console.log("PAYPERDATAREDUCER:Setting in queriesArray:", ret.queries);
        
    break;

    case "SET_SUBSCRIPTION":
      ret = metricsState;
      ret.subscription = metricsAction.payload;
      break;


    default:
      ret = metricsState;
  }
  sessionStorage.setItem("payperDataState", JSON.stringify(ret));
  return ret;
};
