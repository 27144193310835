export const blockChainSigner = async function (_nonce, publicAddress) {
    var toSignPhrase = "Signing with mi Nonce:" + _nonce;
    try {
        console.log("blockChainSigner:SIGNING");
        //alert("blockChainSigner:SIGNING");
        const signature = await window.web3.eth.personal.sign(
            toSignPhrase,
            publicAddress,
            '' // MetaMask will ignore the password argument here
        );

        return { publicAddress, signature };
    } catch (err) {
        console.log('BLOCKCHAIN SIGNER: You need to sign the message to be able to log in.');
    }
}

export function createRandomString(size) {
    var randomString = "";
    var m = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890-";
    var Idlength = size;
    for (let i = 0; i < Idlength; i++) {
        var rnd = parseInt((Math.random() * Number.MAX_SAFE_INTEGER) % m.length);

        var c = m[rnd];
        while (i === 0 && c === '-') {
            rnd = parseInt((Math.random() * Number.MAX_SAFE_INTEGER) % m.length);
            c = m[rnd];
        }
        randomString = randomString + c;
    }
    return randomString;
}

export function createHashOperation(publicAddress) {
    // console.log(publicAddress);
    var hash = hashcode(publicAddress) + "1" + hashcode(createRandomString(10)) + "1" + hashcode(createRandomString(10)) + "1" + hashcode(createRandomString(10));

    return hash;
}

function hashcode(dataStr) {
    return dataStr.split("").reduce(function (a, b) { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0);
}


