import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Input, Menu } from "semantic-ui-react";
//import { userData } from "../../../services/userParams";
import { UserContext } from "../../../services/userContext";
import UserMenu from "../UserMenu/UserMenu";
import "./MenuHeader.scss";

var route1 = "/login";
var route2 = "/login";
var route3 = "/login";
var route4 = "/metrics";
var route5 = "/payperdata";
var route6 = "/queries";

console.log("MENU-HEADER-COMPONENT-MODULE-INIT");

const MenuHeader = () => {
  console.log("---MENU-HEADER-COMPONENT-INIT---");

  var [activeItem, setActiveItem] = useState("play");
  var [userVisible, setUserVisible] = useState(false);
  const [userState, dispatchUser] = useContext(UserContext);

  const setRoutes = () => {
    route1 = "/profile";
    route2 = `/mygalaxy/${userState.userData.realName}`;
    route4 = "/metrics";
    route5 = "/payperdata";
    route6 = "/queries";
  };

  const removeRoutes = () => {
    route1 = "/login";
    route6 = route5 = route4 = route3 = route2 = route1;
    //route4 = '/metrics';
    // route5 = '/payperdata';
  };

  const handleItemClick = (e, { name }) => {
    // console.log(name);
    if (name === "user") {
      setUserVisible(!userVisible);
    }
    setActiveItem(name);
  };

  useEffect(() => {
    console.log(
      "MENU-HEADER: useEffect: userState.userData: ",
      userState.userData
    );
    if (userState.userData) {
      setRoutes();
      console.log(
        "MENU-HEADER: SET ROUTES",
        route1,
        route2,
        route3,
        route4,
        route5,
        route6
      );
    } else {
      removeRoutes();
      console.log(
        "MENU-HEADER: REMOVE ROUTES",
        route1,
        route2,
        route3,
        route4,
        route5,
        route6
      );
    }
  }, [userState.userData]);

  return (
    <>
      <Menu fluid vertical>
        {/* <Menu.Item>
            <Input icon='search' placeholder='Search...' />
          </Menu.Item> */}

        <Menu.Item
          as={Link}
          name="play"
          active={activeItem === "play"}
          onClick={handleItemClick}
          to="/"
        />
        <Menu.Item>
          <a
            href="https://blog.emotiony.io/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "black" }}
          >
            Blog
          </a>
        </Menu.Item>

        <Menu.Item
          as={Link}
          name="marketplace"
          active={activeItem === "marketplace"}
          onClick={handleItemClick}
          to="/marketplace"
        />

        <Menu.Item
          as={Link}
          name="about"
          active={activeItem === "about"}
          onClick={handleItemClick}
          to="/about"
        />

        <Menu.Item
          name="user"
          active={activeItem === "user"}
          onClick={handleItemClick}
        />

        <UserMenu
          userVisible={userVisible}
          route1={route1}
          route2={route2}
          route3={route3}
          route4={route4}
          route5={route5}
          route6={route6}
          logout={
            userState.userData.userAddressId &&
            userState.userData.userAddressId.length > 1
              ? true
              : false
          }
        />
      </Menu>
    </>
  );
};
export default MenuHeader;
