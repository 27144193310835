console.log("BURGER-MENU-COMPONENT-MODULE-INIT");

const BurgerMenu = (props) => {

  console.log("----BURGER-MENU-COMPONENT-INIT---");
  
  return (
    <div id="menuToggle" className={`${props.isMenuOpen ? 'open' : ''}`} onClick={(e) => { props.menuToggle(e); }}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}
export default BurgerMenu;