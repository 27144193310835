import { FilterFrame } from "./FilterFrame";
import { useState, useEffect } from "react";
import { Button, Dropdown } from "semantic-ui-react";
import { SearchAndListAndChipsComponent } from "./searchAndListAndChipsComponent";
import { DateRangeListAndChipsComponent } from "./dateRangeListAndChipsComponent";
export const FilterComponentBase = (props) => {
  const { clearAll, setter, setted, lista, title } = props;
  
  const [value, setValue] = useState([]);
  var [clearValues, setClearValues] = useState(false);

  const handleOnChange = (e, data) => {
    console.log("FILTERCOMPONENT:", title, " HANDLEONCHANGE: ", data.value);

    setter(data.value);
    setValue(data.value);
  };

  useEffect(() => {
    if (clearAll && !clearValues) {
      setValue([]);
      setClearValues(true);
      setter([]);
      console.log("FILTERCOMPONENT:", title, " CLEARED");
    }
    if (!clearAll) {
      setClearValues(false);
    }
  }, [clearAll, clearValues]);

  useEffect(() => {
    setValue(setted);
  }, [setted]);

  return (
    <>
      <label>{title}</label>
      {title !== "Date Range" ? (
        <Dropdown
          placeholder={title}
          onChange={handleOnChange}
          search
          fluid
          multiple
          selection
          clearable
          options={lista}
          value={value}
        />
      ) : (
        <DateRangeListAndChipsComponent
          setter={setter}
          setted={setted}
          title={title}
          lista={lista}
          clearAll={clearAll}
        />
      )}
    </>
  );
};
