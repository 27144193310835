import { useState } from 'react';
import { Image, List, Label } from "semantic-ui-react";

// import LikeHeart from "../commons/likeHeart";
import { favCheck } from "../../services/Graphql/Game/updateGame.js";
import { userData, userId } from "../../services/userParams";
import { tokenizeStatus } from "../../services/tokenizeService";

import "../Card/cardPlanet.scss";


console.log("PLANETIMAGE-COMPONENT-MODULE-INIT");

const PlanetImage = (props) => {
  
  var { stateTokenize, owner } = props;

  const [favorites, setFavorites] = useState([]);
  const [gameId, setGameId] = useState(null);

  var [favorite, setFavorite] = useState(null);
  var [likes, setLikes] = useState(0);

  const likeCheck = async (liked, favorites, planetOperation) => {
    favCheck(
      liked,
      gameId,
      userId,
      userData.currentFavoritePlanetIds,
      userData.currentFavoritePlanetIdsCount,
      favorites,
      planetOperation
    );
    var lks = likes;
    if (liked) {
      lks = ++lks;
    } else {
      lks = --lks;
    }
    setLikes(lks);
    setFavorite(liked);
  };

 

  console.log("---PLANETIMAGE-COMPONENT-INIT---");
  console.log("PLANETIMAGE:PROPS", props);
  return (
    <>
      <List className="cardplanet__tags padding--20">
        <List.Item>
          <List.Content floated='left'>
            {/* <LikeHeart
              favorite={favorite}
              likeCheck={() =>
                likeCheck(
                  !favorite,
                  props.item.favorites,
                  props.item.planetOperation
                )
              }
              carousel={props.carousel}
            />
            {likes} */}

          </List.Content>

          <List.Content floated="right">
            {stateTokenize === "Tokenized!!!" ? (
              <>
                <Label>Tokenized</Label>
                {/* <Label>For sale</Label> */}
              </>
            ) : null}
          </List.Content>
        </List.Item>
      </List>

      <Image src={props.image} wrapped ui={true} />
    </>
  );
};

export default PlanetImage;
