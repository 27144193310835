import { Accordion, Icon } from "semantic-ui-react";
import React, { useState } from "react";


console.log("PUBLISHPLANET-COMPONENT-MODULE-INIT");

const Publish = ({ disabled, owner }) => {

  console.log("---PUBLISHPLANET-COMPONENT-INIT---");
  var [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };
  const onPublish = () => {
    console.log("publish");
  };
  return (
    <>
      <div className={`card-border ${owner ? "" : "hide"}`}>
        <Accordion styled>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={handleClick}
            data-tooltip="Coming soon!"
            data-variation="tiny"
          >
            <div className="subtitle">
              <Icon name="share square" />
              PUBLISH
            </div>

            <Icon name="dropdown" />
          </Accordion.Title>
          <Accordion.Content
            active={activeIndex === 0}
            style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}
          >
            <div className="accordion-content">
              <div className="gradient-button pointer" onClick={onPublish}>
                <div className="item-border">
                  {/* <Image src={NFTLogo} /> */}
                  Publish
                </div>
              </div>
            </div>
          </Accordion.Content>
        </Accordion>
      </div>
    </>
  );
};

export default Publish;
