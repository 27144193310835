import { useState } from "react";
import { Container, Menu, Sidebar } from "semantic-ui-react";
import Header from "../../components/Header";
import MenuHeader from "../../components/Header/MenuHeader/MenuHeader.js";
//import Footer from '../../components/Footer/Footer';

console.log("BASE-LAYOUT-MODULE-INIT");

const Baselayout = (props) => {
  console.log("---BASE-LAYOUT-INIT---");

  const { children } = props;
  var [isMenuOpen, setMenuOpen] = useState(false);

  const menuToggle = (e) => {
    setMenuOpen(!isMenuOpen);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Header
        isMenuOpen={isMenuOpen}
        menuToggle={menuToggle}
        pageProps={props.children.props}
      />

      <Sidebar.Pushable as="main">
        <Sidebar
          animation="push"
          icon="labeled"
          onHide={() => setMenuOpen(false)}
          visible={isMenuOpen}
          width="wide"
          direction="right"
        >
          <MenuHeader />
        </Sidebar>

        {/* <Sidebar.Pusher dimmed={isMenuOpen}> */}
        <Sidebar.Pusher>
          <Container fluid>{children}</Container>
        </Sidebar.Pusher>
      </Sidebar.Pushable>

      {/* <Footer /> */}
    </>
  );
};

export default Baselayout;
