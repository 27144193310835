import { Header, Segment, Image } from "semantic-ui-react";

import about1 from '../../../components/Home/fiber/assets/images/about1.png'
import about2 from '../../../components/Home/fiber/assets/images/about2.png'
import about3 from '../../../components/Home/fiber/assets/images/about3.png'

console.log("ABOUT-PAGE-MODULE-INIT");

const About = () => {
  console.log("---ABOUT-PAGE-INIT---");
  return (
    <>
      <Header as="h1">About</Header>
    
      <Segment>
        <Header as="h2">About Emotiony</Header>
        <p>World changes your emotions, emotions change the world.</p>
        <p>Emotiony is a platform that aims to build a new creative economy—a world where you can use the Polygon and Ethereum blockchain to value your cryptoart  in entirely new ways, and build stronger connections with your community.</p>
        <p>We are creating an economy and a market based on your emotions democratizing the ownership of your Data.</p>
        <Image fluid src={about1} alt="about1"></Image>
      </Segment>

      <Segment>
        <Header as="h2">About our vision</Header>
        <p>Make a worldwide tool to make value to our emotions. Positioning crypto as a force for good and emotions as a tool for the right thing</p>
        <p>Emotiony’s vision is not just about the crypto community but the whole world's emotions. Our goal is not only to bring together everyone with a vested interest in emotions, art,  cryptocurrency and NFTs, but also build awareness and mass adoption globally.</p>
        <p>Our aim is to show how cryptocurrency can be a force for good, and to give people powerful tools which will help to create world class solutions to some of the world’s largest issues.</p>
        <p>In turn, awareness of the benefits that cryptocurrency and NFT’s can bring to the world will grow, as more people are brought together to openly communicate about the benefits that cryptocurrency can bring to the world.</p>
        <Image fluid src={about2} alt="about2"></Image>
      </Segment>

      <Segment>
        <Header as="h2">About your data</Header>
        <p>Take back control of your data.</p>
        <p>Smarts contracts and the associated technology create a fair and transparent system that maintains and reinforces the ownership of people’s data.</p>
        <p>The goal here is to  give back control over data to people.</p>
        <Image fluid src={about3} alt="about3"></Image>
      </Segment>
    </>
  )
}

export default About;