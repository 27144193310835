import React from "react";
import Gargantua from './Gargantua/Gargantua';
import Faqs from './Faqs.jsx';

console.log("HOW-IT-WORKS-COMPONENT-MODULE-INIT");

function HowitWorksComponent(){

    console.log("---HOW-IT-WORKS-COMPONENT-INIT---");
    
    return <div>
                <Gargantua/>
                <h1>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;F A Q</h1>
                <Faqs/>
           </div>
}

export default HowitWorksComponent