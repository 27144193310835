import { Label, Icon } from "semantic-ui-react";

console.log("CHIP-COMPONENT-MODULE-INIT");

const Chip = (props) => {

  console.log("---CHIP-COMPONENT-INIT---");

  return (
    <Label className="chip">
      {props.e.title}
      <Icon name='close' onClick={() => props.deselectItem(props.e, props.i)} />
    </Label>
  );
};
export default Chip;
