import React from "react";

import { Dropdown, Label } from "semantic-ui-react";

import { useContext,useEffect,useState } from "react";
import {MetricsContext} from "../../../services/metricsContext.js";
import DriversDetailWindRose from "../Charts/DriversDetailWindRose.jsx";

var opt = [
  { key: "angular", text: "Angular", value: "angular" },
  { key: "css", text: "CSS", value: "css" },
  { key: "design", text: "Graphic Design", value: "design" },
  { key: "ember", text: "Ember", value: "ember" },
  { key: "html", text: "HTML", value: "html" },
  { key: "ia", text: "Information Architecture", value: "ia" },
  { key: "javascript", text: "Javascript", value: "javascript" },
  { key: "mech", text: "Mechanical Engineering", value: "mech" },
  { key: "meteor", text: "Meteor", value: "meteor" },
  { key: "node", text: "NodeJS", value: "node" },
  { key: "plumbing", text: "Plumbing", value: "plumbing" },
  { key: "python", text: "Python", value: "python" },
  { key: "rails", text: "Rails", value: "rails" },
  { key: "react", text: "React", value: "react" },
  { key: "repair", text: "Kitchen Repair", value: "repair" },
  { key: "ruby", text: "Ruby", value: "ruby" },
  { key: "ui", text: "UI Design", value: "ui" },
  { key: "ux", text: "User Experience", value: "ux" }
];

const Hashtags = () => {
  const [metricsState, dispatchMetrics] = useContext(MetricsContext);
  const [options,setOptions]= useState(opt);
  const renderLabel = (label) => ({
    content: `${label.text}`
  });

  const saveOptionsToContext=(e,{value})=>{
    //console.log("saveOptionsToContext:value",value);
    dispatchMetrics({type:"SET_HASHTAG_FILTERS",payload:value})
  }

  useEffect(()=>{
    if(metricsState.metricFilters && metricsState.metricFilters.hashtags) {
      let newOpt=[];
      for(let i=0;i<metricsState.metricFilters.hashtags.length;i++){
        let h=metricsState.metricFilters.hashtags[i]
        newOpt.push({key:h,text:h,value:h})
      }
      console.log("HASTAGS-METRICS OPTIONS",newOpt);
      setOptions(newOpt);
    }
  },[metricsState.metricFilters]);

  return (
    <>

      <label style={{ width: "190px" }}>Hashtags</label>
      <Dropdown
        fluid
        openOnFocus
        search
        clearable
        placeholder="Search"
        multiple
        selection
        options={options}
        renderLabel={renderLabel}
        onChange={saveOptionsToContext}
      />

    </>
  );
};

export default Hashtags;
