import React, { useEffect, useState ,useContext} from "react";
import { format, getDay, getYear } from "date-fns";
import { Header, Icon } from "semantic-ui-react";
import "./marketPlaceHeader.scss";

import maticIcon from "../../shared/assets/icons/matic-logo.jpg";
//import MarketPlaceSocialItem from './MarketPlaceSocialItem';

//import ProfileModal from './ProfileModal/ProfileModal';

import image3 from "../../shared/assets/images/payxdata.jpg";
import { toast } from "react-toastify";
import NetworkShare from "../PlanetDetail/UserPlanetDetail/NetworkShare";
import { chargeAvatartoIPFS } from "../../services/Game/chargeToIPFSwhitAxios";
//import { userData, userService } from '../../services/userParams';
//import { userService } from "../../services/userParams";
import { timingSafeEqual } from "crypto";
import { Time } from "highcharts";
import { UserContext } from "../../services/userContext";

console.log("MARKETPLACE-HEADER-MODULE-INIT");

const MarketPlaceHeader = () => {
  console.log("----MARKETPLACE-HEADER-COMPONENT-INIT----");

  const[userState,dispatchUser]=useContext(UserContext);

  const [joined, setJoined] = useState({
    month: "",
    year: "",
    day: "",
  });

  // SET PLACEHOLDER, COMMENT FOR PRODUCTION
  const userDataPlaceHolder = {
    userAddressId: "0X 1083030209 DUMMY USER ADDRESS ID 242412489",
    joined: null, //new Date(Date.now()).toDateString() + ":", //"28/Jan/2022:20:00:02 +0000"
    realname: "@DummyExample0000User",
  };

  const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;
  var [userData, setUserData] = useState(userDataPlaceHolder);
  // SET NULL TO INITIAL VALUE USERDATA. UNCOMENT TO PRODUCTION
  //var [userData, setUserData] = useState(null);
 

  var settingJoined = (uu) => {
    if(uu.joined){
      var userjoined = uu.joined.split(":")[0];

      var joinedmonth = format(Date.parse(userjoined), "LLLL");
      var joinedyear = getYear(Date.parse(userjoined));
      var joinedday = userjoined.split(/[\/]/)[0];

      console.log("USERJOINED", userjoined);
      console.log("MONTH:", userjoined.moth);
      console.log("YEAR:", userjoined.year);
      console.log("DAY", userjoined.day);

      setJoined({
        month: joinedmonth,
        year: joinedyear,
        day: joinedday,
      });
   }
  };

  

  const notify = () =>
    toast.success("Link copied to clipboard!", {
      className: "toast-success-container toast-success-container-after",
    });

  const copyToClipBoard = (account) => {
    navigator.clipboard
      .writeText(account)
      .then(() => {
        notify();
        // console.log("Text copied to clipboard...")
      })
      .catch((err) => {
        console.log("Something went wrong", err);
      });
  };
  
  useEffect(() => {
    settingJoined(userDataPlaceHolder);
    if(userState.userData){
      console.log("MARKETPLACE-HEADER-COMPONENT: USERDATA=",userState.userData);
      setUserData(userState.userData);
      settingJoined(userState.userData);
    }
  }, [userState.userData]);

  return userData.realName === "@DummyExample0000User" &&
    userData.userAddressId ===
      "0X 1083030209 DUMMY USER ADDRESS ID 242412489" ? null : (
    <>
      <Header as="h2">
        {userData && userData.realName ? <> {userData.realName + " "} </> : "no user "}
      </Header>
      <Header as="h3">
        {userData &&
          userData.userAddressId &&
          `${userData.userAddressId.slice(
            0,
            6
          )}...${userData.userAddressId.slice(
            userData.userAddressId.length - 4,
            userData.userAddressId.length
          )}`}

        {userData && userData.userAddressId ? (
          <Icon
            name="copy outline"
            onClick={() => copyToClipBoard(userData.userAddressId)}
          ></Icon>
        ) : null}
      </Header>

      <div>
        {userData && userData.realName ? "Joined" : null} {joined.day}{" "}
        {joined.month} {joined.year}
      </div>
    </>
  );
};

export default MarketPlaceHeader;
