import { getUserAWS } from './Graphql/User/queryUser';
import { getUserBalance } from './userWallet';

console.log("USERPARAMSTOREDUCER-MODULE-INIT");

export const _getUser = async (userId) => {
    const user = await getUserAWS(userId);
    if (user.data.listMasterUsers && user.data.listMasterUsers.items[0]) {
        user.data.listMasterUsers.items[0].noAvatar = 'https://seekicon.com/free-icon-download/header-user_1.svg';
    }
    const userData = user.data.listMasterUsers.items[0];
    const balance = await getUserBalance(window.web3, userId);
    if (userData) {
        userData.balance = balance;
    }
    return userData;
}

export const _getUserSigned = async (userId, sign) => {
    const user = await getUserAWS(userId, sign);
    console.log("GETUSERSIGNED", user);
    if (user.data.listMasterUsers && user.data.listMasterUsers.items[0]) {
        user.data.listMasterUsers.items[0].noAvatar = 'https://seekicon.com/free-icon-download/header-user_1.svg';
    }
    const userData = user.data.listMasterUsers.items[0];
    const balance = await getUserBalance(window.web3, userId);
    if (userData) {
        userData.balance = balance;
    }
    //setUser(userData);


    return userData;
}