import React from "react";
// Import Highcharts
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
// import DarkUnica from "highcharts/themes/dark-unica";


import { useContext, useEffect, useState } from "react";
//HcMore(Highcharts);
// DarkUnica(Highcharts);
import { Card } from "semantic-ui-react";
// MODULO DE EXPORTACION HIGHCHARTS
import HighChartExport from "highcharts/modules/exporting";
HighChartExport(Highcharts);

function createOptions(datapos, dataneg) {
  return {
    exporting: {
      enabled: true,
    },
    chart: {
      type: "column",    
      events: {
        load() {
          this.showLoading();
          setTimeout(this.hideLoading.bind(this), 5000);
        }
      }
    },
    title: {
      text: "How are your emotions?"

    },
    
    subtitle: {
      text: "Discover your emotions by day"

    },
    xAxis: {
      minRange: 1,
      labels: {
        format: "{value: %b %e}",
      },
      tickInterval: 60480000,
      type: "datetime",
      //min: 1569888000000 // 2019-10-01T00.00.00.000Z
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "positve",
        data: datapos,
        _colorIndex: 9,
      },
      {
        name: "negative",
        data: dataneg,
        _colorIndex: 5,
      },
    ],
  };
}

// Render app with demo chart

function PolarityByDayArea(props) {
  
  const { metricsState } = props;
   var datapos=[];
   var dataneg=[];



  const [options, setOptions] = useState(createOptions(datapos, dataneg));

  useEffect(() => {
    if (metricsState.metricResults && metricsState.metricResults.dataAllGames) {
      var newdataneg = [];
      var newdatapos = [];
      for (let i = 0; i < metricsState.metricResults.dataAllGames.length; i++) {
        let d = metricsState.metricResults.dataAllGames[i];
        newdataneg.push([d.epoch, d.sum_points_negative]);
        newdatapos.push([d.epoch, d.sum_points_positive]);
      }
      console.log("DATAPOS", newdatapos);
      console.log("DATANEG", newdataneg);
      setOptions(createOptions(newdatapos, newdataneg));
    }
  }, [metricsState.metricResults]);

  return (
    <Card fluid>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Card>
  );
}
export default PolarityByDayArea;
