import React from "react";
import { useState, useContext, useEffect } from "react";
import {
  Icon,
  Dropdown,
  Form,
  Button,
  Input,
  Message,
  TextArea,
  Segment,
} from "semantic-ui-react";

import { wsSubscriptionQuery } from "../../services/WebSockets/wsSubscriptionQuery";
import { createHashOperation } from "../../core/blockchain/blockchainSigner";
import { subscriptionTypeOptions } from "../../services/subscriptionService";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { PayPerDataContext } from "../../services/payperDataContext";

console.log("SUBSCRIPTION-COMPONENT-MODULE-INIT");

var wsQuery = null;

function SubscriptionComponent(props) {
  const { mode, user } = props;
  var { _setsubscription } = props;

  if (_setsubscription) {
    console.log("SUBSCRIPTION-COMPONENT-SETSUBSCRIPTION", _setsubscription);
  }

  console.log("---SUBSCRIPTION-COMPONENT-INIT---", mode, user);
  // USER is at user.user
  // const subscriptionTypeOptions = [
  //   { key: "free", text: "free", value: "free" },
  //   {
  //     key: "queryCounterDown",
  //     text: "queryCounterDown",
  //     value: "queryCounterDown",
  //   },
  // ];
  // console.log("SUBSCRIPTION-COMPONENT-USER", user);
  // console.log(
  //   "SUBSCRIPTION-COMPONENT-SUBSCRIPTIONOPTIONS",
  //   subscriptionTypeOptions()
  // );

  const subscriptionTypeOpts = subscriptionTypeOptions().map((item) => {
    return { key: item.type, text: item.type, value: item.type };
  });

  const getDataSubscription = (type) => {
    return subscriptionTypeOptions().filter((item) => item.type === type)[0]
      .data;
  };
  // console.log(
  //   "SUBSCRIPTION-COMPONENT-EXAMPLE FREE:",
  //   getDataSubscription("free")
  // );
  // console.log(
  //   "SUBSCRIPTION-COMPONENT-EXAMPLE PRO:",
  //   getDataSubscription("pro")
  // );
  // console.log(
  //   "SUBSCRIPTION-COMPONENT-EXAMPLE ENTERPRISE:",
  //   getDataSubscription("enterprise")
  // );

  const [payImport, setPayImport] = useState(0);
  const [subscriptionHidden, setSubscriptionHidden] = useState(true);
  const [subscriptionType, setSubscriptionType] = useState(
    subscriptionTypeOpts[0].text
  );
  const [subscriptionDuration, setSubscriptionDuration] = useState(0);
  const [subscriptionCreationDate, setSubscriptionCreationDate] = useState(
    Date.now()
  );
  const [queryResponse, setQueryResponse] = useState(null);
  const [subscriptionActive, setSubscriptionActive] = useState(null);

  const [subscriptionResultSuccesfully, setSubscriptionResultSuccesfully] =
    useState(null);

  const [metricState, dispatchMetrics] = useContext(PayPerDataContext);

  const onChangeDatePicker = (event, data) => {
    console.log("CHANGE DATE PICKER=", data.value);
    setSubscriptionCreationDate(data.value);
  };
  const onchangeSubscriptionType = (event, data) => {
    event.preventDefault();
    console.log("CHANGE SUBSCRIPTION TYPE=", data.value);
    setSubscriptionType(data.value);
  };
  const onchangeSubscriptionDuration = (event, data) => {
    console.log("CHANGE SUBSCRIPTION DURATION=", data.value);
    setSubscriptionDuration(data.value);
  };
  const onchangeSubscriptionPayImport = (event, data) => {
    console.log("CHANGE SUBSCRIPTION PAY IMPORT=", data.value);
    setPayImport(data.value);
  };

  var subscriptionOperation = (wsQuery, functionLambda, subscriptionData) => {
    // SUBRCRIPTION STATUS IS CALCULATED BY LAMBDA
    subscriptionData.operation = "subscription";
    subscriptionData.functionLambda = functionLambda;
    subscriptionData.userAddressId = user.userAddressId;
    subscriptionData.hashOperation = createHashOperation(user.userAddressId);

    if (functionLambda === "create_subscription") {
      subscriptionData.subscriptionCreation = new Date(
        subscriptionCreationDate
      ).getTime();
    }
    console.log("subscriptionOperation SEND:", subscriptionData);
    wsQuery.send(JSON.stringify(subscriptionData));
  };

  var subscriptionAction = () => {
    console.log("SUBSCRIPTION ACTION");
    if (subscriptionCreationDate && Date.now() >= subscriptionCreationDate) {
      console.log("Date pass=", subscriptionCreationDate);
      if (getDataSubscription(subscriptionType).subScriptionDuration > 0) {
        console.log("Duration pass>0=", subscriptionDuration);
        console.log(
          "Import=",
          parseInt(getDataSubscription(subscriptionType).payImport)
        );
        if (parseInt(getDataSubscription(subscriptionType).payImport) > 0) {
          console.log(
            "pay Import >=",
            getDataSubscription(subscriptionType).payImport,
            " pass=",
            payImport
          );

          wsQuery = new wsSubscriptionQuery(setQueryResponse);

          /*
            codeFromBlockChain=(
              contract operation(
                userAddressId,payImport,subscriptionType,
                subscriptionCreationDate,subscriptionDuration
              )
            );
            */

          subscriptionOperation(
            wsQuery,
            "create_subscription",
            // SUBSCRIPTIONDATA:
            {
              payCodeConfirmation: "codeFromBlockChain",
              payImport: payImport,
              subscriptionType: subscriptionType,
              subscriptionDuration:
                getDataSubscription(subscriptionType).subScriptionDuration,
            }
          );
        } else {
          console.log(
            "FAIL: pay Import >=",
            getDataSubscription(subscriptionType).payImport,
            " NO pass=",
            payImport
          );
        }
      } else {
        console.log("FAIL: Duration NO pass>0=", subscriptionDuration);
      }
    } else {
      console.log("FAIL: Date NO pass=", subscriptionCreationDate);
    }
  };

  var getActiveSubscription = () => {
    var wsQueryGA = new wsSubscriptionQuery(setQueryResponse);
    subscriptionOperation(
      wsQueryGA,
      "get_active_subscription",
      // SUBSCRIPTIONDATA:
      {}
    );
  };

  useEffect(() => {
    if (user && user.userAddressId && !subscriptionResultSuccesfully) {
      console.log("USERADDRESSID=", user.userAddressId);
      getActiveSubscription();
    }
  }, [props, user, subscriptionResultSuccesfully]);

  useEffect(() => {
    console.log("SUBSCRIPTIONCOMPONENT QUERY RESPONSE=", queryResponse);

    if (
      queryResponse &&
      queryResponse.subscriptionResult &&
      !subscriptionResultSuccesfully
    ) {
      if (!queryResponse.subscriptionResult.isActive) {
        setSubscriptionHidden(false);
      } else {
        //var [subscriptionActive,setSubscriptionActive]=useState(null);
        setSubscriptionActive(queryResponse.subscriptionResult);
        if (_setsubscription) {
          _setsubscription(queryResponse.subscriptionResult);
        }

        console.log("SUBSCRIPTION RESULT", queryResponse.subscriptionResult);
      }

      if (queryResponse.subscriptionResult.subscriptionStatus === "active") {
        console.log(
          "SUBSCRIPTION SUCCESSFULLY: ",
          queryResponse.subscriptionResult.subscriptionStatus
        );
        console.log(
          "setSubscriptionResultSuccesfully(queryResponse.subscriptionResult)=",
          queryResponse.subscriptionResult
        );
        setSubscriptionResultSuccesfully(queryResponse.subscriptionResult);
        /*
            subscriptionResult: 
              hashOperation: "-207800590116017597941-15232803001-48374777"
              payCodeConfirmation: "codeFromBlockChain"
              payImport: "200"
              subscriptionDuration: "10"
              subscriptionExpiration: 1669038447905
              subscriptionId: "SID-207800590%06B6D440681596314898-1900747682-1731104104"
              subscriptionKey: "userSubscription#-207800590116017597941-15232803001-48374777"
              subscriptionStatus: "active"
              subscriptionType: "free"
              [[Prototype]]: Object
              userAddressId: "0x7B896e74E2282516346e8893DE734949C7453D55"
            */
      }
    }
  }, [queryResponse]);

  return mode === "profile" ? (
    <>
      <div hidden={subscriptionHidden}>
        <Message attached header="Subscription" content="Subscribe to ..." />

        <Form id="subscripionForm" className="attached fluid segment">
          <Form.Field>
            <label>Pay Import</label>
            {subscriptionType === "enterprise" ? (
              <Input
                onChange={onchangeSubscriptionPayImport}
                placeholder="Matic Payment minimal=200"
              />
            ) : (
              <TextArea
                value={getDataSubscription(subscriptionType).payImport}
                rows={1}
                style={{ resize: "none" }}
              />
            )}
          </Form.Field>

          <Form.Field>
            <label>Duration</label>
            {subscriptionType === "enterprise" ? (
              <Input
                onChange={onchangeSubscriptionDuration}
                placeholder="Duration in days or in QueriesNum"
                value={
                  getDataSubscription(subscriptionType).subScriptionDuration +
                  "  queries"
                }
              />
            ) : (
              <TextArea
                value={
                  getDataSubscription(subscriptionType).subScriptionDuration +
                  "  queries"
                }
                rows={1}
                style={{ resize: "none" }}
              />
            )}
          </Form.Field>

          <Form.Field>
            <label>Type</label>
            <Dropdown
              fluid
              openOnFocus
              placeholder="Select Subscription Type"
              selection
              options={subscriptionTypeOpts}
              value={subscriptionType}
              onChange={onchangeSubscriptionType}
              disabled
            />
          </Form.Field>

          <Form.Field>
            <label>Subscription Creation</label>
            <SemanticDatepicker
              size="small"
              format="MM-DD-YYYY"
              icon="calendar outline"
              showToday
              minDate={new Date(Date.now() - 86400000)}
              value={subscriptionCreationDate}
              onChange={onChangeDatePicker}
              disabled
            ></SemanticDatepicker>
          </Form.Field>

          <Button onClick={subscriptionAction}>
            Subscribe <Icon name="arrow right"></Icon>
          </Button>
        </Form>
      </div>

      <Segment>
        {subscriptionResultSuccesfully &&
        (!subscriptionActive || !subscriptionActive.isActive) ? (
          <div className="ui container">
            <Message>Creating subscription, please wait...</Message>
            <Message
              icon="bell outline"
              header={
                "Active Subscription : " +
                subscriptionResultSuccesfully.subscriptionId
              }
              content={
                "Date of creation: " +
                new Date(
                  subscriptionResultSuccesfully.subscriptionCreation
                ).toDateString() +
                ", Duration : " +
                subscriptionResultSuccesfully.subscriptionDuration +
                (subscriptionResultSuccesfully.subscriptionType === "free"
                  ? subscriptionResultSuccesfully.subscriptionDuration === "1"
                    ? " day"
                    : " days"
                  : subscriptionResultSuccesfully.subscriptionDuration === "1"
                  ? " querie"
                  : " queries") +
                ", Type: " +
                subscriptionResultSuccesfully.subscriptionType
              }
            />
          </div>
        ) : (
          ""
        )}

        {!subscriptionResultSuccesfully &&
        subscriptionActive &&
        subscriptionActive.isActive ? (
          <>
            
              <h3 className="ui container">
                <Icon name="id card outline" />
                {"Active Subscription"}
              </h3>
              <br />
              <p>
                <strong>Subscription Id:</strong>{" "}
                {subscriptionActive.subscriptionActive.subscriptionId}
              </p>
              <p>
                <strong>Date of creation: </strong>{" "}
                {new Date(
                  subscriptionActive.subscriptionActive.subscriptionCreation
                ).toDateString()}
              </p>
              <p>
                <strong>Duration: </strong>
                {subscriptionActive.subscriptionActive.subscriptionDuration +
                  (subscriptionActive.subscriptionActive.subscriptionType ===
                  "free"
                    ? subscriptionActive.subscriptionActive
                        .subscriptionDuration === "1"
                      ? " day"
                      : " days"
                    : subscriptionActive.subscriptionActive
                        .subscriptionDuration === "1"
                    ? " querie"
                    : " queries")}
              </p>
              <p>
                <strong>Type: </strong>
                {subscriptionActive.subscriptionActive.subscriptionType}
              </p>
            
          </>
        ) : (
          ""
        )}
      </Segment>
    </>
  ) : (
    <>
      <p
        style={{
          wordWrap: "break-word",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {subscriptionActive && subscriptionActive.isActive
          ? "Subscription : " +
            subscriptionActive.subscriptionActive.subscriptionId
          : "no subscription"}
      </p>
    </>
  );
}

export default SubscriptionComponent;
