import React from "react";
import { Dropdown, Label } from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import {MetricsContext} from "../../../services/metricsContext.js";

const opt = [
  {
    key: "Nothing",
    text: "Nothing",
    value: "Nothing",
  }
];

const LocationsGames = () => {
  const [options,setOptions]=useState(opt);
  const [metricsState, dispatchMetrics] = useContext(MetricsContext);
  const renderLabel = (label) => ({
    content: `${label.text}`
  });

  const saveOptionsToContext=(e,{value})=>{
    //console.log("saveOptionsToContext:value",value);
    dispatchMetrics({type:"SET_LOCATION_FILTERS",payload:value})
  }

  useEffect(()=>{
    console.log("METRICSTATE",metricsState); 
    
    let newOpt=[];
    if(metricsState.metricFilters && metricsState.metricFilters.locations)
    {
      var loc=metricsState.metricFilters.locations;
      // generate unique values
      loc = loc.filter((v, i, a) => a.indexOf(v) === i);
      metricsState.metricFilters.locations=loc;
      for(let i=0;i<metricsState.metricFilters.locations.length;i++){
        let v=metricsState.metricFilters.locations[i]
        
        if(v.length>1){
          // LOCATION SIMPLYFIER
          /* let vsplit=v.split(",");
          if(vsplit.length===5){
            v=vsplit[3]+", " + vsplit[1];
          } */
          newOpt.push({key:v,text:v,value:v})
        }
      }
      //console.log("OPTIONS",newOpt);
      setOptions(newOpt);
    }    
  },[metricsState.metricFilters]);

  return (
    <>

      <label style={{ width: "190px" }}>Locations </label>
      <Dropdown
        fluid
        openOnFocus
        search
        clearable
        placeholder="Search"
        multiple
        selection
        options={options}
        renderLabel={renderLabel}
        onChange={saveOptionsToContext}
      />

    </>
  );
};

export default LocationsGames;
