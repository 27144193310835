import { useEffect, useState } from "react";
import { Header } from "semantic-ui-react";
// import LikeHeart from "../../commons/likeHeart";
import { favCheck } from "../../../services/Graphql/Game/updateGame.js";
import { userData } from "../../../services/userParams";


console.log("USERDATA-COMPONENT-MODULE-INIT");

const UserData = (props) => {

  console.log("---USERDATA-COMPONENT-INIT---");

  console.log("USERDATA PROPS", props);
  var [gameId, setGameId] = useState("");
  var [favorite, setFavorite] = useState(false);
  var [user, setUser] = useState("UserName");
  var [collection, setCollection] = useState("My Galaxy");
  var [textGame, setTextGame] = useState("No text");
  var [likes, setLikes] = useState(0);

  const likeCheck = async (liked) => {
    favCheck(
      liked,
      gameId,
      sessionStorage.getItem("address"),
      userData.currentFavoritePlanetIds,
      userData.currentFavoritePlanetIdsCount,
      props.gameData.favorites,
      props.gameData.planetOperation
    );
    var lks = likes;
    if (liked) {
      lks = ++lks;
    } else {
      lks = --lks;
    }
    setLikes(lks);
    setFavorite(liked);
  };
  const setFav = () => {
    if (
      props.gameData.favorites &&
      props.gameData.favorites.indexOf(getUserId()) !== -1
    ) {
      setFavorite(true);
    }
    if (props.gameData.favorites) {
      var lks = props.gameData.favorites.length;
      setLikes(lks);
    }
  };
  const getUserId = () => {
    return sessionStorage.getItem("address");
  };

  useEffect(() => {
    if (props.gameData) {
      console.log("USERDATA PROPS", props);
      if (props.gameData.queryMarketplace) setCollection("MARKETPLACE");
      setGameId(props.gameData.idGame);
      setUser(props.gameData.generalData.username || "No username");
      setFavorite(props.gameData.favorite);
      setTextGame(props.gameData.textGame || "No text");
      setLikes(props.gameData.likes || 0);
      setFav();
    }
  }, [props]);

  return (
    <>
      <div>
        <Header as="h1">{textGame}</Header>
      </div>

      <div>
        <div>Owned by</div>
        <div><strong>{user}</strong></div>
      </div>

      <div>
        <div>Planet Id</div>
        <div className="planetdetail__header__id"><strong>{gameId}</strong></div>
      </div>
    </>
  );
};

export default UserData;
