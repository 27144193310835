import { useContext } from 'react';
import { List, Image, Button } from 'semantic-ui-react';
import { metamask } from '../../../services/userWallet';
import { UserContext} from '../../../services/userContext';

console.log("PROVIDER-COMPONENT-MODULE-INIT");

const Provider = (props) => {

  console.log("-----PROVIDER-COMPONENT-INIT----");
  
  const [userState, dispatchUser] = useContext(UserContext);
   
  const go = (activator) => {
    if (activator === 'window.ethereum') {
      var _reducer={userState:userState,dispatchUser:dispatchUser}
      metamask(props.pageProps,_reducer)
    }
  }
  return (
    <>
      <List.Item>
        <List.Content floated='right'>
          <Button key={props.index} onClick={() => { go(props.item.activator) }}>Connect</Button>
        </List.Content>
        <Image circular avatar src={props.metamaskIcon} alt={props.item.name} />
        <List.Content item={props.item}>{props.item.name}</List.Content>
      </List.Item>
    </>
  )
}
export default Provider;