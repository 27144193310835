export function emotionyLanguage() {
    // var numEmotions = 18;
    var language = 0;
    this.setlanguage = function (languageString) {
        switch (languageString) {
            case "english":
                language = 0;
                break;
            case "castellano":
                language = 1;
                break;
            case "français":
                language = 2;
                break;
            case "català":
                language = 3;
                break;
            default:
                language = 0;
                break;

        }
    }

    this.data = {
        language:
            [
                ['english', [

                    ['open', [
                        'understanding',
                        'confident',
                        'friendly',
                        'connected',
                        'flexible',
                        'sympathetic'
                    ]],

                    ['loving', [
                        'considerate',
                        'affectionate',
                        'sensitive',
                        'tender',
                        'warm',
                        'attracted'
                    ]],

                    ['happy', [
                        'blissful',
                        'joyous',
                        'delighted',
                        'overjoyed',
                        'glad',
                        'thankful'
                    ]],

                    ['interested', [
                        'fascinated',
                        'intrigued',
                        'absorbed',
                        'amazed',
                        'engrossed',
                        'curious'
                    ]],

                    ['alive', [
                        'playful',
                        'courageous',
                        'energetic',
                        'liberated',
                        'optimistic',
                        'animated'
                    ]],

                    ['positive', [
                        'motivated',
                        'constructive',
                        'creative',
                        'inspired',
                        'enthusiastic',
                        'brave'
                    ]],

                    ['peaceful', [
                        'calm',
                        'good',
                        'at ease',
                        'comfortable',
                        'pleased',
                        'quiet'
                    ]],

                    ['strong', [
                        'honest',
                        'sure',
                        'unique',
                        'complete',
                        'energized',
                        'hardy'
                    ]],

                    ['relaxed', [
                        'glowing',
                        'radiant',
                        'unhurried',
                        'fluid',
                        'meditative',
                        'centered'
                    ]],

                    ['angry', [
                        'irritated',
                        'enraged',
                        'hostile',
                        'insulting',
                        'frustrated',
                        'upset'
                    ]],

                    ['depressed', [
                        'disappointed',
                        'discouraged',
                        'ashamed',
                        'powerless',
                        'diminished',
                        'guilty'
                    ]],

                    ['confused', [
                        'doubtful',
                        'shy',
                        'indecisive',
                        'perplexed',
                        'embarrassed',
                        'hesitant'
                    ]],

                    ['helpless', [
                        'incapable',
                        'alone',
                        'paralyzed',
                        'fatigued',
                        'useless',
                        'inferior'
                    ]],

                    ['indiferent', [
                        'insensitive',
                        'dull',
                        'robotic',
                        'tired',
                        'cold',
                        'preoccupied'
                    ]],

                    ['afraid', [
                        'fearful',
                        'terrified',
                        'nervous',
                        'anxious',
                        'alarmed',
                        'panicked'
                    ]],

                    ['hurt', [
                        'crushed',
                        'tormented',
                        'victimized',
                        'pained',
                        'tortured',
                        'rejected'
                    ]],

                    ['sad', [
                        'tearful',
                        'sorrowful',
                        'anguished',
                        'desolate',
                        'unhappy',
                        'lonely'
                    ]],

                    ['judmental', [
                        'stony',
                        'serious',
                        'blunt',
                        'hard',
                        'brutal',
                        'disgusted'
                    ]]

                ]],

                ['castellano', [

                    ['abierto', [
                        'comprensivo',
                        'confiado',
                        'amigable',
                        'conectado',
                        'flexible',
                        'simpático',
                    ]],

                    ['amoroso', [
                        'considerado',
                        'cariñoso',
                        'sensible',
                        'tierno',
                        'caluroso',
                        'atraído',
                    ]],

                    ['feliz', [
                        'bendecido',
                        'jubiloso',
                        'encantado',
                        'lleno de alegría',
                        'alegre',
                        'agradecido',
                    ]],

                    ['interesado', [
                        'fascinado',
                        'intrigado',
                        'absorbido',
                        'asombrado',
                        'absorto',
                        'curioso',
                    ]],

                    ['vivo', [
                        'juguetón',
                        'valiente',
                        'energético',
                        'liberado',
                        'optimista',
                        'animado',
                    ]],

                    ['positivo', [
                        'motivado',
                        'constructivo',
                        'creativo',
                        'inspirado',
                        'entusiasta',
                        'valiente',
                    ]],

                    ['pacifico', [
                        'calmado',
                        'bien',
                        'a gusto',
                        'cómodo',
                        'satisfecho',
                        'tranquilo',
                    ]],

                    ['fuerte', [
                        'honesto',
                        'seguro',
                        'único',
                        'completo',
                        'enérgico',
                        'resistente',
                    ]],

                    ['relajado', [
                        'brillante',
                        'radiante',
                        'sin prisas',
                        'fluido',
                        'meditativo',
                        'centrado',
                    ]],

                    [' enojado', [
                        'irritado',
                        'enfurecido',
                        'hostil',
                        'insultado',
                        'frustrado',
                        'trastornado',
                    ]],

                    ['deprimido', [
                        'decepcionado',
                        'desanimado',
                        'avergonzado',
                        'impotente',
                        'disminuido',
                        'culpable',
                    ]],

                    ['confused', [
                        'dubitativo',
                        'tímido',
                        'indeciso',
                        'perplejo',
                        'desconcertado',
                        'vacilante',
                    ]],

                    ['indefenso', [
                        'incapaz',
                        'solo',
                        'paralizado',
                        'fatigado',
                        'inútil',
                        'inferior',
                    ]],

                    ['indiferente', [
                        'insensible',
                        'aburrido',
                        'robótico',
                        'cansado',
                        'frío',
                        'preocupado',
                    ]],

                    ['miedoso', [
                        'temeroso',
                        'aterrorizado',
                        'nervioso',
                        'ansioso',
                        'alarmado',
                        'en pánico',
                    ]],

                    ['herido', [
                        'aplastado',
                        'atormentado',
                        'victimizado',
                        'dolorido',
                        'torturado',
                        'rechazado',
                    ]],

                    ['triste', [
                        'lloroso',
                        'triste',
                        'angustiado',
                        'desolado',
                        'infeliz',
                        'solitario',
                    ]],

                    ['crítico', [
                        'de piedra',
                        'grave',
                        'afilado',
                        'duro',
                        'brutal',
                        'disgustado',
                    ]]

                ]],
                ['français', [

                    [' ouvert', [
                        'compréhensif',
                        'sur de soi',
                        'amical',
                        'lié',
                        'flexible',
                        'sympathique',
                    ]],

                    ['aimant', [
                        'considéré',
                        'affectueux',
                        'sensible',
                        'tendre',
                        'chaud',
                        'attiré',
                    ]],

                    ['heureux', [
                        'béni',
                        'jubilatoire',
                        'enchanté',
                        'plein de joie',
                        'joyeux',
                        'reconnaissant',
                    ]],

                    ['intéressé', [
                        'fasciné',
                        'intrigué',
                        'absorbée',
                        'surpris',
                        'absorbée',
                        'curieux',
                    ]],

                    ['vivant', [
                        'ludique',
                        'courageux',
                        'énergique',
                        'libéré',
                        'optimiste',
                        'animé',
                    ]],

                    ['positif', [
                        'motivé',
                        'constructif',
                        'créatif',
                        'inspiré',
                        'enthousiaste',
                        'courageux',
                    ]],

                    ['pacifique', [
                        'calme',
                        'bien',
                        "à l'aise",
                        'confortable',
                        'satisfait',
                        'tranquile',
                    ]],

                    ['forte', [
                        'honnête',
                        'assurer',
                        'unique',
                        'plein',
                        "plein d'énergie",
                        'résistant',
                    ]],

                    ['détendu', [
                        'brillant',
                        'rayonnante',
                        'sans hâte',
                        'fluide',
                        'méditatif',
                        'centré',
                    ]],

                    ['colère', [
                        'irrité',
                        'enragé',
                        'hostile',
                        'insultés',
                        'frustré',
                        'dérangée',
                    ]],

                    ['déprimé', [
                        'déçu',
                        'découragé',
                        'embarrassé',
                        'impuissant',
                        'diminué',
                        'coupable',
                    ]],

                    ['confus', [
                        'douteux',
                        'timide',
                        'indécis',
                        'perplexes',
                        'désorienté',
                        'vacillant',
                    ]],

                    ['sans défense', [
                        'incapable',
                        'seul',
                        'paralysés',
                        'fatigué',
                        'inutile',
                        'inférieur',
                    ]],

                    ['indifférent', [
                        'insensible',
                        'ennuyeux',
                        'robotique',
                        'fatigué',
                        'froid',
                        'préoccupé',
                    ]],

                    ['peur', [
                        'peur',
                        'terrifié',
                        'nerveux',
                        'anxieux',
                        'alarmée',
                        'panique',
                    ]],

                    ['blessés', [
                        'écrasé',
                        'tourmentée',
                        'victimisés',
                        'peiné',
                        'torturé',
                        'rejeté',
                    ]],

                    ['triste', [
                        'larmoyant',
                        'triste',
                        'angoissé',
                        'désolé',
                        'malheureux',
                        'solitaire',
                    ]],

                    ['critique', [
                        'pierreux',
                        'sérieux',
                        'cru',
                        'dur',
                        'brutal',
                        'dégoûté',
                    ]],

                ]],
                ['català', [

                    ['obert', [
                        'comprensiu',
                        'confiat',
                        'amigable',
                        'connectat',
                        'flexible',
                        'simpàtic',
                    ]],

                    ['amorós', [
                        'considerat',
                        'afectuós',
                        'sensible',
                        'tendre',
                        'calorós',
                        'atret',
                    ]],

                    ['feliç', [
                        'beneït',
                        'joiós',
                        'encantat',
                        "ple d'alegria",
                        'alegre',
                        'agraït',
                    ]],

                    ['interessat', [
                        'fascinat',
                        'intrigat',
                        'absorbit',
                        'sorprès',
                        'absort',
                        'curiós',
                    ]],

                    ['viu', [
                        'juganer',
                        'valent',
                        'energètic',
                        'alliberat',
                        'optimista',
                        'animat',
                    ]],

                    ['positiu', [
                        'motivat',
                        'constructiu',
                        'creatiu',
                        'inspirat',
                        'entusiasta',
                        'valent',
                    ]],

                    ['pacífic', [
                        'calmat',
                        'bé',
                        'a gust',
                        'còmode',
                        'satisfet',
                        'tranquil',
                    ]],

                    ['fort', [
                        'honest',
                        'segur',
                        'únic',
                        'complet',
                        'enèrgic',
                        'resistent',
                    ]],

                    ['relaxat', [
                        'brillant',
                        'radiant',
                        'sense presses',
                        'fluid',
                        'meditatiu',
                        'centrat',
                    ]],

                    ['enutjat', [
                        'irritat',
                        'enfurismat',
                        'hostil',
                        'insultat',
                        'frustrat',
                        'trastornat',
                    ]],

                    ['deprimit', [
                        'decebut',
                        'desanimat',
                        'avergonyit',
                        'impotent',
                        'disminuït',
                        'culpable',
                    ]],

                    ['confused', [
                        'dubitatiu',
                        'tímid',
                        'indecís',
                        'perplex',
                        'desconcertat',
                        'vacil·lant',
                    ]],

                    ['indefens', [
                        'incapaç',
                        'sol',
                        'paralitzat',
                        'fatigat',
                        'inútil',
                        'inferior',
                    ]],

                    ['indiferent', [
                        'insensible',
                        'avorrit',
                        'robòtic',
                        'cansat',
                        'fred',
                        'preocupat',
                    ]],

                    ['poruc', [
                        'temorós',
                        'aterrit',
                        'nerviós',
                        'ansiós',
                        'alarmat',
                        'en pànic',
                    ]],

                    ['ferit', [
                        'aixafat',
                        'turmentat',
                        'victimitzat',
                        'adolorit',
                        'torturat',
                        'rebutjat',
                    ]],

                    ['trist', [
                        'plorós',
                        'trist',
                        'angoixat',
                        'desolat',
                        'infeliç',
                        'solitari',
                    ]],

                    ['crític', [
                        'de pedra',
                        'greu',
                        'afilat',
                        'dur',
                        'brutal',
                        'disgustat',
                    ]],

                ]]

            ]
    }


    var data = this.data;
    this.getEmotion = function (groupEmotion, numEmotion) {
        if (groupEmotion >= 0 && groupEmotion < 18 && numEmotion >= 0 && numEmotion < 6) {
            return data.language[language][1][groupEmotion][1][numEmotion];
        } else {
            return "error, out of Range";
        }
    }

}