import { Segment, Grid } from "semantic-ui-react";
import "./planetDetail.scss";

import { useEffect, useState, useContext } from "react";
import { EmoContext } from "../../services/globalContext";
import { UserContext } from "../../services/userContext";

import DataPlanetDetail from "../../components/PlanetDetail/DataPlanetDetail";
import HeaderPlanetDetail from "../../components/PlanetDetail/HeaderPlanetDetail";

import PlanetImage from "../../components/PlanetDetail/ImagePlanetDetail";
import { getGameById } from "../../services/Graphql/Game/queryGame.js";
import Tokenize from "../../components/PlanetDetail/UserPlanetDetail/TokenizePlanet";

import Publish from "../../components/PlanetDetail/UserPlanetDetail/PublishPlanet";
import EList from "../../components/PlanetDetail/UserPlanetDetail/EmotionList";
import { tokenizeStatus } from "../../services/tokenizeService";

console.log("PLANET-DETAIL-PAGE-MODULE-INIT");

const PlanetDetail = (props) => {
  console.log("--PLANET DETAIL PAGE INIT--");

  const [globalState, dispatchGlobal] = useContext(EmoContext);
  const [userState, dispatchUser] = useContext(UserContext);
  var [planetId, setPlanetId] = useState(null);
  var [owner, setOwner] = useState(false);
  var [gameData, setGameData] = useState(null);
  var [stateTokenize, setStateTokenize] = useState(tokenizeStatus.DONT_READ_STATUS);
  var [stateDisableTokenize, setStateDisableTokenize] = useState(false);
  const [total, setTotal] = useState(null);

  planetId = props.match.params.planetDetail;
  const image = `https://planetsimagelowresolution.s3.eu-west-3.amazonaws.com/${planetId}.png`;

  const setOwnerState = (data) => {
    console.log("PLANET DETAIL: SETOWNERSTATE: DATA=", data);
    if (
      userState.userData.accounts &&
      data &&
      data.userAddressId === userState.userData.accounts[0]
    ) {
      console.log("PLANET DETAIL: SETOWNERSTATE: TRUE");
      setOwner(true);
    } else {
      console.log("PLANET DETAIL: SETOWNERSTATE: FALSE");
    }
  };

  const getGame = async (gameId) => {
    var tot = await getGameById(gameId);
    console.log("PLANET DETAIL: GLOBALSTATE", globalState);
    console.log("PLANET DETAIL: TOTAL", tot);

    const likes = tot.data.getMasterGameById.favorites
      ? tot.data.getMasterGameById.favorites.length
      : 0;
    tot.data.getMasterGameById.likes = likes;
    await setTotal(tot);
    await setGameData(tot.data.getMasterGameById);
    await setOwnerState(tot.data.getMasterGameById);
  };

  useEffect(() => {
    if (userState && userState.userData) {
      console.log("PLANET DETAIL: USE EFFECT:USERSTATE", userState);
      getGame(planetId);
    }
  }, [userState.userData]);

  useEffect(() => {
    if (props) {
      console.log("PLANET DETAIL PROPS", props);
    }

    setPlanetId(props.planetId);
  }, [props]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="planetDetail">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment textAlign="center" className="noborder">
              <PlanetImage stateTokenize={stateTokenize} image={image} owner={owner}/>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment textAlign="center" className="noborder">
              <HeaderPlanetDetail gameData={gameData} planetId={planetId} owner={owner}/>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment textAlign="left" className="noborder">
              <Tokenize
                stateDisableTokenize={stateDisableTokenize}
                setStateDisableTokenize={setStateDisableTokenize}
                setStateTokenize={setStateTokenize}
                stateTokenize={stateTokenize}
                planetId={planetId}
                owner={owner}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <DataPlanetDetail gameData={gameData} owner={owner}/>
          </Grid.Column>
        </Grid.Row>

        {/* <Grid.Row>
          <Grid.Column>
            <Segment textAlign="center">
              <Publish disabled='true' owner={null} />
            </Segment>
          </Grid.Column>
        </Grid.Row> */}

        {/* <Grid.Row>
          <Grid.Column>
            <Segment textAlign="center">
              <EList disabled='true' owner={null} />
            </Segment>
          </Grid.Column>
        </Grid.Row> */}
      </Grid>
    </div>
  );
};
export default PlanetDetail;
