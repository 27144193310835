import { useEffect, useRef, useState, useContext } from "react";
//import GridPlanet from "../Grid/GridPlanet";
import GridPlanetG from "../Grid/GridPlanetg";
import { EmoContext } from "../../services/globalContext";
import { getListItemAvatarUtilityClass } from "@mui/material";
import {
  getMarketPlacePlanetList,
  prepareMarketPlaceQuery,
  getmarketPlaceList,
} from "../../services/Graphql/Game/queryMarketPlace";
import { first, last } from "lodash";
import { getPlanetsFilter } from "../../services/Graphql/Game/queryFIlter";

console.log("MARKETPLACE-COMPONENT-MODULE-INIT");
var counterMarketPlaceComponentCharges = 0;
var filterPlanetSelected = null;

const MarketplaceComponent = (props) => {
  // BUTTONS SELECT STATE
  var { selectedButton, setSelectedButton } = props;

  console.log("----MARKETPLACE-COMPONENT-INIT--");

  console.log("MARKETPLACECOMPONENT PROPS=", props);
  console.log(
    "MARKETPLACECOMPONENT,SESSION STORAGE OBJECT: GLOBALSTATE=",
    JSON.parse(sessionStorage.getItem("globalState"))
  );

  const [globalState, dispatchGlobal] = useContext(EmoContext);
  const [stateList, setStateList] = useState(null);
  const [lastSelectedButton, setLastSelectedButton] = useState(null);
  const [filtersMode, setFiltersMode] = useState(false);
  const [filtersFirstPlanet, setFiltersFirstPlanet] = useState(0);
  const [filterTotalPlanets, setFilterTotalPlanets] = useState(0);

  const [firstPlanet, setFirstPlanet] = useState(0);
  const [lastFirstPlanet, setLastFirstPlanet] = useState(0);
  const [filterHeaders, setFilterHeaders] = useState(false);
  const [totalPlanets, setTotalPlanets] = useState(0);


  var planetsFilterMode = null;
  var emoreducer = {
    globalState: globalState,
    dispatchGlobal: dispatchGlobal,
  };

  var numPlanets = 4;
  var filter = { type: "headers" };

  // get headers, set filterHeaders to true
  // next, get items and set stateList=items

  const getFilterPlanetSelected = () => {
    //find marketplace type filterplanets with "selectedButton" index item
    let filterPlanetsM = globalState.filterPlanets.filter(
      (item) => item.type === "MarketPlace"
    );
    let fPlanetSelected = null;

    if (filterPlanetsM.length !== 0) {
      fPlanetSelected = filterPlanetsM[selectedButton];
      console.log(
        "MARKETPLACECOMPONENT,GETPLANET: MARKETPLACEPLANETS:",
        filterPlanetsM,
        " FILTERPLANET.SELECTED=",
        fPlanetSelected,
        " SELECTEDBUTTON=",
        selectedButton
      );
    }
    return fPlanetSelected;
  };

  const getPlanetsFiltersMode=()=>{

  }
 

  const getplanet = async () => {
    
    if (filterPlanetSelected.firstElement !== filtersFirstPlanet) {
      console.log(
        "MARKETPLACECOMPONENT,GETPLANET: FILTERPLANET.SELECTED,SET FIRST ELEMENT:",
        filterPlanetSelected.firstElement
      );
      await setFiltersFirstPlanet(filterPlanetSelected.firstElement);
    } else {
      console.log(
        "MARKETPLACECOMPONENT,GETPLANET: SET FILTERS FIRST PLANET (ELEMENT):",
        filtersFirstPlanet
      );
      planetsFilterMode = await getPlanetsFilter(
        emoreducer,
        filterPlanetSelected,
        filtersFirstPlanet,
        numPlanets
      );
      await setLastFirstPlanet(filtersFirstPlanet);
    }
    await setFilterTotalPlanets(filterPlanetSelected.items.length);

    console.log(
      "MARKETPLACECOMPONENT,GETPLANET: PLANETSFILTERMODE=",
      planetsFilterMode
    );
    
    if (planetsFilterMode && planetsFilterMode.length > 0) {
      await setFiltersMode(true);
      console.log("SETTED FILTERSMODE TO TRUE");
      await setStateList({
        data: { listMasterGames: { items: planetsFilterMode } },
      });
      await setLastSelectedButton(selectedButton);
    }
  };

  useEffect(()=>{
    if(globalState.filterPlanets){
       // INIT FILTERPLANETSELECTED 
      filterPlanetSelected=getFilterPlanetSelected();
    }
    if (
      globalState.filterPlanets &&
      selectedButton != null &&
      lastSelectedButton != selectedButton
    ) {
      getplanet();
    }  

  },[globalState.filterPlanets,selectedButton,lastSelectedButton])

  
  useEffect(() => {
    const asincronousEffect = async () => {
      if (filtersMode) {
        if (lastFirstPlanet !== filtersFirstPlanet) {
          console.log(
            "ASYNC MARKETPLACECOMPONENT,USEEFFECT, FIRSTELEMENT=",
            lastFirstPlanet,
            filtersFirstPlanet
          );
        }
      }
    };
    asincronousEffect();
  }, [filtersMode, planetsFilterMode, lastFirstPlanet, filtersFirstPlanet]);

  useEffect(() => {
    if (!filtersMode) {
      var filter = { type: "games" };
      console.log(
        "MARKETPLACECOMPONENT,USEEFFECT, FILTERHEADERS=",
        filterHeaders,
        firstPlanet
      );
      if (filterHeaders) {
        getmarketPlaceList(
          firstPlanet,
          numPlanets,
          filter,
          globalState,
          dispatchGlobal,
          setStateList,
          setFilterHeaders,
          setTotalPlanets
        );
        dispatchGlobal({
          type: "SET_FIRST_PLANET",
          payload: firstPlanet,
        });
      }
    } else {
      console.log("MARKETPLACECOMPONENT - FILTERSMODE-ON");
    }
  }, [filterHeaders, firstPlanet, filtersMode]);

  useEffect(() => {
    if (!filtersMode) {
      if (!filterHeaders) {
        getmarketPlaceList(
          firstPlanet,
          numPlanets,
          filter,
          globalState,
          dispatchGlobal,
          setStateList,
          setFilterHeaders,
          setTotalPlanets
        );
        if (firstPlanet === 0 && globalState.marketPlaceFirstPlanet >= 0) {
          console.log(
            "MARKETPLACECOMPONENT,USEEFFECT, GLOBALSTATE.MARKETPLACEFIRSTPLANET=",
            globalState.marketPlaceFirstPlanet
          );
          setFirstPlanet(globalState.marketPlaceFirstPlanet);
        }
      }
    } else {
      console.log("MARKETPLACECOMPONENT - FILTERSMODE-ON");
    }
  }, []);
  console.log("MARKETPLACECOMPONENT: FiltersMode=", filtersMode);
  console.log(
    "MARKETPLACECOMPONENT: FiltersFirstPlanet=",
    filtersFirstPlanet,
    " lastFirstPlanet",
    lastFirstPlanet,
    " filterPlanetSelected=",
    filterPlanetSelected
  );
  console.log("MARKETPLACECOMPONENT: FilterTotalPlanets=", filterTotalPlanets);
  console.log("MARKETPLACECOMPONENT:PlanetsFilterMode=", planetsFilterMode);

  console.log("MARKETPLACECOMPONENT,STATELIST=", stateList);
  console.log(
    "-------------------MARKETPLACECOMPONENT:CounterMarketPlaceComponentCharges=",
    counterMarketPlaceComponentCharges
  );
  counterMarketPlaceComponentCharges++;
  return stateList === null ? (
    <>
      <div>Loading...</div>
    </>
  ) : (
    <>
      {stateList ? (
        <GridPlanetG
          modeGrid="marketplace"
          list={stateList.data.listMasterGames.items}
          firstPlanet={filtersMode ? filtersFirstPlanet : firstPlanet}
          firstPlanetG={0}
          setFirstPlanet={filtersMode ? setFiltersFirstPlanet : setFirstPlanet}
          numPlanets={numPlanets}
          step={numPlanets}
          totalPlanets={filtersMode ? filterTotalPlanets : totalPlanets}
        />
      ) : (
        <div> Loading...</div>
      )}
    </>
  );
};

export default MarketplaceComponent;
