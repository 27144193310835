import { API, graphqlOperation } from "aws-amplify";
import { nextTokenList } from "../../userParams";

console.log("QUERYMARKETPLACE-MODULE-INIT");

const itemsPlanet = `
    idGame  
    userAddressId
    description
    emotions
   
    emotionsFilter
    locationFilter
    locationPretty
    favorites
    generalData {
      date
      deviceId
      email
      emoViewConnectionID
      gameId
      geoloc {
        accuracy
        latitude
        longitude
      }
      pngId
      posnegPlanet
      username
    }
    hashtags
    likes
    
    requesttime
    sourceip
    status
    tags
    timestampEpoch
    tagsFilter
   
    
    gameDesign
    planetOperation
    textGame
    quote
  `;

const itemsHeader = `         
                  idGame  
                  emotions
                  hashtags
                  generalData {
                    date
                  }
                  tagsFilter 
                  textGame
                  locationFilter
                  location                
  `;

const prepareParams = (nextToken, limit) => {
  var params = null;
  if (nextToken) {
    params = `(filter: {status: {contains: "published"}}, limit: ${limit} , nextToken: "${nextToken}")`;
  } else {
    params = `(filter: {status: {contains: "published"}}, limit: ${limit})`;
  }
  return params;
};

export const getmarketPlaceList = async (
  minPlanet,
  numPlanets,
  filters,
  globalState,
  dispatchGlobal,
  setStateList,
  setFilterHeaders,
  setTotalPlanets
) => {
  console.log("MARKETPLACE_QUERY NUMPLANETS",numPlanets);
  if (filters.type === "headers") {
    console.log("MARKETPLACE_QUERY: GET HEADERS");
    // GET ALL HEADERS
    var items = [];
    var nextToken = null;
    var limit = 500;
    var pathToAwsLowResolutionPlanets =
      "https://planetsimagelowresolution.s3.eu-west-3.amazonaws.com/";
    var chargeListFronGraphQl = true;
    var tempList = null;
    // globalState.marketPlacePlanetResults = data.listeMaster20GamesPartiQL.items
    // if items is empty, charge from graphql
    if (
      globalState.marketPlacePlanetResults &&
      globalState.marketPlacePlanetResults.data
    ) {
      console.log("MARKETPLACE_QUERY: GET RESULTS FROM GLOBALSTATE:");
      tempList = globalState.marketPlacePlanetResults;
    } else {
      // STOP IF counterWhiles*limit>=9000
      let counterWhiles = 0;
      do {
        let query = prepareMarketPlaceQuery(
          nextToken,
          limit,
          true,
          "published"
        );
        console.log("MARKETPLACE_QUERY: GET HEADERS QUERY", query);
        let listaux = await API.graphql(graphqlOperation(query));
        console.log("MARKETPLACE_QUERY: LISTAUX=", listaux);
        for (var itemData of listaux.data.listeMaster20GamesPartiQL.items) {
          itemData.url =
            pathToAwsLowResolutionPlanets + itemData.idGame + ".png";
          itemData.queryMarketplace = true;
          items.push(itemData);
        }
        nextToken = listaux.data.listeMaster20GamesPartiQL.nextToken;
        counterWhiles++;
      } while (nextToken != null && counterWhiles * limit < 9000);

      tempList = {
        data: {
          listMasterGames: {
            items: items,
          },
        },
      };

      await dispatchGlobal({
        type: "SET_MARKETPLACE_PLANET_RESULTS",
        payload: tempList,
      });
    }

    console.log("MARKETPLACE_QUERY tempList= ", tempList);
    await setTotalPlanets(tempList.data.listMasterGames.items.length);
    await setFilterHeaders(true);
  } else {
    console.log("MARKETPLACE_QUERY: GET PLANETS");
    console.log(
      "MARKETPLACE_QUERY: TotalPlanets=",
      globalState.marketPlacePlanetResults.data.listMasterGames.items.length
    );
    // GET PLANETS

    if((minPlanet+numPlanets) > (globalState.marketPlacePlanetResults.data.listMasterGames.items.length)){
       minPlanet = (globalState.marketPlacePlanetResults.data.listMasterGames.items.length) - numPlanets;
    }
    var itemPlanetTotal = [];

    var itemPlanets1 = [];
    var itemPlanets2 = [];

    var page1 = Math.floor(minPlanet / 10);
    var minPlanetRelative1 = minPlanet % 10;
    var maxPlanetRelative1 = minPlanetRelative1 + numPlanets;
    var itemPlanets1Aux = await getListOfLimitNextTokenPlanets(
      page1,
      globalState,
      dispatchGlobal
    );
    var page2 = Math.floor((minPlanet + numPlanets) / 10);
    if (page2 > page1) {
      var minPlanetRelative2 = 0;
      var maxPlanetRelative2 = maxPlanetRelative1 % 10;
      var itemPlanets2Aux = await getListOfLimitNextTokenPlanets(
        page2,
        globalState,
        dispatchGlobal
      );
      itemPlanets1 = itemPlanets1Aux.data.listeMaster20GamesPartiQL.items.slice(
        minPlanetRelative1,
        10
      );
      itemPlanets2 = itemPlanets2Aux.data.listeMaster20GamesPartiQL.items.slice(
        minPlanetRelative2,
        maxPlanetRelative2
      );
      console.log(
        "MARKETPLACE_QUERY: PAGE ",
        page1,
        " itemPlanets1Aux=",
        itemPlanets1Aux
      );
      console.log(
        "MARKETPLACE_QUERY: PAGE ",
        page2,
        " itemPlanets2Aux=",
        itemPlanets2Aux
      );
      console.log(
        "MARKETPLACE_QUERY: PAGE ",
        page1,
        " itemPlanets1=",
        itemPlanets1,
        " slice: ",
        minPlanetRelative1,
        10
      );
      console.log(
        "MARKETPLACE_QUERY: PAGE ",
        page2,
        " itemPlanets2=",
        itemPlanets2,
        " slice: ",
        minPlanetRelative2,
        maxPlanetRelative2
      );
      itemPlanetTotal = {
        data: {
          listMasterGames: {
            items: itemPlanets1.concat(itemPlanets2),
          },
        },
      };
    } else {
      itemPlanetTotal = {
        data: {
          listMasterGames: {
            items: itemPlanets1Aux.data.listeMaster20GamesPartiQL.items.slice(
              minPlanetRelative1,
              maxPlanetRelative1
            ),
          },
        },
      };

      console.log(
        "MARKETPLACE_QUERY: UNIQUE PAGE ",
        page1,
        " itemPlanets1Aux=",
        itemPlanets1Aux
      );
      console.log(
        "MARKETPLACE_QUERY: UNIQUE PAGE ",
        page1,
        " itemPlanetsTotalDirect:",
        itemPlanetTotal,
        "slice: ",
        minPlanetRelative1,
        maxPlanetRelative1
      );
    }
  }

  console.log("MARKETPLACE_QUERY: itemPlanetTotal=", itemPlanetTotal);
  setStateList(itemPlanetTotal);
};

const getListOfLimitNextTokenPlanets = async (
  page,
  globalState,
  dispatchGlobal
) => {
  var limit = 10;
  var nextToken = null;
  var listaux = null; // to store planets
  var topPlanets =
    globalState.marketPlacePlanetResults.data.listMasterGames.items.length;

  if (page > 0) {
    if (globalState.marketPlaceNextTokens[page - 1] !== undefined)
      nextToken = globalState.marketPlaceNextTokens[page - 1];
  }
  if (globalState.marketPlacePages.indexOf(page) === -1) {
    //get data from graphql:
    var query = prepareMarketPlaceQuery(nextToken, limit, false, "published"); // query to get the next 10 planets
    console.log("MARKETPLACE_QUERY: QUERY GET PLANETS", query);
    listaux = await API.graphql(graphqlOperation(query));
    console.log("MARKETPLACE_QUERY: CASE PAGE: ",page,": LISTAUX=", listaux);

    //set page at globalState
    await dispatchGlobal({ type: "SET_PAGE_ITEM", payload: page });

    //save nextToken in globalState if not already saved
    nextToken = listaux.data.listeMaster20GamesPartiQL.nextToken;
    await dispatchGlobal({
      type: "SET_NEXTTOKEN_ITEM",
      payload: nextToken,
    });

    //save data in globalState
    /*
    "SET_MARKETPLACE_PLANET_RESULTS_GAMES_COMPLETE":
      result = emoState;
      STORES 10 PLANETS UNSETTED VALUES
      EMOACTION.PAYLOAD FORMAT:
      {initPlanet:initPlanet,finishPlanet:finishPlanet,items:items}
    */
    let minPlanet = page * 10;
    let maxPlanet = minPlanet + 10;
    if (maxPlanet > topPlanets) maxPlanet = topPlanets;
    await dispatchGlobal({
      type: "SET_MARKETPLACE_PLANET_RESULTS_GAMES_COMPLETE",
      payload: {
        initPlanet: minPlanet,
        finishPlanet: maxPlanet,
        items: listaux.data.listeMaster20GamesPartiQL.items,
      },
    });
  } else {
    // GET ALL DATA FROM GLOBALSTATE
    console.log(
      "MARKETPLACE_QUERY: CASE PAGE 0: GET ALL DATA FROM GLOBALSTATE"
    );
    let minPlanet = page * 10;
    let maxPlanet = minPlanet + 10;
    if (maxPlanet > topPlanets) maxPlanet = topPlanets;
    listaux = {
      data: {
        listeMaster20GamesPartiQL: {
          items:
            globalState.marketPlacePlanetResults.data.listMasterGames.items.slice(
              minPlanet,
              maxPlanet
            ),
        },
      },
    }; // get 10 (or maxPlanet - minPlanet ) planets from globalState
  }

  return listaux;
};

export const prepareMarketPlaceQuery = (
  nextToken,
  limit,
  isHeaders, //if true, only headers are returned, if false, full planet
  status
) => {
  let params = prepareParams(nextToken, limit);
  let result = isHeaders ? `items{${itemsHeader}}` : `items{${itemsPlanet}}`;
  let retQuery = `
        query MyQuery {
            listeMaster20GamesPartiQL ${params} {
                nextToken
                ${result}
            }
        }
       `;
  return retQuery;
};

export const getMarketPlacePlanetList = async (
  query,
  setStateList,
  globalState,
  dispatchGlobal
) => {
  const pathToAwsLowResolutionPlanets =
    "https://planetsimagelowresolution.s3.eu-west-3.amazonaws.com/";

  console.log("MARKETPLACEQUERY", query);
  let listaux = null;
  let chargeListFromGraphQl = true;
  let tempList = null;

  if (
    globalState.marketPlacePlanetResults &&
    globalState.marketPlacePlanetResults.data
  ) {
    tempList = globalState.marketPlacePlanetResults;
    chargeListFromGraphQl = false;
  } else {
    listaux = await API.graphql(graphqlOperation(query));
    console.log("LISTAUX=", listaux);
    tempList = {
      data: {
        listMasterGames: listaux.data.listeMaster20GamesPartiQL,
      },
    };

    for (let listElement of tempList.data.listMasterGames.items) {
      listElement.url =
        pathToAwsLowResolutionPlanets + listElement.idGame + ".png";
      listElement.queryMarketplace = true;
    }
  }
  console.log("MARKETPLACE QUERYRESULTLIST ", tempList);

  if (chargeListFromGraphQl) {
    await dispatchGlobal({
      type: "SET_MARKETPLACE_PLANET_RESULTS",
      payload: tempList,
    });
  }
  await setStateList(tempList);
};
