import { FilterFrame } from "./FilterFrame";
import { useState, useEffect, useContext } from "react";
import { Header, Form, Button, Divider } from "semantic-ui-react";
import { FilterComponentBase } from "./FilterComponentBase";
import { EmoContext } from "../../services/globalContext";
import { Label } from "semantic-ui-react";

export const AllFiltersComponent = (props) => {
  // props selection:
  var selectedButtonGalaxyIndex=null,setSelectedButtonGalaxyIndex=null;
  var selectedButtonMarketPlaceIndex=null,setSelectedButtonMarketPlaceIndex=null;

  var { selectedButton, setSelectedButton, filterData, titleHeader, submitFilter, setSubmitFilter } = props;
  

  // dataHashtags

  const dataNumbers = [
    "uno",
    "dos",
    "tres",
    "cuatro",
    "cinco",
    "seis",
    "siete",
    "ocho",
    "nueve",
    "diez",
    "once",
    "doce",
    "trece",
    "catorce",
    "quince",
    "dieciséis",
    "diecisiete",
    "dieciocho",
    "diecinueve",
    "veinte",
    "veintiuno",
    "veintidós",
    "veintitrés",
    "veinticuatro",
    "veinticinco",
    "veintiséis",
    "veintisiete",
    "veintiocho",
    "veintinueve",
    "treinta",
  ];

  const hashtagList = [
    { key: "angular", text: "Angular", value: "angular" },
    { key: "css", text: "CSS", value: "css" },
    { key: "design", text: "Graphic Design", value: "design" },
    { key: "ember", text: "Ember", value: "ember" },
    { key: "html", text: "HTML", value: "html" },
    { key: "ia", text: "Information Architecture", value: "ia" },
    { key: "javascript", text: "Javascript", value: "javascript" },
    { key: "mech", text: "Mechanical Engineering", value: "mech" },
    { key: "meteor", text: "Meteor", value: "meteor" },
    { key: "node", text: "NodeJS", value: "node" },
    { key: "plumbing", text: "Plumbing", value: "plumbing" },
    { key: "python", text: "Python", value: "python" },
    { key: "rails", text: "Rails", value: "rails" },
    { key: "react", text: "React", value: "react" },
    { key: "repair", text: "Kitchen Repair", value: "repair" },
    { key: "ruby", text: "Ruby", value: "ruby" },
    { key: "ui", text: "UI Design", value: "ui" },
    { key: "ux", text: "User Experience", value: "ux" },
  ];

  const superEmos = [
    {
      key: "EMO1_0",
      text: "Open",
      value: "EMO1_0",
      description:
        "understanding, confident, friendly, connected, flexible, sympathetic",
    },
    {
      key: "EMO2_0",
      text: "Loving",
      value: "EMO2_0",
      description:
        "considerate, affectionate, sensitive, tender, warm, attracted",
    },
    {
      key: "EMO3_0",
      text: "Happy",
      value: "EMO3_0",
      description: "blissful, joyous, delighted, overjoyed, glad, thankful",
    },
    {
      key: "EMO4_0",
      text: "Interested",
      value: "EMO4_0",
      description:
        "fascinated, intrigued, absorbed, amazed, engrossed, curious",
    },
    {
      key: "EMO5_0",
      text: "Alive",
      value: "EMO5_0",
      description:
        "playful, courageous, energetic, liberated, optimistic, animated",
    },
    {
      key: "EMO6_0",
      text: "Positive",
      value: "EMO6_0",
      description:
        "motivated, constructive, creative, inspired, enthusiastic, brave",
    },
    {
      key: "EMO7_0",
      text: "Peaceful",
      value: "EMO7_0",
      description: "calm, good, at ease, comfortable, pleased, quiet",
    },
    {
      key: "EMO8_0",
      text: "Strong",
      value: "EMO8_0",
      description: "honest, sure, unique, complete, energized, hardy",
    },
    {
      key: "EMO9_0",
      text: "Relaxed",
      value: "EMO9_0",
      description: "glowing, radiant, unhurried, fluid, meditative, centered",
    },

    {
      key: "EMO10_0",
      text: "Angry",
      value: "EMO10_0",
      description: "irritated, enraged, hostile, insulting, frustrate, upset",
    },
    {
      key: "EMO11_0",
      text: "Depressed",
      value: "EMO11_0",
      description:
        "disappointed, discouraged, ashamed, powerless, diminished, guilty",
    },
    {
      key: "EMO12_0",
      text: "Confused",
      value: "EMO12_0",
      description:
        "doubtful, shy, indecisive, perplexed, embarrassed, hesitant",
    },
    {
      key: "EMO13_0",
      text: "Helpless",
      value: "EMO13_0",
      description: "incapable, alone, paralyzed, fatigued, useless, inferior",
    },
    {
      key: "EMO14_0",
      text: "Indiferent",
      value: "EMO14_0",
      description: "insensitive, dull, robotic, tired, cold, preoccupied",
    },
    {
      key: "EMO15_0",
      text: "Afraid",
      value: "EMO15_0",
      description: "fearful, terrified, nervous, anxious, alarmed, panicke",
    },
    {
      key: "EMO16_0",
      text: "Hurt",
      value: "EMO16_0",
      description: "crushed, tormented, victimized, pained, tortured, rejected",
    },
    {
      key: "EMO17_0",
      text: "Sad",
      value: "EMO17_0",
      description: "tearful, sorrowful, anguished, desolate, unhappy, lonely",
    },
    {
      key: "EMO18_",
      text: "Judmental",
      value: "EMO18_0",
      description: "stony, serious, blunt, hard, brutal, disgusted",
    },
  ];

  const hourRanges = [
    {
      key: "earlymorning",
      text: "Early Morning",
      value: "earlymorning",
      description: "From 06:00 AM to 09:00 AM",
    },
    {
      key: "latemorning",
      text: "Late Morning",
      value: "latemorning",
      description: "From 9:00 AM to 12:00 AM (Noon)",
    },
    {
      key: "earlyafternoon",
      text: "Early Atternoon",
      value: "earlyafternoon",
      description: "From 12:00 (Noon) AM to 15:00 PM",
    },
    {
      key: "lateafternoon",
      text: "Late Atternoon",
      value: "lateyafternoon",
      description: "From 15:00 PM to 18:00 PM",
    },
    {
      key: "earlyevening",
      text: "Early Evening",
      value: "earlyevening",
      description: "From 18:00 PM to 21:00 PM",
    },
    {
      key: "lateevening",
      text: "Late Evening",
      value: "lateevening",
      description: "From 21:00 PM to 24:00 PM (MidNight, 0:00)",
    },
    {
      key: "earlynight",
      text: "Early Night",
      value: "earlynight",
      description: "From 24:00 PM (MidNight, 0:00) to 03:00 AM",
    },
    {
      key: "latenight",
      text: "Late Night",
      value: "latenight",
      description: "From 03:00 AM to 06:00 AM",
    },
  ];

  const drivers = [
    {
      key: "DRI6",
      text: "Scarcity",
      value: "DRI6",
      description:
        "There is not enough of it for the people who need it or want it.",
    },
    {
      key: "DRI7",
      text: "Ownership",
      value: "DRI7",
      description: "The state or fact of being an owner.",
    },
    {
      key: "DRI5",
      text: "Avoidance",
      value: "DRI5",
      description: " The act of keeping away from or preventing from happening",
    },
    {
      key: "DRI4",
      text: "Unpredictability",
      value: "DRI4",
      description: "Something not predictable; not to be foreseen or foretold",
    },
    {
      key: "DRI3",
      text: "Social Influence",
      value: "DRI3",
      description:
        "A person's attitudes or behaviour are altered by some form of social communication.",
    },
    {
      key: "DRI2",
      text: "Empowerment",
      value: "DRI2",
      description:
        "The giving or delegation of power or authority; authorization",
    },
    {
      key: "DRI1",
      text: "Meaning",
      value: "DRI1",
      description:
        "The inner, symbolic, or true interpretation, value. Intending; having purpose",
    },
    {
      key: "DRI8",
      text: "Acomplishment",
      value: "DRI8",
      description: "The successful achievement of a task.",
    },
  ];

  // HOOKS DATA

  const [dataHashtags, setDataHashtags] = useState(hashtagList);
  const [dataHourRange, setDataHourRange] = useState(hourRanges);
  const [dataLocations, setDataLocations] = useState(
    dataNumbers.map((value) => ({
      value: "location-" + value,
      key: "location-" + value,
      text: "location-" + value,
      description: "location-" + value,
    }))
  );
  const [dataEmos, setDataEmos] = useState(
    dataNumbers.map((value) => ({
      value: "emos-" + value,
      key: "emos-" + value,
      text: "emos-" + value,
      description: "emos-" + value,
    }))
  );
  const [dataDateRange, setDataDateRange] = useState([
    "MM-DD-YYYY",
    "MM-DD-YYYY",
  ]);
  const [dataSuperEmos, setDataSuperEmos] = useState(superEmos);
  const [dataDrivers, setDataDrivers] = useState(drivers);
  const [dataTags, setDataTags] = useState(
    dataNumbers.map((value) => ({
      value: "tags-" + value,
      key: "tags-" + value,
      text: "tags-" + value,
      description: "tags-" + value,
    }))
  );

  // HOOKS SELECTION

  const [selectedDataHashtags, setSelectedDataHashtags] = useState([]);
  const [selectedDataHourRange, setSelectedDataHourRange] = useState([]);
  const [selectedDataLocations, setSelectedDataLocations] = useState([]);
  const [selectedDataEmos, setSelectedDataEmos] = useState([]);
  const [selectedDataDateRange, setSelectedDataDateRange] = useState([]);
  const [selectedDataSuperEmos, setSelectedDataSuperEmos] = useState([]);
  const [selectedDataDrivers, setSelectedDataDrivers] = useState([]);
  const [selectedDataTags, setSelectedDataTags] = useState([]);
  const [clearAll, setClearAll] = useState(false);
  const [filteredSearches, setFilteredSearches] = useState([]);
  const [globalState, dispatchGlobal] = useContext(EmoContext);
  var galaxyLabel = [];
  var marketPlaceLabel = [];
  

  const handleClearAll = (event) => {
    console.log("CLEAR ALL", event);
    setClearAll(true);
    setTimeout(() => {
      console.log("200 MILISECONDS");
      setClearAll(false);
    }, 200);
  };

  const handleSubmit = (event) => {
    console.log("ALLFILTERS COMPONENT: SUBMIT", event);
    // if(titleHeader.includes("Galaxy")){
    //   console.log("ALLFILTERS COMPONENT: SUBMIT GALAXY", event);
    //   if(selectedButtonGalaxyIndex === null){
    //     setSelectedButtonGalaxyIndex(
    //       galaxyLabel.length > 0 ? galaxyLabel.length  : 0
    //     )
    //   }
    // }
    // if(titleHeader.includes("MarketPlace")){
    //   console.log("ALLFILTERS COMPONENT: SUBMIT MARKETPLACE", event);
    //   if(selectedButtonMarketPlaceIndex === null){
    //     setSelectedButtonMarketPlaceIndex(
    //       marketPlaceLabel.length > 0 ? marketPlaceLabel.length  : 0
    //     )
    //   }
    // }
    
    setSubmitFilter({
      selectedDataHashtags: selectedDataHashtags,
      selectedDataHourRange: selectedDataHourRange,
      selectedDataLocations: selectedDataLocations,
      selectedDataEmos: selectedDataEmos,
      selectedDataDateRange: selectedDataDateRange,
      selectedDataSuperEmos: selectedDataSuperEmos,
      selectedDataDrivers: selectedDataDrivers,
      selectedDataTags: selectedDataTags,
    });
  };

  // HANDLE GALAXYBUTTONS

  const handleButtons = (event) => {
   
   
    if (event.item.type === "Galaxy") {
      console.log("HANDLE GALAXY BUTTONS", event);
      setSelectedButtonGalaxyIndex(event.index);
    }
    else if (event.item.type === "MarketPlace"){ 
      console.log("HANDLE MARKETPLACE BUTTONS", event);
      setSelectedButtonMarketPlaceIndex(event.index);
    }

    setSelectedDataHashtags(event.item.filter.selectedDataHashtags);
    setSelectedDataHourRange(event.item.filter.selectedDataHourRange);
    setSelectedDataLocations(event.item.filter.selectedDataLocations);
    setSelectedDataEmos(event.item.filter.selectedDataEmos);
    setSelectedDataDateRange(event.item.filter.selectedDataDateRange);
    setSelectedDataSuperEmos(event.item.filter.selectedDataSuperEmos);
    setSelectedDataDrivers(event.item.filter.selectedDataDrivers);
    setSelectedDataTags(event.item.filter.selectedDataTags);
  };
  
  // TYPE OF BUTTONS

  if (titleHeader.includes("Galaxy")){
   
    selectedButtonGalaxyIndex=selectedButton;
    setSelectedButtonGalaxyIndex=setSelectedButton;
  } 
  if(titleHeader.includes("MarketPlace")){
   
    selectedButtonMarketPlaceIndex=selectedButton;
    setSelectedButtonMarketPlaceIndex=setSelectedButton;

  }

  // SET THE DATA FROM PROPS.FILTERDATA:

  useEffect(() => {
    if (filterData) {
      if (filterData.dataDateRange) setDataDateRange(filterData.dataDateRange);
      if (filterData.dataDrivers) setDataDrivers(filterData.dataDrivers);
      if (filterData.dataEmos) setDataEmos(filterData.dataEmos);
      if (filterData.dataHashtags) setDataHashtags(filterData.dataHashtags);
      if (filterData.dataHourRange) setDataHourRange(filterData.dataHourRange);
      if (filterData.dataLocations) setDataLocations(filterData.dataLocations);
      if (filterData.dataSuperEmos) setDataSuperEmos(filterData.dataSuperEmos);
      if (filterData.dataTags) setDataTags(filterData.dataTags);
    }
  }, [filterData]);

  useEffect(() => {
    if (globalState.filterPlanets) {
      // IF GLOBALSTATE.FILTERPLANETS ELEMENTS HAVE TYPE="Galaxy")
      // LET GALAXYLABEL=[] AND PUSH IT ALL ELEMENTS CONTAINS
      // push({index:indexOfItem,item:{type:"Galaxy",userAddressId:userAddressId, 
      // filter:filter, items:items, firstElement:firstElement  })
      // IF GLOBALSTATE.FILTERPLANETS ELEMENTS HAVE TYPE="MarketPlace")
      // LET MARKETPLACELABEL=[] AND PUSH IT ALL ELEMENTS CONTAINS
      // push({index:indexOfItem,item:{type:"MarketPlace",userAddressId:userAddressId, 
      // filter:filter, items:items, firstElement:firstElement  })

     
      globalState.filterPlanets.forEach((element) => {
        if (element.type === "Galaxy") {
          galaxyLabel.unshift({ index: galaxyLabel.length, item: element });
        }
        if (element.type === "MarketPlace") {
          marketPlaceLabel.unshift({
            index: marketPlaceLabel.length,
            item: element,
          });
        }
      });

      let showLabels = {};
      if (galaxyLabel.length > 0) {
        showLabels.galaxyLabel = galaxyLabel;
        showLabels.galaxyLabelTitle = "Galaxy";
      }
      if (marketPlaceLabel.length > 0) {
        showLabels.marketPlaceLabel = marketPlaceLabel;
        showLabels.marketPlaceLabelTitle = "MarketPlace";
      }

      console.log("SHOW FILTERED SEARCHES", showLabels);
      setFilteredSearches(showLabels);
    }
  }, [globalState]);

  return (
    <div id="allFilters">
      <Header as="h2">{titleHeader}</Header>

      <Form className='attached fluid mt--double'>
        <Form.Field>
          <FilterComponentBase
            setter={setSelectedDataHashtags}
            setted={selectedDataHashtags}
            lista={dataHashtags}
            title={"HashTags"}
            clearAll={clearAll}
          />
        </Form.Field>

        <Form.Field>
          <FilterComponentBase
            setter={setSelectedDataHourRange}
            setted={selectedDataHourRange}
            lista={dataHourRange}
            title={"Hour Range"}
            clearAll={clearAll}
          />
        </Form.Field>

        <Form.Field>
          <FilterComponentBase
            setter={setSelectedDataDateRange}
            setted={selectedDataDateRange}
            lista={dataDateRange}
            title={"Date Range"}
            clearAll={clearAll}
          />
        </Form.Field>
        
        <Form.Field>
          <FilterComponentBase
            setter={setSelectedDataLocations}
            setted={selectedDataLocations}
            lista={dataLocations}
            title={"Location"}
            clearAll={clearAll}
          />
        </Form.Field>
        
        <Form.Field>
          <FilterComponentBase
            setter={setSelectedDataEmos}
            setted={selectedDataEmos}
            lista={dataEmos}
            title={"Emos"}
            clearAll={clearAll}
          />
        </Form.Field>

        <Form.Field>
          <FilterComponentBase
            setter={setSelectedDataSuperEmos}
            setted={selectedDataSuperEmos}
            lista={dataSuperEmos}
            title={"Super Emos"}
            clearAll={clearAll}
          />
        </Form.Field>

        <Form.Field>
          <FilterComponentBase
            setter={setSelectedDataDrivers}
            setted={selectedDataDrivers}
            lista={dataDrivers}
            title={"Drivers"}
            clearAll={clearAll}
          />
        </Form.Field>

        <Form.Field>
          <FilterComponentBase
            setter={setSelectedDataTags}
            setted={selectedDataTags}
            lista={dataTags}
            title={"Tags"}
            clearAll={clearAll}
          />
        </Form.Field>

        <Button
          fluid
          size="big"
          className="filled"
          onClick={handleSubmit}
          disabled={
            selectedDataHashtags.length === 0 &&
            selectedDataHourRange.length === 0 &&
            selectedDataLocations.length === 0 &&
            selectedDataEmos.length === 0 &&
            selectedDataDateRange.length === 0 &&
            selectedDataSuperEmos.length === 0 &&
            selectedDataDrivers.length === 0 &&
            selectedDataTags.length === 0
          }
        >
          Submit
        </Button>

        <Button
          className="aslink mt--double"
          attached="bottom"
          size="mini"
          onClick={handleClearAll}
          disabled={
            selectedDataHashtags.length === 0 &&
            selectedDataHourRange.length === 0 &&
            selectedDataLocations.length === 0 &&
            selectedDataEmos.length === 0 &&
            selectedDataDateRange.length === 0 &&
            selectedDataSuperEmos.length === 0 &&
            selectedDataDrivers.length === 0 &&
            selectedDataTags.length === 0
          }
        >
          Clear All
        </Button>
      </Form>

      <div>
        {filteredSearches.galaxyLabel &&
        titleHeader.includes(filteredSearches.galaxyLabelTitle) ? (
          <>
            <Divider />

            <Header as="h5">Your filter history</Header>
              
            {filteredSearches.galaxyLabel &&
              filteredSearches.galaxyLabel.map((element) => (
                <Button
                  className={(selectedButtonGalaxyIndex === element.index) ? "filled" : ""}
                  key={element.index}
                  size={(selectedButtonGalaxyIndex === element.index) ? "small" : "mini"}
                  onClick={() => handleButtons(element)}
                >
                  {element.index + 1}
                </Button>
              ))
            }
          </>
        ) : null}

        {filteredSearches.marketPlaceLabel &&
        titleHeader.includes(filteredSearches.marketPlaceLabelTitle) ? (
          <>
            <Divider />
            
            <Header as="h5">Your filter history</Header>
              
            {filteredSearches.marketPlaceLabel &&
              filteredSearches.marketPlaceLabel.map((element) => (
                <Button
                  className={(selectedButtonMarketPlaceIndex === element.index) ? "filled" : ""}
                  key={element.index}
                  size={(selectedButtonMarketPlaceIndex === element.index) ? "small" : "mini"}
                  onClick={() => handleButtons(element)}
                >
                  {element.index + 1}
                </Button>
              ))
            }
          </>
        ) : null}
      </div>
    </div>
  );
};
