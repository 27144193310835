import React from "react";
// Import Highcharts
import Highcharts from "highcharts/highstock";
import HcMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

import { useContext,useEffect,useState } from "react"
import {Drivers} from "../../../core/Utils.js"; 
import { Card } from "semantic-ui-react";
// MODULO DE EXPORTACION HIGHCHARTS
import HighChartExport from "highcharts/modules/exporting";
HighChartExport(Highcharts);

HcMore(Highcharts);

function createOptions(datapos,dataneg)
{  
   return({
    series: [
      {
        name: "Positive",
        data: datapos,
        _colorIndex: 5
      },
      {
        name: "Negative",
        data: dataneg,
        _colorIndex: 9
      }
    ],
    chart: {
      polar: true,
      type: "column"
    },
    title: {
      text: "What moves your emotions?"
    },
    subtitle: {
      //text: "Wind rose by polarity"
    },
    legend: {
      align: "center",
      horizontalAlign: "down",
      layout: "horizontal"
    },
    xAxis: {
      tickmarkPlacement: "on",
      type: "category"
    },
    yAxis: {
      min: 0,
      endOnTick: false,
      showLastLabel: true,

      labels: {
        formatter: function () {
          return this.value;
        }
      },
      reversedStacks: true
    },

    tooltip: {
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yValue:.2f}</b><br/>'
    },

    plotOptions: {
      series: {
        stacking: "normal",
        shadow: false,
        groupPadding: 0,
        pointPlacement: "on"
      }
    },
    credits: {
      enabled: false
    }
  });
}

function DriversPolarityWindRose(props) {
  
  
  const { metricsState } = props;
  var datapos=[];
  var dataneg=[];

  const [options,setOptions] = useState(createOptions(datapos,dataneg));
 

  useEffect(()=>{
    if(metricsState.metricResults && metricsState.metricResults.DriverPolarity)
    { 
        
        var newdatapos = [];
        var newdataneg = [];

        for(let i=0;i<metricsState.metricResults.DriverPolarity.length;i++)
        {
          let d=metricsState.metricResults.DriverPolarity[i];
          for(let j=0;j<Drivers.length;j++)
          {
            if(d.driver === Drivers[j].key)
            {
              newdatapos.push([Drivers[j].text,d.positives]);
              newdataneg.push([Drivers[j].text,d.negatives]);
            }
          }
        }
        console.log("DRIVERPOLARITY:newdatapos",newdatapos);
        console.log("DRIVERPOLARITY:newdataneg",newdataneg);
        setOptions(createOptions(newdatapos,newdataneg));

    }
  },[metricsState.metricResults]);

  return (
    
    <Card fluid>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Card>
  );
}
export default DriversPolarityWindRose;
