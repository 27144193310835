import { Container, Image, Popup } from 'semantic-ui-react';
import { useRef, useState } from 'react';
import './Gargantua.scss';

import icon1 from '../../../shared/assets/icons/nft-logo.jpg'
import icon2 from '../../../shared/assets/icons/polygon-logo.jpg'
import icon3 from '../../../shared/assets/icons/matic-logo.jpg'
import icon4 from '../../../shared/assets/icons/metamaks-logo.jpg'
import icon5 from '../../../shared/assets/icons/opensea-logo.jpg'
import image1 from '../../../shared/assets/images/playgame.jpg';
import image2 from '../../../shared/assets/images/playtoearn.jpg';
import image3 from '../../../shared/assets/images/payxdata.jpg';
import { appParams } from '../../../services/appParams';
import GargantuaFase from './fases/GargantuaFase.js';

console.log("GARGANTUA-COMPONENT-MODULE-INIT");

const Gargantua = (props) => {

    console.log("---GARGANTUA-COMPONENT-INIT---");

    const imageRef1 = useRef(null);
    const imageRef2 = useRef(null);
    const imageRef3 = useRef(null);

    let [step, setStep] = useState(1);

    const hideAll = () => {
        const elements = document.getElementsByClassName('imageContainer');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('hide');
        }
    }
    const show = (element) => {
        hideAll();
        element.classList.remove('hide');
    }
    const fadeOut = () => {
        window.scrollTo(0, 0);
        const elements = document.getElementsByClassName('fadeable');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('fadeOut');
        }
    }
    const fadeOutRemove = () => {
        const elements = document.getElementsByClassName('fadeable');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('fadeOut');
        }
    }
    const stepScroll = (value) => {
        fadeOut();
        setTimeout(() => {
            //window.scrollTo(0, 0);
            fadeOutRemove();
            if (value === 0) {
                if (step === 3) {
                    step = 1;
                } else {
                    ++step;
                }
            } else if (value === -1) {
                if (step === 1) {
                    step = 3;
                } else {
                    --step;
                }
            } else {
                step = value;
            }
            setStep(step);
            if (step === 1) {
                show(imageRef1.current)
            } else if (step === 2) {
                show(imageRef2.current)
            } else if (step === 3) {
                show(imageRef3.current)
            }
        }, 6500);
    }
    var fases = [
        {
            ref: imageRef1,
            title: {
                first: 'World changes your emotions,',
                second: 'emotions change the World.'
            },
            mainTitle: <><div> </div><div>GAME</div><div> </div></>,
            image: image1,
            actionText: {
                first: 'TAG YOUR HASHTAG',
                second: 'PLAY THE GAME',
                third: 'DISCOVER YOUR PLANET',
                fourth: 'SHARE'
            },
            button: 'DISCOVER',
            shown: true
        },
        {
            ref: imageRef2,
            title: {
                first: "Don't give your hashtags.",
                second: ''
            },
            mainTitle: <><div>PLAY</div><div>2</div><div>EARN</div></>,
            image: image2,
            actionText: {
                first: 'TAG YOUR HASHTAG',
                second: 'DISCOVER YOUR PLANET',
                third: 'TOKENIZE',
                fourth: 'EARN MATICS'
            },
            button: 'DISCOVER',
            shown: false
        },
        {
            ref: imageRef3,
            title: {
                first: "If you know What you feel,",
                second: 'you will know Why you need it.'
            },
            mainTitle: <><div>PAY</div><div>X</div><div>DATA</div></>,
            image: image3,
            actionText: {
                first: 'FILTER DATA',
                second: 'DISCOVER PLANET',
                third: 'GET METRICS',
                fourth: 'MAKE DATA DRIVEN DECISION'
            },
            button: 'DISCOVER',
            shown: false
        }
    ]
    const imageBlock = fases.map((i, j) => {
        return (
            <GargantuaFase key={`gargantua${j}`} i={i} j={j} onGameScroll={props.onGameScroll} stepScroll={stepScroll} />
        )
    })
    return (
        <>
            <Container className='gargantua'>
                <div className='icons fadeable'>
                    <div data-tooltip="NFT" data-position="bottom center">
                        <Image src={icon1} href="https://www.wired.com/story/the-10000-faces-that-launched-an-nft-revolution/" target="_blank" />
                    </div>
                    <div data-tooltip="Polygon" data-position="bottom center">
                        <Image src={icon2} href="https://polygon.technology/" target="_blank" />
                    </div>
                    <div data-tooltip="Matic" data-position="bottom center">
                        <Image src={icon3} href="https://www.coinbase.com/es/price/polygon" target="_blank" />
                    </div>
                    <div data-tooltip="Metamask" data-position="bottom center">
                        <Image src={icon4} href="https://metamask.io/" target="_blank" />
                    </div>
                    <div data-tooltip="OpenSea" data-position="bottom center">
                        <Image src={icon5} href="https://opensea.io/assets" target="_blank" />
                    </div>
                </div>
                {imageBlock}
                <div className={`road-map ${appParams.device === 'mobile' ? 'mobile' : ''}`}>
                    <Popup
                        trigger={<Image src={image1} onClick={() => stepScroll(1)} className={`${step === 1 ? 'checked' : ''}`}></Image>}
                        position="bottom center">Game</Popup>
                    <Popup
                        trigger={<Image src={image2} onClick={() => stepScroll(2)} className={`${step === 2 ? 'checked' : ''}`}></Image>}
                        position="bottom center">Play 2 Earn</Popup>
                    <Popup
                        trigger={<Image src={image3} onClick={() => stepScroll(3)} className={`${step === 3 ? 'checked' : ''}`}></Image>}
                        position="bottom center">Pay X Data</Popup>
                </div>
            </Container>
        </>
    )
}

export default Gargantua;