import { areEqual } from "./planetFilter";
import { nextTokenList } from "./userParams";
// EMOACTION PARTS:

// emoAction.type
// emoAction.payload
// emoAction.error
// emoAction.meta
// emoAction.OTHER <= ( all other things! )

console.log("GLOBALREDUCER-MODULE-INIT");

export const emoReducer = (emoState, emoAction) => {
  var result = null;
  switch (emoAction.type) {
    case "SET_MARKETPLACE_GRAPHQL_PARAMS":
      result = {
        ...emoState,
        marketPlaceGrapQlParams: emoAction.payload,
      };
      console.log(
        "GLOBALREDUCER:SET_MARKETPLACE_GRAPHQL_PARAMS",
        emoAction.payload
      );
      break;

    case "SET_MARKETPLACE_PLANET_RESULTS":
      result = {
        ...emoState,
        marketPlacePlanetResults: emoAction.payload,
      };
      console.log(
        "GLOBALREDUCER:SET_MARKETPLACE_PLANET_RESULTS",
        emoAction.payload
      );
      break;
    case "SET_MARKETPLACE_PLANET_RESULTS_GAMES_COMPLETE":
      result = emoState;
      /*
      STORES 10 PLANETS UNSETTED VALUES
      EMOACTION.PAYLOAD FORMAT:
      {initPlanet:initPlanet,finishPlanet:finishPlanet,items:items}
      */
      var { initPlanet, finishPlanet, items } = emoAction.payload;
      //var itemCount = 0;
      var itemsAtStorage =
        result.marketPlacePlanetResults.data.listMasterGames.items;
        console.log("GLOBALREDUCER:SET_MARKETPLACE_PLANET_RESULTS_GAMES_COMPLETE:ITEMS AT STORAGE:",itemsAtStorage);
      //for (var i = initPlanet; i < finishPlanet; i++) {
      for (var i = 0; i < itemsAtStorage.length; i++) {
        var item = itemsAtStorage[i];
        var indexItemToStorage = items.findIndex((it) => {  
          return it.idGame === item.idGame; 
        });
        if(indexItemToStorage===-1) continue;
        var itemToStorage = items[indexItemToStorage];
        
        console.log(
          "GLOBALREDUCER:SET_MARKETPLACE_PLANET_RESULTS_GAMES_COMPLETE:ITEM AT STORAGE:",
          item,
          i
        );
        console.log(
          "GLOBALREDUCER:SET_MARKETPLACE_PLANET_RESULTS_GAMES_COMPLETE:ITEMTOSTORAGE:",
          itemToStorage,
          indexItemToStorage
        );
        //item.idGame=itemToStorage.idGame;
        item.planetOperation = itemToStorage.planetOperation;
        item.userAddressId = itemToStorage.userAddressId;
        item.description = itemToStorage.description;
        item.emotions = itemToStorage.emotions;
        item.emotionsFilter = itemToStorage.emotionsFilter;
        //item.locationFilter=itemToStorage.locationFilter;
        item.locationPretty = itemToStorage.locationPretty;
        item.favorites = itemToStorage.favorites;
        item.gameDesign = itemToStorage.gameDesign;
        // ONLY GENERALDATA.DATE IS CHARGED, BUT THIS IS TO SET
        // COMPLETE COMPLEX DATA IN GENERALDATA
        item.generalData = itemToStorage.generalData;
        //item.hashtags=itemToStorage.hashtags;
        item.likes = itemToStorage.likes;
        item.quote = itemToStorage.quote;
        item.requesttime = itemToStorage.requesttime;
        item.sourceip = itemToStorage.sourceip;
        item.status = itemToStorage.status;
        item.tags = itemToStorage.tags;
        //item.textGame=itemToStorage.textGame;
        item.timestampEpoch = itemToStorage.timestampEpoch;
        //item.tagsFilter=itemToStorage.tagsFilter;
        //itemCount++;
      }

      console.log(
        "GLOBALREDUCER:SET_MARKETPLACE_PLANET_RESULTS_GAMES_COMPLETE:",
        emoAction.payload,
        " PLANETRESULT:",
        result.marketPlacePlanetResults
      );
      break;

    case "SET_WALLET":
      result = {
        ...emoState,
        userWallet: emoAction.payload,
      };
      console.log("GLOBALREDUCER: SET_WALLET", emoAction.payload);
      break;

    case "SET_ITEMS_PLANETS_GALAXY":
      result = {
        ...emoState,
        itemsPlanetsGalaxy: emoAction.payload,
      };
      console.log("GLOBALREDUCER: SET_ITEMS_PLANETS_GALAXY", emoAction.payload);
      break;

    case "SET_ITEMS_PLANETS_MARKETPLACE":
      result = {
        ...emoState,
        itemsPlanetsMarketPlace: emoAction.payload,
      };
      console.log(
        "GLOBALREDUCER: SET_ITEMS_PLANETS_MARKETPLACE",
        emoAction.payload
      );
      break;

    case "SET_FILTER_PLANETS":
      // IF EMOACTION.PAYLOAD IS NOT AT FILTERPLANETS, SET IT;
      if (emoAction.payload) {
        var filterPlanets = emoState.filterPlanets;
        var filterPlanet = emoAction.payload;
        //FILTERPLANET FORMAT:
        // {type:type, userAddressId: null, filter: null, items: [], firstElement:firstElement  })
        // filterPlanet && filterPlanetItem are complex Objects:
        var filterPlanetIndex = filterPlanets.findIndex((filterPlanetItem) => {
          return (
            filterPlanetItem.type === filterPlanet.type &&
            filterPlanetItem.userAddressId === filterPlanet.userAddressId &&
            areEqual(filterPlanetItem.filter, filterPlanet.filter)
          );
        });
        // SEARCH IN FILTERPLANET THE POSITION OF THE "TYPE" FILTERPLANET
        // IF NOT FOUND, SET IT TO LAST POSITION OF "TYPE" FILTERPLANET
        // IF FOUND, SET IT TO THE POSITION OF THE "TYPE" FILTERPLANET
        // use a counter of filterplanets "TYPE"
        var typeCounter = 0;
        var typeIndex = -1;
        var type=filterPlanet.type;
        for(var f=0;f<filterPlanets.length;f++){
          var filterPlanetItem=filterPlanets[f];
          if(filterPlanetItem.type===type){
            
            if(filterPlanetIndex!==-1 && 
               filterPlanet.userAddressId===filterPlanetItem.userAddressId
               && areEqual(filterPlanet.filter, filterPlanetItem.filter)){
              typeIndex=typeCounter;
            }
            typeCounter++;
          }          
        };
        if(typeIndex===-1){
        var filtersetterButton=typeCounter; 
        }else{
          var filtersetterButton=typeIndex;
        }
        console.log("GLOBALREDUCER: FILTERSETTERBUTTON",filtersetterButton);
        if(emoAction.other){
          console.log("GLOBALREDUCER: SET_FILTER_PLANETS: OTHER",emoAction.other);
          emoAction.other(filtersetterButton);
        }
       
          console.log("GLOBALREDUCER: SET_FILTER_PLANETS: FILTER SETTER BUTTON",filtersetterButton);
        if (filterPlanetIndex === -1) {
          filterPlanets.push(filterPlanet);
        } else {
          console.log(
            "GLOBALREDUCER: SET_FILTER_PLANETS: FILTERPLANET ALREADY EXISTS"
          );
        }
        result = {
          ...emoState,
          filterPlanets: filterPlanets,
        };
      } else {
        result = emoState;
      }
      console.log("GLOBALREDUCER: SET_FILTER_PLANETS", filterPlanets);
      break;

    case "SET_FIRST_ELEMENT":
      // EMOACTION.PAYLOAD FORMAT:
      // var {type, userAddressId, filter, firstElement}
      var { type, userAddressId, filter, firstElement } = emoAction.payload;
      var filterPlanets = emoState.filterPlanets;
      var filterPlanetIndex = filterPlanets.findIndex((filterPlanetItem) => {
        return (
          filterPlanetItem.type === type &&
          filterPlanetItem.userAddressId === userAddressId &&
          areEqual(filterPlanetItem.filter, filter)
        );
      });
      if (filterPlanetIndex === -1) {
        console.log(
          "GLOBALREDUCER: SET_FIRST_ELEMENT: FILTERPLANET DOES NOT EXIST"
        );
      } else {
        filterPlanets[filterPlanetIndex].firstElement = firstElement;
      }
      result = {
        ...emoState,
        filterPlanets: filterPlanets,
      };
      console.log("GLOBALREDUCER: SET_FIRST_ELEMENT", filterPlanets);
      break;

    case "SET_FILTER_PLANETS_ITEMS":
      // EMOACTION.PAYLOAD FORMAT:
      // var {items:[],filterPlanet}
      var { items, filterPlanet } = emoAction.payload;
      var filterPlanets = emoState.filterPlanets;
      var filterPlanetIndex = filterPlanets.findIndex((filterPlanetItem) => {
        return (
          filterPlanetItem.type === filterPlanet.type &&
          filterPlanetItem.userAddressId === filterPlanet.userAddressId &&
          areEqual(filterPlanetItem.filter, filterPlanet.filter)
        );
      });
      if (filterPlanetIndex === -1) {
        console.log(
          "GLOBALREDUCER: SET_FILTER_PLANETS_ITEMS: FILTERPLANET DOES NOT EXIST"
        );
      } else {
        // SEARCH ALL ITEM OF ITEMS AT FILTERPLANET.ITEMS. IF NOT FOUND, ADD IT.
        items.forEach((item) => {
          var itemIndex = filterPlanets[filterPlanetIndex].items.findIndex(
            (itemItem) => {
              return itemItem.idGame === item.idGame;
            }
          );
          if (itemIndex !== -1) {
            filterPlanets[filterPlanetIndex].items[itemIndex] = item;
            console.log(
              "GLOBALREDUCER: SET_FILTER_PLANETS_ITEMS: ITEM FOUND AND REPLACED",
              item
            );
          } else {
            filterPlanets[filterPlanetIndex].items.push(item);
            console.log(
              "GLOBALREDUCER: SET_FILTER_PLANETS_ITEMS: ITEM ADDED",
              item
            );
          }
        });
      }
      result = {
        ...emoState,
        filterPlanets: filterPlanets,
      };
      console.log("GLOBALREDUCER: SET_FILTER_PLANETS_ITEMS", filterPlanets);
      break;

    case "SET_NEXTTOKEN_ITEM":
      result = emoState;
      result.marketPlaceNextTokens.push(emoAction.payload);
      console.log(
        "GLOBALREDUCER: SET_NEXTTOKEN AT PAYLOAD,MARKETPLACENEXTTOKENS : ",
        emoAction.payload,
        result.marketPlaceNextTokens
      );
      break;

    case "SET_PAGE_ITEM":
      result = emoState;
      result.marketPlacePages.push(emoAction.payload);
      console.log(
        "GLOBALREDUCER: SET_PAGE AT PAYLOAD,MARKETPLACEPAGES : ",
        emoAction.payload,
        result.marketPlacePages
      );
      break;
   case "SET_FIRST_PLANET":
      result = emoState;
      result.marketPlaceFirstPlanet = emoAction.payload;
      console.log(
        "GLOBALREDUCER: SET_FIRST_PLANET AT PAYLOAD,FIRSTPLANET : ",
        emoAction.payload,
        result.marketPlaceFirstPlanet
      );

    break;

    default:
      result = emoState;
  }

  sessionStorage.setItem("globalState", JSON.stringify(result));
  console.log("GLOBALREDUCER RESULT=", result);
  return result;
};
