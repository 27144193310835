import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { UserContext } from "../../services/userContext";
import { useHistory } from "react-router-dom";
import { Grid, Segment } from "semantic-ui-react";
import { PayPerDataContext } from "../../services/payperDataContext";
import { wsSubscriptionQuery } from "../../services/WebSockets/wsSubscriptionQuery";

import TotalsStatistics from "../Metrics/Totals/TotalsStatistics.jsx";
import TotalDateHourBox from "../Metrics/Totals/TotalDateHourBox.jsx";
import TotalsTop from "../Metrics/Totals/TotalsTop.jsx";
import PolarityByDayArea from "../Metrics/Charts/PolarityByDayArea.jsx";
import PieTime from "../Metrics/Charts/PieTime.jsx";
import PolarityBar from "../Metrics/Charts/PolarityBar.jsx";
import DriversDetailWindRose from "../Metrics/Charts/DriversDetailWindRose.jsx";
import DriversPolarityWindRose from "../Metrics/Charts/DriversPolarityWindRose.jsx";
import SuperEmotionsBar from "../Metrics/Charts/SuperEmotionsBar.jsx";
import LocationsMap from "../Metrics/Charts/LocationsMap.jsx";
import TopEmotionsBar from "../Metrics/Charts/TopEmotionsBar.jsx";
import EmotionsCloud from "../Metrics/Charts/EmotionsCloud.jsx";

// var metricsData=null
// const setMetricsData = (data) => {
//   console.log("PAYPERDATA-METRICS-COMPONENT-METRICSDATA= ", data);
//   metricsData = data;
// };
function PayperDataMetricsComponent(props) {
 

  const [queryId, setQueryId] = useState(null); // props.props.match.params.metricsId.replace(/\./g, "%");
  const [payperDataState, dispatchMetrics] = useContext(PayPerDataContext);
  const [metricsState, setMetricsState] = useState(null);
  const history = useHistory();
  var subscriptionData;

  function installMetrics(subscription,queryId){
    console.log(
      "-----------------------------------------------------\n" +
        "-----------------------------------------------------\n" +
        "-----------------------------------------------------\n" +
        "PAYPERDATA-METRICS-COMPONENT-INSTALLMETRICS: QUERYID",
      queryId,
      "subscription=",
      subscription,
      +"\n" + "\n"
    );

    var qid = "SID-" + queryId.split("-SID-")[1];

    if (qid === subscription.subscriptionActive.subscriptionId) {
      console.log(
        "PAYPERDATA-METRICS-COMPONENT-INSTALLMETRICS: QUERYID EQUALS SUBSCRIPTIONID"
      );
      // find in metricsState.queries the queries[?] with queryId=queryId
      var query = payperDataState.queries.find((q) => q.queryId === queryId);
      console.log("PAYPERDATA-METRICS-COMPONENT-INSTALLMETRICS: QUERY=", query);
      if (query) {
        console.log("PAYPERDATA-METRICS-COMPONENT-INSTALLMETRICS: QUERY FOUND");
        var metrics = query.jsonDataMetrics.metricsResult;
        console.log(
          "PAYPERDATA-METRICS-COMPONENT-INSTALLMETRICS: METRICS=",
          metrics
        );
        if (metrics) {
          console.log(
            "PAYPERDATA-METRICS-COMPONENT-INSTALLMETRICS: METRICS FOUND"
          );
          //setMetricsData( { metricResults: metrics });
          setMetricsState({ metricResults: metrics });
          //dispatchMetrics({ type: "SET_METRIC_RESULTS", payload: metrics });
        } else {
          console.log(
            "PAYPERDATA-METRICS-COMPONENT-INSTALLMETRICS: METRICS NOT FOUND"
          );
        }
      }
    }
    console.log(
      "-----------------------------------------------------\n" +
        "-----------------------------------------------------\n" +
        "-----------------------------------------------------\n"
    );
  };

  useEffect(() => {
    if (queryId && payperDataState.subscription) {
      console.log(
        "PAYPERDATA-METRICS-COMPONENT-EFFECT - SUBSCRIPTION- QUERYID",
        payperDataState.subscription,queryId
      );
    
      console.log("PAYPERDATA-METRICS-COMPONENT-EFFECT - SUBSCRIPTION", payperDataState.subscription);
      installMetrics(payperDataState.subscription,queryId,);
    }
  }, [queryId,payperDataState.subscription]);

  useEffect(() => {
    if(payperDataState.subscription){
      console.log("PAYPERDATA-METRICS-COMPONENT-EFFECT - SUBSCRIPTION", payperDataState.subscription);
    }
  },[payperDataState.subscription]);


  useEffect(() => {
    if (props && props.props && props.props.match.params.metricsId) {
      var quid = props.props.match.params.metricsId.replace(/\./g, "%");
      console.log("PAYPERDATA-METRICS-COMPONENT-EFFECT - PROPS", props.props);
      setQueryId(quid);
     
    }
    if(props){
      var {subscription} = props;
      subscriptionData=subscription;
      console.log("PAYPERDATA-METRICS-COMPONENT-EFFECT - PROPS", props);
      console.log("PAYPERDATA-METRICS-COMPONENT-EFFECT - SUBSCRIPTION", subscription);
    }
  }, [props]);

  return (
   
      
        
      
      (metricsState && metricsState.metricResults )?
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <TotalsStatistics metricsState={metricsState} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <TotalDateHourBox metricsState={metricsState} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <TotalsTop metricsState={metricsState} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <PolarityByDayArea metricsState={metricsState} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <PieTime metricsState={metricsState} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <PolarityBar metricsState={metricsState} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <DriversDetailWindRose metricsState={metricsState} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <DriversPolarityWindRose metricsState={metricsState} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <SuperEmotionsBar metricsState={metricsState} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <LocationsMap metricsState={metricsState} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <TopEmotionsBar metricsState={metricsState} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <EmotionsCloud metricsState={metricsState} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

    :<Segment><div>No results</div></Segment> 
  );
}

export default PayperDataMetricsComponent;
