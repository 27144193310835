import React from "react";
import { Container, Grid } from "semantic-ui-react";
import TotalsStatisticsBox from "./MetricsCards/TotalsStatisticsBox";

import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../services/userContext.js";
import {
  getConnectionDatainWallet,
  getUserDatainWallet,
} from "../../../services/userWallet";

const TotalsStatistics = (props) => {
  const { metricsState } = props;
  // USER && USERDATA
  const [userState, dispatchUser] = useContext(UserContext);


  const [totalPlanets, setTotalPlanets] = useState(0);
  //var totalPlanets = metricsState.metricsFilters.planetids.lenght;

  const [percentagePositive, setPercentagePositive] = useState(0);

  const [percentagePositiveString, setPercentagePositiveString] =
    useState("0 %");
  const [totalPublic, setTotalPublic] = useState(0);
  //var totalPublic = 0;

  const [totalMinted, setTotalMinted] = useState(0);
  //var totalMinted = 0;

  // USEEFECT CONTEXT CONTROL BLOCK CODE INIT

  // USEEFECT CONTEXT CONTROL BLOCK CODE END

  useEffect(() => {
    

    if (metricsState.metricResults) {
      
      console.log(
        "TOTALSTATISCTICS: METRICS RESULT IS CHANGING",
        metricsState.metricResults
      );
      setTotalPlanets(metricsState.metricResults.TotalGames);
      setPercentagePositive(
        Math.floor(metricsState.metricResults.Polarity_Positive)
      );
      setPercentagePositiveString(
        Math.floor(metricsState.metricResults.Polarity_Positive).toString() +
          " %"
      );
      setTotalPublic(0);
    }
  }, [metricsState.metricResults]);

  useEffect(()=>{
       let userData = getUserDatainWallet();
       //let userData=userState.userData
      //console.log("TOTALSTATISCTICS: GETUSERDATA-in-CONTEXT", userData);
      console.log("TOTALSTATISCTICS: GETUSERDATA-in-WALLET", userData);
      if (userData && userData.contractEmotionyAsset) {
        console.log("TOTALSTATISCTICS: GETUSERDATA-in-WALLET", userData);
        // console.log(" >>>>>>>>>> GET TOKENS FROM CONTRACT CALL >>>>>>>>> ");
        // GET TOKENS OF OWNER (MAINACCOUNT) (CONTRACT CALL)
        userData
          .contractEmotionyAsset()
          .methods.getTokensOfOwner(userState.userData.userAddressId)
          .call((err, arrayResult) => {
            if (err) {
              console.log("ERROR!!!", err);
            }
            // console.log("GET TOKENS OF OWNER: ", userData.userAddressId, "IS:", arrayResult);

            // SET TO BLOCKCHAIN ARRAYTOKENS
            userData.tokensOfOwner.owner = userData.userAddressId;

            // GET INDIVIDUAL VALUES OF ARRAYLIST TOKENS OF OWNER (MAINACCOUNT)
            if (arrayResult) {
              //console.log("GET TOKENS OF OWNER:",userData.userAddressId," IS arrayResult:",arrayResult);
              setTotalMinted(arrayResult.length);
            }
          });
      }
    }

  ,[userState.userData]);

  return (
    <>
      <Grid columns={2}>
        <Grid.Column>
          <TotalsStatisticsBox value={totalPlanets} dataRibbon="Planets" />
        </Grid.Column>

        <Grid.Column>
          <TotalsStatisticsBox
            value={percentagePositiveString}
            dataRibbon="Positive emotions"
          />
        </Grid.Column>

        <Grid.Column>
          <TotalsStatisticsBox
            value={totalMinted}
            dataRibbon="Planets minted"
          />
        </Grid.Column>

        <Grid.Column>
          <TotalsStatisticsBox
            value={totalPublic}
            dataRibbon="Public planets"
          />
        </Grid.Column>
      </Grid>
    </>
  );
};
export default TotalsStatistics;
