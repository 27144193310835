//import { useEffect,useState } from 'react';
import { Card, Icon, Image, List, Header, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./cardPlanet.scss";
import { useEffect, useState } from "react";
// import LikeHeart from "../commons/likeHeart";
import { favCheck } from "../../services/Graphql/Game/updateGame.js";
import { appParams } from "../../services/appParams";
import { format } from "date-fns";
import { emotionyLanguage } from "../../services/Game/emotionyLanguage";
//import { userData, userId } from "../../services/userParams";
import { FrontSide } from "three";
import {
  getNameEmotionFromCode,
  getEmotionsArraysFromPropsFromDinamo,
  getSuperEmotionArrayFromPropsFromDinamo,
  getSuperEmotionArrayNamesFromPropsFromDinamo,
  getSuperEmotionArrayNamesWithoutRepetitionsFromPropsFromDinamo,
} from "../Home/fiber/getemotionyname";
import {
  getTokenizeState,
  tokenizeStatus,
} from "../../services/tokenizeService";

import { UserContext } from "../../services/userContext";
import { useContext } from "react";
import { KECCAK256_NULL } from "ethereumjs-util";
import { sassNull } from "sass";
var imageBack = "/assets/images/imageDummybn.png";
var rescueUrl = "https://planetsimagelowresolution.s3.eu-west-3.amazonaws.com/";

console.log("CARD-PLANET-COMPONENT-MODULE-INIT");

const CardPlanet = (props) => {
  const { key } = props;
  console.log("-------CARD-PLANET-COMPONENT-INIT------");

  console.log("CARD PLANET PROPS", props);

  const [gameId, setGameId] = useState(null);
  const [generalData, setGeneralData] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [to, setTo] = useState("/planetdetail");
  const [image, setImage] = useState(rescueUrl + props.item.idGame + ".png");
  const [user, setUser] = useState(null);
  const [hashtagsList, setHashtagsList] = useState(null);
  const [cardheader, setCardHeader] = useState(null);
  const [date, setDate] = useState(null);
  const [principalemotions, setPrincipalemotions] = useState(null);
  const [stateTokenize, setStateTokenize] = useState(
    tokenizeStatus.NO_TOKENIZE
  );
  const [stateDisableTokenize, setStateDisableTokenize] = useState(false);
  const [labelText, setLabelText] = useState(KECCAK256_NULL);

  const [userState, dispatchUser] = useContext(UserContext);
  const [contractEmotiony, setcontractEmotiony] = useState(null);

  const city = "Barcelona";
  const emoLang = new emotionyLanguage();
  const emolist = [];

  var [tags, setTags] = useState(null);
  var [favorite, setFavorite] = useState(null);
  var [likes, setLikes] = useState(1);
  var [listTags, setListTags] = useState(null);

  const setDateFunc = async (props) => {
    //let dateParse = props.item.timestampEpoch;
    let dateParse = props.item.generalData.date;
    //let dateParse=props.item.requesttime;
    //if (props.item.timestampEpoch === null) { dateParse = new Date().getTime() / 1000; }
    if (dateParse === null) {
      dateParse = new Date().getTime() / 1000;
    }
    //const date = format(new Date(dateParse * 1000), 'Pp');
    let dateAux = new Date(dateParse);
    
    await setDate(format(dateAux, "Pp"));
    console.log("CARD-PLANET-COMPONENT: DATEAUX=", dateAux, " DATEPARSE=",dateParse," DATE=",date);
  };

  const setEmotions = (props) => {
    props.item.emotions
      .substring(0, props.item.emotions.length - 2)
      .split("],")
      .map((elem) => {
        var emobi, em1, em2, value;
        [emobi, value] = elem
          .substring(5)
          .split(",")
          .map((el) => el);
        [em1, em2] = emobi.split("_").map((el) => el);

        return emolist.push([
          emoLang.getEmotion(em1 - 1, em2 - 1),
          parseInt(value),
        ]);
      });

    var EMOLISTCARDNUM = 5;
    var emolistCard = "",
      emolistValues = [],
      MAXEMOVALUE = 120,
      listCount = 0;
    for (
      let emovaluemaxcount = MAXEMOVALUE;
      emovaluemaxcount >= 0;
      emovaluemaxcount = emovaluemaxcount - 20
    ) {
      for (var count = 0; count < emolist.length; count++) {
        if (listCount === EMOLISTCARDNUM) break;
        var emo, value;
        [emo, value] = emolist[count];
        if (value === emovaluemaxcount) {
          emolistCard = emolistCard + emo + ", ";
          emolistValues.push(value);
          listCount++;
        }
      }
    }
    emolistCard = emolistCard.substring(0, emolistCard.length - 2);
    setPrincipalemotions(emolistCard);
  };

  const likeCheck = async (liked, favorites, planetOperation) => {
    favCheck(
      liked,
      gameId,
      userState.userData.userAddressId,
      userState.userData.currentFavoritePlanetIds,
      userState.userData.currentFavoritePlanetIdsCount,
      favorites,
      planetOperation
    );
    var lks = likes;
    if (liked) {
      lks = ++lks;
    } else {
      lks = --lks;
    }
    setLikes(lks);
    setFavorite(liked);
  };

  const tagList = () => {
    console.log("CARDPLANET:TAGLIST");
    if (tags) {
      console.log(
        "CARDPLANET:TAGLIST",
        tags,
        " TIPEOF:",
        typeof tags,
        " length",
        tags.length
      );
      if (typeof tags === "string") {
        tags = tags.split(",");
      }
      const listTags = tags.map((t, i) => {
        return (
          <>
            <Icon name="tag" key={t} />
            <span>{t}</span>
          </>
        );
      });
      setListTags(listTags);
    }
  };

  const setLks = () => {
    if (props.item && props.item.favorites) {
      console.log("CARDPLANET:TAGLIST", props.item.favorites);
      setLikes(
        props.item.favorites.length > 0 ? props.item.favorites.length : 0
      );
    }
  };

  const setFav = () => {
    console.log("CARDPLANET:TAGLIST");
    if (
      props.item.favorites &&
      props.item.favorites.indexOf(userState.userData.userAddressId) !== -1
    ) {
      likeCheck(true, props.item.favorites, props.item.planetOperation);
    } else {
      likeCheck(false, props.item.favorites, props.item.planetOperation);
    }
  };

  window.onscroll = () => {
    const html = document.documentElement;
    const elements = document.getElementsByClassName("card-column");
    if (appParams.device === "mobile") {
      for (let i = 0; i < elements.length; i++) {
        var rect = elements[i].getBoundingClientRect();
        if (rect.bottom < html.clientHeight + 300 && rect.y > -300) {
          elements[i].classList.add("show");
        } else {
          elements[i].classList.remove("show");
        }
      }
    }
  };

  useEffect(() => {
    if (userState.userData.contractEmotionyAsset)
      setcontractEmotiony(userState.userData.contractEmotionyAsset);

    if (!props.item.url) {
      console.log("RESCUE URL...");
      setImage(rescueUrl + props.item.idGame + ".png");
    } else {
      setImage(props.item.url);
    }
    if (props.item) {
      setGameId(props.item.idGame);
      setGeneralData(props.item.generalData);

      setTo("/planetdetail/" + props.item.idGame);
      if (props.item.generalData) setUser(props.item.generalData.username);
      setHashtagsList(props.item.hashtags);
      setCardHeader(props.item.textGame);
      if (
        props.item.favorites &&
        props.item.favorites.length > 0 &&
        !Array.isArray(props.item.favorites)
      ) {
        setFavorites(props.item.favorites.split(","));
        console.log(
          "CARD PLANET FAVORITES: MODIFIED!!!!! ",
          `"${props.item.favorites}"`
        );
      }

      console.log("CARD PLANET FAVORITES", `"${props.item.favorites}"`);
      if (props.item.favorites)
        console.log(
          "CARD PLANET FAVORITES.length",
          props.item.favorites.length
        );

      if (props.item.emotions) {
        setEmotions(props);
      }
      if (props.item && props.item.generalData && props.item.generalData.date) {
        setDateFunc(props);
      }
      if (props.item.tags) setTags(props.item.tags);
      if (props.item.favorite);
      setFavorite(props.item.favorite);
    }

    if (props.item && userState && userState.userData) {
      console.log("CARD PLANET USERDATA", userState.userData);
      tagList();
      setFav();
      setLks();
      if (props.item.emotions !== undefined) {
        var { emotionsArrayCodes, emotionsNames } =
          getEmotionsArraysFromPropsFromDinamo(props.item.emotions);
        var superEmotionsWRArrayNames =
          getSuperEmotionArrayNamesWithoutRepetitionsFromPropsFromDinamo(
            props.item.emotions
          );
        var superEmotionsArrayNames =
          getSuperEmotionArrayNamesFromPropsFromDinamo(props.item.emotions);
        console.log(
          "TOPARSE-EMOTIONS:",
          emotionsArrayCodes,
          emotionsNames,
          superEmotionsArrayNames,
          superEmotionsWRArrayNames
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item, userState]);

  useEffect(() => {
    if (favorite) {
      console.log("CARDPLANET:USEEFFECT:FAVORITE ACTIVE", favorite);
    }
    if (cardheader) {
      console.log("CARDPLANET:USEEFFECT:CARDHEADER ACTIVE", cardheader);
    }
    if (contractEmotiony) {
      console.log(
        "CARDPLANET:USEEFFECT:CONTRACTEMOTIONY ACTIVE",
        contractEmotiony
      );
    }
    if (gameId) {
      console.log("CARDPLANET:USEEFFECT:GAMEID ACTIVE", gameId);
    }
    if (generalData) {
      console.log("CARDPLANET:USEEFFECT:GENERALDATA ACTIVE", generalData);
    }
    if (hashtagsList) {
      console.log("CARDPLANET:USEEFFECT:HASHTAGSLIST ACTIVE", hashtagsList);
    }
    if (userState.userData) {
      console.log(
        "CARDPLANET:USEEFFECT:USERSTATE.USERDATA ACTIVE",
        userState.userData
      );
    }
    if (key) {
      console.log("CARDPLANET:USEEFFECT:KEY ACTIVE", key);
    }
    if (userState.userData.connectionData.connectedBlockchain) {
      console.log(
        "CARDPLANET:USEEFFECT:USERSTATE.USERDATA.CONNECTIONDATA CONNECTEDBLOCKCHAIN ACTIVE",
        userState.userData.connectionData.connectedBlockchain
      );
    }

    if (userState.userData.isConfigNetWorkId) {
      console.log(
        "CARDPLANET:USEEFFECT:USERSTATE.USERDATA.ISCONFIGNETWORKID ACTIVE",
        userState.userData.isConfigNetWorkId
      );
    }

    if (
      props.item.idGame &&
      contractEmotiony &&
      userState.userData.isConfigNetWorkId &&
      userState.userData.connectionData.connectedBlockchain
    ) {
      console.log(
        "CARDPLANET:USEEFFECT:CONTRACTEMOTIONY ACTIVE",
        contractEmotiony
      );
      getTokenizeState(
        userState.userData.connectionData,
        props.item.idGame,
        setStateDisableTokenize,
        setStateTokenize,
        tokenizeStatus,
        setLabelText
      );
      console.log(
        "CARD PLANET TOKENIZESTATUS=",
        stateTokenize,
        labelText,
        stateDisableTokenize
      );
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userState.userData.connectionData.connectedBlockchain,
    userState.userData.isConfigNetWorkId,
    props.item,
    userState.userData,
    contractEmotiony,
    cardheader,
    userState.userData.contractEmotionyAsset,
    favorite,
    gameId,
    generalData,
    hashtagsList,
    key,
  ]);

  return (
    <>
      <Card fluid key={gameId + "-card"} className="cardplanet">
        <Card.Content className="cardplanet__tags">
          <List>
            <List.Item>
              <List.Content floated="left">
                {/* <LikeHeart
                  favorite={favorite}
                  likeCheck={() =>
                    likeCheck(
                      !favorite,
                      props.item.favorites,
                      props.item.planetOperation
                    )
                  }
                  carousel={props.carousel}
                />
                {likes} */}
              </List.Content>

              <List.Content floated="right">
                {stateTokenize === "Tokenized!!!" ? (
                  <>
                    <Label>Tokenized</Label>
                    {/* <Label>For sale</Label> */}
                  </>
                ) : null}
                {stateTokenize === "Not tokenized!" ? (
                  <>{/* <Label>Not Tokenized</Label> */}</>
                ) : null}
              </List.Content>
            </List.Item>
          </List>
        </Card.Content>

        <Link to={to} item={props.item.idGame}>
          <Image src={image} fluid />
        </Link>

        <Card.Content>
          <Card.Header>
            <Header as="h2">{cardheader}</Header>
          </Card.Header>
          <Card.Meta>
            <div>
              by{" "}
              <a href="#">
                <strong>{user}</strong>
              </a>
            </div>
          </Card.Meta>
          <Card.Description>
            <List>
              <List.Item key={0}>
                <strong>Date:</strong> {date}
              </List.Item>
              <List.Item key={1}>
                <strong>City:</strong> {city}
              </List.Item>
              <List.Item key={2}>
                <strong>Emotions:</strong> {principalemotions}
              </List.Item>
              <List.Item
                key={3}
                className={`tags ${props.carousel ? "hide" : ""}`}
              >
                {listTags}
              </List.Item>
            </List>
          </Card.Description>
        </Card.Content>
        {/* <Card.Content extra>
          <List divided verticalAlign='middle'>
            <List.Item>
              <List.Content floated='left'>
                <Icon name="btc" /> To sell
              </List.Content>

              <List.Content floated='right'>
                <Icon name="cart arrow down" /> Tokenize
              </List.Content>
            </List.Item>
          </List>
        </Card.Content> */}
      </Card>
    </>
  );
};

export default CardPlanet;
