import { Header } from "semantic-ui-react";

console.log("POLICY-PAGE-MODULE-INIT");

const Policy = () => {

  console.log("---POLICY-PAGE-INIT----");
  
  return (
    <>
      <Header as="h1">Privacy Policy</Header>
    </>
  )
}

export default Policy;