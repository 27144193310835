import React from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import { Label } from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import {MetricsContext} from "../../../services/metricsContext.js";

function DatesGames() {
  const [dates, setDates] = useState([]);
  const [metricsState, dispatchMetrics] = useContext(MetricsContext);
  const handleDatesChange = (event, data) => {
    setDates(data.value);
    var init=0;
    var finish=0;
    if(data && data.value && data.value.length>1){
      var dinit=new Date(data.value[0]);
      var dfinish=new Date(data.value[1]);
      init=dinit.getTime();
      finish=dfinish.getTime(); 
      //console.log("FILTERSATAGAMES:",init,finish);
      dispatchMetrics({type:"SET_DATE_RANGE",payload:{init:init,end:finish}});
    }
  }

  const renderLabel = (label) => ({
    content: `${label.text}`
  });

  return (
    <>

      <label style={{ width: "190px" }}>Date Range </label>
      <SemanticDatepicker
        format="MM-DD-YYYY"
        icon="calendar outline"
        onChange={handleDatesChange}
        type="range"
        label={renderLabel}
      />

    </>
  );
}

export default DatesGames;
