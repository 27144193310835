/**
 *
 * This function gets Geolocation with params using XMLHttpRequest
 * from the ip-api endpoint URL
 *  XX http://ip-api.com/batch?fields=status,message,country,countryCode,region,regionName,city,zip,lat,lon,timezone,isp,org,as,reverse,mobile,proxy,query XX CORS PROBLEM
 * http://ip-api.com/json/{ip}?fields=status,message,country,countryCode,region,regionName,city,zip,lat,lon,timezone,isp,org,as,reverse,mobile,proxy,query
 *
 */

import jsonp from "jsonp";

console.log("GEOLOCATION-COMPONENT-MODULE-INIT");

export function getGeolocation(queryIp) {
  console.log("---GEOLOCATION-COMPONENT-INIT---");

  var request;
  var geolocation = {};

  //var endpoint="http://ip-api.com/batch?";
  var endpoint = "http://ip-api.com/json/";
  var fields =
    "status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,mobile,proxy,hosting,query";
  //var lang="en";
  //var IPs=[{"query":queryIp,"fields":fields,"lang":lang}];
  //var data=JSON.stringify(IPs);
  var queryGet = endpoint + queryIp + "?fields=" + fields;
  request = new XMLHttpRequest();
  //request.setRequestHeader('Access-Control-Allow-Origin', '*');
  //request.open('POST', endpoint, false);
  //request.send(data);
  request.open("GET", queryGet, false);

  console.log("GET GEOLOCATION: QUERY:", queryGet);
  request.send();
  if (request.status === 200) {
    geolocation = JSON.parse(request.responseText);
  } else {
    console.log("ERROR GETTING GEOLOCATION", request);
  }

  console.log("GET GEOLOCATION RESULT", geolocation);

  return JSON.stringify(geolocation);
}

export function getGeolocation_jsonp(geolocation_jsonp) {
  console.log("---GEOLOCATION-JSONP-COMPONENT-INIT---");

  var endpoint = "http://ip-api.com/json/";
  var fields =
    "status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,mobile,proxy,hosting,query";
  var uri = endpoint + "?fields=" + fields; //+queryIp;//+"?fields="+fields;
  if (!geolocation_jsonp.setGeoloc) {
    jsonp(
      uri,
      function (err, data) {
        if (err) {
          console.log("GEOLOCATION CALLBACK ERR", err);
          geolocation_jsonp.err = err;
        } else {
          console.log("GEOLOCATION CALLBACK DATA", data);
          geolocation_jsonp.data = JSON.stringify(data);
          geolocation_jsonp.setGeoloc = true;
          console.log("GEOLOCATION CALLBACK DATA", geolocation_jsonp.data);
        }
      },
      function (err, data) {
        if (err) {
          console.log("ERROR GETTING GEOLOCATION", err);
        } else {
          console.log("GET GEOLOCATION RESULT", data);
        }
      }
    );
  }
}

/*
Para obtener el nombre de tu ciudad utilizando OpenStreetMap y tu longitud y latitud, puedes utilizar su servicio de geocodificación inversa llamado Nominatim. A continuación, te explicaré cómo hacerlo:

Realiza una solicitud HTTP GET a la siguiente URL, reemplazando "LATITUD" y "LONGITUD" con tus propias coordenadas:

https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=LATITUD&lon=LONGITUD

Por ejemplo, si tus coordenadas son 40.4168 grados de latitud y -3.7038 grados de longitud, la URL sería:

https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=40.4168&lon=-3.7038

La respuesta que obtendrás será un objeto JSON que contiene información sobre la ubicación correspondiente a tus coordenadas. El nombre de tu ciudad estará en la propiedad "city" (o "town" o "village", dependiendo del tamaño de la localidad). También puedes encontrar información adicional, como el país, el código postal, la dirección, etc.

*/

export function getGeolocation_osm(latitude, longitude) {
  console.log("---GEOLOCATION-OSM-COMPONENT-INIT---");

  var request;
  var geolocation = {};

  var endpoint = "https://nominatim.openstreetmap.org/reverse?";
  var format = "jsonv2";
  var lat = latitude;//"40.4168";
  var lon = longitude;//"-3.7038";
  var queryGet = endpoint + "format=" + format + "&lat=" + lat + "&lon=" + lon;
  request = new XMLHttpRequest();
  //request.setRequestHeader('Access-Control-Allow-Origin', '*');
  //request.open('POST', endpoint, false);
  //request.send(data);
  request.open("GET", queryGet, false);

  console.log("GET GEOLOCATION: QUERY:", queryGet);
  request.send();
  if (request.status === 200) {
    geolocation = JSON.parse(request.responseText);
  } else {
    console.log("ERROR GETTING GEOLOCATION", request);
  }

  console.log("GET GEOLOCATION RESULT", geolocation);

  return JSON.stringify(geolocation);
}