import React from "react";
import { Card, Label } from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";

import { setOptions } from "highcharts";

const TotalDateHourBox = (props) => {
  const { metricsState } = props;

  const [dateRange, setDateRange] = useState(
    "from " + "1/5/2022" + " to " + "6/5/2022"
  );
  const hoursRange = "Early Morning & Late Morning";

  useEffect(() => {
    //console.log("METRICSTATE",metricsState);
    //console.log("INIT=",metricsState.metricResults.IntervalData.epoch_init);
    //console.log("FINISH=",metricsState.metricResults.IntervalData.epoch_finish);
    if (metricsState.metricResults && metricsState.metricResults.IntervalData) {
      let einit = metricsState.metricResults.IntervalData.epoch_init;
      let efinish = metricsState.metricResults.IntervalData.epoch_finish;

      let init = new Date(einit).toLocaleDateString();
      let finish = new Date(efinish).toLocaleDateString();
      //console.log("DATEINIT",init);
      //console.log("DATEFINISH",finish);

      setDateRange("from " + init + " to " + finish);
    }
  }, [metricsState.metricResults]);

  return (
    <>
      <Card fluid>
        <Card.Content>
          <Card.Header textAlign="center">
            This data is filtered to the next time range
          </Card.Header>

          <Card.Content textAlign="center">{dateRange}</Card.Content>
          <Card.Content textAlign="center">{hoursRange}</Card.Content>
        </Card.Content>
      </Card>
    </>
  );
};

export default TotalDateHourBox;
