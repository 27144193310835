import React from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import { Label } from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import { PayPerDataContext } from "../../../services/payperDataContext.js";

function PayPerDataDatesGames() {
  const [dates, setDates] = useState([]);
  const [metricsState, dispatchMetrics] = useContext(PayPerDataContext);

  const handleDatesChange = (event, data) => {
    console.log("handleDatesChange event:", event, " data:", data);
    setDates(data.value);
    if (!data.value) {
      console.log("handleDatesChange: no data.value");
      dispatchMetrics({
        type: "SET_DATE_RANGE",
        payload: { init: null, end: null },
      });
    }

    var init = 0;
    var finish = 0;
    
    if (data && data.value && data.value.length > 1) {
      var dinit = new Date(data.value[0]);
      var dfinish = new Date(data.value[1]);
      init = dinit.getTime();
      finish = dfinish.getTime();
      console.log("FILTERSATAGAMES:", init, finish);
      dispatchMetrics({
        type: "SET_DATE_RANGE",
        payload: { init: init, end: finish },
      });
    }
  };



  const renderLabel = (label) => ({
    content: `${label.text}`,
  });

  /*
   metricsState.effectiveFilters={
      hashtags:[],
      superEmos:[],
      drivers:[],
      dateRange:{init:null,end:null},
      hoursRange:[],
      locations:[],
      planetids:[]
    }
  */

  useEffect(() => { 

    console.log("PayPerDataDatesGames:metricsState",metricsState.effectiveFilters);

    if(metricsState.effectiveFilters && metricsState.effectiveFilters.dateRange){
      var init = metricsState.effectiveFilters.dateRange.init;
      var end = metricsState.effectiveFilters.dateRange.end;
      
      if(init && end){
        var dinit = new Date(init);
        var dfinish = new Date(end);
        var initStr = dinit.toLocaleDateString();
        var endStr = dfinish.toLocaleDateString();
        setDates([dinit,dfinish]);
      }
    }
  }, [metricsState.effectiveFilters]);
  


  return (
    <>
      <label style={{ width: "190px" }}>Date Range </label>
      <SemanticDatepicker
        format="MM-DD-YYYY"
        icon="calendar outline"
        onChange={handleDatesChange}
       
        type="range"
        label={renderLabel}
        value={dates}
      />
    </>
  );
}

export default PayPerDataDatesGames;
