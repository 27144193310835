import React from "react";
// Import Highcharts
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
// import DarkUnica from "highcharts/themes/dark-unica";
// import HcMore from "highcharts/highcharts-more";

import { useContext, useEffect, useState } from "react";
// HcMore(Highcharts);
// DarkUnica(Highcharts);

import { Card } from "semantic-ui-react";
// MODULO DE EXPORTACION HIGHCHARTS
import HighChartExport from "highcharts/modules/exporting";
HighChartExport(Highcharts);

function createOptions(data1, data2) {
  return {
    chart: {
      type: "column",
      alignTicks: false,
    },
    title: {
      text: "How are your emotions changing?"
    },
    series: [
      {
        dataGrouping: {
          units: [
            [
              "week", // unit name
              [1], // allowed multiples
            ],
            ["month", [1, 2, 3, 4, 6]],
          ],
        },

        data: data1,
        _colorIndex: 5,
      },
      {
        data: data2,
        _colorIndex: 9,
      },
    ],
  };
}

// Render app with demo chart

function PolarityBar(props) {
  const { metricsState } = props;
  
  var data1 = [];
  var data2 = [];

  const [options, setOptions] = useState(createOptions(data1, data2));

  useEffect(() => {
    if (
      metricsState.metricResults &&
      metricsState.metricResults.dataAllGamesGeneral
    ) {
      let newdatapos = [];
      let newdataneg = [];
      for (
        let i = metricsState.metricResults.dataAllGamesGeneral.length - 1;
        i >= 0;
        i--
      ) {
        let d = metricsState.metricResults.dataAllGamesGeneral[i];
        newdatapos.push([d.epoch, d.sum_points_positive]);
        newdataneg.push([d.epoch, d.sum_points_negative]);
      }
      console.log("TOTALGAMES pos=", newdatapos);
      console.log("TOTALGAMES neg=", newdataneg);
      setOptions(createOptions(newdatapos, newdataneg));
    }
  }, [metricsState.metricResults]);

  return (
    <Card fluid>
      <HighchartsReact
        constructorType={"stockChart"}
        highcharts={Highcharts}
        options={options}
      />
    </Card>
  );
}
export default PolarityBar;
