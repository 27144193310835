import { da } from "date-fns/locale";
import {
  getAllEmotionyNames,
  getEmotionsArraysFromPropsFromDinamo,
  getSuperEmotionArrayNamesWithoutRepetitionsFromPropsFromDinamo,
} from "../components/Home/fiber/getemotionyname";
import { matrix_emo_driver } from "../core/matrix_emo_driver";

console.log("PLANET-FILTER-MODULE-INIT");

export const getPlanetFilter = (data) => {
  var filterData = {};

  console.log("GETPLANETFILTER");

  /* 
    X setDataDateRange(filterData.dataDateRange);
    X setDataDrivers(filterData.dataDrivers);
    X if (filterData.dataEmos) setDataEmos(filterData.dataEmos);
    X if (filterData.dataHashtags) setDataHashtags(filterData.dataHashtags);
    X setDataHourRange(filterData.dataHourRange);
    X if (filterData.dataLocations) setDataLocations(filterData.dataLocations);
    X setDataSuperEmos(filterData.dataSuperEmos);
    X if (filterData.datatags) setDataTags(filterData.dataTags); 
    */

  filterData.dataSuperEmos = superEmos;
  filterData.dataDrivers = drivers;
  filterData.hourRanges = hourRanges;
  filterData.dataDateRange = ["MM-DD-YYYY  ", "  MM-DD-YYYY"];

  // DATA EMOS
  filterData.dataEmos = getAllEmotionyNames().map((val) => {
    return {
      key: val[0],
      text: val[1],
      description: "",
      value: val[1],
    };
  });
  console.log("PLANET FILTER data=", data);
  if (data) {
    // DATA HASHTAGS
    if (data.hashtags) {
      filterData.dataHashtags = data.hashtags.map((val) => {
        return {
          key: val,
          text: val, //.charAt(0).toUpperCase() + val.slice(1),
          description: "",
          value: val,
        };
      });
    }

    // DATA LOCATIONS
    if (data.currentLocations) {
      let temp = data.currentLocations.map((val) => {
        return {
          key: val,
          text: val,
          description: "",
          value: val,
        };
      });
      filterData.dataLocations = temp.filter((obj, index, arr) => {
        return (
          index ===
          arr.findIndex((o) => {
            return o.key === obj.key;
          })
        );
      });
    }

    // DATA TAGS
    if (data.tags) {
      filterData.dataTags = data.tags.map((val) => {
        return {
          key: val,
          text: val,
          description: "",
          value: val,
        };
      });
    }
  }

  if (!filterData.dataLocations) filterData.dataLocations = [];
  if (!filterData.dataEmos) filterData.dataEmos = [];
  if (!filterData.dataHashtags) filterData.dataHashtags = [];
  if (!filterData.dataTags) filterData.dataTags = [];

  return filterData;
};

/*
  THIS FUNCTION RECEIVES THE FILTERS FROM THE FILTERS COMPONENT AT GALAXY
  OR MARKETPLACE AND RETURNS THE PLANETS (GAMEID's) THAT MATCH THE FILTERS
  COMPARING THE SELECTED DATA OF HASHTAGS, LOCATIONS, EMOS, ETC. WITH THE   
  DATA OF THE PLANETS (GAMEID's) THAT ARE BEING DISPLAYED IN THE GALAXY OR
  MARKETPLACE.

  THE FILTERS ARE RECEIVED IN THE FOLLOWING FORMAT:
  {                       
    selectedDataHashtags: selectedDataHashtags,
    selectedDataHourRange: selectedDataHourRange,
    selectedDataLocations: selectedDataLocations,
    selectedDataEmos: selectedDataEmos,
    selectedDataDateRange: selectedDataDateRange,
    selectedDataSuperEmos: selectedDataSuperEmos,
    selectedDataDrivers: selectedDataDrivers,
    selectedDataTags: selectedDataTags,
  }

*/
export const getPlanetsFiltered = (
  classFromOrigin,
  submitFilters,
  dataPlanetsToFilter
) => {
  var planetsIdsFilteredHahstags = [];
  console.log("GET_PLANETS_FILTERED: CLASS FROM ORIGIN:", classFromOrigin);
  console.log("GET_PLANETS_FILTERED: SUBMITFILTERS:", submitFilters);
  console.log(
    "GET_PLANETS_FILTERED: DATAPLANETSTOFILTER:",
    dataPlanetsToFilter
  );

  // COMPARING HASHTAGS
  if (submitFilters.selectedDataHashtags.length > 0) {
    dataPlanetsToFilter.forEach((planet) => {
      if (planet.hashtags) {
        planet.hashtags.split(",").forEach((hashtag) => {
          if (submitFilters.selectedDataHashtags.includes(hashtag)) {
            // IF PLANET NOT ALREADY IN ARRAY, ADD IT
            if (!planetsIdsFilteredHahstags.includes(planet.idGame))
              planetsIdsFilteredHahstags.push(planet.idGame);
          }
        });
      }
    });
  }
  if (planetsIdsFilteredHahstags.length !== 0) {
    console.log(
      "GET_PLANETS_FILTERED: HASHTAGS PLANETSIDSFILTERED:",
      submitFilters.selectedDataHashtags,
      planetsIdsFilteredHahstags
    );
  }

  var planetIdsFilteredLocations = [];

  // COMPARING LOCATIONS
  if (submitFilters.selectedDataLocations.length > 0) {
    dataPlanetsToFilter.forEach((planet) => {
      if (submitFilters.selectedDataLocations.includes(planet.locationFilter)) {
        // IF PLANET NOT ALREADY IN ARRAY, ADD IT
        if (!planetIdsFilteredLocations.includes(planet.idGame))
          planetIdsFilteredLocations.push(planet.idGame);
      }
    });
  }
  console.log(
    "GET_PLANETS_FILTERED: LOCATIONS PLANETSIDSFILTERED:",
    submitFilters.selectedDataLocations,
    planetIdsFilteredLocations
  );

  var planetIdsFilteredEmos = [];

  // COMPARING EMOS
  if (submitFilters.selectedDataEmos.length > 0) {
    dataPlanetsToFilter.forEach((planet) => {
      if (planet.emotions) {
        // IF EMOTIONSNAMES CONTAINS ALL (AND)(EVERY) SUBMITFILTERS SELECTEDDATAEMOS
        // ADD PLANET TO ARRAY
        // IF EMOTIONSNAMES CONTAINS ONE (OR)(SOME)SUBMITFILTERS SELECTEDDATAEMOS
        console.log("GET_PLANETS_FILTERED: EMOS PLANET:", planet.emotions);
        var { emotionsArrayCodes, emotionsNames } =
          getEmotionsArraysFromPropsFromDinamo(planet.emotions);
        if (emotionsNames) {
          if (emotionsNames.length > 0) {
            if (
              emotionsNames.some((emo) =>
                submitFilters.selectedDataEmos.includes(emo[0])
              )
            ) {
              // IF PLANET NOT ALREADY IN ARRAY, ADD IT
              if (!planetIdsFilteredEmos.includes(planet.idGame))
                planetIdsFilteredEmos.push(planet.idGame);
            }
          }
        }
      }
    });
  }
  console.log(
    "GET_PLANETS_FILTERED: EMOS PLANETSIDSFILTERED:",
    submitFilters.selectedDataEmos,
    planetIdsFilteredEmos
  );

  // COMPARING TAGS

  var planetIdsFilteredTags = [];

  if (submitFilters.selectedDataTags.length > 0) {
    dataPlanetsToFilter.forEach((planet) => {
      if (planet.tagsFilter) {
        planet.tagsFilter.split(",").forEach((tag) => {
          if (submitFilters.selectedDataTags.includes(tag)) {
            // IF PLANET NOT ALREADY IN ARRAY, ADD IT
            if (!planetIdsFilteredTags.includes(planet.idGame))
              planetIdsFilteredTags.push(planet.idGame);
          }
        });
      }
    });
  }
  console.log(
    "GET_PLANETS_FILTERED: TAGS PLANETSIDSFILTERED:",
    submitFilters.selectedDataTags,
    planetIdsFilteredTags
  );

  // COMPARING SUPER EMOS

  var planetIdsFilteredSuperEmos = [];

  if (submitFilters.selectedDataSuperEmos.length > 0) {
    dataPlanetsToFilter.forEach((planet) => {
      if (planet.emotions) {
        // IF SUPEREMOTIONWRARRAYNAMES CONTAINS ALL (AND)(EVERY) SUBMITFILTERS SELECTEDDATASUPEREMOS
        // ADD PLANET TO ARRAY
        // IF SUPEREMOTIONWRARRAYNAMES CONTAINS ONE (OR)(SOME) SUBMITFILTERS SELECTEDDATASUPEREMOS

        var superEmotionWRarraynames =
          getSuperEmotionArrayNamesWithoutRepetitionsFromPropsFromDinamo(
            planet.emotions
          );
        if (superEmotionWRarraynames) {
          if (superEmotionWRarraynames.length > 0) {
            if (
              superEmotionWRarraynames.some((superEmo) =>
                submitFilters.selectedDataSuperEmos.includes(
                  "EMO" + superEmo[0].split("#")[0] + "_0"
                )
              )
            ) {
              // IF PLANET NOT ALREADY IN ARRAY, ADD IT
              if (!planetIdsFilteredSuperEmos.includes(planet.idGame))
                planetIdsFilteredSuperEmos.push(planet.idGame);
            }
          }
        }
      }
    });
  }

  console.log(
    "GET_PLANETS_FILTERED: SUPER EMOS PLANETSIDSFILTERED:",
    submitFilters.selectedDataSuperEmos,
    planetIdsFilteredSuperEmos
  );

  // COMPARING HOUR RANGE

  var planetIdsFilteredHourRange = [];

  if (submitFilters.selectedDataHourRange.length > 0) {
    dataPlanetsToFilter.forEach((planet) => {
      if (
        (planet.generalData && planet.generalData.date) ||
        planet.timestampEpoch
      ) {
        if (!(planet.generalData && planet.generalData.date)) {
          planet.generalData = { date: planet.timestampEpoch };
        }
        var date = new Date(planet.generalData.date);
        var hour = date.getHours();
        submitFilters.selectedDataHourRange.forEach((selectedHourRange) => {
          // OBTAIN RANGE FROM HOURRANGES
          var { range } = hourRanges.find(
            (hourRange) => hourRange.value === selectedHourRange
          );

          if (range[0] <= hour && hour < range[1]) {
            // IF PLANET NOT ALREADY IN ARRAY, ADD IT
            if (!planetIdsFilteredHourRange.includes(planet.idGame))
              planetIdsFilteredHourRange.push(planet.idGame);
          }
        });
      }
    });
  }

  console.log(
    "GET_PLANETS_FILTERED: HOUR RANGE PLANETSIDSFILTERED:",
    submitFilters.selectedDataHourRange,
    planetIdsFilteredHourRange
  );

  // COMPARING DATE RANGE

  var planetIdsFilteredDateRange = [];

  if (submitFilters.selectedDataDateRange.length > 0) {
    dataPlanetsToFilter.forEach((planet) => {
      if (
        (planet.generalData && planet.generalData.date) ||
        planet.timestampEpoch
      ) {
        if (!(planet.generalData && planet.generalData.date)) {
          planet.generalData = { date: planet.timestampEpoch };
        }
        var date = new Date(planet.generalData.date);
        // DATE IS IN TIMESTAMP FORMAT AND SUBMITFILTERS.SELECTEDDATERANGES
        // IS IN TIMESTAMP FORMAT, AND SUBMITFILTERS.SELECTEDDATERANGES IS A
        // TWO ELEMENTS ARRAY OF MINOR AND MAJOR TIMESTAMP
        var dateTimestamp = date.getTime();
        var minor = submitFilters.selectedDataDateRange[0];
        var major = submitFilters.selectedDataDateRange[1];
          if (
            minor <= dateTimestamp &&
            dateTimestamp < major
          ) {
            // IF PLANET NOT ALREADY IN ARRAY, ADD IT
            if (!planetIdsFilteredDateRange.includes(planet.idGame))
              planetIdsFilteredDateRange.push(planet.idGame);
          }
             }
    });
  }

  console.log(
    "GET_PLANETS_FILTERED: DATE RANGE PLANETSIDSFILTERED:",
    submitFilters.selectedDataDateRange,
    planetIdsFilteredDateRange
  );

  // COMPARING DRIVERS

  var planetIdsFilteredDrivers = [];

  if (submitFilters.selectedDataDrivers.length > 0) {
    for (let iplanet in dataPlanetsToFilter) {
      let planet = dataPlanetsToFilter[iplanet];
      let planetDriversEmocodes = {
        emocodes: [],
        drivers: [
          { DRI1: 0 },
          { DRI2: 0 },
          { DRI3: 0 },
          { DRI4: 0 },
          { DRI5: 0 },
          { DRI6: 0 },
          { DRI7: 0 },
          { DRI8: 0 },
        ],
      };
      if (planet.emotions) {
        var { emotionsArrayCodes, emotionsNames } =
          getEmotionsArraysFromPropsFromDinamo(planet.emotions);

        if (emotionsArrayCodes && emotionsArrayCodes.length > 0) {
          for (let index in emotionsArrayCodes) {
            let emocode = emotionsArrayCodes[index];
            if (emocode[1] === 0) continue;
            let planetDrivers = null;
            for (let indexMatrix in matrix_emo_driver) {
              let matrix = matrix_emo_driver[indexMatrix];
              for (let iemos in matrix.emos) {
                let emo = matrix.emos[iemos];
                if (emo.key === emocode[0]) {
                  planetDrivers = emo.drivers.map((driv) => driv * emocode[1]);
                  break;
                }
              }
              if (planetDrivers) break;
            }
            if (planetDrivers) {
              planetDriversEmocodes.emocodes.push(emocode[0]);
              planetDriversEmocodes.drivers = planetDriversEmocodes.drivers.map(
                (driver, index) => {
                  return {
                    [Object.keys(driver)[0]]:
                      driver[Object.keys(driver)[0]] + planetDrivers[index],
                  };
                }
              );
            }
          }
        }
      }
      // IF PLANETDRIVERSEMOCODES CONTAINS ALL (AND)(EVERY) SUBMITFILTERS SELECTEDDATADRIVERS
      // ADD PLANET TO ARRAY
      // IF PLANETDRIVERSEMOCODES CONTAINS ONE (OR)(SOME) SUBMITFILTERS SELECTEDDATADRIVERS
      if (planetDriversEmocodes.emocodes.length > 0) {
        if (
          planetDriversEmocodes.drivers.some(
            (driver) =>
              submitFilters.selectedDataDrivers.includes(
                Object.keys(driver)[0] + ""
              ) && driver[Object.keys(driver)[0]] > 0
          )
        ) {
          // IF PLANET NOT ALREADY IN ARRAY, ADD IT
          if (!planetIdsFilteredDrivers.includes(planet.idGame))
            planetIdsFilteredDrivers.push(planet.idGame);
        }
      }
    }
  }
  console.log(
    "GET_PLANETS_FILTERED: DRIVERS PLANETSIDSFILTERED:",
    submitFilters.selectedDataDrivers,
    planetIdsFilteredDrivers
  );

  // JOIN ALL planetIdsFiltered  BUT NOT DUPLICATES
  var finalPlanetsFiltered = [];
  finalPlanetsFiltered = [
    ...new Set([
      ...planetIdsFilteredSuperEmos,
      ...planetIdsFilteredHourRange,
      ...planetIdsFilteredDateRange,
      ...planetIdsFilteredDrivers,
      ...planetIdsFilteredLocations,
      ...planetIdsFilteredDrivers,
      ...planetIdsFilteredEmos,
      ...planetIdsFilteredTags,
      ...planetsIdsFilteredHahstags,
    ]),
  ];

  console.log(
    "GET_PLANETS_FILTERED: FINAL PLANETSIDSFILTERED:",
    finalPlanetsFiltered
  );

  return finalPlanetsFiltered;
};

const superEmos = [
  {
    key: "EMO1_0",
    text: "Open",
    value: "EMO1_0",
    description:
      "understanding, confident, friendly, connected, flexible, sympathetic",
  },
  {
    key: "EMO2_0",
    text: "Loving",
    value: "EMO2_0",
    description:
      "considerate, affectionate, sensitive, tender, warm, attracted",
  },
  {
    key: "EMO3_0",
    text: "Happy",
    value: "EMO3_0",
    description: "blissful, joyous, delighted, overjoyed, glad, thankful",
  },
  {
    key: "EMO4_0",
    text: "Interested",
    value: "EMO4_0",
    description: "fascinated, intrigued, absorbed, amazed, engrossed, curious",
  },
  {
    key: "EMO5_0",
    text: "Alive",
    value: "EMO5_0",
    description:
      "playful, courageous, energetic, liberated, optimistic, animated",
  },
  {
    key: "EMO6_0",
    text: "Positive",
    value: "EMO6_0",
    description:
      "motivated, constructive, creative, inspired, enthusiastic, brave",
  },
  {
    key: "EMO7_0",
    text: "Peaceful",
    value: "EMO7_0",
    description: "calm, good, at ease, comfortable, pleased, quiet",
  },
  {
    key: "EMO8_0",
    text: "Strong",
    value: "EMO8_0",
    description: "honest, sure, unique, complete, energized, hardy",
  },
  {
    key: "EMO9_0",
    text: "Relaxed",
    value: "EMO9_0",
    description: "glowing, radiant, unhurried, fluid, meditative, centered",
  },

  {
    key: "EMO10_0",
    text: "Angry",
    value: "EMO10_0",
    description: "irritated, enraged, hostile, insulting, frustrate, upset",
  },
  {
    key: "EMO11_0",
    text: "Depressed",
    value: "EMO11_0",
    description:
      "disappointed, discouraged, ashamed, powerless, diminished, guilty",
  },
  {
    key: "EMO12_0",
    text: "Confused",
    value: "EMO12_0",
    description: "doubtful, shy, indecisive, perplexed, embarrassed, hesitant",
  },
  {
    key: "EMO13_0",
    text: "Helpless",
    value: "EMO13_0",
    description: "incapable, alone, paralyzed, fatigued, useless, inferior",
  },
  {
    key: "EMO14_0",
    text: "Indiferent",
    value: "EMO14_0",
    description: "insensitive, dull, robotic, tired, cold, preoccupied",
  },
  {
    key: "EMO15_0",
    text: "Afraid",
    value: "EMO15_0",
    description: "fearful, terrified, nervous, anxious, alarmed, panicke",
  },
  {
    key: "EMO16_0",
    text: "Hurt",
    value: "EMO16_0",
    description: "crushed, tormented, victimized, pained, tortured, rejected",
  },
  {
    key: "EMO17_0",
    text: "Sad",
    value: "EMO17_0",
    description: "tearful, sorrowful, anguished, desolate, unhappy, lonely",
  },
  {
    key: "EMO18_",
    text: "Judmental",
    value: "EMO18_0",
    description: "stony, serious, blunt, hard, brutal, disgusted",
  },
];

const hourRanges = [
  {
    key: "earlymorning",
    text: "Early Morning",
    value: "earlymorning",
    description: "From 06:00 AM to 09:00 AM",
    range: [6, 9],
  },
  {
    key: "latemorning",
    text: "Late Morning",
    value: "latemorning",
    description: "From 9:00 AM to 12:00 AM (Noon)",
    range: [9, 12],
  },
  {
    key: "earlyafternoon",
    text: "Early Atternoon",
    value: "earlyafternoon",
    description: "From 12:00 (Noon) AM to 15:00 PM",
    range: [12, 15],
  },
  {
    key: "lateafternoon",
    text: "Late Atternoon",
    value: "lateyafternoon",
    description: "From 15:00 PM to 18:00 PM",
    range: [15, 18],
  },
  {
    key: "earlyevening",
    text: "Early Evening",
    value: "earlyevening",
    description: "From 18:00 PM to 21:00 PM",
    range: [18, 21],
  },
  {
    key: "lateevening",
    text: "Late Evening",
    value: "lateevening",
    description: "From 21:00 PM to 24:00 PM (MidNight, 0:00)",
    range: [21, 24],
  },
  {
    key: "earlynight",
    text: "Early Night",
    value: "earlynight",
    description: "From 24:00 PM (MidNight, 0:00) to 03:00 AM",
    range: [0, 3],
  },
  {
    key: "latenight",
    text: "Late Night",
    value: "latenight",
    description: "From 03:00 AM to 06:00 AM",
    range: [3, 6],
  },
];

const drivers = [
  {
    key: "DRI6",
    text: "Scarcity",
    value: "DRI6",
    description:
      "There is not enough of it for the people who need it or want it.",
  },
  {
    key: "DRI7",
    text: "Ownership",
    value: "DRI7",
    description: "The state or fact of being an owner.",
  },
  {
    key: "DRI5",
    text: "Avoidance",
    value: "DRI5",
    description: " The act of keeping away from or preventing from happening",
  },
  {
    key: "DRI4",
    text: "Unpredictability",
    value: "DRI4",
    description: "Something not predictable; not to be foreseen or foretold",
  },
  {
    key: "DRI3",
    text: "Social Influence",
    value: "DRI3",
    description:
      "A person's attitudes or behaviour are altered by some form of social communication.",
  },
  {
    key: "DRI2",
    text: "Empowerment",
    value: "DRI2",
    description:
      "The giving or delegation of power or authority; authorization",
  },
  {
    key: "DRI1",
    text: "Meaning",
    value: "DRI1",
    description:
      "The inner, symbolic, or true interpretation, value. Intending; having purpose",
  },
  {
    key: "DRI8",
    text: "Acomplishment",
    value: "DRI8",
    description: "The successful achievement of a task.",
  },
];

// COMPARE TWO OBJECTS TO SEE IF THEY ARE EQUAL

export const areEqual = (obj1, obj2) => {
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  if (typeof obj1 === "object") {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let i = 0; i < keys1.length; i++) {
      const key = keys1[i];

      if (!obj2.hasOwnProperty(key)) {
        return false;
      }

      if (!areEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  return obj1 === obj2;
};
