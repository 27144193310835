import { pricingData, plans } from './pricingService';

// export const pricingData = [
//     {
//       type: "FREE",
//       data: {
//         price: "0.00$",
//         title: "Free",
//         extra: "Free Plan For Ever",
//         feature: featureBox1,
//       },
//     },
//     {
//       type: "PRO",
//       data: {
//         price: "49.95$",
//         title: "Pro",
//         extra: "Profesional Plan: One Free Month",
//         feature: featureBox2,
//       },
//     },
//     {
//       type: "ENTERPRISE",
//       data: {
//         price: "Contact us",
//         title: "Enterprise",
//         extra: "Contact us",
//         feature: featureBox4, // email to hello@emotiony.app
//       },
//     },
//   ];
//const plans = { FREE: 0, PRO: 1, ENTERPRISE: 2 };

    
export const subscriptionTypeOptions = () => {

    // subscriptions is an array based from plans
    // subscriptions have a subScriptionExpiration (is a Date in timeStamp format)
    // subscriptions have a subScriptionType (is a string type in pricingData)
    // subscriptions have a subScriptionDuration (is a Date in timeStamp format)
    // subscription have subscriptionState (is a boolean, true if active, false if not active: default: false)
    // subscription have a userAddressId (is a string from userState.userData.userAddressId from UserContext)
    // subscription have a subscriptionCreation (is a Date in timeStamp format, null if not active)
    // subscription have a subscriptionOperation: (string, in default is "") 
    // subscription have a payImport: (string: is price from pricingData)
    // the subscription duration have a logic: days in free type, num of queries in professional type, num of queries in enterprise type
    // the subscription expiration have a logic: subscriptionCreation + subscriptionDuration
    // the subscription state have a logic: if subscriptionExpiration > Date.now() then true else false
    // plans is not an array, is an object with three keys: FREE, PRO, ENTERPRISE
    let subs= [];
    for(let plan in plans){
        let pricing= pricingData[plans[plan]].data;
        subs.push(
            {
                text: pricing.title.toLowerCase(),
                type: pricing.title.toLowerCase(),
                key: pricing.title.toLowerCase(),
                data: {
                    subScriptionExpiration: null,
                    subScriptionType: pricing.title,
                    subScriptionDuration: pricing.counterDown,
                    subscriptionState: false,
                    userAddressId: null,
                    subscriptionCreation: null,
                    subscriptionOperation: "",
                    payImport: pricing.price,
                }
            }
        )
    }
   
   // ONLY RETURN ARRAY 
   return (subs);


}


  
  
  