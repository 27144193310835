import { List } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";


console.log("LOCATIONDETAIL-COMPONENT-MODULE-INIT");

export const LocationDetail = (props) => {
  console.log("---LOCATIONDETAIL-COMPONENT-INIT---");
  const { date, location ,owner} = props;
  const [dateCard, setDateCard] = useState("");

  const setDateFunc = async (dateParse) => {
    //let dateParse = props.item.timestampEpoch;
    
    //let dateParse=props.item.requesttime;
    //if (props.item.timestampEpoch === null) { dateParse = new Date().getTime() / 1000; }
    if (dateParse === null) {
      dateParse = new Date().getTime() / 1000;
    }
    //const date = format(new Date(dateParse * 1000), 'Pp');
    let dateAux = new Date(dateParse);
    
    await setDateCard(format(dateAux, "Pp"));
    console.log("LOCATIONDETAIL-COMPONENT: DATEAUX=", dateAux, " DATEPARSE=",dateParse," DATE=",dateCard);
  };
   
 
  useEffect(()=>{
    setDateFunc(date);
  },[date]);

  return (
    <List>
      <List.Item>
        <List.Content floated='right'>
          {dateCard}
        </List.Content>
        <List.Content className="emotion__name">Date</List.Content>
      </List.Item>

      <List.Item>
        <List.Content floated='right'>
          <strong>{location}</strong>
        </List.Content>
        <List.Content className="emotion__name">Location</List.Content>
      </List.Item>
    </List>
  );
};
