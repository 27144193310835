import { textAlign } from "@mui/system";
import React from "react";
import { useContext, useReducer } from "react";
import PayperDataComponent from "./../../components/PayperData/PayperDataComponent";
import { Segment } from "semantic-ui-react";

console.log("PAYPERDATA-PAGE-MODULE-INIT");

function PayperData() {
  console.log("--PAYPERDATA-PAGE-.INIT--");

  return <PayperDataComponent />;
}

export default PayperData;
