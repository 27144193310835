import { useContext } from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import {
  webSocketLogout,
  getWsLogin,
} from "../../../services/WebSockets/wsLogin";
import { userDataDefault } from "../../../services/userDefault";
import { UserContext } from "../../../services/userContext";
import { disconnectMetaMask } from "../../../services/userWallet";

console.log("USER-MENU-COMPONENT-MODULE-INIT");

const UserMenu = (props) => {
  console.log("---USER-MENU-COMPONENT-INIT---");

  console.log(
    "PROPS ROUTES",
    props.route1,
    props.route2,
    props.route3,
    props.route4,
    props.route5,
    props.route6,
    props.logout
  );
  const [userState, dispatchUser] = useContext(UserContext);
  const handleLogout = async () => {
    if (!userState.userData.userAddressId) return;
    console.log("USER MENU: LOGOUT");
    console.log("USER MENU: CLOSING...");
    //disconnectMetaMask();
    //webSocketLogout();

    sessionStorage.clear();
    dispatchUser({ type: "SET_SESSION", payload: undefined });
    dispatchUser({ type: "SET_TYPE", payload: undefined });
    dispatchUser({ type: "SET_INITIAL_STATE", payload: userDataDefault() });

    if (getWsLogin()) getWsLogin().close(1000, "WsLogin-close-SessionExpiring");
    // PUSH ROUTE ("/")
    window.history.pushState({}, null, "/");
    console.log("USER MENU: RELOAD...");
    window.location.reload();
  };

  return (
    <>
      <Menu vertical fluid className={`${props.userVisible ? "" : "hide"}`}>
        <Menu.Item as={Link} name="My profile" to={props.route1} />

        <Menu.Item as={Link} name="My Galaxy" to={props.route2} />

        <Menu.Item as={Link} name="My Favorites" to={props.route2} />

        <Menu.Item as={Link} name="My Metrics" to={props.route4} />

        <Menu.Item as={Link} name="My PayPerData" to={props.route5} />

        <Menu.Item as={Link} name="My Queries" to={props.route6} />

        {props.logout ? (
          <Menu.Item as={Link} name="Logout" onClick={handleLogout} to={"/"} />
        ) : null}
      </Menu>
    </>
  );
};
export default UserMenu;
