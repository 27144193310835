//import { GameImages, loadImg, requestImgCatchComprobation } from './gameImages.js';
//import { clearThree } from './game.js'
import { ImgCatchImage } from "./image";

console.log("WEBSOCKET-ENGINE-COMPONENT-MODULE-INIT");

export function webSocketEngine(mode,notFoundImage,setNotFoundImage) {

    console.log("---WEBSOCKET-ENGINE-COMPONENT-INIT---");
    //var w=new WebSocket("wss://echo.websocket.org");
    var scope = this;
    scope.url = "https://planetsimagelowresolution.s3.eu-west-3.amazonaws.com/";
    scope.imgurl = null;
    //scope.catchImage = false;
    //scope.finalImage = new GameImages();
    scope.connectionId = " ";
    scope.hasConnectionId = false;
    scope.opened = false;
    scope.arraySends = [];
    scope.wMode = mode;
    
    // IMAGE NOTFOUND and SETNOTFOUND SPECIAL CASE PROPERTY

    
    scope.notFoundImage=notFoundImage;
    scope.setNotFoundImage=setNotFoundImage;


   
    if (scope.wMode.modetestServerConnection)
        scope.server = "emotionytest";
    else
        scope.server = "emotionyserver";

    scope.countsockets = 0;

   


    // DISPOSING CONTROL
    scope.disposing = false;

    scope.connect = function () {
        if (!scope.disposing) {
            scope.countsockets++;
            //console.log("@@@@ COUNT SOCKET CONNECTIONS:"+scope.countsockets);
            if (scope.wMode.modetestServerConnection) {
                scope.w = new WebSocket("wss://bvjtb5hjne.execute-api.eu-west-3.amazonaws.com/test");
            } else {
                scope.w = new WebSocket("wss://2i6mynw13k.execute-api.eu-west-3.amazonaws.com/v1");
            }
        }

        scope.w.onopen = function (event) {
            if (!scope.disposing) {
                //console.log("ON_OPEN:",event, "COUNT CONECTION SOCKET="+scope.countsockets);
                scope.opened = true;
                scope.sendRaw('{"sendgame": "'
                    + scope.server
                    + '", "game": "HELLO_INTERNAL_SERVER_ERROR_TO_OBTAIN_CONNECTION-ID"}'
                );
                if (scope.arraySends.length > 0) {
                    for (let i = 0; i < scope.arraySends.length; i++) {
                        var send = scope.arraySends[i];
                        scope.w.send(send);
                        // console.log("ARRAY SENDING DATA...",send);

                        //document.write("ARRAY SENDING DATA..",send);
                        //document.write("</br>");
                        //document.write("</br>");     
                    }
                    scope.arraySends.length = 0;

                }
                /*
                scope.onopenTimeout = setTimeout(function () {
                    scope.w.close();
                }, 1500000);
                */
            }
        }

        scope.w.onclose = function (event) {
            console.log("CLOSING CONECTION",scope.connectionId)
            console.log("EVENT=",event);
            
            /*
            if (!scope.disposing) {
                scope.opened = false;
                console.log('Socket is closed. Reconnect will be attempted in 1 second.', event.reason);
                scope.oncloseTimeout = setTimeout(function () {
                    scope.connect();
                }, 1000);
            } else {
                scope.opened = false;
                // console.log('Closing and dispose');
                clearTimeout(scope.oncloseTimeout);
                scope.w = null;
                scope.url = null;
                scope.imgurl = null;
                scope.catchImage = null;
                scope.finalImage = null;
                scope.connectionId = null;
                scope.hasConnectionId = null;
                scope.opened = null;
                scope.arraySends = null;
                scope.wMode = null;
                scope.countsockets = null;
                scope = null;
            }
            */
            
            
        }

        scope.w.onmessage = function (event) {
            if (!scope.disposing) {
                console.log("ON_MESSAGE, receive:")
                console.log("EVENT",event);

                //document.write("EVENT DATA:",event.data);
                //document.write("</br>")
                //document.write("</br>"); 

                // console.log("PARSING in DRECEIVE");
                var dreceive = JSON.parse(event.data);
                
                // console.log(dreceive);
                if (event.data.includes("undefined sent to engine and DB")) {
                    // console.log("SENT CATCHING");
                    scope.wMode.notVideos = true;
                    console.log("SENDING IMAGE TO: "+scope.imgurl);
                    //scope.catchImage = true;
                

                  
                   
                    
                    ImgCatchImage(scope.setImgPlanet,scope.imgurl,scope);
                    //scope.w.close();

                    

                }

                if (scope.hasConnectionId && event.data.includes("Internal server error")) {
                    console.log("SERVER PROBABLY IS DOWN!",event);

                    setNotFoundImage(true);
                    scope.w.close(); 

                }



                if (!scope.hasConnectionId && event.data.includes("connectionId")) {
                    // console.log("CONNECTION_ID_CATCHING:");
                    scope.connectionId = dreceive.connectionId;
                    // console.log("ConnectionId=",scope.connectionId);
                    
                    

                    scope.hasConnectionId = true;
                    scope.data.generalData.emoViewConnectionID=scope.connectionId;
                    scope.send(scope.data);
                   

                }


                //document.write("DRECEIVE:",dreceive);
                //document.write("</br>");
                //document.write("</br>");  
            }

        }

        scope.w.onerror = function (error) {
            if (!scope.disposing) {
                console.log("[error] ", error.message);
                scope.w.close();
                //document.write("[error]",error.message);
                //document.write("</br>");
                //document.write("</br>"); 
            }
        }
    }


    scope.send = function (data) {
        if (!scope.disposing) {
            var send = '{ "sendgame" : "' + scope.server + '", "game" : ' + JSON.stringify(data) + '}';
            if (!scope.opened) scope.arraySends.push(send);
            else {
                scope.w.send(send);
                 console.log("SENDING DATA...",send); 
                // RECEIVE IMAGE           

                //document.write("SENDING DATA...",send);
                //document.write("</br>");
                //document.write("</br>"); 
            }
        }
    }

    scope.sendRaw = function (send) {
        if (!scope.disposing) {
            if (!scope.opened) scope.arraySends.push(send);
            else {
                scope.w.send(send);
                // console.log("SENDING DATA...",send);

                //document.write("SENDING DATA...",send);
                //document.write("</br>");
                //document.write("</br>"); 
            }
        }
    }

    scope.sendrecharge = function () {
        if (!scope.disposing) {
            var send = '{"sendgame":"emotionyrecharge", "game":{"emotions":["RECHARGE"],"generalData":{"email":"recharge@email.com","date":"RECHARGE","geoloc":{"accuracy":0,"latitude":0,"longitude":0},"gameId":"RECHARGERECHARGE","deviceId":"RECHARGE DEVICE","ip":"RECHARGE","username":"RECHARGE"}}}';
            if (!scope.opened) scope.arraySends.push(send);
            else {
                scope.w.send(send);
                // console.log("SENDING DATA...",send);

                //document.write("SENDING DATA...",send);
                //document.write("</br>");
                //document.write("</br>"); 
            }
        }
    }
/*
    scope.catchImagef = function () {
        if (!scope.disposing) {
            //loadImg(scope.finalImage, scope.imgurl);
        }
    }

    scope.requestCatchImagef = function () {
        if (!scope.disposing) {
            return "img"; //requestImgCatchComprobation(scope.imgurl);
        }
    }
*/
    scope.dispose = function () {
        // console.log("CLEARING FINAL IMAGE")
        //clearThree(scope.finalImage);
        scope.disposing = true;
        clearTimeout(scope.onopenTimeout);
        // console.log("WEB SOCKET ENGINE DISPOSE FUNCTION");
        if (scope.w.close) scope.w.close();

    }

    scope.set = function (data){
        scope.data=data;
        
    }

    scope.connect();




}