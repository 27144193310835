import React from "react";

import Highcharts from "highcharts";
import HighchartsMap from "highcharts/modules/map";
import HighchartsReact from "highcharts-react-official";
import map from "@highcharts/map-collection/custom/world.geo.json";
// import DarkUnica from "highcharts/themes/dark-unica";

import { useContext, useEffect, useState } from "react";
import { Super_Emos, Drivers } from "../../../core/Utils.js";

import { Card } from "semantic-ui-react";
// MODULO DE EXPORTACION HIGHCHARTS
import HighChartExport from "highcharts/modules/exporting";
HighChartExport(Highcharts);

//HcMore(Highcharts);
// DarkUnica(Highcharts);
HighchartsMap(Highcharts);

var mapData = [
  {
    code3: "AND",
    z: 105,
    code: "AD",
  },
  {
    code3: "USA",
    z: 105,
    code: "US",
  },
];

var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

function createOptions(mapData) {
  return {
    chart: {
      map: map,
    },
    title: {
      text: "Planets & Polarity",
    },
    subtitle: {
      //text: "Games by location"
    },
    legend: {
      enabled: true,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Countries",
        color: "#6684A7",
        enableMouseTracking: true,
      },
      {
        type: "mapbubble",
        name: "Planets",
        color: "#CC1264",
        joinBy: ["iso-a3", "code3"],
        data: mapData,
        minSize: 4,
        maxSize: "12%",
        tooltip: {
          pointFormat: "{point.properties.hc-a2}: {point.z} ",
        },
      },
    ],
  };
}

function Map(props) {
  const { metricsState } = props;

  //console.log("MAPDATA",mapData);
  const [options, setOptions] = useState(null);

  var newMapData = [];

  useEffect(() => {
    if (
      metricsState.metricResults &&
      metricsState.metricResults.TopLocationsAll != null
    ) {
      for (
        let i = 0;
        i < metricsState.metricResults.TopLocationsAll.length;
        i++
      ) {
        let l = metricsState.metricResults.TopLocationsAll[i];
        let country = "";
        let city = "";

        let num = l.num;

        console.log(l);
        let sl = l.location.split(",");
        if (sl.length > 2) {
          //console.log(sl);
          country = sl[1].trim();
          city = sl[3].trim();
        } else {
          country = sl[1].trim();
          city = sl[0].trim();
        }

        let code = countries.getAlpha3Code(country, "en");
        let code2 = countries.getAlpha2Code(country, "en");
        console.log(
          "[" +
            city +
            "] [" +
            country +
            "] [" +
            code +
            "] [" +
            code2 +
            "] [" +
            num +
            "]"
        );
        if (newMapData.length === 0) {
          newMapData.push({ code3: code, z: num, code: code2 });
        } else {
          let found = 0;
          for (let k = 0; k < newMapData.length; k++) {
            if (newMapData[k].code3 === code) {
              newMapData[k].z += num;
              found = 1;
            }
          }
          if (found === 0) {
            newMapData.push({ code3: code, z: num, code: code2 });
          }
        }
      }
      console.log("NEWMAPDATA:", newMapData);
      setOptions(createOptions(newMapData));
    }
  }, [metricsState.metricResults]);

  return (
    <Card fluid>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        constructorType={"mapChart"}
      />
    </Card>
  );
}
export default Map;
