import PayperDataMetricsComponent from "./../../components/PayperData/PayperDataMetricsComponent";
import UserHeader from "../../components/User/UserHeader";
import { useEffect, useState, useContext } from "react";
import { Button, Icon, Segment } from "semantic-ui-react";
import { UserContext } from "../../services/userContext";
import { useHistory } from "react-router-dom";
import SubscriptionComponent from "../../components/MyProfile/SubscriptionComponent";
import { PayPerDataContext } from "../../services/payperDataContext";

console.log("PAYPERDATA-METRICS PAGE-MODULE-INIT");

function PayPerDataMetrics(props) {
  const [userState, dispatchUser] = useContext(UserContext);
  const [metricsState, dispatchMetrics] = useContext(PayPerDataContext);
  // props.props.match.params.metricsId.replace(/\./g, "%");

  const history = useHistory();

  console.log("--PAYPERDATA-METRICS-PAGE-.INIT--", props);

  const handleClickBack = () => {
    history.push("/queries");
  };

  const handleSubscription = (subs) => {
    console.log("PAYPERDATA-METRICS-PAGE-SUBSCRIPTION= ", subs);
    dispatchMetrics({ type: "SET_SUBSCRIPTION", payload: subs });
    return subs;
  };

  useEffect(() => {
    if (metricsState.subscription) {
      console.log(
        "PAYPERDATA-METRICS-PAGE - SUBSCRIPTION - SETSUBSCRIPTION",
        metricsState.subscription
      );
    }
  }, [metricsState.subscription]);

  return (
    <>
      <UserHeader
        title={
          <>
            {/* <Button icon circular onClick={handleClickBack}>
              <Icon name="arrow left" size={"tiny"} />
            </Button> */}
            PAYPERDATA METRICS
          </>
        }
        userData={userState.userData}
      />
      <Segment
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button onClick={handleClickBack}>MY QUERIES</Button>
      </Segment>

      <Segment>
        <SubscriptionComponent
          mode="payperdata"
          user={userState.userData}
          _setsubscription={handleSubscription}
        />
      </Segment>
      <PayperDataMetricsComponent
        props={props}
        subscription={metricsState.subscription}
      />
    </>
  );
}

export default PayPerDataMetrics;
