import React from "react";

import Highcharts from "highcharts/highstock";
import HcMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
// import DarkUnica from "highcharts/themes/dark-unica";

import { useContext, useEffect, useState } from "react";
import { Super_Emos, Drivers } from "../../../core/Utils.js";
import { CompressedTextureLoader } from "three";
import { Card } from "semantic-ui-react";
// MODULO DE EXPORTACION HIGHCHARTS
import HighChartExport from "highcharts/modules/exporting";
HighChartExport(Highcharts);



HcMore(Highcharts);
// DarkUnica(Highcharts);

function createOptions(dataDriverSeries) {
  return {
    series: dataDriverSeries,

    chart: {
      polar: true,
      type: "column",
    },

    title: {

      text: "What is behind your emotions?"
    },

    subtitle: {
      //text: "Emotional drivers By Super-Emo and polarity"

    },
    legend: {
      align: "center",
      horizontalAlign: "down",
      layout: "horizontal",
    },

    xAxis: {
      tickmarkPlacement: "on",
      type: "category",
    },

    yAxis: {
      min: 0,
      endOnTick: false,
      showLastLabel: true,

      labels: {
        formatter: function () {
          return this.value;
        },
      },
      reversedStacks: true,
    },
    /* resource: {
        files: "http://remedio.fr/js/themes/dark-unica.js"
  }, */

    tooltip: {
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yValue:.2f}</b><br/>',
    },

    plotOptions: {
      series: {
        stacking: "normal",
        shadow: false,
        groupPadding: 0,
        pointPlacement: "on",
      },
    },
    credits: {
      enabled: false,
    },
  };
}

function DriversDetailWindRose(props) {
  const { metricsState } = props;

  var dataDriverSeries = [
    {
      name: "EMO1",
      data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 
      ],
      _colorIndex: 5,
    },
    {
      name: "EMO2",
      data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 

      ],
      _colorIndex: 5,
    },
    {
      name: "EMO3",
      data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 

      ],
      _colorIndex: 5,
    },
    {
      name: "EMO4",
      data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 

      ],
      _colorIndex: 5,
    },
    {
      name: "EMO5",
      data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 

      ],
      _colorIndex: 5,
    },
    {
      name: "EMO6",
      data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 
      ],
      _colorIndex: 5,
    },
    {
      name: "EMO7",
      data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 
      ],
      _colorIndex: 5,
    },
    {
      name: "EMO8",
      data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 
      ],
      _colorIndex: 5,
    },
    {
      name: "EMO9",
      data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 
      ],
      _colorIndex: 5,
    },
    {
      name: "EMO10",
      data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 

      ],
      _colorIndex: 9,
    },
    {
      name: "EMO11",
            data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 
      ],
      _colorIndex: 9,
    },
    {
      name: "EMO12",
            data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 

      ],
      _colorIndex: 9,
    },
    {
      name: "EMO13",
            data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 

      ],
      _colorIndex: 9,
    },
    {
      name: "EMO14",
            data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 
      ],
      _colorIndex: 9,
    },
    {
      name: "EMO15",
            data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 
      ],
      _colorIndex: 9,
    },
    {
      name: "EMO16",
            data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 
      ],
      _colorIndex: 9,
    },
    {
      name: "EMO17",
            data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 
      ],
      _colorIndex: 9,
    },
    {
      name: "EMO18",
            data: [
        ["Acomplishment", 0],
        ["Meaning", 0],
        ["Empowerment", 0],
        ["Social Influence", 0],
        ["Unpredictability", 0],
        ["Avoidance", 0],
        ["Scarcity", 0],
        ["Ownership", 0] 
      ],
      _colorIndex: 9,
    },
  ];

  const [options, setOptions] = useState(createOptions(dataDriverSeries));

  useEffect(() => {
    if (
      metricsState.metricResults &&
      metricsState.metricResults.TotalGamesByDriverAndSuperEmo
    ) {
      for (let i = 0; i < dataDriverSeries.length; i++) {
        let emodata = dataDriverSeries[i].data;
        //console.log("EMODATA",emodata);
        for (
          let j = 0;
          j < metricsState.metricResults.TotalGamesByDriverAndSuperEmo.length;
          j++
        ) {
          let dse = metricsState.metricResults.TotalGamesByDriverAndSuperEmo[j];
          //console.log("DSE=",dse);
          for (let k = 0; k < Drivers.length; k++) {
            let d = Drivers[k];
            //console.log("DRIVER",d);
            if (d.key === dse.driver) {
              //console.log("D.key[",k,"]:",d.key," === ",d.text);
              for (let m = 0; m < emodata.length; m++) {
                if (d.text === emodata[m][0]) {
                  //console.log("EMODATA(",m,")=",d.text," = dse.semos[",i,"]=",dse.semos[i]);
                  emodata[m][1] = dse.semos[i];
                }
              }
            }
          }
        }
      }
      console.log("FINAL DATADRIVERSERIES=", dataDriverSeries);
      // CONVERT SUPER EMO "EMOx" to REAL NAME SUPER EMOTION (text)
      for (let i = 0; i < dataDriverSeries.length; i++) {
        let e = dataDriverSeries[i].name;
        dataDriverSeries[i].name = (Super_Emos[e.split("O")[1]-1]).text;
      }

      setOptions(createOptions(dataDriverSeries));
    }
  }, [metricsState.metricResults]);

  return (
    <Card fluid>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Card>
  );
}
export default DriversDetailWindRose;
