import {createContext} from 'react';


console.log("PAYPERDATACONTEXT-MODULE-INIT");

export const PayPerDataContext = createContext();


var metricFilters={
    hashtags:[],
    superEmos:[],
    drivers:[],
    dateRange:{init:null,end:null},
    hoursRange:[],
    locations:[],
    planetids:[]
}

var metricResults=null;
var metricDataHash=[];


var payperDataState="nothing";
var effectiveFilters={
    hashtags:[],
    superEmos:[],
    drivers:[],
    dateRange:{init:null,end:null},
    hoursRange:[],
    locations:[],
    planetids:[]
}

// metricDataHash data structure:
// {
//     hash:hash,
//     query:queryResp,
//     metricFilters:metricsState.metricFilters,
//     metricResults:metrics
// }

export const PayperDataInitialState={
    metricFilters:metricFilters,
    metricResults:metricResults,
    metricDataHash:metricDataHash,
    payperDataState,
    effectiveFilters,
    queries:[],
    subscription:null
};
