import "./Marketplace.scss";
import MarketPlaceHeader from "../../components/MarketPlace/MarketPlaceHeader";
import { userData, userService } from "../../services/userParams";
import React, { useEffect, useState, useContext } from "react";
import MarketplaceComponent from "../../components/MarketPlace/MarketPlaceComponent";
import { EmoContext } from "../../services/globalContext";
import {UserContext} from "../../services/userContext";
import { Grid, Segment } from "semantic-ui-react";
import { AllFiltersComponent } from "../../components/Filters/allFiltersComponent";
import {
  getPlanetFilter,
  getPlanetsFiltered,
} from "../../services/planetFilter";

console.log("MARKETPLACE-PAGE-MODULE-INIT");

const Marketplace = () => {
  console.log("---MARKETPLACE PAGE INIT---");
  const [userState,dispatchUser] = useContext(UserContext);
  const [globalState, dispatchGlobal] = useContext(EmoContext);

  var [dataToFilters, setDataToFilters] = useState(null);
  var [submitFilter, setSubmitFilter] = useState(null);
  var [itemsPlanets, setItemsPlanets] = useState(null);
  const [userData,setUserData]=useState(null);
  const[selectedButtonM,setSelectedButtonM]=useState(null);


  console.log("MARKETPLACE PAGE,GLOBALSTATE", globalState);
  
  
  
  useEffect(() => {
    window.scrollTo(0, 0);
    setUserData(userState.userData);
  }, [userState.userData]);

  useEffect(() => {
    if (submitFilter && itemsPlanets) {
      console.log("MARKETPLACE PAGE: SUBMIT FILTER:");
      console.log(submitFilter);
      let idPlanets = getPlanetsFiltered("MarketPlace",submitFilter, itemsPlanets);
      // SETTING IN REDUCER userAddressId,submitFilters,and idPlanets at GLOBALSTATE
      // FILTERPLANET FORMAT:
      // {type:type, userAddressId: null, filter: null, items: [], firstElement:firstElement  })
      // PREVENTS USERDATA IS NULL
      // ITEMS IS AN COMPLEX OBJECT FROM IDPLANETS
      
      if (idPlanets.length>0) {
        let items=[];
        for(let index in idPlanets){
          let item={
            idGame:idPlanets[index]
          }
          items.push(item);
        }

        let complexObject={
          type: "MarketPlace",
          userAddressId:
            userState.userData && userState.userData.userAddressId
              ? userState.userData.userAddressId
              : null,
          filter: submitFilter,
          items: items,
          firstElement:0,
        };
        dispatchGlobal({
          type: "SET_FILTER_PLANETS",
          payload: complexObject,
          other: setSelectedButtonM
        });
      }
    }
  }, [submitFilter]);

  useEffect(() => {
    if (globalState.marketPlacePlanetResults.data) {
      let items =
        globalState.marketPlacePlanetResults.data.listMasterGames.items;
      
      setItemsPlanets(items);
      
      if(!globalState.itemsPlanetsMarketPlace){
        dispatchGlobal({ type: "SET_ITEMS_PLANETS_MARKETPLACE", payload: items });
      }

      let hashtags = [];
      let locations = [];
      let tags = [];
   

      function getUniqueData(items, field) {
        const uniqueData = new Set();
      
        items.forEach(item => {
          if(item[field]===null) return;
          item[field].split(",").forEach(data => {
            const trimmedData = data.trim();
            if (trimmedData !== "") {
              uniqueData.add(trimmedData);
            }
          });
        });
      
        return Array.from(uniqueData);
      }
      

      for (let index in items) {
        let item = items[index];
        if (item.locationFilter) {
          if(locations.indexOf(item.locationFilter)===-1){
            locations.push(item.locationFilter);
          }
        }
        
      }

      hashtags = getUniqueData(items, "hashtags");
      tags = getUniqueData(items, "tagsFilter");

      console.log("MARKETPLACE PAGE: hashtags=", hashtags);
      console.log("MARKETPLACE PAGE: locations=", locations);
      console.log("MARKETPLACE PAGE: tags=", tags);

      let data = {
        hashtags: hashtags,
        currentLocations: locations,
        tags: tags,
      };

      let dfilters = getPlanetFilter(data);
      console.log("MARKETPLACE PAGE: FILTERS = ", dfilters);
      setDataToFilters(dfilters);
    }
  }, [globalState.marketPlacePlanetResults]);

  return (
    <div className="marketplace">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment textAlign="center" className="noborder">
              <MarketPlaceHeader userData={userData} />
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment textAlign="left">
              <AllFiltersComponent
                selectedButton={selectedButtonM}
                setSelectedButton={setSelectedButtonM}
                titleHeader="MarketPlace Filters"
                filterData={dataToFilters}
                submitFilter={submitFilter}
                setSubmitFilter={setSubmitFilter}

              />
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <MarketplaceComponent 
              userData={userData} 
              selectedButton={selectedButtonM}
              setSelectedButton={setSelectedButtonM}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Marketplace;
