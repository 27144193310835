import React from "react";
import Hashtags from "./Filters/FilterHashTags";
import SuperEmos from "./Filters/FilterSuperEmos";
import Drivers from "./Filters/FilterDrivers";
import LocationsGames from "./Filters/FilterLocationsGames";
import DatesGames from "./Filters/FilterDatesGames";
import HoursGames from "./Filters/FilterHoursGames";
import { Message, Form, Button } from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import { MetricsContext } from "../../services/metricsContext.js";
import {
  prepareFilters,
  prepareMetricsQuery,
  getMetrics,
} from "../../services/Graphql/Game/queryMetrics";
import { UserContext } from "../../services/userContext.js";
import { PulseLoader } from "react-spinners";
import { button } from "leva";

console.log("METRICS-FILTERS-COMPONENT-MODULE-INIT");

function Filters() {
  console.log("---METRICS-FILTERS-COMPONENT-INIT----");

  var MD5 = require("md5.js");

  const [metricsState, dispatchMetrics] = useContext(MetricsContext);
  const [userState, dispatchUser] = useContext(UserContext);
  const [user, setUser] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const submitFilters = async () => {
    console.log("FILTER: SUBMITFILTERS");
    setSubmitting(true);
    if (user && user.userAddressId) {
      console.log("FILTER: EFFECTIVE_FILTERS:", metricsState.effectiveFilters);
      var filter = prepareFilters(
        metricsState.effectiveFilters.dateRange.init,
        metricsState.effectiveFilters.dateRange.end,
        metricsState.effectiveFilters.drivers,
        metricsState.effectiveFilters.hashtags,
        metricsState.effectiveFilters.locations,
        metricsState.effectiveFilters.superEmos,
        metricsState.effectiveFilters.hoursRange
      );
      console.log("FILTER: FILTER", filter);

      let queryResp = prepareMetricsQuery(user.userAddressId, 10, filter);
      console.log("FILTER: QUERYRESP", queryResp);

      var md5stream = new MD5();
      md5stream.end(queryResp);
      var hash = md5stream.read(queryResp).toString("hex");

      var comprobationHash = await comprobateHash(hash);
      console.log("FILTER: COMPROBATIONHASH", comprobationHash);

      var rmetrics = null;
      var metrics = null;

      if (comprobationHash.result === null) {
        rmetrics = await getMetrics(queryResp);
        console.log("FILTER: METRICS RESULT=", rmetrics);
        metrics = JSON.parse(rmetrics.data.getGameMetrics.metricsGame);
        console.log("FILTER: METRICS FROMJSON", metrics);
        dispatchMetrics({
          type: "SET_METRIC_DATAHASH",
          payload: {
            hash: hash,
            query: queryResp,
            metricFilters: metricsState.metricFilters,
            metricResults: metrics,
          },
        });
      } else {
        metrics = comprobationHash.result;
        console.log(
          " FILTER: >>>>>> DIRECT >>>>>> COMPROBATIONHASH HMETRICS=",
          metrics
        );
      }

      await dispatchMetrics({ type: "SET_METRIC_RESULTS", payload: metrics });
      var  elem= document.getElementById("totaldatehourbox");
      window.scrollTo({
        top:  elem?(elem.offsetTop + elem.offsetHeight):1200,
        behavior: 'smooth',
      })
      setSubmitting(false);
      
    }
  };

  const comprobateHash = async (hash) => {
    console.log(
      "COMPROBATEHASH: metricState=",
      metricsState,
      " metricState.metricDataHash=",
      metricsState.metricDataHash,
      " metricsState.metricDataHash.length   =",
      metricsState.metricDataHash.length
    );
    if (metricsState.metricDataHash) {
      for (let i = 0; i < metricsState.metricDataHash.length; i++) {
        console.log(
          "COMPROBATE HASH:" +
            hash +
            "METRICSTATE[" +
            i +
            "]=" +
            metricsState.metricDataHash[i].hash
        );
        if (metricsState.metricDataHash[i].hash === hash) {
          return { result: metricsState.metricDataHash[i].metricResults };
        }
      }
    }
    return { result: null };
  };

  useEffect(() => {
    if (userState.userData) {
      console.log("FILTER: USERSTATE:", userState);
      setUser(userState.userData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.userData]);

  useEffect(() => {
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Message
        attached
        header="Filters"
        content="Filter your planets and games by the next selections"
      />

      <Form className="attached fluid segment" id="form">
        <Form.Field>
          <Hashtags />
        </Form.Field>

        <Form.Field>
          <SuperEmos />
        </Form.Field>

        <Form.Field>
          <Drivers />
        </Form.Field>

        <Form.Field>
          <DatesGames />
        </Form.Field>

        <Form.Field>
          <HoursGames />
        </Form.Field>

        <Form.Field>
          <LocationsGames />
        </Form.Field>

        <Button
          id="submitFilters"
          onClick={submitFilters}
          fluid
          size="big"
          className="filled mt--double"
        >
          {submitting ? (
            <>
              <div
                className="sweet-loading"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <PulseLoader
                  size={15}
                  margin={2}
                  color={"#36D7B7"}
                  loading={submitting}
                />
              </div>
            </>
          ) : (
            <>Submit</>
          )}
        </Button>
      </Form>
    </div>
  );
}

export default Filters;
