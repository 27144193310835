import { Grid, Segment, Header, Card } from "semantic-ui-react"
import logo from "../../shared/assets/images/logo-emotiony2.png";
import { SimpleCardBox } from "../Pricing/Box";
import {plans,pricingData} from "../../services/pricingService";

// Set one unique item in the selection list of plans.
export const PlanProfile = (props) => {
  const planValues=Object.values(plans)
  const handlebutton=(event)=>{
    console.log("PLANPROFILE: BUTTON CLICKED",event)
    console.log("PLANPROFILE: BUTTON ID",event.target.id)
  }
  // // Desactivar la advertencia de tiempo de ejecución del manejador de eventos de clic
  // console.warn = (...args) => {
  //     const message = args[0];
  //     if (typeof message === "string" && message.includes("'click' handler took")) {
  //     return;
  //     }
  //     console.warn(...args);
  // };

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Header as="h2">Plans</Header>

              <Card.Group>
                {planValues.map((plan) =>{
                  return(
                    <SimpleCardBox
                      price={pricingData[plan].data.price}
                      title={pricingData[plan].data.title}
                      extra={pricingData[plan].data.extra}
                      feature={pricingData[plan].data.feature}
                      handlebutton={handlebutton}
                    />
                  )}
                )}
              </Card.Group>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>        
    </>
  )
}