import React from "react";
// Import Highcharts
import Highcharts from "highcharts";
import wordCloud from "highcharts/modules/wordcloud.js";
//import HighchartsReact from "./HighchartsReact.js";
import HighchartsReact from "highcharts-react-official";
// import DarkUnica from "highcharts/themes/dark-unica";

import { useContext, useEffect, useState } from "react";
import { ConsoleLogger } from "@aws-amplify/core";

import { Card } from "semantic-ui-react";
// MODULO DE EXPORTACION HIGHCHARTS
import HighChartExport from "highcharts/modules/exporting";
HighChartExport(Highcharts);

// DarkUnica(Highcharts);
wordCloud(Highcharts);

Highcharts.seriesTypes.wordcloud.prototype.deriveFontSize = function (
  relativeWeight
) {
  var maxFontSize = 55;
  // Will return a fontSize between 0px and 25px.
  return Math.floor(maxFontSize * relativeWeight);
};

function createData(text,values) {
  var lines = text.split(/[, ]+/g);
  var lines = lines.filter((e) => e.length > 1);
  
  console.log("EMOCLOUD:lines",lines);
  var maxValueInValues=1;
  if(values) maxValueInValues=values.reduce((a,b)=>Math.max(a,b));
  console.log("EMOCLOUD:maxValueInValues",maxValueInValues);
  return Highcharts.reduce(
    lines,
    function (arr, word) {
      var obj = Highcharts.find(arr, function (obj) {
        return obj.name === word;
      });
      if (obj) {
        obj.weight += 1;
      } else {
        let index=lines.indexOf(word);
        let factor=1;
        let w = 0;
        if(index>=0 && values){
          factor=values[index];
          w = Math.ceil(250 * (factor/2) / maxValueInValues);
          //w = Math.ceil(Math.random() * 250);
          console.log("EMOCLOUD:factor",factor, " WORD:",word, " WEIGHT:",w," INDEX:",index);
        }


        obj = {
          name: word,
          weight:  w
        };
        if(w!==0 && factor>0) arr.push(obj);
      }
      return arr;
    },
    []
  );
}

function createState(data) {
  return {
    title: {
      text: "Emotions-Cloud Top-15",
    },
    subtitle: {
      text: "List of top emotions",
    },
    series: [
      {
        type: "wordcloud",
        data: data,
      }
      ],
      credits: {
        enabled: false
      }
    }
  

}

// Render app with demo chart
function EmotionsCloud(props) {
  const { metricsState } = props;

  var data = createData("");
  const [options, setOptions] = useState(createState(data));

  useEffect(() => {
    let newdata = "";
    let newvalues = [];
    if (metricsState.metricResults && metricsState.metricResults.TopEmoCloud) {
      for (let i = 0; i < metricsState.metricResults.TopEmoCloud.length; i++) {
        let e = metricsState.metricResults.TopEmoCloud[i].emoname;
        let value=metricsState.metricResults.TopEmoCloud[i].points;
        newdata = newdata + e + " ";
        newvalues.push(value);
      }
      console.log("EMOCLOUD:", newdata,newvalues);

      setOptions(createState(createData(newdata,newvalues)));
    }
  }, [metricsState.metricResults]);

  return (
    <Card fluid>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Card>
  );
}
export default EmotionsCloud;
