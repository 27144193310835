import Amplify, { API, graphqlOperation } from 'aws-amplify';
import awsconfig from '../../../core/a-cf';
import { getGameById } from './queryGame';

Amplify.configure(awsconfig);

console.log("UPDATEGAME-MODULE-INIT");

const updateDesc = (gameId, planetOperation, description) => {
    return `
    mutation MyMutation {
        updateGame(input: { planetOperation: "${planetOperation}",
                            idGame: "${gameId}", 
                            description: "${description}"}) {
        description
        idGame
        }
    }
    `
}
export const updateDescription = async (gameId, planetOperation, description) => {

    const game = await API.graphql(
        graphqlOperation(
            updateDesc(gameId, planetOperation, description)
        ));

    return game;
}

const updateFavInGame = (gameId, planetOperation, favorites) => {
    
    let favs = ``;
    //favorites.map(h => {
    //    return favs = favs.concat(`"${h}"`);
    //});
   
    favs=favs+`"`
    
    for(let i=0;i<favorites.length;i++){
        let f=favorites[i];
        favs=favs+`${f}`;
        if(i<(favorites.length-1)){
            favs=favs+",";
        }
    }
    
    favs=favs+`"`
    
    const mut= `
    mutation MyMutation {
        updateGame(input: {
            planetOperation: "${planetOperation}",
            idGame: "${gameId}", 
            favorites: ${favs} }) {
        favorites
        idGame
        }
    }
    `
    console.log("updateFavInGame MUTATION",mut);
    return mut;
}

const updateFavInUser = (gameId, planetOperation, favorites,userId,currentFavoritePlanetIds,currentFavoritePlanetIdsCount) => {
    let favs = ``;
  
    favs=favs+`"`
    
    for(let i=0;i<currentFavoritePlanetIds.length;i++){
        let cf=currentFavoritePlanetIds[i];
        favs=favs+`${cf}`;
        if(i<(currentFavoritePlanetIds.length-1)){
            favs=favs+",";
        }
    }
    favs=favs+`"`
    
    const mut= `
    mutation MyMutation {
        updateUser(input: {
            userOperation: "userSummary",
            userAddressId: "${userId}", 
            currentFavoritePlanetIds: ${favs},
            currentFavoritePlanetIdsCount: ${currentFavoritePlanetIdsCount}
        }) {
        userAddressId
        userOperation
        currentFavoritePlanetIds
        currentFavoritePlanetIdsCount
        }
    }
    `
    console.log("updateFavInUser MUTATION",mut);
    return mut;
}

export const updateFavorite = async (gameId, planetOperation,favorites,userId,currentFavoritePlanetIds,currentFavoritePlanetIdsCount) => {

    if(gameId){
        const favInGame = await API.graphql(
            graphqlOperation(
                updateFavInGame(gameId, planetOperation, favorites)
            ));
        console.log("FAV IN GAME RETURN",favInGame);
    }
    if(userId){    
        const favInUser=await API.graphql(
            graphqlOperation(
                updateFavInUser(gameId, planetOperation, favorites,userId,currentFavoritePlanetIds,currentFavoritePlanetIdsCount)
            ));
        console.log("FAV IN USER RETURN",favInUser);
    }
    //return favInGame;
}

export const favCheck = async (
    liked, 
    gameId, 
    userId,
    currentFavoritePlanetIds,
    currentFavoritePlanetIdsCount,
    favorites,
    planetOperation
    ) => {
    let changefav=false;
    console.log("FAV CHECK  FAVORITES",`"${favorites}"`);
    if(favorites){
        console.log("FAV CHECK FAVORITES.length", favorites.length);
    }

    let posGame,posSummary;
    if(userId && favorites) posGame = favorites.indexOf(userId);
    
    if(gameId && currentFavoritePlanetIds) posSummary = currentFavoritePlanetIds.indexOf(gameId);

    let changeFav=false;

    if(userId && liked && favorites && posGame === -1){
        favorites.push(userId);
        if(gameId && liked && currentFavoritePlanetIds && posSummary === -1){
            currentFavoritePlanetIds.push(gameId);
            currentFavoritePlanetIdsCount=currentFavoritePlanetIds.length;
            changeFav=true;
        }
    }
    

    if(userId && !liked && favorites && posGame !== -1){
        favorites.splice(posGame, 1);
        if(gameId && !liked && currentFavoritePlanetIds && posSummary !== -1){
            currentFavoritePlanetIds.splice(posSummary, 1);
            currentFavoritePlanetIdsCount=currentFavoritePlanetIds.length;
            changeFav=true;
        }
    }

    

    if (changeFav) //&& game.favorites.indexOf(userId) === -1)
    {
        
        console.log("UPDATE GAME: GAMEID",gameId," PLANET OPERATION",planetOperation," FAVORITES",favorites);
        await updateFavorite(gameId, planetOperation, favorites,userId,currentFavoritePlanetIds,currentFavoritePlanetIdsCount);
    }
/*  
   // if (gameId) {
    //     const gameData = await getGameById(gameId);
    //     const game = gameData.data.getMasterGameById;
    //     console.log("FAV CHECK  FAVORITES",`"${game.favorites}"`);
    //     console.log("FAV CHECK FAVORITES.length", game.favorites.length);
        
    //     if (
    //         liked
    //         && (
    //             (game.favorites) ||
    //             (
    //                 game.favorites && game.favorites.length>0 &&
    //                 game.favorites.indexOf(userId) === -1
    //             )
    //         )
    //     ) {
    //         if (game.favorites && game.favorites.length>0 && game.favorites.indexOf(userId) === -1) {
    //             game.favorites.push(userId);
    //         } 
    //     } else {
    //         if (game.favorites && !liked) {
    //             const pos = game.favorites.indexOf(userId);
    //             if (pos !== -1) {
    //                 game.favorites.splice(pos, 1);
    //                 changefav=true;
    //             }
    //         }
    //     }
    //     if (game.favorites.length>0 || changefav) //&& game.favorites.indexOf(userId) === -1)
    //      {
    //         console.log("UPDATE GAME: GAMEID",gameId," PLANET OPERATION",game.planetOperation," FAVORITES",game.favorites);
    //         await updateFavorite(gameId, game.planetOperation, game.favorites,userId,currentFavoritePlanetIds,currentFavoritePlanetIdsCount);
    //     }
     */// }
}

const updateHash = (gameId, planetOperation, hashtags) => {
    let hashtag = '';
    hashtags.map(h => {
        return hashtag = hashtag.concat(`"${h}"`);
    });
    return `
    mutation MyMutation {
        updateGame(input: {
            planetOperation: "${planetOperation}",
            idGame: "${gameId}", 
            hashtags: [${hashtag}]}) {
        hashtags
        idGame
        }
    }
    `
}
export const updateHashtags = async (gameId, planetOperation, hashtags) => {

    const game = await API.graphql(
        graphqlOperation(
            updateHash(gameId, planetOperation, hashtags)
        ));

    return game;
}

const updateTag = (gameId, planetOperation, tags) => {
    let tag = '';
    //console.log("UUUUUUUUUUUPDATE TAGGGGG: ",gameId,planetOperation,tags);
    tags.map(t => {
        return tag = tag.concat(`"${t}"`);
    });
    let tagsFilter = '';

   
    tags.map((t, i) => {
        let coma = '';
        if (i > 0) {
            coma = ',';
        }
        return tagsFilter = tagsFilter + (`${coma}${t}`);
    });
    //console.log(tagsFilter);
    return `
    mutation MyMutation {
        updateGame(input: {
            planetOperation: "${planetOperation}",
            idGame: "${gameId}", 
            tags: "${tagsFilter}", 
            tagsFilter: "${tagsFilter}"}) {
        tags
        idGame
        }
    }
    `
}
export const updateTags = async (gameId, planetOperation, tags) => {
    console.log("UUUUUUPPDATETAGSSSS",updateTag(gameId, planetOperation, tags));
    const game = await API.graphql(
        graphqlOperation(
            updateTag(gameId, planetOperation, tags)
        ));
    console.log("RESULT OF UUUUUUPDATE=",game);
    return game;
}

const updateGral = (gameId, planetOperation, generalData) => {
    return `
    mutation MyMutation {
        updateGame(input: {
            planetOperation: "${planetOperation}",
            idGame: "${gameId}", 
            generalData: { 
            date:"${generalData.date}",
            deviceId:"${generalData.deviceId}",
            email:"${generalData.email}",
            emoViewConnectionID:"${generalData.emoViewConnectionID}",
            gameId:"${generalData.gameId}",
            geoloc:{
                latitude:${generalData.latitude || 0},
                longitude:${generalData.longitude || 0},
                accuracy:${generalData.accuracy || 0}
            },
            pngId: "${generalData.pngId}" 
            posnegPlanet:"${generalData.posnegPlanet}",
            username:"${generalData.username}",
        } }) {
            generalData {
                description
            }
            idGame
        }
    }
    `
}
export const updateGeneralData = async (gameId, planetOperation, generalData) => {
    // console.log(gameId, generalData);
    const game = await API.graphql(
        graphqlOperation(
            updateGral(gameId, planetOperation, generalData)
        ));

    return game;
}
