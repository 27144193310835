import { React, useReducer } from "react";
import Navigation from "./routes/Navigation";

import { EmoContext, emoInitialState } from "./services/globalContext";
import { emoReducer } from "./services/globalReducer.js";
import { userReducer } from "./services/userReducer";
import { UserContext, userInitialState } from "./services/userContext";
import { MetricsContext, metricsInitialState } from "./services/metricsContext";
import { metricsReducer } from "./services/metricsReducer";
import {
  PayPerDataContext,
  PayperDataInitialState,
} from "./services/payperDataContext";
import { payperDataReducer } from "./services/payperDataReducer";
import { showVars } from "./core/initVarsAndFunctions";
import { MainProbe } from "./services/Test/mainProbe";
import { mode } from "./mode";

/**
 * App main function.
 *
 * @param {void} none
 * @return {void} none
 */
console.log("APP-MODULE-INIT");

export default function App() {
  console.log("\n\n-----App----");
  console.log(showVars());
  console.log("-----App----\n\n");

  var emoinitial = emoInitialState;
  var userinitial = userInitialState;
  var metricsinitial = metricsInitialState;
  var payperDatainitial = PayperDataInitialState;

  if (sessionStorage.getItem("globalState")) {
    var storageGlobalState = JSON.parse(sessionStorage.getItem("globalState"));
    console.log("GLOBAL STATE PRESENT", storageGlobalState);
    //console.log("GLOBAL STATE PRESENT: JSON",sessionStorage.getItem("globalState"));
    emoinitial = storageGlobalState;
  }

  if (sessionStorage.getItem("userState")) {
    var storageUserState = JSON.parse(sessionStorage.getItem("userState"));
    console.log("USER STATE PRESENT", storageUserState);
    userinitial = storageUserState;
  }

  if (sessionStorage.getItem("metricsState")) {
    var storageMetricsState = JSON.parse(
      sessionStorage.getItem("metricsState")
    );
    console.log("METRICS STATE PRESENT", storageMetricsState);
    metricsinitial = storageMetricsState;
  }

  if (sessionStorage.getItem("payperDataState")) {
    var storagePayperDataState = JSON.parse(
      sessionStorage.getItem("payperDataState")
    );
    console.log("PAYPERDATA STATE PRESENT", storagePayperDataState);
    payperDatainitial = storagePayperDataState;
  }

  const [metricsState, dispatchMetrics] = useReducer(
    metricsReducer,
    metricsinitial
  );

  const [payperDataState, dispatchPayperData] = useReducer(
    payperDataReducer,
    payperDatainitial
  );

  const [globalState, dispatchGlobal] = useReducer(emoReducer, emoinitial);

  const [userState, dispatchUser] = useReducer(userReducer, userinitial);

  

  return (
    <EmoContext.Provider value={[globalState, dispatchGlobal]}>
      <MetricsContext.Provider value={[metricsState, dispatchMetrics]}>
        <PayPerDataContext.Provider value={[payperDataState, dispatchPayperData]}>
          <UserContext.Provider value={[userState, dispatchUser]}>
            <Navigation />
            {mode.modetest ? <MainProbe /> : null}
          </UserContext.Provider>
        </PayPerDataContext.Provider>
      </MetricsContext.Provider>
    </EmoContext.Provider>
  );
}
