import React, { useState, useEffect, useContext } from 'react';
import { PersonalData } from './PersonalData';
import { PlanProfile } from './PlanProfile';
import { HashtagxMatic } from './HashtagxMatic';
import { Button, Segment } from 'semantic-ui-react';
import { UserContext } from '../../services/userContext';
import SubscriptionComponent  from './SubscriptionComponent'

import './ProfileForm.scss';

console.log("PROFILEFORM-COMPONENT-MODULE-INIT");

const ProfileForm = (props) => {
  console.log("---PROFILEFORM-COMPONENT-INIT---");
  const [userState,dispatchUser] = useContext(UserContext);
  var [user, setUser] = useState(null);

  useEffect(() => {
    if(userState.userData){
        console.log("PAYPERDATA-COMPONENT-USEEFFECT-USERDATA",userState.userData)
        setUser(userState.userData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.userData]);

  return (
    <div class="myprofile">
      <PersonalData/>
      <PlanProfile/>
      
      <SubscriptionComponent mode="profile" user={user} />
   
      <Segment>
        <HashtagxMatic/>
      </Segment> 
     
      {/* <Button fluid>Play</Button>         */}
    </div>
  )
}

export default ProfileForm;