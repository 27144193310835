import { React, useContext, useEffect, useState } from "react";
import { Segment, Grid, Divider, Header } from "semantic-ui-react";
import DriversPolarityWindRose from "./Charts/DriversPolarityWindRose";
import DriversDetailWindRose from "./Charts/DriversDetailWindRose";
import TopEmotionsBar from "./Charts/TopEmotionsBar";
import LocationsMap from "./Charts/LocationsMap";
import PolarityByDayArea from "./Charts/PolarityByDayArea";
import PolarityBar from "./Charts/PolarityBar";
import PieTime from "./Charts/PieTime";
import SuperEmotionsBar from "./Charts/SuperEmotionsBar";
import TotalDateHourBox from "./Totals/TotalDateHourBox";
import EmotionsCloud from "./Charts/EmotionsCloud";
import Filters from "./Filters";
import TotalsStatistics from "./Totals/TotalsStatistics";
import TotalsTop from "./Totals/TotalsTop";
import { MetricsContext } from "../../services/metricsContext.js";
import {
  getMetrics,
  prepareMetricsQuery,
} from "../../services/Graphql/Game/queryMetrics.js";
import { UserContext } from "../../services/userContext.js";

console.log("METRICS-DASHBOARD-COMPONENT-MODULE-INIT");

function Dashboard() {
  console.log("----METRICS-DASHBOARD--COMPONENT-INIT---");

  const [metricsState, dispatchMetrics] = useContext(MetricsContext);
  const [dashboardState, setDashboardState] = useState("initial");
  const [userState, dispatchUser] = useContext(UserContext);

  var MD5 = require("md5.js");

  const setDash = async (user) => {
    if (dashboardState === "initial") {
      let query = prepareMetricsQuery(user.userAddressId, -1, false);
      console.log("DASHBOARD QUERY", query);
      let result = await getMetrics(query);
      result.data.getGameMetrics.hashtags =
        result.data.getGameMetrics.hashtags.split(",");
      console.log("DASHBOARD RESULT=", result.data.getGameMetrics);
      setDashboardState("getDashboard");

      dispatchMetrics({
        type: "SET_METRIC_FILTERS",
        payload: result.data.getGameMetrics,
      });

      let queryResp = prepareMetricsQuery(user.userAddressId, 10, "{}");
      console.log("DAHSBOARD QUERYRESP", queryResp);

      var md5stream = new MD5();
      md5stream.end(queryResp);
      var hash = md5stream.read(queryResp).toString("hex");

      var comprobationHash = await comprobateHash(hash);
      console.log("COMPROBATIONHASH", comprobationHash);

      var rmetrics = null;
      var metrics = null;

      if (comprobationHash.result === null) {
        rmetrics = await getMetrics(queryResp);
        console.log("DASHBOARD METRICS RESULT=", rmetrics);
        metrics = JSON.parse(rmetrics.data.getGameMetrics.metricsGame);
        console.log("DASHBOARD METRICS FROMJSON", metrics);
        dispatchMetrics({
          type: "SET_METRIC_DATAHASH",
          payload: {
            hash: hash,
            query: queryResp,
            metricFilters: metricsState.metricFilters,
            metricResults: metrics,
          },
        });
      } else {
        metrics = comprobationHash.result;
        console.log(
          " DASHBOARD >>>>>> DIRECT >>>>>> COMPROBATIONHASH HMETRICS=",
          metrics
        );
      }

      dispatchMetrics({ type: "SET_METRIC_RESULTS", payload: metrics });

      setDashboardState("initialResultsDashboard");
    }
  };

  const comprobateHash = async (hash) => {
    console.log(
      "COMPROBATEHASH: metricState=",
      metricsState,
      " metricState.metricDataHash=",
      metricsState.metricDataHash,
      " metricsState.metricDataHash.length   =",
      metricsState.metricDataHash.length
    );
    if (metricsState.metricDataHash) {
      for (let i = 0; i < metricsState.metricDataHash.length; i++) {
        console.log(
          "COMPROBATE HASH:" +
            hash +
            "METRICSTATE[" +
            i +
            "]=" +
            metricsState.metricDataHash[i].hash
        );
        if (metricsState.metricDataHash[i].hash === hash) {
          return { result: metricsState.metricDataHash[i].metricResults };
        }
      }
    }
    return { result: null };
  };

  useEffect(() => {
    if (userState.userData) {
      setDash(userState.userData);
    }
  }, userState.userData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mymetrics">
      <Grid>
        
        <Grid.Row>
          <Grid.Column>
            <TotalsStatistics metricsState={metricsState} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            {/* <Segment> */}
            <Filters />
            {/* </Segment> */}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <TotalDateHourBox id="totaldatehourbox" metricsState={metricsState} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <TotalsTop metricsState={metricsState}  />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <PolarityByDayArea  metricsState={metricsState} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <PieTime metricsState={metricsState} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <PolarityBar metricsState={metricsState} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <DriversDetailWindRose metricsState={metricsState} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <DriversPolarityWindRose metricsState={metricsState} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <SuperEmotionsBar metricsState={metricsState} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <LocationsMap metricsState={metricsState}  />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <TopEmotionsBar metricsState={metricsState} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <EmotionsCloud metricsState={metricsState} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default Dashboard;
