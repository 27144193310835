import React from 'react';
import "./Error404.scss";
console.log("ERROR404-PAGE-MODULE-INIT");

export default function Error404() {
    console.log("----ERROR404-PAGE-INIT--");
    return (
        <div>
            <h1>ERROR 404 ...</h1>
        </div>
    )
}
