const featureBox1 = [
  "Apply any filter to your planet collection.",
  "Play and share your planets.",
  "For Ever Subscription.",
];
const featureBox2 = [
  "Apply 50 filters wherever you want.",
  "Storage for all your queries.",
  "Priority email support.",
  "Help center access.",
];

const featureBox4 = [
  "Apply any filters wherever you want.",
  "Storage for all your queries.",
  "Phone and email support.",
  "Help center access.",
];

export const pricingData = [
  {
    type: "PRO",
    data: {
      price: "49.95$",
      title: "Pro",
      extra: "Profesional Plan: One Free Month",
      duration: "2 Month",
      counterDown: 100,
      feature: featureBox2,
    },
  },
  {
    type: "FREE",
    data: {
      price: "0.00$",
      title: "Free",
      extra: "Free Plan For Ever",
      duration: "For Ever",
      counterDown: 0,
      feature: featureBox1,
    },
  },
  {
    type: "ENTERPRISE",
    data: {
      price: "Contact us",
      title: "Enterprise",
      extra: "Contact us",
      duration: "Enterprise",
      counterDown: 1000000,
      feature: featureBox4, // email to hello@emotiony.app
    },
  },
];
export const plans = { FREE: 0, PRO: 1, ENTERPRISE: 2 };


