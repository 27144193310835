import { textAlign } from "@mui/system";
import React from "react";
import PricingComponent from './../../components/Pricing/PricingComponent'
import { Container, Segment } from "semantic-ui-react";

console.log("PRICING-PAGE-MODULE-INIT");
function Pricing() {
  console.log("---PRICING-PAGE-INIT---");
  return (
    <Segment> 
      <PricingComponent/>
    </Segment>
  )
}
export default Pricing;

/*
<div className="ui container pricing-loading"
          style={{
            textAlign:"center"
          }}
         >
            <br/><br/><br/>
            <br/><br/><br/>
            <br/><br/><br/>
            <br/><br/><br/>
            P R I C I N G
            <PricingComponent/>
            </div>;
            */