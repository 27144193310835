import { Header } from "semantic-ui-react";

console.log("TERMS-PAGE-MODULE-INIT");

const Terms = () => {
  console.log("---TERMS-PAGE-INIT---");
  return (
    <>
      <Header as="h1">Terms of Service</Header>
    </>
  )
}

export default Terms;