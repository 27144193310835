// IPFS VARS
const pinataApiKey = "88a562986c180faf2121";
const pinataSecretApiKey = "4b9c6b116e107f73770c49b80f1d41d8e9620579aec7e9e2983afef7b43a4970";
const urlIPFS = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
const urlJsonIPFS = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
//AMPLIFY USER
//const awsAmplify={
//  userName:'amplify-v35Hd',  
//  aws_access_key_id:'AKIA2F3G4TIMMUP34SGU',
//  aws_secret_access_key:'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'   
//}

const emotionyApp1S3Planets={
  userName:'emotionyApp1S3Planets',  
  aws_access_key_id:'AKIA2F3G4TIML4ZE2JH4',
  aws_secret_access_key:'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'
}


// BLOCKCHAIN VARS //

var addressEmotionyAsset;
var networkIdNum;
var ownerEmotiony;

// POLYGON MATIC MUMBAI TESTNET
// ACCESSING WITH OLDABIS
networkIdNum=80001;//MUMBAI
//networkIdNum=5777; //GANACHE  
//networkIdNum=3; // ROPSTEN // DESACTIvADA 5 de octubre 2022
// PUBLISHER: ROPSTEN
//0x1465014386d7174885807A89617B3B0f3772EB39 
// PRIVATE KEY
//d1df409d3aef4cc9f4ba9094718d4a3fbafe0d02f748190e332086370a0dbff5

//addressEmotionyAsset="0x907a6fe0c42b5744b91c94498d4d43da3ffc83b2";
//addressEmotionyAsset="0xF1bfa9c4576C9B04d3ade21AD27aE708EDDb37A1";
//addressEmotionyAsset="0xe16bae953fe04068ec8bf273f9df18b0a65765b6"; //QUEDO DESACTIVADA 5 DE OCTUBRE DE 2022



// POLYGON MATIC MAINNET
//addressEmotionyAsset="0x7f911FDaA00896246ecd3A507ec8dc5F907B9FB0"; <-destroyed v2

//addressEmotionyAsset=netWorkDataEmotionyAsset.address;
// OLD ROPTEN ADDRESS OLD CONTRACT
//addressEmotionyAsset='0x607321e276ea6765eb53CAe142DfDef7bC179a8e';
//addressEmotionyAsset='0x3659391995697893Ba242811A1033b644f25e1fF';
// adress ROPSTEN
//addressEmotionyAsset='0x06a33914315DE6bc6814a28EfD1e3Bd74AC995A2'; <-- direccion de viejo contrato
// DIRECCION DE CONTRATO COWSAIL 0xE6D38638527Ddf4F193ed2aDc30071e2d73E54E6 <-- direccion de contrato Complejo Inactivable
// addressEmotionyAsset='0xf1fF76239c548ec6dF38098ED1D3dbc6F3397858'; // <-- direccion de contrato Complejo Inactivable

// adress MATIC TESTNET
addressEmotionyAsset=
//'0x0f06844103694d2d3B5C1479B9917aAF9913764A'; // CONTRATO NO FUNCIONA
'0xDedF5280e5AF63De9c3b8772819fa29CB796Da96'; // SE HA DEPLOYADO DE NUEVO : 2 de Marzo de 2023
ownerEmotiony='0x7B896e74E2282516346e8893DE734949C7453D55';

// address MATIC MAIN NET
//addressEmotionyAsset='0x6dF0889A26863b73C803275001fCf4174414Ed05';

// DUMMY GANACHE ADDRESS
//addressEmotionyAsset='0x9fCE6BF583678Ab642467971CD966f150aC041eF';    //
//
//

var config={
    networkId: networkIdNum,
    contract_address:addressEmotionyAsset,    
    tokenImage: "token.png",
    pinataApiKey : pinataApiKey,
    pinataSecretApiKey : pinataSecretApiKey,
    url : urlIPFS,
    urlJson : urlJsonIPFS,
    emotionyApp1S3Planets : emotionyApp1S3Planets
    
};

export function getConfiguration () {
        
    return config;
}