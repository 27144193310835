import {React,useEffect,useReducer,useContext} from "react";
import Dashboard from "../../components/Metrics/Dashboard";
import UserHeader from "../../components/User/UserHeader";
import { UserContext } from "../../services/userContext";



console.log("METRICS-PAGE-MODULE-INIT");

const Metrics = () => {
  console.log("---METRICS-PAGE-INIT---");
  const [userState, dispatchUser] = useContext(UserContext);

  useEffect (() => {
    console.log("METRICS-PAGE-EFFECT",userState);
  }, [userState.userData]);

  return (
      <div>
         <UserHeader title={"METRICS"} userData={userState.userData} />   
        <Dashboard />
      </div>
  )
}

export default Metrics;

