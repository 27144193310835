import React from "react";
import { Segment } from "semantic-ui-react";
import {
  propsAboutEmotiony,
  propsAboutMoney,
  propsAboutData,
  propsAboutnft
} from "./builderlayoutfaqs";
import PanelsFaqs from "./panelsfaqs";

const AccordionFaqs = () => (
  <>
    <Segment
      style={{
        background: "black",
        color: "grey",
        fontFamily: "Tahoma",
        whiteSpace: "pre-line"
      }}
    >
      <PanelsFaqs data={propsAboutEmotiony} />
      <PanelsFaqs data={propsAboutMoney} />
      <PanelsFaqs data={propsAboutData} />
      <PanelsFaqs data={propsAboutnft} />
    </Segment>
  </>
);

export default AccordionFaqs;
