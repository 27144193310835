import * as THREE from 'three'
import { useRef,  useMemo , useEffect, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import {
  Text,
  TrackballControls,
  MeshDistortMaterial,
  Sphere,
  Stars
} from '@react-three/drei'
import { Object3D, OctahedronGeometry } from 'three'
import { useControls } from 'leva'


console.log("CORE-EMO-SPHERE-COMPONENT-MODULE-INIT");

export function Core(){

  console.log("---CORE-COMPONENT-INIT---");
    
    return (
      <>
        <EmoSphere fibonacci="true" radius="4" key="true" />
        <EmoSphere fibonacci="true" radius="8" />)
      </>
    )
  
}


function EmoSphere({ fibonacci, radius, n1 = 786 }) {

    console.log("---EMO-SPHERE-COMPONENT-INIT---");
    
    const instance = useRef()
  
    const [objects] = useState(() => {
      let points = null
  
      if (fibonacci) {
        const n = n1 // same as 13 detail octa
        const _points = getFibonacciSpherePoints(n, radius)
  
        points = [...new Array(n)].map((_, i) => {
          const o = new Object3D()
  
          if (Math.random() * 180 < 41)
            o.position.set(_points[i].x, _points[i].y, _points[i].z)
            var r=(Math.random() * 180);
          if (r<60) o.scale.set(0.7, 0.7, 0.7)
          else if (r<120) o.scale.set(0.9, 1.0, 0.9)
          else o.scale.set(1.2, 1.1, 1.2)
          return o
        })
      } else {
        const sphere = new OctahedronGeometry(radius, 13)
        const n = sphere.vertices.length
  
        points = [...new Array(n)].map(() => new Object3D())
  
        points.forEach((point, i) => {
          point.position.copy(sphere.vertices[i])
        })
      }
  
      return points
    })
  
    useEffect(() => {
      let id = 0
  
      for (let i = 0; i < objects.length; i++) {
        objects[id].updateMatrix()
        instance.current.setMatrixAt(id, objects[id++].matrix)
      }
      instance.current.instanceMatrix.needsUpdate = true
    })
  
    useFrame(({ clock }) => {
      instance.current.rotation.y = clock.getElapsedTime() / 17
    })
  
    return (
      <instancedMesh ref={instance} args={[null, null, objects.length]}>
        <icosahedronGeometry args={[0.05, 4]} />
        <meshBasicMaterial color="#EEE495" />
      </instancedMesh>
    )
}
  
function getFibonacciSpherePoints(samples = 1, radius = 1, randomize = false) {
    var random = 1
    if (randomize === true) {
      random = Math.random() * samples
    }
  
    const points = []
    const offset = 3 / samples
    const increment = Math.PI * (3 - Math.sqrt(5))
  
    for (let i = 0; i < samples; i++) {
      let y = i * offset - 1 + offset / 2
      const distance = Math.sqrt(1 - Math.pow(y, 2))
      const phi = ((i + random) % samples) * increment
      let x = Math.cos(phi) * distance
      let z = Math.sin(phi) * distance
      x = x * radius
      y = y * radius
      z = z * radius
  
      const point = {
        x: x,
        y: y,
        z: z
      }
      points.push(point)
    }
    return points
  }