import { appParams } from '../services/appParams';

export const getUserDevice = () => {
    if (
        navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    ) {
        appParams.device = 'mobile';
        appParams.filterVisible = false;
        // console.log("You're using Mobile Device!!", appParams.device);
    }
    else {
        appParams.device = 'web';
        appParams.filterVisible = true;
        // console.log(navigator.userAgent, appParams.device, appParams.providersList);
    }
    return appParams.device;
}