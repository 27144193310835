import { Header, Icon, Input } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

console.log("DESCRIPTION-COMPONENT-MODULE-INIT");
const Description = (props) => {
  var {owner} = props;
  console.log("---DESCRIPTION-COMPONENT-INIT---PROPS=",props);
  console.log("---DESCRIPTION-COMPONENT-INIT---OWNER=",owner);
  
  var [gameId, setGameId] = useState(null);
  var [planetOperation, setPlanetOperation] = useState(null);
  var [description, setDescription] = useState("");
  var [edit, setEdit] = useState(false);
 

  useEffect(() => {
   
    if (props.gameData) {
      console.log("DESCRIPTION COMPONENT PROPS.GAMEDATA=",props.gameData);
      setGameId(props.gameData.idGame);
      setPlanetOperation(props.gameData.planetOperation);
      setDescription(props.gameData.description || "");
     
    }
  }, [props]);

  const editDesc = () => {
    if(owner){
      setEdit(true);
    }
  };
  const setDesc = (e, { value }) => {
    setDescription(value);
  };
  const updateDesc = async () => {
    
    props.updateDesc(gameId, planetOperation, description);
    setEdit(false);
  };
  // const clearDesc = () => {
  //   setDescription("");
  // };

  return (
    <>
      <Header as="h2">Description</Header>
      {edit? (
        <>
          <Input
            as="div"
            icon={<Icon name="check" link onClick={updateDesc} />}
            placeholder='Your description...'
            onChange={setDesc}
            value={description}
           
            
          />

          {/* <Icon
            name="close"
            onClick={clearDesc}
            className="pointer"
            disabled={description === "" ? true : false}
          ></Icon> */}
        </>
      ) : (
        <>
          <Input
            as="div"
            icon={<Icon name="edit" link onClick={editDesc} />}
            placeholder='Your description...'
            onChange={setDesc}
            value={description}
            disabled
          />
        </>
      )}
    </>
  );
};

export default Description;
