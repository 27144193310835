import { Accordion, Header, Icon, List } from "semantic-ui-react";
import { useState } from "react";

console.log("HASHTAGS-DETAIL-COMPONENT-MODULE-INIT");

export const HashtagsDetail = (props) => {

  console.log("---HASHTAGS-DETAIL-COMPONENT-INIT---");
  const { hashtag ,owner } = props;
  var slicedhashtagList=null;
  var hashtagList=null;
  if(hashtag){
    slicedhashtagList=hashtag.split(",").slice(0, Math.ceil(hashtag.split(",").length * 0.3));
    hashtagList=hashtag.split(",");

  }
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (event, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <Accordion>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={handleClick}
      >
        <Header as="h2">Hashtags <Icon name="dropdown" /></Header>
      </Accordion.Title>

      <Accordion.Content index={1} active={activeIndex === 0}>
        
          <List>
            {(owner && hashtag)?hashtagList.map((index) => (
              <List.Item key={index} >#{index}</List.Item>
            )):(slicedhashtagList && slicedhashtagList.map((index) => (
                <List.Item key={index} >#{index}</List.Item>
               )))
          }
          {owner?null:<List.Item>...</List.Item>}
          </List>
        
      </Accordion.Content>
    </Accordion>
  );
};
