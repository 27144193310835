import { createContext } from "react";
import { userDefault } from "./userParams";
import {userDataDefault} from "./userDefault"

/*
 -->	userData
    .		.profile
    .		.summary
    .		.blockchainPointer->
 */
console.log("USERLCONTEXT-MODULE-INIT");

export const UserContext = createContext();

var getUserStateFromStorage = () => {
  return JSON.parse(sessionStorage.getItem("userState"));
};

export const userInitialState = {
 
  userData: userDataDefault(),
  session: undefined,
  type: undefined,
  getUserStateFromStorage: getUserStateFromStorage,
};
