import React, { useEffect, useState } from 'react';
import { Container, Segment, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";


//import SearchHeader from './SearchHeader/SearchHeader';
import "./Header.scss";
import BurgerMenu from '../commons/burgerMenu';
import { userService } from '../../services/userParams';
import logoEmotiony from '../../shared/assets/images/logo-emotiony.svg'

console.log("HEADER-COMPONENT-MODULE-INIT");

const Header = (props) => {
  
  console.log("----HEADER-COMPONENT-INIT---");

    var [user, setUser] = useState(null);
    var userDataSubs = undefined;

    const assignUser = async () => {
      userDataSubs = userService.getUserData().subscribe(u => {
        if (u && u.userAddressId) {
          setUser(u);
        } else {
          // res de res
          setUser(null);
        }
      });
    }

    useEffect(() => {
      window.scrollTo(0, 0);
      assignUser();
      return (() => {
        userDataSubs.unsubscribe();
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <header className="topheader">
        <Container fluid>
          <div className="topheader__content">
            <div className="topheader__content__logo">
              <Link to="/">
                <Image id="emotionyLogo" src={logoEmotiony} alt="Emotiony"></Image>
              </Link>
            </div>
          
            <BurgerMenu isMenuOpen={props.isMenuOpen} menuToggle={props.menuToggle} className="topheader__content__burgermenu" />
          </div>
        </Container>
      </header>
    );
}

export default Header;
