import { chargeImageToIPFSpinata1 } from "./Game/chargeImageToIPFSpinata";
import { getTokenizeStatus } from "./userWallet";
import { connectionData } from "./userParams";


export const tokenizeStatus = {
  DONT_READ_STATUS: "Don't read status",
  NO_TOKENIZE: "Not tokenized!",
  GETTING_BLOB: "Getting cors image from S3 to blob",
  SAVING_BLOB: "Saving blob from image",
  CONVERTING_BLOB: "Blob convert base64 to binary",
  SENDING_IMG_TO_IPFS: "Sending image to IPFS network filesystem",
  CREATING_JSON_METADATA: "Creating json metadata from IPFS png response",
  SENDING_JSON_TO_IPFS: "Sending json from image to IPFS network filesystem",
  SENDING_DATA_TO_CONTRACT: "Sending IPFS uri and metadata to contract",
  MINTING_NFT: "Mint the New NFT with PRICE in EmotionyAsset contract",
  TOKENIZED: "Tokenized!!!",
};

export const tokenize = async (
  event,
  planetId,
  setStateTokenize,
  setStateDisableTokenize,
  _reducer
) => {
  var { userState, dispatchUser } = _reducer;

  if (userState.userData.connectionData) {
    let connectionData = userState.userData.connectionData;
    if (userState.userData.isConfigNetWorkId) {
      console.log("TOKENIZE SERVICE: IS CONFIG NETWORK ID at connectionData=",connectionData);
      //manager.chargeImageToIPFSpinata(event,item,setStateTokenize,tokenizeStatus,setStateDisableTokenize);

      await chargeImageToIPFSpinata1(
        event,
        planetId,
        setStateTokenize,
        tokenizeStatus,
        setStateDisableTokenize,
        _reducer
      );
    } else {
      alert(
        `PLEASE CONNECT TO A CORRECT NETWORK: ${connectionData.configNetWork.name} AT NETWORKID: ${connectionData.config.networkId}`
      );
    }
  }
};

export const getTokenizeState = async (
  connectionData,
  planetId,
  setStateDisableTokenize,
  setStateTokenize,
  tokenizeStatus,
  setLabelText
) => {

    if (connectionData) {
        console.log(" TOKENIZE SERVICE:CONNECTIONDATA=", connectionData);
  
        if (connectionData.connectedBlockchain) {
          console.log(
            " TOKENIZE SERVICE: CONNECTED_BLOCKCHAIN=",
            connectionData.connectedBlockchain
          );
  
          await getTokenizeStatus(
            planetId,
            setStateDisableTokenize,
            setStateTokenize,
            tokenizeStatus,
            setLabelText
          );
  
          console.log("TOKENIZE SERVICE:: executed getTokenizeStatus()...");
        }
      }
      else{
        console.log("TOKENIZE SERVICE:: connectionData is undefined",connectionData);
      }

};
