import { Button } from "semantic-ui-react";
import CardPlanet from "../Card/CardPlanet";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../services/userContext";

console.log("GRID-PLANETG-COMPONENT-MODULE-INIT");
var countScroll = 0;
const GridPlanetG = (props) => {
  console.log("---GRID-PLANETG-COMPONENT-INIT---");

  var {
    modeGrid,
    list,
    firstPlanet,
    firstPlanetG,
    setFirstPlanet,
    step,
    numPlanets,
    totalPlanets,
  } = props;

  const [cards, setCards] = useState(null);
  const [columns, setColumns] = useState(null);
  const [userState, dispatchUser] = useContext(UserContext);
  const [loadingAllImages, setLoadingAllImages] = useState(true);
  const [modeGridPlanet, setModeGridPlanet] = useState(null);

  var hasScroll = true;

  console.log(
    "GRID-PLANETG COMPONENT:  FIRSTPLANET=",
    firstPlanet,
    " FIRSTPLANETG=",
    firstPlanetG,
    " TOTALPLANETS=",
    totalPlanets,
    " MODEGRID=",
    modeGrid,
    " LIST=",
    list
  );
  if (modeGridPlanet !== modeGrid) {
    setModeGridPlanet(modeGrid);
  }

  if (modeGrid === "galaxy") {
    firstPlanet = firstPlanetG;
  }

  const handleNext = async () => {
    if (!(firstPlanet + step > totalPlanets - numPlanets)) {
      await setFirstPlanet(firstPlanet + step);
      hasScroll = true;

      console.log(
        "GRID-PLANETG: handlenext",
        numPlanets,
        cards.length,
        "FIRST PLANET=",
        firstPlanet
      );
    }
  };

  const scrollGrid = () => {
    console.log("GRID-PLANETG: scrollGrid", countScroll);

    var allfiltersComponent = document.getElementById("allFilters");

    window.scrollTo({
      top:
        countScroll < 2
          ? 0
          : allfiltersComponent.offsetTop + allfiltersComponent.offsetHeight,
      behavior: "smooth",
    });
  };

  const handleBack = async () => {
    if (!(firstPlanet - step < 0)) {
      await setFirstPlanet(firstPlanet - step);
      hasScroll = true;

      console.log(
        "GRID-PLANETG: handleback",
        numPlanets,
        cards.length,
        "FIRST PLANET=",
        firstPlanet
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    let loaded = 0;
    const images = document.querySelectorAll("img");
    setLoadingAllImages(true);

    function handleImageLoad() {
      loaded++;
      console.log(
        "GRID-PLANETG: handleImageLoad: IMAGES-LENGTH=",
        images.length,
        " LOADED=",
        loaded,
        " LOADING-ALL-IMAGES=",
        loadingAllImages ? "false" : "true"
      );
      if (loaded === images.length) {
        setLoadingAllImages(false);
      }
      if (hasScroll) {
        scrollGrid();
        hasScroll = false;
        countScroll++;
      }
    }

    function handleImageError() {
      loaded++;
      console.log(
        "GRID-PLANETG: handleImageError: IMAGES-LENGTH=",
        images.length,
        " LOADED=",
        loaded,
        " LOADING-ALL-IMAGES=",
        loadingAllImages ? "false" : "true"
      );
      if (loaded === images.length) {
        setLoadingAllImages(false);
      }
    }

    if (props.list) {
      // setting first planet of the list planets and cards items from it

      await dispatchUser({ type: "SET_FIRST_PLANET", payload: firstPlanet });
      await setCards(props.list);
      await setColumns(
        list.map((card, index) => {
          setLoadingAllImages(true);
          loaded = 0;
          return <CardPlanet item={card} key={index + card.idGame} />;
        })
      );

      //select images from control loaded
      setTimeout(() => {
        loaded = 0;
        setLoadingAllImages(true);
        images.forEach((image) => {
          if (
            image.complete &&
            image.naturalWidth > 0 &&
            image.naturalHeight > 0
          ) {
            handleImageLoad();
          } else if (
            image.complete &&
            image.naturalWidth === 0 &&
            image.naturalHeight === 0
          ) {
            handleImageError();
          } else {
            image.addEventListener("load", handleImageLoad);
            image.addEventListener("error", handleImageError);
          }
        });
      }, 300);
    }

    return () => {
      images.forEach((image) => {
        image.removeEventListener("load", handleImageLoad);
        image.removeEventListener("error", handleImageError);
      });
    };
  }, [props, firstPlanet]);

  return (
    <>
      {setLoadingAllImages ? columns : <>Loading ...</>}

      <Button
        disabled={loadingAllImages || firstPlanet === 0}
        floated="left"
        onClick={handleBack}
        className="filled"
      >
        Back
      </Button>
      <Button
        disabled={
          loadingAllImages || firstPlanet + step > totalPlanets - numPlanets
        }
        floated="right"
        onClick={handleNext}
        className="filled"
      >
        Next
      </Button>
    </>
  );
};
export default GridPlanetG;
