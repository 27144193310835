import { Segment } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { emotionyLanguage } from "../../../services/Game/emotionyLanguage";
import Emotion from "./Emotion/Emotion";
import { HashtagsDetail } from "./HashtagsDetail";
import { EmotionsDetail } from "./EmotionsDetail";
import { LocationDetail } from "./LocationDetail";
import { sha256 } from "ethereumjs-util";
import { GuardDuty } from "aws-sdk";

console.log("DETAIL-COMPONENT-MODULE-INIT");
const Detail = (props) => {
  console.log("---DETAIL-COMPONENT-INIT---");
  var [date, setDate] = useState(null);
  var [hashtag, setHashtag] = useState(null);
  var [emotions, setEmotions] = useState("");
  var [activeIndex, setActiveIndex] = useState(null);
  var [location, setLocation] = useState(null);
  var [owner,setOwner]=useState(false);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  const setEmos = () => {
    const emolist = [];
    var emoLang = new emotionyLanguage();
    props.gameData.emotions
      .substring(0, props.gameData.emotions.length - 2)
      .split("],")
      .map((elem, i) => {
        var emobi, em1, em2, value;
        [emobi, value] = elem
          .substring(5)
          .split(",")
          .map((el) => el);
        [em1, em2] = emobi.split("_").map((el) => el);

        return emolist.push({
          emotion: emoLang.getEmotion(em1 - 1, em2 - 1),
          value: parseInt(value),
        });
      });
    emolist.sort((a, b) => b.value - a.value);
    const listEmos = emolist.map((d, i) => {
      if (d.value > 0) {
        return <Emotion key={i} emotion={d.emotion} value={d.value} />;
      } else return null;
    });
    setEmotions(listEmos);
  };
  const getLoc = () => {
    // console.log('geolocation');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  const showPosition = (position) => {
    console.log(
      "Latitude: " +
      position.coords.latitude +
      "<br>Longitude: " +
      position.coords.longitude
    );
    setLocation(
      <div>
        {" "}
        {"Latitude: " + position.coords.latitude} <br />{" "}
        {"Longitude: " + position.coords.longitude}
      </div>
    );
  };
  useEffect(() => {
    if (props.gameData) {
      console.log("DETAIL", props);
      setDate(props.gameData.generalData.date);
      setHashtag(props.gameData.hashtags);
      setEmos();
      setLocation(props.gameData.locationPretty);
      setOwner(props.owner);
      //getLoc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      <Segment textAlign="left">
        <HashtagsDetail hashtag={hashtag} owner={owner}/>
      </Segment>

      <Segment textAlign="left">
        <EmotionsDetail emotions={emotions} owner={owner}/>
      </Segment>
      
      <Segment textAlign="left">
        <LocationDetail date={date} location={location} owner={owner}/>
      </Segment>
      
    </>
  );
};

export default Detail;
