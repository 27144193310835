import { Form, Header, Grid, Segment, Input, Button } from "semantic-ui-react";
import { useState, useContext,useEffect } from "react";
import { UserContext } from "../../services/userContext";
import { setMutationInAwsProfileData } from "../../services/Graphql/User/updateUser";
export const PersonalData = (props) => {
  const [userState, dispatchUser] = useContext(UserContext);
  const [userName, setUserName] = useState("");
  const [userDescription, setUserDescription] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [twitterAccount, setTwitterAccount] = useState("");
  const [instagramAccount, setInstagramAccount] = useState("");
  const [redditAccount, setRedditAccount] = useState("");
  const [discordAccount, setDiscordAccount] = useState("");
  /*
  {
    "userAddressId" : userProfile.data.getMasterUser.userAddressId,
    // GATEWAY: https://gateway.pinata.cloud/ipfs/
    "avatarLink" : userProfile.data.getMasterUser.avatarLink?userProfile.data.getMasterUser.avatarLink.replace("https://ipfs.io/ipfs/", "https://gateway.pinata.cloud/ipfs/", 1):"",
    "discordAccount": userProfile.data.getMasterUser.discordAccount,
    "email" : userProfile.data.getMasterUser.email,
    "hashtags" : ((userSummary.data.getMasterUser.currentHashtags)? userSummary.data.getMasterUser.currentHashtags.split(","): []),
    "instagramAccount" : userProfile.data.getMasterUser.instagramAccount,
    "joined" : userProfile.data.getMasterUser.joined,
    "myGalaxyName" : userProfile.data.getMasterUser.myGalaxyName,
    "publicProfile" : userProfile.data.getMasterUser.publicProfile,
    "realName" : userProfile.data.getMasterUser.user,
    "redditAccount" : userProfile.data.getMasterUser.redditAccount,
    "sessions" : userSummary.data.getMasterUser.currentSessionIds,
    "tags" : ((userSummary.data.getMasterUser.currentTags)? (userSummary.data.getMasterUser.currentTags).toString().split(","): []),
    "twitterAccount" : userProfile.data.getMasterUser.twitterAccount,
    "user" : userProfile.data.getMasterUser.user,
    "userDescription" : userProfile.data.getMasterUser.userDescription,
    "webAddress" : userProfile.data.getMasterUser.webAddress,
    "currentFavoritePlanetIds" : currentFav,
    "currentFavoritePlanetIdsCount" : userSummary.data.getMasterUser.currentFavoritePlanetIdsCount,
    "subscriptionIds" : userSummary.data.getMasterUser.subscriptionIds,
    "subscriptionKeys" : userSummary.data.getMasterUser.subscriptionKeys,
    "queryHashes" : userSummary.data.getMasterUser.queryHashes,
    "currentPublicPlanetIds" : userSummary.data.getMasterUser.currentPublicPlanetIds,
    "currentPublicPlanetsCount" : userSummary.data.getMasterUser.currentPublicPlanetsCount,
    "currentPlanetIds" : (userSummary.data.getMasterUser.currentPlanetIds)?
      userSummary.data.getMasterUser.currentPlanetIds.substring(
        1,userSummary.data.getMasterUser.currentPlanetIds.length-1
        ).split(', '):[],
    "currentPlanetIdsCount" : userSummary.data.getMasterUser.currentPlanetIdsCount,
    "currentHashtagsCount" : userSummary.data.getMasterUser.currentHashtagsCount,
    "currentLocations" : userSummary.data.getMasterUser.currentLocations  
  }
  */

  const handleUserName = (e) => {
    setUserName(e.target.value);
    console.log("PERSONAL DATA: USER NAME: ", e.target.value);
  };

  const handleUserEmail = (e) => {
    setUserEmail(e.target.value);
    console.log("PERSONAL DATA: USER EMAIL: ", e.target.value);
  };

  const handleUserDescription = (e) => {
    setUserDescription(e.target.value);
    console.log("PERSONAL DATA: USER DESCRIPTION: ", e.target.value);
  };

  const handleTwitterAccount = (e) => {
    setTwitterAccount(e.target.value);
    console.log("PERSONAL DATA: TWITTER ACCOUNT: ", e.target.value);
  };

  const handleInstagramAccount = (e) => {
    setInstagramAccount(e.target.value);
    console.log("PERSONAL DATA: INSTAGRAM ACCOUNT: ", e.target.value);
  };

  const handleRedditAccount = (e) => {
    setRedditAccount(e.target.value);
    console.log("PERSONAL DATA: REDDIT ACCOUNT: ", e.target.value);
  };

  const handleDiscordAccount = (e) => {
    setDiscordAccount(e.target.value);
    console.log("PERSONAL DATA: DISCORD ACCOUNT: ", e.target.value);
  };

  const handleSave = () => {
    console.log(
      "PERSONAL DATA: SAVE: ",
      userName,
      userDescription,
      userEmail,
      twitterAccount,
      instagramAccount,
      redditAccount,
      discordAccount
    );
    dispatchUser(
      {"SET_PROFILE_DATA": {
        "userName": userName,
        "userDescription": userDescription,
        "userEmail": userEmail,
        "twitterAccount": twitterAccount,
        "instagramAccount": instagramAccount,
        "redditAccount": redditAccount,
        "discordAccount": discordAccount
      }}
    );
    /*
      "user" : userProfile.data.getMasterUser.user,
      "realName" : userProfile.data.getMasterUser.user,
      */
      setMutationInAwsProfileData(
        // userAction.payload.userName,
        // userAction.payload.userDescription,
        // userAction.payload.userEmail,
        // userAction.payload.twitterAccount,
        // userAction.payload.instagramAccount,
        // userAction.payload.redditAccount,
        // userAction.payload.discordAccount
        userState.userData.userAddressId,
        userName,
        userDescription,
        userEmail,
        twitterAccount,
        instagramAccount,
        redditAccount,
        discordAccount

      );
  };

  useEffect(() => {
    if(userState.userData.userAddressId){
      console.log("UPDATEUSER.JS: PERSONAL DATA: USER DATA: ", userState.userData);
      setUserName(userState.userData.user);
      setUserDescription(userState.userData.userDescription);
      setUserEmail(userState.userData.email);
      setTwitterAccount(userState.userData.twitterAccount);
      setInstagramAccount(userState.userData.instagramAccount);
      setRedditAccount(userState.userData.redditAccount);
      setDiscordAccount(userState.userData.discordAccount);
    }

  }, [userState.userData]);

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Header as="h2">Personal data</Header>

              <Form className='attached fluid'>
                <Form.Field>
                  <label>User name</label>
                  <Input
                    placeholder="User name"
                    value={userName}
                    onChange={handleUserName}
                  />
                </Form.Field>

                <Form.Field>
                  <label>User description</label>
                  <Input
                    placeholder="User description"
                    value={userDescription}
                    onChange={handleUserDescription}
                  />
                </Form.Field>

                <Form.Field>
                  <label>User email</label>
                  <Input
                    placeholder="User email"
                    value={userEmail}
                    onChange={handleUserEmail}
                  />
                </Form.Field>

                <Form.Field> 
                  <label>Twitter account</label>
                  <Input
                    placeholder="Twitter account"
                    value={twitterAccount}
                    onChange={handleTwitterAccount}
                  />
                </Form.Field>

                <Form.Field> 
                  <label>Instagram account</label>
                  <Input
                    placeholder="Instagram account"
                    value={instagramAccount}
                    onChange={handleInstagramAccount}
                  />
                </Form.Field>

                <Form.Field> 
                  <label>Reddit account</label>
                  <Input
                    placeholder="Reddit account"
                    value={redditAccount}
                    onChange={handleRedditAccount}
                  />
                </Form.Field>

                <Form.Field> 
                  <label>Discord account</label>
                  <Input
                    placeholder="Discord account"
                    value={discordAccount}
                    onChange={handleDiscordAccount}
                  />
                </Form.Field>

                <Button onClick={handleSave} fluid size="big" className="filled mt--double">Save</Button>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
